import { makeStyles } from '@material-ui/core/styles'
import { red } from 'shared/theme/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
  },
  typography: {
    fontWeight: 600,
    fontSize: 35,
    fontHeight: 54,
    color: red,
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '37px',
    },
  },
  subtitle1: {
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '25px',
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}))

// TODO: Remove default export
// eslint-disable-next-line import/no-default-export
export default useStyles
