import React, { useContext, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

interface DrawerProviderProps {
  children: React.ReactNode
}

interface DrawerContextType {
  cleanUpDrawerWrap: (el: HTMLDivElement | null) => void
  generateDrawerWrap: () => HTMLDivElement | null
  drawerWrap: HTMLDivElement | null
}

const defaultValue = {
  cleanUpDrawerWrap: () => null,
  generateDrawerWrap: () => null,
  drawerWrap: null,
}

const DrawerContext = React.createContext<DrawerContextType>(defaultValue)

export function DrawerProvider(props: DrawerProviderProps) {
  const drawerWrap = useRef<HTMLDivElement>(null)

  function generateDrawerWrap() {
    const id = uuidv4()
    const el = document.createElement('div')
    el.id = id
    drawerWrap.current?.appendChild(el)
    return el
  }

  function cleanUpDrawerWrap(el: HTMLDivElement | null) {
    el?.remove()
  }

  return (
    <DrawerContext.Provider
      value={{
        cleanUpDrawerWrap,
        generateDrawerWrap,
        drawerWrap: drawerWrap.current,
      }}
    >
      {props.children}
      <div ref={drawerWrap} id="drawers"></div>
    </DrawerContext.Provider>
  )
}

export const useDrawerProvider = () => {
  return useContext(DrawerContext)
}
