import * as React from 'react'
import { Link } from 'react-router-dom'

import { HandlerOf } from '../../helpers/typeHelper'
import { sizes, variants } from '../../theme'
import {
  AnchoredButtonWrap,
  ButtonIconWrap,
  ButtonInner,
  ButtonOuter,
  ButtonPadding,
  ButtonSpinner,
  ButtonStyled,
  ButtonTextWrap,
  HiddenChildren,
} from './Button.styles'

export interface ButtonProps {
  onClick?: HandlerOf<React.MouseEvent>
  children?: React.ReactNode
  className?: string
  isDisabled?: boolean
  fullWidth?: boolean
  smFullWidth?: boolean
  xsFullWidth?: boolean
  type?: 'button' | 'reset' | 'submit'
  size?: keyof typeof sizes
  smSize?: keyof typeof sizes
  xsSize?: keyof typeof sizes
  variant?: keyof typeof variants
  margin?: string
  href?: string
  target?: '_blank'
  mt?: number
  mb?: number
  isInline?: boolean
  isLoading?: boolean
  icon?: React.ReactNode
  iconOnRight?: boolean
  iconColor?: string
  shouldRemoveHorizontalPaddings?: boolean
  align?: 'left'
}

export const Button = React.memo(
  ({
    onClick,
    children,
    className,
    margin,
    href,
    smSize,
    xsSize,
    mt,
    mb,
    isDisabled = false,
    fullWidth = false,
    smFullWidth = false,
    xsFullWidth = false,
    size = sizes.large,
    type = 'button',
    variant = variants.primary,
    target,
    isInline,
    isLoading,
    shouldRemoveHorizontalPaddings,
    icon = null,
    iconOnRight,
    iconColor,
    align,
  }: ButtonProps) => {
    const isIconOnly = Boolean(icon && !children)

    const baseProps = {
      onClick,
      className,
      $fullWidth: fullWidth,
      $smFullWidth: smFullWidth,
      $xsFullWidth: xsFullWidth,
      $size: size,
      $xsSize: xsSize,
      $smSize: smSize,
      $variant: variant,
      $margin: margin,
      $mt: mt,
      $mb: mb,
      $isInline: isInline,
      $hasIcon: Boolean(icon),
      $hasChildren: Boolean(children),
      $isIconOnly: isIconOnly,
      $align: align,
      $isLoading: isLoading,
      $disabled: isDisabled,
    }

    const innerProps = {
      $variant: variant,
      $isInline: isInline,
      $size: size,
      $fullWidth: fullWidth,
      $hasIcon: Boolean(icon),
    }

    function renderChildren() {
      if (isLoading) {
        return (
          <>
            <ButtonSpinner $variant={variant} size={size === 'small' || size === 'medium' ? 16 : 24} />
            <HiddenChildren>{children}</HiddenChildren>
          </>
        )
      }
      if (iconOnRight) {
        return (
          <>
            <ButtonTextWrap>{children}</ButtonTextWrap>
            {icon && (
              <ButtonIconWrap
                $isIconOnly={isIconOnly}
                $variant={variant}
                $color={iconColor}
                $iconOnRight
                $size={size}
              >
                {icon}
              </ButtonIconWrap>
            )}
          </>
        )
      }
      return (
        <>
          {icon && (
            <ButtonIconWrap $isIconOnly={isIconOnly} $variant={variant} $color={iconColor} $size={size}>
              {icon}
            </ButtonIconWrap>
          )}
          <ButtonTextWrap>{children}</ButtonTextWrap>
        </>
      )
    }

    function renderOuterChildren() {
      if (shouldRemoveHorizontalPaddings) {
        return <ButtonInner {...innerProps}>{renderChildren()}</ButtonInner>
      }

      return (
        <ButtonOuter {...innerProps}>
          <ButtonPadding />
          <ButtonInner {...innerProps}>{renderChildren()}</ButtonInner>
          <ButtonPadding />
        </ButtonOuter>
      )
    }

    if (href) {
      const AnchorWrap = isInline ? React.Fragment : AnchoredButtonWrap
      if (href.startsWith('http') || target === '_blank') {
        return (
          <AnchorWrap {...innerProps} $align={align}>
            <ButtonStyled
              {...baseProps}
              as="a"
              href={href}
              target={target}
              data-testid="primary"
              data-size={size}
              data-variant={variant}
            >
              {renderOuterChildren()}
            </ButtonStyled>
          </AnchorWrap>
        )
      }
      return (
        <AnchorWrap {...innerProps} $align={align}>
          <ButtonStyled {...baseProps} as={Link} to={href} data-testid="primary" data-variant={variant}>
            {renderOuterChildren()}
          </ButtonStyled>
        </AnchorWrap>
      )
    }

    return (
      <ButtonStyled
        {...baseProps}
        type={type}
        disabled={isDisabled || isLoading}
        data-testid="primary"
        data-size={size}
        data-variant={variant}
      >
        {renderOuterChildren()}
      </ButtonStyled>
    )
  }
)
