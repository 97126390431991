import * as types from '../actions/apiStatus/actionTypes'

let initState: number = 0

// TODO: Remove default export
// eslint-disable-next-line import/no-default-export
export default function apiCallStatusReducer(state = initState, action: any) {
  if (action.type === types.BEGIN_API_CALL) {
    return state + 1
  } else if (action.type === types.END_API_CALL) {
    return state - 1
  }

  return state
}
