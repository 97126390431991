import '@stripe/stripe-js'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as Sentry from '@sentry/browser'
import { isEmpty } from 'lodash'
import queryString from 'query-string'
import { useEffect, useLayoutEffect, useRef } from 'react'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { generatePath, useHistory } from 'react-router-dom'
import { showErrorBanner } from 'shared/actions/ui/actions'
import { DrawerProvider } from 'shared/components/Drawer/DrawerContext'
import { Spinner } from 'shared/components/Spinner'
import { loadGoogleMapApis } from 'shared/helpers/loadGoogleMapApis'

import { Layout } from 'components/Layout/Layout'
import { AppContainer } from './AppContainer'
import { AppUserProvider } from './components/Context/AppUserProvider'
import { LayoutProvider } from './components/Context/LayoutContext'
import { OnboardingChecklistContext } from './components/Context/OnboardingChecklistContext'
import { REFERRAL_CODE, REFERRAL_SOURCE, REGISTER_INTENDED_PRODUCT_KEY } from './helpers/constants'
import { initAnalytics } from './helpers/initAnalytics'
import { useOnboardingChecklist } from './hooks/query/useOnboardingChecklist'
import { useProductSubscription } from './hooks/useProductSubscription'
import { LOG_IN_REDIRECT } from './pages/Registration/VerifyCode/VerifyCode'
import { getSettings, setGoogleApisLoaded } from './redux/actions/settings/actions'
import { getUserAndTeammateInfo } from './redux/actions/users/actions'
import { currentTeamSelector } from './redux/selectors/team'
import { currentUserSelector, isVendorUserSelector } from './redux/selectors/users'
import { ApplicationState, ReduxDispatch } from './redux/store/models'
import { JOBS_PATH, PROFILE_SUBSCRIPTION_CHANGE_PAYMENT_PATH, REGISTRATION_PATH } from './Routes'

export const App: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const dispatch: ReduxDispatch = useDispatch()
  const history = useHistory()
  const settings = useSelector((state: ApplicationState) => state.settings)
  const isUserDataRefreshed = useRef(false)

  //  flag for nav, bookmarks and job list changes
  const isAuthenticated = useSelector((state: ApplicationState) => state.auth.isAuthenticated)
  const googleApiKey = useSelector((state: ApplicationState) => state.settings.web_google_api_key as string)
  const isVendorUser = useSelector(isVendorUserSelector)
  const currentUser = useSelector(currentUserSelector)
  const currentTeam = useSelector(currentTeamSelector)
  const location = useLocation()
  const isPublicPath =
    location.pathname.includes('registration') ||
    location.pathname.includes('mobile-auth') ||
    location.pathname.includes('job-tracking')
  const params = queryString.parse(location.search)
  const referralCode = params.referral_code as string
  const referralSource = params.referral_source as string
  const shouldFetchChecklist =
    isAuthenticated &&
    !isPublicPath &&
    Boolean(currentTeam?.product_subscription?.product_name) &&
    Boolean(currentUser.id) &&
    !isVendorUser

  const { allChecklistItems, isLoading: isChecklistLoading } = useOnboardingChecklist(currentUser.id, {
    enabled: shouldFetchChecklist,
  })

  const { isStripeSubscriptionPastDue } = useProductSubscription()

  if (referralCode || referralSource) {
    // save any referral info
    localStorage?.setItem?.(REFERRAL_CODE, referralCode)
    localStorage?.setItem?.(REFERRAL_SOURCE, referralSource)
  }

  useLayoutEffect(() => {
    if (isAuthenticated && !isPublicPath && (!isUserDataRefreshed.current || !currentUser.id)) {
      isUserDataRefreshed.current = true
      dispatch(getUserAndTeammateInfo()).then((data) => {
        if (data.vendor_membership) return

        if (!data.team_object?.id) {
          const intendedProduct = localStorage?.getItem?.(REGISTER_INTENDED_PRODUCT_KEY)
          const redirectParam = (queryString.parse(window.location.search).redirectUrl as string) || ''

          if (redirectParam.startsWith('/registration')) {
            localStorage?.removeItem?.(REGISTER_INTENDED_PRODUCT_KEY)
            return history.push(redirectParam)
          }

          history.push(
            intendedProduct ? generatePath(REGISTRATION_PATH, { bundle: intendedProduct }) : '/registration'
          )
        }
      })
    }
  }, [isAuthenticated, isPublicPath, dispatch, currentUser.id, history])

  useEffect(() => {
    if (currentUser?.id) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: currentUser.id,
          team_id: currentTeam?.id,
          is_team_admin: currentUser.is_team_admin,
          is_team_driver: currentUser.is_team_driver,
        })
      })

      const redirectParam = (queryString.parse(window.location.search).redirectUrl as string) || ''
      const noRedirect = !redirectParam || redirectParam === '/'
      if (isAuthenticated && noRedirect && localStorage.getItem(LOG_IN_REDIRECT) === '1') {
        history.push(JOBS_PATH)
      }
      localStorage.removeItem(LOG_IN_REDIRECT)
    }
  }, [
    isAuthenticated,
    currentUser?.id,
    currentUser?.is_team_admin,
    currentUser?.is_team_driver,
    currentTeam?.id,
    history,
  ])

  useEffect(() => {
    if (!isAuthenticated && !isPublicPath) {
      return
    }
    if (isEmpty(settings)) {
      if (isAuthenticated) dispatch(getSettings())
    } else {
      loadGoogleMapApis(googleApiKey, () => {
        dispatch(setGoogleApisLoaded())
      })
    }
  }, [dispatch, settings, googleApiKey, isAuthenticated, isPublicPath])

  useEffect(() => {
    if (isStripeSubscriptionPastDue) {
      dispatch(
        showErrorBanner({
          title: 'Your subscription payment failed',
          message:
            'To regain full access to your Flex Starter subscription, please update your payment details.',
          action: () => history.push(PROFILE_SUBSCRIPTION_CHANGE_PAYMENT_PATH),
          actionLabel: 'Update Payment',
          autoHideDuration: 100000,
        })
      )
    }
  }, [isStripeSubscriptionPastDue, history, dispatch])

  initAnalytics(currentUser, currentTeam)

  if (
    isAuthenticated &&
    !isPublicPath &&
    (!currentUser.id || (!currentTeam.id && !currentUser.vendor_membership))
  ) {
    return <Spinner open={true} />
  }

  if (isChecklistLoading) {
    return <Spinner open={true} />
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <OnboardingChecklistContext.Provider value={{ allChecklistItems }}>
        <AppUserProvider>
          <LayoutProvider>
            <Layout checklist={allChecklistItems}>
              <DrawerProvider>
                <AppContainer>{children}</AppContainer>
              </DrawerProvider>
            </Layout>
          </LayoutProvider>
        </AppUserProvider>
      </OnboardingChecklistContext.Provider>
    </LocalizationProvider>
  )
}
