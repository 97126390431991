import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

import React, { forwardRef } from 'react'
import { DateRangePicker as ReactDateRangePicker } from 'react-date-range'
import styled from 'styled-components'

import { cyanD1, midGray } from '../../theme/colors'
import { DropdownProps } from './Dropdown'
import { DropDownOpener } from './DropdownOpener'

interface DateRangeDropdownOptions
  extends Omit<
    DropdownProps<string>,
    'renderOpener' | 'staticLabel' | 'error' | 'onChange' | 'options' | 'maxHeight' | 'width'
  > {
  placeholder?: string
  disableOptionFilter?: boolean
  children?: React.ReactNode
  width?: number | string
  dateRange: [Date, Date]
  setDateRange: (dateRange: [Date, Date], done: boolean) => void
}

export const DateRangeDropdown = forwardRef(function DateRangeDropdown(
  props: DateRangeDropdownOptions,
  ref: any
) {
  const { children, dateRange, setDateRange } = props
  const [datesSelectedInterim, setDatesSelectedInterim] = React.useState<
    [Date | undefined, Date | undefined]
  >([dateRange[0], dateRange[1]])
  const [secondDateSelected, setSecondDateSelected] = React.useState(false)

  return (
    <StyledDropDownOpener
      {...props}
      options={[
        {
          label: 'Date Range',
          value: 'Date Range',
          renderer: () => (
            <StyledReactDateRangePicker
              key={1}
              minDate={new Date(2019, 0, 1)}
              maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
              ranges={[
                {
                  color: cyanD1,
                  startDate: dateRange[0],
                  endDate: dateRange[1],
                  key: 'selection',
                },
              ]}
              onChange={({ selection }) => {
                setDatesSelectedInterim([selection.startDate, selection.endDate])
                if (selection.startDate && selection.endDate) {
                  const done =
                    secondDateSelected || selection.startDate.getTime() !== selection.endDate.getTime()
                  setDateRange([selection.startDate, selection.endDate], done)
                  setSecondDateSelected(!done)
                }
              }}
            />
          ),
        },
      ]}
      onBlur={() => {
        if (datesSelectedInterim[0] && datesSelectedInterim[1])
          setDateRange([datesSelectedInterim[0], datesSelectedInterim[1]], true)
        props.onBlur?.()
      }}
      onToggle={(open) => {
        setSecondDateSelected(false)
        if (!open && datesSelectedInterim[0] && datesSelectedInterim[1])
          setDateRange([datesSelectedInterim[0], datesSelectedInterim[1]], true)
      }}
      ref={ref}
    >
      {() => children}
    </StyledDropDownOpener>
  )
})

const StyledDropDownOpener = styled(DropDownOpener)`
  .dropdown-opener-options {
    width: auto;
    max-height: none;
  }
`
const StyledReactDateRangePicker = styled(ReactDateRangePicker)`
  & * {
    font-family: Overpass !important;
  }

  .rdrDay {
    height: unset;
    aspect-ratio: 1 / 1;
  }

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrStartEdge,
  .rdrDayStartPreview,
  .rdrDayStartOfWeek > .rdrInRange,
  .rdrDayStartOfWeek > .rdrDayInPreview,
  .rdrEndEdge,
  .rdrDayEndPreview,
  .rdrDayEndOfWeek > .rdrInRange,
  .rdrDayEndOfWeek > .rdrDayInPreview,
  .rdrInRange,
  .rdrDayInPreview {
    top: 4px;
    bottom: 4px;
  }

  .rdrStartEdge,
  .rdrDayStartPreview,
  .rdrDayStartOfWeek > .rdrInRange,
  .rdrDayStartOfWeek > .rdrDayInPreview {
    left: 4px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .rdrEndEdge,
  .rdrDayEndPreview,
  .rdrDayEndOfWeek > .rdrInRange,
  .rdrDayEndOfWeek > .rdrDayInPreview {
    right: 4px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .rdrDayNumber > span::after {
    bottom: 8px;
    background: ${cyanD1};
  }

  .rdrNextPrevButton {
    background: transparent;
  }

  .rdrPprevButton > i,
  .rdrNextButton > i {
    border: solid ${midGray};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
  }

  .rdrPprevButton > i {
    transform: rotate(135deg);
  }

  .rdrNextButton > i {
    transform: rotate(-45deg);
  }
`
