import { forwardRef } from 'react'

export const ContractorPaymentsIcon = forwardRef(function ContractorPaymentsIcon(
  { className }: { className?: string },
  ref: any
) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 18 14"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ marginLeft: '2px' }}
      ref={ref}
    >
      <path
        clipRule="evenodd"
        d="m1.5 13.75c-.4125 0-.765625-.1469-1.059375-.4406-.29375-.2938-.440625-.6469-.440625-1.0594v-10.5c0-.4125.146875-.765625.440625-1.059375s.646875-.440625 1.059375-.440625h15c.4125 0 .7656.146875 1.0594.440625.2937.29375.4406.646875.4406 1.059375v10.5c0 .4125-.1469.7656-.4406 1.0594-.2938.2937-.6469.4406-1.0594.4406zm4.5-5.75c-.55 0-1.02083-.19583-1.4125-.5875s-.5875-.8625-.5875-1.4125.19583-1.02083.5875-1.4125.8625-.5875 1.4125-.5875 1.02083.19583 1.4125.5875.5875.8625.5875 1.4125-.19583 1.02083-.5875 1.4125-.8625.5875-1.4125.5875zm-4 4v-1.4c0-.2833.07292-.5438.21875-.78125.14583-.2375.33958-.41875.58125-.54375.51667-.25833 1.04167-.45208 1.575-.58125s1.075-.19375 1.625-.19375 1.09167.06458 1.625.19375 1.05833.32292 1.575.58125c.24167.125.43542.30625.58125.54375.14583.23745.21875.49795.21875.78125v1.4zm12.0449-1h-1.1218v-.8357c-.5022-.1179-.9108-.33484-1.226-.65091s-.5475-.73125-.6971-1.24553l1.0577-.41786c.1282.43929.3285.76875.601.98839.2724.21965.5849.32947.9375.32947.3525 0 .6543-.08304.9054-.24911s.3766-.42589.3766-.77946c0-.31072-.1309-.5625-.3926-.75536-.2618-.19286-.7292-.4125-1.4023-.65893-.6303-.225-1.0924-.49286-1.3862-.80357-.2938-.31072-.4407-.71786-.4407-1.22143 0-.43929.1496-.83571.4487-1.18929.2992-.35357.7158-.58928 1.25-.70714v-.80357h1.1218v.80357c.3846.03214.7345.1875 1.0497.46607s.5315.60536.649.98036l-1.0256.41786c-.0855-.24643-.2244-.45268-.4167-.61875s-.4594-.24911-.8012-.24911c-.374 0-.6598.08036-.8574.24107-.1977.16072-.2965.38036-.2965.65893s.1229.49821.3686.65893c.2457.16071.6891.34821 1.3301.5625.7693.27857 1.2821.60536 1.5385.98036s.3846.7875.3846 1.2375c0 .63214-.1923 1.10892-.5769 1.43035s-.844.53036-1.3782.62676z"
        fillRule="evenodd"
      />
    </svg>
  )
})
