import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { Dropdown, DropdownProps } from 'shared/components/Dropdown/Dropdown'
import { UserAvatar } from 'shared/components/UserAvatar/UserAvatar'
import styled from 'styled-components'

import { ListUser } from '../../models/user'
import { currentUserSelector } from '../../redux/selectors/users'

interface TeamMembersDropdownExtraProps {
  teamMembers: ListUser[]
  isFullWidth?: boolean
  renderDropdownLabel?: (user: ListUser) => React.ReactNode
}

export type TeamMembersDropdownProps = TeamMembersDropdownExtraProps & Omit<DropdownProps<unknown>, 'options'>

export const UserLabel = styled.div`
  position: relative;
  top: 2px;
`

export const renderUserDropdownLabel = (userName: string) => {
  return (
    <FlexBox gap="10px" alignItems="center">
      <UserAvatar userName={userName} width="24px" height="24px" borderWidth="1px" fontSize="9px" />
      <UserLabel>{userName}</UserLabel>
    </FlexBox>
  )
}

export const TeamMembersDropdown = forwardRef(function TeamMembersDropdown(
  { teamMembers, renderDropdownLabel, ...rest }: TeamMembersDropdownProps,
  ref: any
) {
  const currentUser = useSelector(currentUserSelector)

  const options = teamMembers.map((user) => ({
    value: user.id,
    dropDownLabel: renderDropdownLabel
      ? renderDropdownLabel(user)
      : renderUserDropdownLabel(
          `${user.first_name} ${user.last_name}${user.id === currentUser.id ? ` (You)` : ''}`
        ),
    label: `${user.first_name} ${user.last_name}${user.id === currentUser.id ? ` (You)` : ''}`,
  }))

  return <Dropdown ref={ref} {...rest} options={options} />
})
