import { css } from 'styled-components'

import { breakpoints } from './breakpoints'
import { primaryBlack } from './colors'

const header = css`
  font-family: Overpass;
  font-style: normal;
  font-weight: 600;
  color: ${primaryBlack};
`

export const header0 = css`
  ${header};
  font-size: 48px;
  line-height: 1.53;

  @media ${breakpoints.md} {
    font-size: 40px;
  }

  @media ${breakpoints.sm} {
    font-size: 35px;
  }
`

export const header1 = css`
  ${header};
  font-size: 30px;
  line-height: 1.53;

  @media ${breakpoints.sm} {
    font-size: 28px;
  }
`

export const header2 = css`
  ${header};
  font-size: 24px;
  line-height: 1.54;

  @media ${breakpoints.sm} {
    font-size: 22px;
  }
`

export const header3 = css`
  ${header};
  font-size: 16px;
  line-height: 1.56;

  @media ${breakpoints.sm} {
    font-size: 14px;
  }
`

export const header4 = css`
  ${header};
  font-size: 14px;
  line-height: 1.5;
`

// replacement for h4 design wanted to use interim
export const header5 = css`
  ${header};
  font-size: 12px;
  line-height: 1.5;
`

// 20 px is not a mistake, it's a replacement for h1 design wanted to use interim
export const header6 = css`
  ${header};
  font-size: 20px;
  line-height: 1.5;
`

export const paragraph = css`
  font-family: Overpass;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.56;
  color: ${primaryBlack};
`

export const paragraph0 = css`
  ${paragraph};
  font-size: 18px;
  line-height: 1.56;

  @media ${breakpoints.sm} {
    font-size: 16px;
  }
`

export const paragraph1 = css`
  ${paragraph};
  font-size: 16px;
  line-height: 1.56;

  @media ${breakpoints.sm} {
    font-size: 14px;
  }
`

export const paragraph2 = css`
  ${paragraph};
  font-size: 14px;
  line-height: 1.5;
`

export const paragraph3 = css`
  ${paragraph};
  font-size: 12px;
  line-height: 1.5;
`

const label = css`
  font-family: Overpass;
  color: ${primaryBlack};
`

export const label1 = css`
  ${label};
  font-size: 20px;
  line-height: 1.55;
`

export const label2 = css`
  ${label};
  font-size: 16px;
  line-height: 1.56;

  @media ${breakpoints.sm} {
    font-size: 14px;
  }
`

export const label3 = css`
  ${label};
  font-size: 14px;
  line-height: 1.5;
`

export const label4 = css`
  ${label};
  font-size: 12px;
  line-height: 1.5;
`

const uppercaseLabel = css`
  font-family: Overpass;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: ${primaryBlack};
`

export const uppercaseLabel1 = css`
  ${uppercaseLabel};
  font-size: 16px;
  line-height: 1.4;

  @media ${breakpoints.sm} {
    font-size: 13px;
  }
`

export const uppercaseLabel2 = css`
  ${uppercaseLabel};
  font-size: 13px;
  line-height: 1.53;

  @media ${breakpoints.sm} {
    font-size: 11px;
  }
`

export const uppercaseLabel2noResponsive = css`
  ${uppercaseLabel};
  font-size: 13px;
  line-height: 1.53;
`

export const uppercaseLabel3 = css`
  ${uppercaseLabel};
  font-size: 12px;
  line-height: 1.5;
`
