import { Authentication } from 'models/models'
import * as types from '../actions/auth/authActionTypes'

let initState: Authentication = { userContact: '', isAuthenticated: false }

// TODO: Remove default export
// eslint-disable-next-line import/no-default-export
export default function authReducer(state = initState, action: any) {
  switch (action.type) {
    case types.SET_USER_CONTACT:
      return { ...state, userContact: action.contact }
    case types.USER_AUTHENTICATION_SUCCESS:
      return { ...state, isAuthenticated: true }
    case types.LOGOUT_USER:
      return { ...state, isAuthenticated: false }
    default:
      return state || initState
  }
}
