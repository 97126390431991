import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@material-ui/core'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Grid } from 'shared/components/Base/Base'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { Button } from 'shared/components/Button/Button'
import { Checkbox } from 'shared/components/Checkbox/Checkbox'
import { TextAreaInput } from 'shared/components/TextInput/TextAreaInput'
import { Handler } from 'shared/helpers/typeHelper'
import { breakpoints } from 'shared/theme/breakpoints'
import { bgGray } from 'shared/theme/colors'
import styled from 'styled-components'
import { mixed, object } from 'yup'

interface ConsultantMessageFeedbackFormProps {
  onSubmit: (values: ConsultantMessageFeedbackFormValues) => Promise<void>
  onCancel: Handler
}

export interface ConsultantMessageFeedbackFormValues {
  feedbackContent: string
  inaccurate: boolean
  offensive: boolean
  notHelpful: boolean
  other: boolean
}

const Schema = () =>
  object().shape({
    feedbackContent: mixed().test(function (val) {
      if (!val && this.parent.other) {
        return this.createError({ message: 'This field is required.' })
      }

      return true
    }),
    inaccurate: mixed(),
    offensive: mixed(),
    notHelpful: mixed(),
    other: mixed(),
  })

const initialForm = {}

export function ConsultantMessageFeedbackForm({ onSubmit, onCancel }: ConsultantMessageFeedbackFormProps) {
  const [isLoading, setIsLoading] = useState(false)
  const {
    control,
    handleSubmit: _handleSubmit,
    formState: { errors },
  } = useForm<ConsultantMessageFeedbackFormValues>({
    mode: 'onSubmit',
    defaultValues: initialForm,
    resolver: yupResolver(Schema()),
  })

  async function handleSubmit(values: ConsultantMessageFeedbackFormValues) {
    setIsLoading(true)
    await onSubmit(values)
    setIsLoading(false)
  }

  return (
    <Wrap>
      <form onSubmit={_handleSubmit(handleSubmit)}>
        <Controller
          render={({ field, fieldState }) => (
            <StyleTextAreaInput
              {...field}
              label="Why did you dislike the message?"
              error={fieldState.error?.message}
              height="100px"
              noMargin
            />
          )}
          name="feedbackContent"
          control={control}
        />

        <Box mt={errors.feedbackContent ? 2 : 0} />

        <Grid columns="auto 1fr" gap="40px">
          <div>
            <Controller
              render={({ field, fieldState }) => (
                <Checkbox {...field} label="Inaccurate" error={fieldState.error?.message} />
              )}
              name="inaccurate"
              control={control}
            />
            <Box mt={-2}>
              <Controller
                render={({ field, fieldState }) => (
                  <Checkbox {...field} label="Not Helpful" error={fieldState.error?.message} />
                )}
                name="notHelpful"
                control={control}
              />
            </Box>
          </div>
          <div>
            <Controller
              render={({ field, fieldState }) => (
                <Checkbox {...field} label="Offensive" error={fieldState.error?.message} />
              )}
              name="offensive"
              control={control}
            />
            <Box mt={-2}>
              <Controller
                render={({ field, fieldState }) => (
                  <Checkbox {...field} label="Other" error={fieldState.error?.message} />
                )}
                name="other"
                control={control}
              />
            </Box>
          </div>
        </Grid>

        <Box mt={2} />
        <FlexBox justifyContent="flex-end">
          <FlexBox alignItems="center" justifyContent="center" gap="20px">
            <StyleCancelButton variant="text" onClick={onCancel}>
              Cancel
            </StyleCancelButton>
            <Button type="submit" size="small" isLoading={isLoading}>
              Send
            </Button>
          </FlexBox>
        </FlexBox>
      </form>
    </Wrap>
  )
}

const Wrap = styled.div`
  margin-top: 10px;
  padding: 24px;
  background: ${bgGray};
  border-radius: 6px;

  @media ${breakpoints.sm} {
    padding: 15px;
  }
`

const StyleTextAreaInput = styled(TextAreaInput)`
  width: 100%;
  max-width: 100%;

  label {
    white-space: nowrap;
  }
`

const StyleCancelButton = styled(Button)`
  font-size: 14px;
  margin-top: 2px;
`
