import PostAddIcon from '@material-ui/icons/PostAdd'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { Box } from 'shared/components/Box/Box'
import { Button } from 'shared/components/Button/Button'
import { TabLink } from 'shared/components/TabLink/TabLink'
import { useBreakpoint } from 'shared/hooks/useBreakpoint'
import { breakpoints } from 'shared/theme/breakpoints'
import styled, { css } from 'styled-components'

import { ToolbarPortal, useFixedNav } from '../../components/Context/LayoutContext'
import { useJobFilters } from '../../hooks/useJobFilters'
import { usePermission } from '../../hooks/usePermission'
import { useProductSubscription } from '../../hooks/useProductSubscription'
import { PermissionType } from '../../models/permission'
import {
  CONTACTED_LOADS_PATH,
  EXTERNAL_BOOK_PATH,
  JOBS_PAST_PATH,
  JOBS_PATH,
  JOBS_TEMPLATES_PATH,
} from '../../Routes'
import { ExternalBookInstructionsModal } from '../ExternalBook/PremiumFactoringExternalBook/ExternalBookInstructionsModal'

export function JobsNav() {
  const history = useHistory()
  const location = useLocation()
  const isMobile = useBreakpoint('xs')
  const { isFlexStarter } = useProductSubscription()
  const { renderFiltersDropdown, renderSelectedFilters } = useJobFilters()
  const [isExternalBookingOpen, setIsExternalBookingOpen] = useState(false)
  const canSeeLineTemplates = usePermission(PermissionType.LANE_TEMPLATES).enabled

  useFixedNav()

  return (
    <ToolbarPortal>
      <div>
        <Box mt={4} smMt={3} />
        <StyledNav
          alignItems="center"
          justifyContent="space-between"
          $canSeeJobTemplates={canSeeLineTemplates}
        >
          <StyledNavLeft>
            <TabsContainer>
              <FlexBox gap="32px" smGap="20px">
                <TabLink
                  onClick={() => history.push({ pathname: JOBS_PATH, search: location.search })}
                  $isSelected={location.pathname === JOBS_PATH}
                >
                  Your Jobs
                </TabLink>

                <TabLink
                  onClick={() => history.push({ pathname: JOBS_PAST_PATH, search: location.search })}
                  $isSelected={location.pathname === JOBS_PAST_PATH}
                >
                  Past
                </TabLink>

                <TabLink
                  onClick={() => history.push({ pathname: CONTACTED_LOADS_PATH, search: location.search })}
                  $isSelected={location.pathname === CONTACTED_LOADS_PATH}
                >
                  Negotiating
                </TabLink>
              </FlexBox>

              {renderFiltersDropdown()}
            </TabsContainer>
            {canSeeLineTemplates && (
              <Button variant="text" href={JOBS_TEMPLATES_PATH}>
                Manage templates
              </Button>
            )}
          </StyledNavLeft>
          <Button
            variant="primary"
            size="large"
            margin="2px 0 0 0"
            onClick={() =>
              isFlexStarter ? history.push(EXTERNAL_BOOK_PATH) : setIsExternalBookingOpen(true)
            }
            shouldRemoveHorizontalPaddings={isMobile}
            icon={<PostAddIcon />}
          >
            {isMobile ? null : 'Add load'}
          </Button>
        </StyledNav>
        {isExternalBookingOpen && (
          <ExternalBookInstructionsModal onClose={() => setIsExternalBookingOpen(false)} />
        )}
        <Box mt={3} />
        {renderSelectedFilters()}
        <Box mt={1} />
      </div>
    </ToolbarPortal>
  )
}

const StyledNav = styled(FlexBox)<{ $canSeeJobTemplates?: boolean }>`
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  ${(p) =>
    p.$canSeeJobTemplates &&
    css`
      @media ${breakpoints.sm} {
        align-items: flex-start;
      }
    `}
`

const StyledNavLeft = styled(FlexBox)`
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  @media ${breakpoints.sm} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`
