import UIBox, { BoxProps } from '@material-ui/core/Box'
import styled, { css } from 'styled-components'

import { containerQuery } from '../../helpers/utils'
import { muiTheme } from '../../theme'
import { breakpoints } from '../../theme/breakpoints'

const StyledBox = styled(UIBox)<{ $xsMt?: number; $smMt?: number }>`
  ${(p) =>
    p.$smMt &&
    containerQuery(
      breakpoints.sm,
      css`
        margin-top: ${muiTheme.spacing() * p.$smMt}px;
      `
    )}

  ${(p) =>
    p.$xsMt &&
    containerQuery(
      breakpoints.xs,
      css`
        margin-top: ${muiTheme.spacing() * p.$xsMt}px;
      `
    )}
`

export const Box = ({ xsMt, smMt, ...rest }: { xsMt?: number; smMt?: number } & BoxProps) => (
  <StyledBox {...rest} $xsMt={xsMt} $smMt={smMt} />
)
