const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/

export function isHex(value: string) {
  return hexRegex.test(value)
}

export function hexWithOpacity(hex: string, alpha: number) {
  const isAlphaValid = typeof alpha === 'number' && alpha >= 0 && alpha <= 1
  const opacity = isAlphaValid ? alpha : 1

  if (!isHex(hex)) return 'rgb(0,0,0,1)'

  const r = `0x${hex[1]}${hex[2]}`
  const g = `0x${hex[3]}${hex[4]}`
  const b = `0x${hex[5]}${hex[6]}`

  return `rgb(${+r},${+g},${+b},${opacity})`
}
