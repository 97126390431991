import { logout, requestLoginToken, verifyLoginToken } from 'api/authApi'
import { setVerifyRequestIsInitiated } from '../../../pages/Registration/VerifyCode/VerifyCode'
import { ReduxDispatch } from '../../store/models'
import * as apiActions from '../apiStatus/actions'
import * as actionTypes from './authActionTypes'

export const forceClientLogout = () => ({
  type: actionTypes.LOGOUT_USER,
})

export const setUserContact = (contact: string) => ({
  type: actionTypes.SET_USER_CONTACT,
  contact,
})

export const userAuthenticationSuccess = () => ({
  type: actionTypes.USER_AUTHENTICATION_SUCCESS,
})

export const getLoginToken = (contact: string) => (dispatch: ReduxDispatch) => {
  contact = contact.trim().toLowerCase()
  dispatch(setUserContact(contact))
  dispatch(apiActions.beginApiCall())
  return requestLoginToken(contact)
    .then(() => {
      setVerifyRequestIsInitiated()
      dispatch(apiActions.endApiCall())
    })
    .catch((error) => {
      dispatch(apiActions.endApiCall())
      throw error
    })
}

export const authenticateUser = (loginToken: string, userContact: string) => (dispatch: ReduxDispatch) => {
  dispatch(apiActions.beginApiCall())
  return verifyLoginToken(loginToken, userContact)
    .then(() => {
      dispatch(apiActions.endApiCall())
      dispatch(userAuthenticationSuccess())
    })
    .catch((error) => {
      dispatch(apiActions.endApiCall())
      throw error
    })
}

export const logoutUser = () => (dispatch: ReduxDispatch) => {
  dispatch(apiActions.beginApiCall())
  return logout()
    .then(() => {
      dispatch(apiActions.endApiCall())
      // logout resets state so we shouldn't
      // dispatch any actions after this
      dispatch(forceClientLogout())
    })
    .catch((error) => {
      dispatch(apiActions.endApiCall())
      throw error
    })
}
