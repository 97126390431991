import { forwardRef, HTMLAttributes, Ref } from 'react'
import styled, { css, IStyledComponent } from 'styled-components'

import { AddPrefixToObject } from '../../helpers/typeHelper'
import { containerQuery } from '../../helpers/utils'
import { muiTheme } from '../../theme'
import { breakpoints } from '../../theme/breakpoints'

interface GridProps {
  columns?: string
  smColumns?: string
  xsColumns?: string
  alignItems?: 'flex-start' | 'center' | 'flex-end'
  smAlignItems?: 'flex-start' | 'center' | 'flex-end'
  xsAlignItems?: 'flex-start' | 'center' | 'flex-end'
  justifyItems?: 'start' | 'center' | 'end' | 'normal'
  xsJustifyItems?: 'start' | 'center' | 'end' | 'normal'
  smJustifyItems?: 'start' | 'center' | 'end' | 'normal'
  gap?: string
  smGap?: string
  xsGap?: string
  styles?: string
  mt?: number
  mb?: number
}

const StyledGrid = styled.div<AddPrefixToObject<GridProps, '$'>>`
  display: grid;

  ${(p) =>
    p.$alignItems &&
    css`
      align-items: ${p.$alignItems};
    `};

  ${(p) =>
    p.$justifyItems &&
    css`
      justify-items: ${p.$justifyItems};
    `};

  ${(p) =>
    p.$columns &&
    css`
      grid-template-columns: ${p.$columns};
    `};

  ${(p) =>
    p.$gap &&
    css`
      gap: ${p.$gap};
    `};

  ${(p) =>
    p.$mt &&
    css`
      margin-top: ${muiTheme.spacing() * p.$mt}px;
    `};

  ${(p) =>
    p.$mb &&
    css`
      margin-bottom: ${muiTheme.spacing() * p.$mb}px;
    `};

  ${(p) =>
    p.$smGap &&
    containerQuery(
      breakpoints.sm,
      css`
        gap: ${p.$smGap};
      `
    )}

  ${(p) =>
    p.$smColumns &&
    containerQuery(
      breakpoints.sm,
      css`
        grid-template-columns: ${p.$smColumns};
      `
    )}

  ${(p) =>
    p.$smAlignItems &&
    containerQuery(
      breakpoints.sm,
      css`
        align-items: ${p.$smAlignItems};
      `
    )}

  ${(p) =>
    p.$smJustifyItems &&
    containerQuery(
      breakpoints.sm,
      css`
        justify-items: ${p.$smJustifyItems};
      `
    )}

  ${(p) =>
    p.$xsGap &&
    containerQuery(
      breakpoints.xs,
      css`
        gap: ${p.$xsGap};
      `
    )}

  ${(p) =>
    p.$xsColumns &&
    containerQuery(
      breakpoints.xs,
      css`
        grid-template-columns: ${p.$xsColumns};
      `
    )}

  ${(p) =>
    p.$xsAlignItems &&
    containerQuery(
      breakpoints.xs,
      css`
        align-items: ${p.$xsAlignItems};
      `
    )}

  ${(p) =>
    p.$xsJustifyItems &&
    containerQuery(
      breakpoints.xs,
      css`
        justify-items: ${p.$xsJustifyItems};
      `
    )}

  ${(p) => p.$styles};
`

export const Grid = forwardRef(
  (
    {
      columns,
      smColumns,
      xsColumns,
      alignItems,
      smAlignItems,
      xsAlignItems,
      justifyItems,
      smJustifyItems,
      xsJustifyItems,
      gap,
      smGap,
      xsGap,
      styles,
      mt,
      mb,
      ...rest
    },
    ref: Ref<any>
  ) => {
    return (
      <StyledGrid
        $columns={columns}
        $smColumns={smColumns}
        $xsColumns={xsColumns}
        $alignItems={alignItems}
        $smAlignItems={smAlignItems}
        $xsAlignItems={xsAlignItems}
        $justifyItems={justifyItems}
        $smJustifyItems={smJustifyItems}
        $xsJustifyItems={xsJustifyItems}
        $gap={gap}
        $smGap={smGap}
        $xsGap={xsGap}
        $styles={styles}
        $mt={mt}
        $mb={mb}
        ref={ref}
        {...rest}
      />
    )
  }
) as IStyledComponent<'web', GridProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>
