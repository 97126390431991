import { CoreDocument, CoreDocumentAttachment, CoreDocumentStatus } from 'shared/models/document'

export type { CoreDocument, CoreDocumentAttachment }
export { CoreDocumentStatus }

export enum DocumentType {
  BANK_DOCUMENT = 'BANK_DOCUMENT',
}

export interface Attachment<T extends Record<string, any>> {
  id: string
  attachment_file: string
  mimetype: string
  original_filename: string
  attachment_type: string
  metadata: T
  review_status: CoreDocumentStatus
  version_number: number
  status: CoreDocumentStatus
  created_by: string
  updated_by: string
}

export interface Document<T extends Record<string, any>> {
  document_attachments: Attachment<T>[]
  document_attachment_versions: Attachment<T>[]

  created_by: string
  date_created: string
  date_effective: string | null
  date_expired: string
  date_updated: string
  description: string
  document_name: string
  document_type: string
  folder_name: string
  id: string
  is_ct_managed_document: boolean
  metadata: T
  related_models: Record<string, string>
  status: CoreDocumentStatus
  updated_by: string
}

function parseCoreDocumentAttachment<T extends Record<string, any>>(
  a: Attachment<T>
): CoreDocumentAttachment<T> {
  return {
    attachmentFile: a.attachment_file,
    attachmentType: a.attachment_type,
    id: a.id,
    metadata: a.metadata,
    mimetype: a.mimetype,
    originalFilename: a.original_filename,
    reviewStatus: a.review_status,
    status: a.status,
  }
}

export function parseCoreDocument<T extends Record<string, any>>(
  d: Document<T> | undefined
): CoreDocument<T> | null {
  if (!d) return null

  return {
    createdBy: d.created_by,
    dateCreated: d.date_created,
    dateEffective: d.date_effective,
    dateExpired: d.date_expired,
    description: d.description,
    documentAttachmentVersions: d.document_attachment_versions.map(parseCoreDocumentAttachment),
    documentAttachments: d.document_attachments.map(parseCoreDocumentAttachment),
    documentName: d.document_name,
    documentType: d.document_type,
    id: d.id,
    isCtManagedDocument: d.is_ct_managed_document,
    metadata: d.metadata,
    status: d.status,
    isValidStatus: d.status === CoreDocumentStatus.VALID,
    firstAttachmentFilename: d.document_attachments[0]?.original_filename,
  }
}
