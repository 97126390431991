import { errorD1 } from 'shared/theme/colors'
import { centeredContent } from 'shared/theme/mixins'
import { header2, header3, label1, paragraph1, paragraph2 } from 'shared/theme/typography'
import styled from 'styled-components'

export const Container = styled.div`
  ${centeredContent};
  flex-direction: column;
  width: 670px;
  margin: 0 auto;

  @media only screen and (max-width: 680px) {
    width: 400px;
    max-width: 100%;
    text-align: center;
  }
`

export const Title = styled.h2`
  ${header2}
  margin: 20px 0 40px;
  text-align: center;
`

export const Contact = styled.h3`
  ${header3};
  margin: 0;
`

export const Paragraph1 = styled.p`
  ${paragraph1};
  margin: 0;
  margin-bottom: 8px;
`

export const Paragraph2 = styled.p`
  ${paragraph2};
  margin: 0;
`

export const ErrorMessage = styled.div`
  ${label1};
  color: ${errorD1};
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
`
