import { forwardRef } from 'react'
import * as React from 'react'

import { Handler, HandlerOf } from '../../helpers/typeHelper'
import { warningD1 } from '../../theme/colors'
import { Text } from '../Text/Text'
import { Arrow, DropdownContainer, ErrorMessage, Label, Select, StaticLabel } from './Dropdown.styles'
import { DropDownOpener } from './DropdownOpener'

const DEFAULT_LABEL = 'Select'

export interface DropdownOption<T> {
  label: React.ReactNode
  value?: T
  dropDownLabel?: React.ReactNode
  onClick?: Handler
  renderer?: (d: DropdownOption<T>, closeOption: Handler) => React.ReactNode
}

export interface DropdownProps<T> {
  options: Array<DropdownOption<T>>
  onChange?: HandlerOf<T>
  onBlur?: Handler
  // If dropdown is used inside a form, react-hook-form will override the onBlur handler, this is a fallback
  onNonFormBlur?: Handler
  onToggle?: HandlerOf<boolean>
  value?: T
  className?: string
  isInitiallyOpen?: boolean
  closeOnClick?: boolean
  error?: React.ReactElement | string | boolean
  warning?: string | React.ReactElement
  label?: React.ReactNode
  optionsLabel?: React.ReactNode
  emptyOptionsLabel?: React.ReactNode
  staticLabel?: React.ReactNode
  noMargin?: boolean
  isFullWidth?: boolean
  dataTestId?: string
  bottom?: number
  width?: number | string
  containerMaxWidth?: string
  align?: 'left' | 'right'
  position?: 'top' | 'bottom'
  maxHeight?: number
  backgroundColor?: string
  labelColor?: string
  isDisabled?: boolean
  optionsMargin?: string
  fontSize?: string
  renderOpener?: (isOpen: boolean) => React.ReactNode
  formatLabel?: (label: string) => React.ReactNode
  addOptionsFilter?: boolean
  bottomAction?: React.ReactNode
}

export const Dropdown = forwardRef(function Dropdown<T>(props: DropdownProps<T>, ref: any) {
  const {
    options,
    value,
    className,
    error,
    warning,
    label,
    staticLabel,
    isFullWidth,
    dataTestId,
    renderOpener,
    formatLabel,
    noMargin,
    backgroundColor,
    labelColor,
    isDisabled,
    containerMaxWidth,
  } = props

  const selectedOption = options.find((option) => option.value === value)
  const selectedLabel: React.ReactNode = (selectedOption ? selectedOption.label : label) ?? DEFAULT_LABEL
  const showstaticLabel = Boolean(staticLabel && selectedOption)

  return (
    <DropdownContainer
      className={className}
      $noMargin={noMargin}
      $isFullWidth={isFullWidth}
      $minWidth="0"
      $maxWidth={containerMaxWidth}
    >
      <DropDownOpener {...props} ref={ref}>
        {(isOpen) =>
          renderOpener ? (
            renderOpener(isOpen)
          ) : (
            <>
              <Select
                className="dropdown-select"
                data-testid={dataTestId || 'select'}
                $isOpen={isOpen}
                $hasError={Boolean(error)}
                $backgroundColor={backgroundColor}
                $isDisabled={isDisabled}
                $hasWarning={Boolean(warning && !error)}
              >
                <Label
                  className="dropdown-label"
                  $isSelected={!!selectedOption}
                  $hasStaticLabel={showstaticLabel}
                  $labelColor={labelColor}
                >
                  {formatLabel && typeof selectedLabel === 'string'
                    ? formatLabel(selectedLabel)
                    : selectedLabel}
                </Label>
                {isDisabled ? null : <Arrow $isOpen={isOpen} />}
              </Select>
              {showstaticLabel && <StaticLabel> {staticLabel} </StaticLabel>}
              {warning && !error && (
                <Text variant="label3" fontColor={warningD1}>
                  {warning}
                </Text>
              )}
            </>
          )
        }
      </DropDownOpener>
      {error && error !== true && <ErrorMessage>{error}</ErrorMessage>}
    </DropdownContainer>
  )
})
