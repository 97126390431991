import { flatten } from 'lodash'
import { useEffect } from 'react'
import { useInfiniteQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { CacheKeys } from '../../helpers/cacheKeys'
import { parseQueryParams } from '../../helpers/utils'
import { fetchTrucks } from '../../models/truck'
import { currentUserSelector } from '../../redux/selectors/users'

interface UseTrucksProps {
  shouldFetchAllPages?: boolean
  enabled?: boolean
}

export function useTrucks({ shouldFetchAllPages, enabled }: UseTrucksProps = {}) {
  const user = useSelector(currentUserSelector)

  const { data, fetchNextPage, hasNextPage, isFetching, refetch, isLoading } = useInfiniteQuery(
    `${CacheKeys.TRUCKS}-${user.id}`,
    ({ pageParam = 1 }) => fetchTrucks(pageParam),
    {
      enabled,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => (lastPage.next ? parseQueryParams(lastPage.next).page : null),
    }
  )

  const trucks = flatten(data?.pages.map((page) => page.results))

  useEffect(() => {
    if (shouldFetchAllPages && !isFetching && hasNextPage && enabled) {
      fetchNextPage()
    }
  }, [shouldFetchAllPages, isFetching, hasNextPage, fetchNextPage, enabled])

  return {
    trucks,
    refetch,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  }
}
