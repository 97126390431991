import axios from 'axios'
import * as HttpStatus from 'http-status-codes'
import { BillingPeriod } from 'shared/models/productSubscription'
import {
  Feature,
  FeatureId,
  parseUpcomingInvoice,
  Product,
  ProductPrice,
  UpcomingInvoice,
} from 'shared/models/subscription'

import { ProductSubscription } from './productSubscription'

export interface StripeSubscription {
  created: number | null
  createdDate: Date | null
  currentPeriodEnd: number | null
  currentPeriodEndDate: Date | null
  trialEnd: number | null
  trialEndDate: Date | null
  cancelAt: number | null
  cancelAtDate: Date | null
  canceledAt: number | null
  canceledAtDate: Date | null
  paymentMethod?: StripeSubscriptionPaymentMethod
  upcomingInvoice?: UpcomingInvoice
}

type PaymentMethodCheck = 'pass' | 'fail' | 'unavailable' | 'unchecked'

export interface StripeSubscriptionPaymentMethod {
  type: 'card'
  brand:
    | 'American Express'
    | 'Diners Club'
    | 'Discover'
    | 'JCB'
    | 'MasterCard'
    | 'UnionPay'
    | 'Visa'
    | 'Unknown'
  last4: string
  addressLine1Check: PaymentMethodCheck
  addressPostalCodeCheck: PaymentMethodCheck
  cvcCheck: PaymentMethodCheck
}

export interface SubscriptionSessionInfo {
  trial: {
    trialPeriodDays?: number
    trialEnd?: number
    trialEndDate?: Date
  }
  prices: ProductPrice[]
}

export function parseProduct(data: any): Product {
  return {
    id: data.id,
    featureId: data.feature_id,
    name: data.name,
  }
}

export function parsePrice(data: any) {
  return {
    id: data.id,
    amount: data.amount,
    billingPeriod: data.billing_period,
    default: data.default,
    product: data.product ? parseProduct(data.product) : null,
    recurring: data.recurring,
    description: data.description,
    maxChargedSeats: data.max_charged_seats,
  }
}

export function getStripeSubscriptionSessionInfo(): Promise<SubscriptionSessionInfo> {
  return axios.get(`/api/v1/teams/stripe/checkout-session/`).then((response) => ({
    trial: {
      trialPeriodDays: response.data.trial?.trial_period_days,
      trialEnd: response.data.trial?.trial_end,
      trialEndDate: response.data.trial?.trial_end
        ? new Date(response.data.trial?.trial_end * 1000)
        : undefined,
    },
    prices: response.data.prices.map(parsePrice),
  }))
}

export function getDefaultPriceForProduct(
  prices: ProductPrice[],
  feature: FeatureId,
  hasAnnualBilling?: boolean
) {
  return prices.find(
    (price) =>
      price.product.featureId === feature &&
      price.recurring === 'RECURRING' &&
      price.billingPeriod === (hasAnnualBilling ? BillingPeriod.YEARLY : BillingPeriod.MONTHLY) &&
      price.default
  )
}

export function createStripeBillingPortalSessionLink({ redirectPath }: { redirectPath: string }) {
  return axios
    .post(`/api/v1/teams/stripe/billing-portal-session/`, { redirect_path: redirectPath })
    .then((response) => response.data.billing_portal_url)
}

function parsePaymentMethod(data: any): StripeSubscriptionPaymentMethod {
  return {
    type: data.type,
    brand: data.brand,
    last4: data.last4,
    addressLine1Check: data.address_line1_check,
    addressPostalCodeCheck: data.address_postal_code_check,
    cvcCheck: data.cvc_check,
  }
}

export function getStripeSubscription(): Promise<StripeSubscription> {
  return axios.get(`/api/v1/teams/stripe/subscription/`).then((response) => ({
    created: response.data.created,
    createdDate: response.data.created ? new Date(response.data.created * 1000) : null,
    currentPeriodEnd: response.data.current_period_end,
    currentPeriodEndDate: response.data.current_period_end
      ? new Date(response.data.current_period_end * 1000)
      : null,
    trialEnd: response.data.trial_end,
    trialEndDate: response.data.trial_end ? new Date(response.data.trial_end * 1000) : null,
    cancelAt: response.data.cancel_at,
    cancelAtDate: response.data.cancel_at ? new Date(response.data.cancel_at * 1000) : null,
    canceledAt: response.data.canceled_at,
    canceledAtDate: response.data.canceled_at ? new Date(response.data.canceled_at * 1000) : null,
    paymentMethod: response.data.payment_method
      ? parsePaymentMethod(response.data.payment_method)
      : undefined,
    upcomingInvoice: parseUpcomingInvoice(response.data.upcoming_invoice),
  }))
}

export function getProduct(productSubscription: ProductSubscription, featureId: FeatureId) {
  return productSubscription.items.find((item) => item.product.featureId === featureId)
}

export function createSetupIntent() {
  return axios
    .post(`/api/v1/teams/stripe/create-setup-intent/`)
    .then((response) => ({ setupIntentClientSecret: response.data.setup_client_secret }))
}

export function startFlexSubscription(payload: { quantity: number; payment_method: string }) {
  return axios.post(`/api/v1/teams/stripe/start-flex-subscription/`, payload)
}

export function updatePaymentMethod({ token }: { token: string }) {
  return axios.post(`/api/v1/teams/stripe/subscription/update-payment-method/`, { token })
}

export function retrievePaymentMethod(payload: { payment_method: string }) {
  return axios
    .post(`/api/v1/teams/stripe/payment-method-details/`, payload)
    .then((response) => parsePaymentMethod(response.data.payment_method))
}

export enum PaymentHoldStatus {
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_ACTION = 'requires_action',
  PROCESSING = 'processing',
  REQUIRES_CAPTURE = 'requires_capture',
  CANCELED = 'canceled',
  SUCCEEDED = 'succeeded',
}

interface StripeSubscriptionQuantityPreview {
  amount: string
  amountInCents: number
}

export function getStripeSubscriptionQuantityPreview({
  feature,
}: {
  feature: FeatureId
}): Promise<StripeSubscriptionQuantityPreview | null> {
  return axios
    .get(`/api/v1/teams/stripe/subscription/change-quantity/?feature=${feature}`)
    .then((response) => {
      if (response.status === HttpStatus.OK) {
        return {
          amount: response.data.amount,
          amountInCents: response.data.amount_in_cents,
        }
      }

      return null
    })
}

export interface SubscriptionCancelReasons {
  options: Record<string, string>
  detailRequired: string[]
}

export function getSubscriptionCancelReasons(): Promise<SubscriptionCancelReasons> {
  return axios.get(`/api/v1/teams/stripe/subscription/cancel/`).then((response) => {
    return {
      options: response.data.options,
      detailRequired: response.data.detail_required,
    }
  })
}

interface CancelSubscriptionPayload {
  reasons: string[]
  reasons_detail?: string
}

export function cancelSubscription(payload: CancelSubscriptionPayload) {
  return axios.post(`/api/v1/teams/stripe/subscription/cancel/`, payload)
}

export function renewSubscription() {
  return axios.post(`/api/v1/teams/stripe/subscription/stop-cancel/`)
}

export type { Product, ProductPrice }
export { FeatureId, Feature }
