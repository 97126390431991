import { makeStyles, SvgIconProps } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { bluefa, cyan, royalBlue } from '../../theme/colors'
import { centeredContent } from '../../theme/mixins'

export interface OutlinedIconBaseProps {
  variant?: 'outlined' | 'filled'
  className?: string
  isLarge?: boolean
}

export interface OutlinedIconIProps {
  Icon: (props: SvgIconProps) => JSX.Element
  Image?: never
}

export interface OutlinedIconSrcProps {
  Image: React.ReactNode
  Icon?: never
}

type OutlinedIconProps = OutlinedIconIProps | OutlinedIconSrcProps

const useStyles = makeStyles(() => ({
  base: {
    width: '65px',
    height: '65px',
    padding: '10px',
    borderRadius: '50%',
    display: 'block',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },

  flex: {
    display: 'flex',
  },

  outlinedIcon: {
    color: royalBlue,
    background: bluefa,
  },

  filledIcon: {
    color: cyan,
    border: `3px solid ${cyan}`,
    background: 'white',
  },

  large: {
    width: '96px',
    height: '96px',
  },
}))

export function OutlinedIcon({
  Icon,
  variant,
  className = '',
  Image,
  isLarge,
}: OutlinedIconBaseProps & OutlinedIconProps) {
  const classes = useStyles()
  const isFilled = variant === 'filled'

  if (Image) {
    return (
      <div
        className={`${classes.base} ${classes.flex} ${className} ${
          isFilled ? classes.filledIcon : classes.outlinedIcon
        } ${isLarge ? classes.large : ''}`}
      >
        {Image}
      </div>
    )
  }

  if (Icon) {
    return (
      <Icon
        className={`${classes.base} ${className} ${isFilled ? classes.filledIcon : classes.outlinedIcon} ${
          isLarge ? classes.large : ''
        }`}
      />
    )
  }

  return null
}

const StyledOutlinedImgIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledOutlinedImgIcon = styled.div`
  ${centeredContent};
  background-color: white;
  border-radius: 50px;
`

export function OutlinedImgIcon({ src }: { src: string }) {
  return (
    <StyledOutlinedImgIconWrap>
      <StyledOutlinedImgIcon>
        <img src={src} alt="" />
      </StyledOutlinedImgIcon>
    </StyledOutlinedImgIconWrap>
  )
}
