export enum CreditApplicationStatus {
  // initial state for team
  NOT_STARTED = 'NOT_STARTED',
  // team signed agreement, in onboarding process
  IN_PROGRESS = 'IN_PROGRESS',
  // approved teams are good to book loads in app
  IN_REVIEW = 'IN_REVIEW',
  // team is ineligible for credit
  INELIGIBLE = 'INELIGIBLE',
  // credit application was approved by risk team
  APPROVED = 'APPROVED',
  // team has accepted credit application
  ACCEPTED = 'ACCEPTED',
  // credit application was canceled by applicant
  CANCELED = 'CANCELED',
  // credit terms were declined by applicant
  DECLINED = 'DECLINED',
  // credit application was rejected by risk team
  REJECTED = 'REJECTED',
}
