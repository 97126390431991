import { snackbarsState } from 'shared/reducers/snackbarsReducer'

import { Snackbar, SnackBarReduxProps } from './Snackbar'

export function InfoSnackbar({ onClose, useSelector }: SnackBarReduxProps) {
  const { infoSnackbarOpen, message, title, footer, action, actionLabel, actionRenderer, autoHideDuration } =
    useSelector(({ snackbars }: { snackbars: snackbarsState }) => snackbars)

  return (
    <Snackbar
      type="info"
      isOpen={infoSnackbarOpen}
      title={title}
      message={message}
      footer={footer}
      action={action}
      actionLabel={actionLabel}
      actionRenderer={actionRenderer}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    />
  )
}
