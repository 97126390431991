import * as H from 'history'
import { Button } from 'shared/components/Button/Button'
import { DropdownButton } from 'shared/components/Dropdown/DropdownButton'
import { SlimTeam } from 'shared/models/team'

import { OnboardingChecklist } from '../../../../models/onboarding'
import { ProductSubscriptionBundleName } from '../../../../models/productSubscription'
import {
  ONBOARDING_EQUIPMENT_ADD_TRAILER_PATH,
  ONBOARDING_EQUIPMENT_ADD_TRUCK_PATH,
} from '../../../../Routes'

export function getOnboardingEquipmentAddCta(task: OnboardingChecklist, history: H.History, team: SlimTeam) {
  const label = `Add ${task.subitems?.length > 0 ? 'More' : ''}`

  if (team?.productSubscriptionName === ProductSubscriptionBundleName.PREMIUM) {
    const dropdownOptions = [
      {
        label: 'Truck',
        onClick: () => history.push(ONBOARDING_EQUIPMENT_ADD_TRUCK_PATH),
      },
      {
        label: 'Trailer',
        onClick: () => history.push(ONBOARDING_EQUIPMENT_ADD_TRAILER_PATH),
      },
    ]

    return <DropdownButton options={dropdownOptions} label={label} />
  }

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    history.push(ONBOARDING_EQUIPMENT_ADD_TRUCK_PATH)
  }

  return (
    <Button size="medium" variant="secondary" onClick={onClick}>
      {label}
    </Button>
  )
}
