import { forwardRef, HTMLAttributes, Ref } from 'react'
import styled, { css, IStyledComponent } from 'styled-components'

import { AddPrefixToObject } from '../../helpers/typeHelper'
import { muiTheme } from '../../theme'
import { breakpoints } from '../../theme/breakpoints'
import { dividerGray } from '../../theme/colors'

const StyledDivider = styled.div<AddPrefixToObject<DividerProps, '$'>>`
  ${(p) =>
    p.$vertical
      ? `border-left: ${p.$borderWidth ?? '1px'} solid ${dividerGray};`
      : `border-top: ${p.$borderWidth ?? '1px'} solid ${dividerGray};`}

  ${(p) => p.$width && `width: ${p.$width};`}
  ${(p) => p.$height && `height: ${p.$height};`}
  ${(p) => p.$color && `border-color: ${p.$color};`}
  ${(p) => p.$margin && `margin: ${p.$margin};`}

  ${(p) =>
    p.$mt &&
    css`
      margin-top: ${muiTheme.spacing() * p.$mt}px;
    `};

  ${(p) =>
    p.$mb &&
    css`
      margin-bottom: ${muiTheme.spacing() * p.$mb}px;
    `};

  @media ${breakpoints.xs} {
    ${(p) =>
      p.$xsmt &&
      css`
        margin-top: ${muiTheme.spacing() * p.$xsmt}px;
      `};

    ${(p) =>
      p.$xsmb &&
      css`
        margin-bottom: ${muiTheme.spacing() * p.$xsmb}px;
      `};
  }
`

export interface DividerProps {
  mt?: number
  mb?: number
  xsmt?: number
  xsmb?: number
  width?: string
  height?: string
  color?: string
  borderWidth?: string
  margin?: string
  vertical?: boolean
}

export const Divider = forwardRef(
  ({ mt, mb, xsmt, xsmb, width, height, color, borderWidth, margin, vertical, ...rest }, ref: Ref<any>) => {
    return (
      <StyledDivider
        $mt={mt}
        $mb={mb}
        $xsmt={xsmt}
        $xsmb={xsmb}
        $width={width}
        $height={height}
        $color={color}
        $borderWidth={borderWidth}
        $margin={margin}
        $vertical={vertical}
        ref={ref}
        {...rest}
      />
    )
  }
) as IStyledComponent<'web', DividerProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>

export const StyledVerticalDivider = styled.div<{ $height?: string }>`
  height: 24px;
  width: 1px;
  display: inline-block;
  border-right: 1px solid ${dividerGray};
  ${(p) => p.$height && `height: ${p.$height};`}
`

interface VerticalDividerProps {
  height?: string
}

export const VerticalDivider = forwardRef(({ height, ...rest }, ref: Ref<any>) => {
  return <StyledVerticalDivider $height={height} ref={ref} {...rest} />
}) as IStyledComponent<'web', VerticalDividerProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>
