import { EquipmentType } from 'shared/models/equipment'
import {
  BookingTypes,
  DocumentType,
  JobsFilter,
  JobStatus,
  JobStatusDetail,
  JobTrackingType,
} from 'shared/models/job'
import {
  BookingTypeOption,
  ContactedLoadStatus,
  DistanceTypes,
  LoadBookingSources,
  LoadBookingTypes,
  LoadStopLoadingType,
  LoadStopProgressStatus,
  LoadStopType,
  LoadStopTypeDetail,
  LoadTag,
  TrackingStatus,
} from 'shared/models/load'

import {
  ANYWHERE,
  DRIVER_CLOSING_TEXT_BLOCKING_MULTIPLE_DRIVERS,
  DRIVER_CLOSING_TEXT_BLOCKING_SINGLE_DRIVER,
  DRIVER_CLOSING_TEXT_NON_BLOCKING_CURRENT_USER,
  DRIVER_CLOSING_TEXT_NON_BLOCKING_OTHER_USER,
  DRIVER_CONFLICT_GENERIC_BLOCKING_ERROR_TEXT,
  DRIVER_CONFLICT_GENERIC_NON_BLOCKING_ERROR_TEXT,
  DRIVER_CONFLICT_TEXT_CURRENT_USER,
  DRIVER_CONFLICT_TEXT_OTHER_USER,
} from '../helpers/constants'
import { JobInvoice } from './jobInvoice'
import { ConnectedIntegrationStatusEnum, DropdownItem, FeasibilityStatus, SortType, User } from './models'

export { EquipmentType, BookingTypeOption, TrackingStatus, LoadStopProgressStatus }

export interface Load {
  id: string
  original_source_id?: string
  posted_date: string | null
  trip_stops: string
  source: string
  provider_display_name: string
  comment?: string
  contact_phone?: string
  contact_phone_booking: string
  contact_email: string
  contact_email_booking: string
  truck_length_ft: number
  equipment_detail?: string
  equipment: EquipmentType[]
  trip_distance_mi: number
  truck_weight_lb: number
  contact_name: string
  total_deadhead_mi: number
  source_id: string
  source_human_id: string
  truck_full_load: boolean
  instant_book: boolean
  standard_book: boolean
  offline_book: boolean
  trip_rate: number
  broker_name?: string | null
  broker_mc_number?: string | null
  broker_load_id?: string | null
  company_name: string
  estimated_rate: number
  estimated_rate_min: number
  estimated_rate_max: number
  estimated_revenue_per_hour: number
  prex_scores: number
  bid_book: boolean
  stops: LoadStop[]
  date_booked?: string
  origin_deadhead_mi?: number
  integration_status?: ConnectedIntegrationStatusEnum
  origin_pickup_date?: string
  dest_delivery_date?: string
  bid_min?: number
  bid_max?: number
  masked_data?: boolean
  age_min?: number | null
  broker?: string
  load_tags?: LoadTag[] | null
  bookmarked_load_id?: string
  contacted_load_id?: string

  is_contacted: boolean
  booking_types: LoadBookingTypeOption[]
  source_detail_link?: string
  details: {
    origin: LoadStop
    hasEstimates: boolean
    tripRate: number
    tripRatePerMile: string
    loadEquipment: string
    ctRange: string | null
    ctEstimate: string | null
    loadOriginLocation: string
    destination: LoadStop
    loadDestLocation: string
  }
}

export interface LoadBookingTypeOption {
  type: BookingTypeOption
  error: null | 'link_provider' | 'mc_number' | 'dot_number'
}

// Temporary alias
export type LoadDetail = Load

export interface LocationVector {
  latitude: number
  longitude: number
  timestamp: string
}

export interface ScheduleEvent {
  drive_date_end: string
  off_duty_hours: number
  on_duty_hours: number
  total_hours: number
  schedule_id: string
}

export interface LoadStopProgress {
  date_arrived: string
  date_departed: string
  date_estimated_arrival: string
  date_updated: string
  is_current: boolean
  status: LoadStopProgressStatus
  stop: LoadStop
  tracking_last_location_vector: LocationVector
  last_schedule_event: ScheduleEvent
}

export interface LoadStop {
  date_end: string
  date_start: string
  date_end_timezone_name: string
  date_start_timezone_name: string
  is_appointment_confirmed: boolean
  loading_type: LoadStopLoadingType | null
  location_address1: string
  location_address2: string
  location_city: string
  location_lat: number
  location_long: number
  location_name?: string
  location_state: string
  location_timezone: string
  location_zip: string
  sequence_number: number
  notes: string
  type: LoadStopType
  reference_code: string
  truck_weight_lb: number
  type_detail: LoadStopTypeDetail
  trailer_number: string
}

export interface ContactedLoad {
  id: string
  status: ContactedLoadStatus
  load: LoadDetail
  bid_amount: number
  assigned_to: string
}

export type SearchHandler = (options?: { async: boolean; forcePayload?: QueryFilter }) => void

export interface QueryFilter {
  origin_location?: string
  origin_range_mi__max: number
  origin_pickup_date__min: string
  dest_location?: string
  dest_range_mi__max?: number
  equipment?: EquipmentType[]
  total_pay__min?: number
  trip_rate__min?: number
  sort_type?: SortType
  booking_type: string
  trip_distance_mi__max?: number
  trip_distance_mi__min?: number
  trip_distances?: string[]
  date_queried?: string
  hide_sort_info?: boolean
  load_providers?: Array<string>
  age_min__min?: number
  age_min__max?: number
  truck_weight_lb__max?: number
  requested_states?: string[]
  is_offline_book_compatible?: boolean
  masked_data?: boolean
  async?: boolean
}

export interface JobsSearchFilter {
  driver?: string | null
  truck?: string | null
  date_range_start?: string | null
  date_range_end?: string | null
  broker?: string | null
}

const DEFAULT_RADIUS = 50
const today = new Date()
const DEFAULT_EQUIPMENT = [
  EquipmentType.POWER_ONLY,
  EquipmentType.DRY_VAN,
  EquipmentType.FLATBED,
  EquipmentType.REEFER,
]
export const ALL_DISTANCES = [DistanceTypes.SHORT, DistanceTypes.LOCAL, DistanceTypes.LONG]
export const initialQueryFilter = {
  origin_location: '',
  dest_location: ANYWHERE,
  dest_range_mi__max: DEFAULT_RADIUS,
  origin_range_mi__max: DEFAULT_RADIUS,
  equipment: DEFAULT_EQUIPMENT,
  origin_pickup_date__min: today.toString(),
  trip_distances: ALL_DISTANCES,
  booking_type: LoadBookingTypes.ALL,
}

export interface BookmarkedLoad {
  date_created: string
  id: string
  load: LoadDetail
}

export interface JobsFilters {
  status: JobStatus
  date?: string
  driver?: User
  statusDetail?: JobStatusDetail
}

export interface AssignedUser {
  id: string
  first_name: string
  last_name: string
}

export interface AssignedTruck {
  id: string
  vin_number: string
}

export interface Job {
  assigned_to?: AssignedUser
  assigned_truck?: AssignedTruck
  booking_type: BookingTypes
  broker_policy: string
  confirmed_rate: number
  created_by: string
  notes: string
  date_created: string
  date_updated: string
  human_id: string
  id: string
  status: JobStatus
  load: Load
  statements: Statement[]
  status_detail: JobStatusDetail
  human_status_detail: string
  human_status_detail_color: string
  documents?: Document[]
  user_submitted_bid_max?: number
  user_submitted_bid_min?: number
  is_canceled: boolean
  is_factored: boolean
  is_approved: boolean
  is_delivery_rejected: boolean
  is_in_progress: boolean
  show_cancel_action: boolean
  show_request_cancellation_action: boolean
  show_complete_action: boolean
  show_delete_action: boolean
  show_edit_job_action: boolean
  show_remove_action: boolean
  show_report_issue_action: boolean
  show_upload_document_action: boolean
  show_assign_to_action: boolean
  show_tracking_action: boolean
  show_create_invoice_action: boolean
  show_notify_factoring_company_action: boolean

  latest_location_vector?: LocationVector
  load_stop_progresses?: LoadStopProgress[]
  tracking_current_load_stop_progress?: LoadStopProgress
  tracking_status?: TrackingStatus
  tracking_type?: JobTrackingType
  sent_by_email?: boolean

  invoices: JobInvoice[]
}

// temporary alias
export type JobDetail = Job

export interface BookingFeasibleResponse {
  user: User
  is_feasible: boolean
  load: string
  load_stop: string | null
  flags: Flag[]
}

export interface Flag {
  code: ConflictCode | WarningCode | InfoCode | BookingCode
  message: string
  type: FlagType
}

export enum ConflictCode {
  APPOINTMENT_TIME = 'APPOINTMENT_TIME',
  HOS_OVERRUN = 'HOS_OVERRUN',
  HOURS_FROM_HOME = 'HOURS_FROM_HOME',
  LATEST_RETURN_DATE = 'LATEST_RETURN_DATE',
  ON_DUTY_OVERRUN = 'ON_DUTY_OVERRUN',
  OVERLAPPING_WINDOW = 'OVERLAPPING_WINDOW',
  PICKUP_TIME = 'PICKUP_TIME',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

const conflictText = {
  [ConflictCode.HOS_OVERRUN]: 'nearing an HOS violation',
  [ConflictCode.OVERLAPPING_WINDOW]: 'already assigned to another load around the same time',
  [ConflictCode.APPOINTMENT_TIME]: 'too far from the pickup or delivery location to arrive on time',
} as Record<ConflictCode, string>

export const getDriverConflictItems = (driverName: string | null, response?: BookingFeasibleResponse[]) => {
  let conflictCodes: ConflictCode[] = []
  if (response && response.length > 0) {
    conflictCodes = response[0].flags
      .filter((flag) => flag.code in ConflictCode)
      .map((flag) => flag.code) as ConflictCode[]

    // If OVERLAPPING_WINDOW and APPOINTMENT_TIME are both present, remove APPOINTMENT_TIME since it becomes redundant
    if (
      conflictCodes.includes(ConflictCode.OVERLAPPING_WINDOW) &&
      conflictCodes.includes(ConflictCode.APPOINTMENT_TIME)
    ) {
      conflictCodes = conflictCodes.filter((code) => code !== ConflictCode.APPOINTMENT_TIME)
    }
  }

  const conflictMessages = conflictCodes
    .map((code) => {
      const prefix = driverName === null ? "You're" : driverName
      const conflictMessage = conflictText[code]
      return conflictMessage !== undefined
        ? `${prefix} ${driverName === null ? '' : 'is'} ${conflictMessage}`
        : ''
    })
    .filter((message) => message !== '')

  return conflictMessages
}

export const getDriverConflictText = (
  feasibilityStatus: FeasibilityStatus,
  conflictItems: string[],
  currentUserIsDriver: boolean
) => {
  // if the flag causing the issue is not one we deal with individually, display generic error
  if (conflictItems.length === 0) {
    if (feasibilityStatus === FeasibilityStatus.ADMIN_DRIVER_CONFLICT) {
      return DRIVER_CONFLICT_GENERIC_BLOCKING_ERROR_TEXT
    }
    return DRIVER_CONFLICT_GENERIC_NON_BLOCKING_ERROR_TEXT
  }
  return currentUserIsDriver ? DRIVER_CONFLICT_TEXT_CURRENT_USER : DRIVER_CONFLICT_TEXT_OTHER_USER
}

export const getDriverClosingText = (
  feasibilityStatus: FeasibilityStatus,
  conflictItems: string[],
  currentUserIsDriver: boolean,
  multipleDrivers: boolean
) => {
  const isCurrentUser = currentUserIsDriver && feasibilityStatus
  const isBlocking =
    feasibilityStatus === FeasibilityStatus.ADMIN_DRIVER_CONFLICT ||
    feasibilityStatus === FeasibilityStatus.DRIVER_BLOCKING_CONFLICT
  // if the flag causing the issue is not one we deal with individually, there is no closing error text
  if (conflictItems.length === 0) {
    return ''
  }
  if (!isBlocking) {
    return isCurrentUser
      ? DRIVER_CLOSING_TEXT_NON_BLOCKING_CURRENT_USER
      : DRIVER_CLOSING_TEXT_NON_BLOCKING_OTHER_USER
  } else {
    return multipleDrivers
      ? DRIVER_CLOSING_TEXT_BLOCKING_MULTIPLE_DRIVERS
      : DRIVER_CLOSING_TEXT_BLOCKING_SINGLE_DRIVER
  }
}

export enum WarningCode {
  INVALID_LOAD = 'INVALID_LOAD',
  UNCONFIRMED_LOAD = 'UNCONFIRMED_LOAD',
}

export enum InfoCode {
  OTHER = 'OTHER',
  REST = 'REST',
}

export enum BookingCode {
  BLOCK = 'BLOCK',
  OTHER = 'OTHER',
  UNCONFIRMED_BOOK = 'UNCONFIRMED_BOOK',
}

export enum FlagType {
  BOOKING = 'BOOKING',
  CONFLICT = 'CONFLICT',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export interface Attachment {
  file: string
  mimetype: string
  original_filename: string
}

export interface Document {
  date_created: string
  date_updated: string
  description: string
  is_editable: boolean
  id: string
  job: string
  type: DocumentType
  uploaded_by: string
  attachments: Attachment[]
}

export interface Statement {
  date_created: string
  date_updated: string
  description: string
  id: string
  lease_fee: number
  lease_fee_percent: number
  line_items: LineItem[]
  service_fee: number
  service_fee_percent: number
  dispatch_service_fee: number
  dispatch_service_fee_percent: number
  subtotal: number
  total: number
  fee_items: FeeItem[]
  type: string
}

export interface LineItem {
  amount: string
  date_created: string
  date_updated: string
  description: string
  type: string
}

export interface FeeItem {
  description: string
  amount: string
  date_created: string
  date_updated: string
}

export interface UploadedFile extends File {
  lastModifiedDate: Date
}

export interface UploadDocumentPayload {
  jobId: string
  formData: FormData
}

export interface UpdateDocumentPayload {
  docId: string
  attachments: FormData
  jobId?: string
}

export const BookingTypesLabels: DropdownItem[] = [
  { value: LoadBookingTypes.INSTANT, label: 'Instant Book' },
  { value: LoadBookingTypes.STANDARD, label: 'Standard Book' },
]

export const BookingSourcesLabels: DropdownItem[] = [{ value: LoadBookingSources.DAT, label: 'DAT Only' }]

export interface ReloadQueryResponse {
  filter_options: QueryFilter
  loads: Load[]
}

export interface BrokerSelfServeJobTracking {
  human_id?: string
  carrier?: string
  mc_number?: string
  truck?: string
  load?: {
    source_human_id: string
    broker: {
      name: string
    }
  }
  load_stop_progresses?: LoadStopProgress[]
  tracking_current_load_stop_progress?: LoadStopProgress
  tracking_status?: TrackingStatus
  tracking_type?: JobTrackingType
  web_google_api_key?: string
  latest_location_vector?: LocationVector
}

export {
  JobsFilter,
  JobTrackingType,
  JobStatusDetail,
  JobStatus,
  BookingTypes,
  DocumentType,
  LoadStopType,
  LoadStopTypeDetail,
  LoadStopLoadingType,
  ContactedLoadStatus,
  DistanceTypes,
  LoadBookingTypes,
  LoadBookingSources,
}
