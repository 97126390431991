import { useCallback, useLayoutEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Divider } from 'shared/components/Base/Divider'
import { DateRangeValueType } from 'shared/components/DateRangeSelector/DateRangeSelector'
import { useBreakpoint } from 'shared/hooks/useBreakpoint'
import styled from 'styled-components'

import { useLayoutContext } from '../components/Context/LayoutContext'
import { jobsFilterFn, parseUrlParamsForJobsSearchFilters } from '../helpers/jobs'
import { Job } from '../models/jobs'
import {
  JobFiltersTypes,
  SelectedJobFiltersType,
  SelectedJobFiltersValuesType,
} from '../pages/Jobs/JobFilters/jobFiltersConstants'
import { JobFiltersDropdown } from '../pages/Jobs/JobFilters/JobFiltersDropdown'
import { JobFiltersRow } from '../pages/Jobs/JobFilters/JobFiltersRow'
import { JOBS_PAST_PATH, JOBS_PATH } from '../Routes'

export function useJobFilters() {
  const history = useHistory()
  const { search, pathname } = useLocation()
  const { recalculateToolbarHeight } = useLayoutContext()
  const isSmallBreakpoint = useBreakpoint('sm')

  const showJobFilters = pathname === JOBS_PAST_PATH || pathname === JOBS_PATH
  const parsedJobFilters = parseUrlParamsForJobsSearchFilters(search)

  const jobsFilterFunction = useCallback(
    (job: Job) => jobsFilterFn(parseUrlParamsForJobsSearchFilters(search))(job),
    [search]
  )

  const updateUrlParams = (search: string) => {
    history.replace({ search })
  }

  const [selectedFilters, setSelectedFilters] = useState<SelectedJobFiltersType>({
    [JobFiltersTypes.DRIVER]: !!parsedJobFilters.driver,
    [JobFiltersTypes.TRUCK]: !!parsedJobFilters.truck,
    [JobFiltersTypes.PICKUP_DATE]: !!parsedJobFilters.date_range_start,
    [JobFiltersTypes.BROKER]: !!parsedJobFilters.broker,
  })

  const [currentlyActiveFilter, setCurrentlyActiveFilter] = useState<JobFiltersTypes | undefined>(undefined)

  const [selectedFiltersValues, setSelectedFiltersValues] = useState<SelectedJobFiltersValuesType>({
    [JobFiltersTypes.DRIVER]: undefined,
    [JobFiltersTypes.BROKER]: undefined,
    [JobFiltersTypes.TRUCK]: undefined,
  })
  const [selectedDateRangeValue, setSelectedDateRangeValue] = useState<DateRangeValueType>([
    undefined,
    undefined,
  ])

  const initializeAllFilters = () => {
    setSelectedFiltersValues({
      [JobFiltersTypes.DRIVER]: parsedJobFilters.driver || undefined,
      [JobFiltersTypes.BROKER]: parsedJobFilters.broker || undefined,
      [JobFiltersTypes.TRUCK]: parsedJobFilters.truck || undefined,
    })
    setSelectedDateRangeValue([
      parsedJobFilters.date_range_start === 'ALL'
        ? 'ALL'
        : parsedJobFilters.date_range_start
        ? new Date(parsedJobFilters.date_range_start)
        : undefined,
      parsedJobFilters.date_range_end === 'ALL'
        ? 'ALL'
        : parsedJobFilters.date_range_end
        ? new Date(parsedJobFilters.date_range_end)
        : undefined,
    ])
  }

  function renderFiltersDropdown() {
    if (!showJobFilters) return null

    return (
      <JobFiltersContainer>
        {!isSmallBreakpoint && <Divider vertical margin="-3px 20px 3px" />}

        <JobFiltersDropdown
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          selectedFiltersValues={selectedFiltersValues}
          setSelectedFiltersValues={setSelectedFiltersValues}
          selectedDateRangeValue={selectedDateRangeValue}
          setSelectedDateRangeValue={setSelectedDateRangeValue}
          updateUrlParams={updateUrlParams}
          currentlyActiveFilter={currentlyActiveFilter}
          setCurrentlyActiveFilter={setCurrentlyActiveFilter}
        />
      </JobFiltersContainer>
    )
  }

  useLayoutEffect(recalculateToolbarHeight)

  function renderSelectedFilters() {
    if (!showJobFilters) return null

    return (
      <JobFiltersRow
        updateUrlParams={updateUrlParams}
        initializeAllFilters={initializeAllFilters}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        selectedFiltersValues={selectedFiltersValues}
        setSelectedFiltersValues={setSelectedFiltersValues}
        selectedDateRangeValue={selectedDateRangeValue}
        setSelectedDateRangeValue={setSelectedDateRangeValue}
      />
    )
  }

  return {
    renderFiltersDropdown,
    renderSelectedFilters,
    jobsFilterFunction,
    selectedFilters,
    selectedFiltersValues,
    selectedDateRangeValue,
    setSelectedFiltersValues,
    setSelectedDateRangeValue,
  }
}

const JobFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
`
