import {
  dividerGray,
  error,
  errorD1,
  information,
  kellyGreen,
  lightGray1,
  mangoYellow,
  marigoldOrange,
  midGray,
  primaryBlack,
  royalBlue,
  white,
} from '../theme/colors'
import { CoreDocument } from './document'

export enum EquipmentStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REMOVED = 'REMOVED',
}

export const EquipmentStatusLabel: Record<
  EquipmentStatus,
  {
    label: string
    background: string
    color: string
  }
> = {
  [EquipmentStatus.ACTIVE]: {
    label: 'Active',
    background: information,
    color: royalBlue,
  },
  [EquipmentStatus.INACTIVE]: {
    label: 'Inactive',
    background: dividerGray,
    color: midGray,
  },
  [EquipmentStatus.REMOVED]: {
    label: 'Removed',
    background: error,
    color: errorD1,
  },
}

export enum EquipmentType {
  ALL = 'ALL',
  DRY_VAN = 'DRY_VAN',
  FLATBED = 'FLATBED',
  REEFER = 'REEFER',
  POWER_ONLY = 'POWER_ONLY',
  OTHER = 'OTHER',
  BOX_TRUCK = 'BOX_TRUCK',
  HOT_SHOT = 'HOT_SHOT',
  SPRINTER_VAN = 'SPRINTER_VAN',
  TANKER = 'TANKER',
  HOPPER = 'HOPPER',
}

export enum OwnershipType {
  OWN = 'OWN',
  LEASE = 'LEASE',
  RENT = 'RENT',
}

export const OwnershipTypeLabel: Record<OwnershipType, string> = {
  [OwnershipType.OWN]: 'Own',
  [OwnershipType.LEASE]: 'Lease',
  [OwnershipType.RENT]: 'Rental program',
}

export enum EquipmentColor {
  WHITE = 'WHITE',
  BLACK = 'BLACK',
  GRAY = 'GRAY',
  SILVER = 'SILVER',
  RED = 'RED',
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW',
  PURPLE = 'PURPLE',
  OTHER = 'OTHER',
}

export const EquipmentColorLabel: Record<EquipmentColor, string> = {
  [EquipmentColor.WHITE]: 'White',
  [EquipmentColor.BLACK]: 'Black',
  [EquipmentColor.GRAY]: 'Gray',
  [EquipmentColor.SILVER]: 'Silver',
  [EquipmentColor.RED]: 'Red',
  [EquipmentColor.BLUE]: 'Blue',
  [EquipmentColor.GREEN]: 'Green',
  [EquipmentColor.ORANGE]: 'Orange',
  [EquipmentColor.YELLOW]: 'Yellow',
  [EquipmentColor.PURPLE]: 'Purple',
  [EquipmentColor.OTHER]: 'Other',
}

export const EquipmentColorHex: Record<EquipmentColor, [string, string]> = {
  [EquipmentColor.WHITE]: [white, midGray],
  [EquipmentColor.BLACK]: [primaryBlack, primaryBlack],
  [EquipmentColor.GRAY]: [lightGray1, lightGray1],
  [EquipmentColor.SILVER]: ['#C0C0C0', '#C0C0C0'],
  [EquipmentColor.RED]: [errorD1, errorD1],
  [EquipmentColor.BLUE]: ['#9A6DE6', '#9A6DE6'],
  [EquipmentColor.GREEN]: [kellyGreen, kellyGreen],
  [EquipmentColor.ORANGE]: [marigoldOrange, marigoldOrange],
  [EquipmentColor.YELLOW]: [mangoYellow, '#F0FF40'],
  [EquipmentColor.PURPLE]: ['#613E9B', '#613E9B'],
  [EquipmentColor.OTHER]: ['transparent', 'transparent'],
}

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export interface LastDotInspectionDocument extends CoreDocument<{ LAST_DOT_INSPECTION_DATE: string }> {}

export interface Equipment {
  id?: string
  status?: EquipmentStatus
  dateUpdated?: string
  vehicleYear?: number
  vehicleMake?: string
  vehicleModel?: string
  vehicleColor?: EquipmentColor
  vinNumber?: string
  licensePlateNumber?: string
  licensePlateState?: string
  ownershipType?: OwnershipType
  vehicleRegistration?: string
  vehicleRegistrationDocument?: CoreDocument<{}> | null
  vehicleRegistrationExpiration?: string
  vehicleNumber?: string
  lastDotInspection?: string
  dateLastDotInspection?: string
  dateLastDotInspectionExpired?: string
  lastDotInspectionDocument?: LastDotInspectionDocument | null
  dateVehicleConfirmed?: string
  insurancePhysicalDamage?: string
  insurancePhysicalDamageDocument?: CoreDocument<{}> | null
  insurancePhysicalDamageExpiration?: string
  insuranceNonTruckingLiability?: string
  insuranceNonTruckingLiabilityDocument?: CoreDocument<{}> | null
  insuranceNonTruckingLiabilityExpiration?: string
}
