import React, { useContext } from 'react'

import { Handler } from '../../helpers/typeHelper'
import { User } from '../../models/user'

interface UseProductSubscription {
  isPremium: boolean
  isFlexStarter: boolean
  hasStarterFeature: boolean
  hasFactoringFeature: boolean
}

export interface BannerProps {
  message: string | string[] | JSX.Element[] | JSX.Element
  title?: string
  footer?: string
  action?: Handler
  actionLabel?: string
  actionRenderer?: Handler
  autoHideDuration?: number
}

interface UserContextType {
  permissions: {
    canViewRates: boolean
    emailCanBeChanged: boolean
    canChangeCustomUser: boolean
    canViewReferrals: boolean
  }
  showBanner: (type: 'error' | 'success' | 'warning', data: BannerProps) => void
  isSidebarVisible: boolean
  clearUserCache: () => Promise<unknown>
  setIsSideBarCollapsed: (isExpannded: boolean) => void
  subscription: UseProductSubscription | null
  loggedInUser: Partial<User> | null
}

const defaultValue = {
  permissions: {
    canViewRates: false,
    emailCanBeChanged: false,
    canChangeCustomUser: false,
    canViewReferrals: false,
  },
  showBanner: () => null,
  isSidebarVisible: false,
  clearUserCache: () => Promise.resolve(),
  setIsSideBarCollapsed: () => null,
  subscription: null,
  loggedInUser: null,
}

const UserContext = React.createContext<UserContextType>(defaultValue)

interface UserProviderProps {
  children: React.ReactNode
  isSidebarVisible: UserContextType['isSidebarVisible']
  showBanner: UserContextType['showBanner']
  clearUserCache: UserContextType['clearUserCache']
  permissions: UserContextType['permissions']
  subscription: UserContextType['subscription']
  loggedInUser: UserContextType['loggedInUser']
  setIsSideBarCollapsed?: UserContextType['setIsSideBarCollapsed']
}

export function UserProvider(props: UserProviderProps) {
  return (
    <UserContext.Provider
      value={{
        showBanner: props.showBanner,
        isSidebarVisible: props.isSidebarVisible,
        clearUserCache: props.clearUserCache,
        permissions: props.permissions,
        subscription: props.subscription,
        loggedInUser: props.loggedInUser,
        setIsSideBarCollapsed: props.setIsSideBarCollapsed || (() => null),
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export const useUserProvider = () => {
  return useContext(UserContext)
}
