/*
GTM URL - https://tagmanager.google.com/?authuser=0#/container/accounts/6002586268/containers/35491246/workspaces/35
Docs - https://developers.google.com/tag-platform/tag-manager/web
*/

// @ts-nocheck
export function loadTagManager(containerId: string) {
  ;(function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : ''
    j.async = true
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
    f.parentNode.insertBefore(j, f)
  })(window, document, 'script', 'dataLayer', containerId)
}
