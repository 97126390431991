import { BusinessType } from './team'

export enum YesNo {
  YES = 'yes',
  NO = 'no',
}

export type ApiVersion = 'v1' | 'v3'

export interface FleetApplication {
  businessType: BusinessType | null
  businessDocumentation: string | null
  certificateOfInsurance?: string | null
  operatingAuthorityLetter: string | null
}
