import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { Drawer } from 'shared/components/Drawer/Drawer'
import { Pill } from 'shared/components/Pill/Pill'
import { Text } from 'shared/components/Text/Text'
import { lightGray1 } from 'shared/theme/colors'
import { styled } from 'styled-components'

import { usePermission } from '../../hooks/usePermission'
import { PermissionType } from '../../models/permission'
import { closeConsultant, openConsultant } from '../../redux/actions/consultant/consultant'
import { isConsultantOpenSelector } from '../../redux/selectors/consultant'
import { ConsultantConversation } from './ConsulantConversation'
import { ConsultantTerms } from './ConsultantTerms'

const CONSULTANT_TERMS_KEY = 'CONSULTANT_TERMS_KEY'
const CONSULTANT_TERMS_VALUE = '1'

export function Consultant() {
  const dispatch = useDispatch()
  const isConsultantOpen = useSelector(isConsultantOpenSelector)
  const [areTermsAccepted, setAreTermsAccepted] = useState(
    localStorage?.getItem?.(CONSULTANT_TERMS_KEY) === CONSULTANT_TERMS_VALUE
  )
  const hasAiConsultantPermission = usePermission(PermissionType.AI_CONSULTANT)

  function handleClose() {
    dispatch(closeConsultant())
  }

  useEffect(() => {
    if (window.location.search.includes('open-consultant')) {
      dispatch(openConsultant())
    }
  }, [dispatch])

  if (!isConsultantOpen || !hasAiConsultantPermission.enabled) return null

  function onTermsSuccess() {
    setAreTermsAccepted(true)
    localStorage?.setItem?.(CONSULTANT_TERMS_KEY, CONSULTANT_TERMS_VALUE)
  }

  if (!areTermsAccepted) {
    return <ConsultantTerms onSuccess={onTermsSuccess} />
  }

  return (
    <Drawer
      isOpen={isConsultantOpen}
      onClose={handleClose}
      header={
        <FlexBox alignItems="center" gap="10px">
          <FlexBox alignItems="center" gap="5px">
            <DrawerHeaderTitle>Ask Cai</DrawerHeaderTitle>
            <Pill mb={0.7}>Beta</Pill>
          </FlexBox>
          <Text variant="label3" fontColor={lightGray1}>
            This AI may occasionally make errors or inaccurate statements.
          </Text>
        </FlexBox>
      }
    >
      {(wrapRef) => <ConsultantConversation container={wrapRef} />}
    </Drawer>
  )
}

const DrawerHeaderTitle = styled((props: { children: string }) => <Text {...props} variant="header2" />)`
  min-width: 87px;
`
