import { Equipment, EquipmentColor, EquipmentType, OwnershipType } from 'shared/models/equipment'

import { Document, parseCoreDocument } from './document'

export { EquipmentType, OwnershipType }

export type { Equipment }

export const EquipmentTypeLabel: Record<EquipmentType, string> = {
  [EquipmentType.ALL]: 'All',
  [EquipmentType.DRY_VAN]: 'Dry Van',
  [EquipmentType.FLATBED]: 'Flatbed',
  [EquipmentType.REEFER]: 'Reefer',
  [EquipmentType.POWER_ONLY]: 'Power Only',
  [EquipmentType.OTHER]: 'Other',
  [EquipmentType.BOX_TRUCK]: 'Box Truck',
  [EquipmentType.HOT_SHOT]: 'Hot Shot',
  [EquipmentType.SPRINTER_VAN]: 'Sprinter Van',
  [EquipmentType.TANKER]: 'Tanker',
  [EquipmentType.HOPPER]: 'Hopper',
}

export enum EquipmentStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REMOVED = 'REMOVED',
}

export interface EquipmentPayload {
  id?: string
  status?: EquipmentStatus
  date_updated?: string
  formData?: FormData
  vehicle_year?: number
  vehicle_make?: string
  vehicle_model?: string
  vehicle_number?: string
  vehicle_color?: EquipmentColor
  vin_number?: string
  license_plate_number?: string
  license_plate_state?: string
  ownership_type?: OwnershipType
  vehicle_registration?: string
  vehicle_registration_expiration?: string
  vehicle_registration_document?: Document<{ license_plate_number: string; license_plate_state: string }>
  last_dot_inspection?: string
  date_last_dot_inspection?: string
  date_last_dot_inspection_expired?: string
  last_dot_inspection_document?: Document<{
    LAST_DOT_INSPECTION_DATE: string
    license_plate_number: string
    license_plate_state: string
  }>
  date_vehicle_confirmed?: string
  insurance_physical_damage?: string
  insurance_physical_damage_document?: Document<{ license_plate_number: string; license_plate_state: string }>
  insurance_physical_damage_expiration?: string
  insurance_non_trucking_liability?: string
  insurance_non_trucking_liability_document?: Document<{
    license_plate_number: string
    license_plate_state: string
  }>
  insurance_non_trucking_liability_expiration?: string
}

export function parseEquipment(equipment: EquipmentPayload): Equipment {
  return {
    id: equipment.id,
    status: equipment.status,
    dateUpdated: equipment.date_updated,
    vehicleYear: equipment.vehicle_year,
    vehicleMake: equipment.vehicle_make,
    vehicleModel: equipment.vehicle_model,
    vehicleNumber: equipment.vehicle_number,
    vehicleColor: equipment.vehicle_color,
    vinNumber: equipment.vin_number,
    licensePlateNumber: equipment.license_plate_number,
    licensePlateState: equipment.license_plate_state,
    ownershipType: equipment.ownership_type,
    vehicleRegistration: equipment.vehicle_registration,
    vehicleRegistrationDocument: parseCoreDocument(equipment.vehicle_registration_document),
    vehicleRegistrationExpiration: equipment.vehicle_registration_expiration,
    lastDotInspection: equipment.last_dot_inspection,
    lastDotInspectionDocument: parseCoreDocument(equipment.last_dot_inspection_document),
    dateLastDotInspection: equipment.date_last_dot_inspection,
    dateLastDotInspectionExpired: equipment.date_last_dot_inspection_expired,
    dateVehicleConfirmed: equipment.date_vehicle_confirmed,
    insurancePhysicalDamage: equipment.insurance_physical_damage,
    insurancePhysicalDamageDocument: parseCoreDocument(equipment.insurance_physical_damage_document),
    insurancePhysicalDamageExpiration: equipment.insurance_physical_damage_expiration,
    insuranceNonTruckingLiability: equipment.insurance_non_trucking_liability,
    insuranceNonTruckingLiabilityDocument: parseCoreDocument(
      equipment.insurance_non_trucking_liability_document
    ),
    insuranceNonTruckingLiabilityExpiration: equipment.insurance_non_trucking_liability_expiration,
  }
}
