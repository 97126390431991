import { ErrorSnackbar } from './ErrorSnackbar'
import { InfoSnackbar } from './InfoSnackbar'
import { SnackBarReduxProps } from './Snackbar'
import { SuccessSnackbar } from './SuccessSnackbar'
import { WarningSnackbar } from './WarningSnackbar'

export function Snackbars(props: SnackBarReduxProps) {
  return (
    <>
      <SuccessSnackbar {...props} />
      <InfoSnackbar {...props} />
      <ErrorSnackbar {...props} />
      <WarningSnackbar {...props} />
    </>
  )
}
