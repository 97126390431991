import { format } from 'date-fns-tz'
import * as React from 'react'
import { DateRangeSelectorLabel } from 'shared/components/DateRangeSelector/DateRangeSelectorLabel'
import { DateRangeDropdown } from 'shared/components/Dropdown/DateRangeDropdown'
import { Handler } from 'shared/helpers/typeHelper'

export type DateRangeValueType = [Date | 'ALL' | undefined, Date | 'ALL' | undefined]

type Props = {
  selected: boolean
  deselect?: Handler
  label: string
  selectedDateRange: DateRangeValueType
  setSelectedDateRange: (values: DateRangeValueType, done: boolean) => void
  isInitiallyOpen?: boolean
  opener?: React.ReactNode
  onBlur?: Handler
  width?: number | string
}

export function DateRangeSelector({
  selected,
  deselect,
  selectedDateRange,
  setSelectedDateRange,
  label,
  isInitiallyOpen,
  opener,
  onBlur,
  width,
}: Props) {
  if (!selected && !isInitiallyOpen) return null

  return (
    <DateRangeDropdown
      dateRange={[
        selectedDateRange[0] && selectedDateRange[0] !== 'ALL' ? selectedDateRange[0] : new Date(),
        selectedDateRange[1] && selectedDateRange[1] !== 'ALL' ? selectedDateRange[1] : new Date(),
      ]}
      setDateRange={setSelectedDateRange}
      isInitiallyOpen={isInitiallyOpen}
      onBlur={onBlur}
      width={width}
    >
      {opener ?? (
        <DateRangeSelectorLabel
          label={label}
          optionLabel={
            selectedDateRange[0] &&
            selectedDateRange[0] !== 'ALL' &&
            selectedDateRange[1] &&
            selectedDateRange[1] !== 'ALL'
              ? `${format(selectedDateRange[0], 'MMM d')} - ${format(selectedDateRange[1], 'MMM d')}`
              : ''
          }
          deselect={deselect}
        />
      )}
    </DateRangeDropdown>
  )
}
