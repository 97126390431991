import * as types from '../actions/settings/actionTypes'

const initState = {}

// TODO: Remove default export
// eslint-disable-next-line import/no-default-export
export default function settingsReducer(state = initState, action: any) {
  switch (action.type) {
    case types.SAVE_SETTINGS:
      return action.settings

    case types.GOOGLE_APIS_LOADED:
      return { ...state, googleApisLoaded: true }
    default:
      return state || initState
  }
}
