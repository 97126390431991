import { BannerProps } from 'shared/components/Context/UserContext'

import * as actionTypes from './actionTypes'

export const showSuccessBanner = ({
  message,
  title,
  footer,
  action,
  actionLabel,
  actionRenderer,
  autoHideDuration,
}: BannerProps) => ({
  type: actionTypes.SNACKBAR_SUCCESS,
  message,
  title,
  footer,
  action,
  actionLabel,
  actionRenderer,
  autoHideDuration,
})

export const showInfoBanner = ({
  message,
  title,
  footer,
  action,
  actionLabel,
  actionRenderer,
  autoHideDuration,
}: BannerProps) => ({
  type: actionTypes.SNACKBAR_INFO,
  message,
  title,
  footer,
  action,
  actionLabel,
  actionRenderer,
  autoHideDuration,
})

export const showErrorBanner = ({
  message,
  title,
  footer,
  action,
  actionLabel,
  actionRenderer,
  autoHideDuration,
}: BannerProps) => ({
  type: actionTypes.SNACKBAR_ERROR,
  message,
  title,
  footer,
  action,
  actionLabel,
  actionRenderer,
  autoHideDuration,
})

export const showWarningBanner = ({
  message,
  title,
  footer,
  action,
  actionLabel,
  actionRenderer,
  autoHideDuration,
}: BannerProps) => ({
  type: actionTypes.SNACKBAR_WARNING,
  message,
  title,
  footer,
  action,
  actionLabel,
  actionRenderer,
  autoHideDuration,
})

export const clearBanner = () => ({
  type: actionTypes.SNACKBAR_CLEAR,
})
