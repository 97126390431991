import LogRocket from 'logrocket'
import { ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { formattedName } from 'shared/helpers/formatters'

import { isDevelopment, isProduction } from 'config/config'
import { ApplicationState } from 'redux/store/models'
import { camelizeUser } from '../../models/user'

interface IntercomProps {
  children: ReactNode
}

export const Intercom = ({ children }: IntercomProps) => {
  const { boot, shutdown, update } = useIntercom()
  const user = useSelector((state: ApplicationState) => state.user)
  const location = useLocation()

  useEffect(() => {
    let shouldCancelBoot = false

    if (isProduction) {
      LogRocket.getSessionURL((sessionURL) => {
        if (shouldCancelBoot) return

        boot({
          customAttributes: {
            logrocketURL: sessionURL,
          },
        })
      })
    } else {
      if (isDevelopment) return
      boot()
    }

    return () => {
      shouldCancelBoot = true
      shutdown()
    }
  }, [boot, shutdown])

  useEffect(() => {
    if (user.id && user.email && !isDevelopment)
      update({
        userId: user.id,
        userHash: user.intercom_identity,
        email: user.email,
        name: formattedName(camelizeUser(user)),
      })
  }, [update, user])

  useEffect(() => {
    if (!isDevelopment) update()
  }, [update, location.pathname])

  return <>{children}</>
}
