import { ErrorOption } from 'react-hook-form'

import {
  GENERIC_ERROR,
  GENERIC_ERROR_CONTACT_US,
  PO_BOX_ERROR_MESSAGE,
  PO_BOX_VALIDATION_ERROR,
} from './constants'

export function parseError(err: any) {
  if (err?.response?.status === 500) {
    return GENERIC_ERROR_CONTACT_US
  }
  const errors = err?.response?.data || err?.data || err?.message || err || {}

  const shippingAddError = errors?.shipping_address
  if (Boolean(shippingAddError)) {
    const poBoxError = shippingAddError?.non_field_errors?.includes(PO_BOX_VALIDATION_ERROR)
    if (poBoxError) {
      return PO_BOX_ERROR_MESSAGE
    }
  }

  if (typeof errors === 'string') {
    if (errors.startsWith('<h1>Server Error')) return GENERIC_ERROR
    return errors
  }

  if (Array.isArray(errors)) {
    return errors.map((err) => <div>{parseErrorMessage(err)}</div>)
  }

  const keys = Object.keys(errors)
  if (keys[0]) {
    return keys.map((key) => (
      <div>
        {key.replace(/_/gi, ' ')}: {parseErrorMessage(errors[key])}
      </div>
    ))
  }

  return GENERIC_ERROR
}

function parseErrorMessage(message: string | string[]): any {
  if (Array.isArray(message)) return message.join(' ')

  if (typeof message === 'object') {
    return parseError(message)
  }

  return message
}

export function parseInlineErrors(err: any, setError: (key: any, settings: ErrorOption) => void) {
  for (const [key, value] of Object.entries(err)) {
    setError(key, { message: value as string, type: 'notMatch' })
  }
}
