import { CircularProgress } from '@material-ui/core'
import styled, { css } from 'styled-components'

import { muiTheme, sizes, variants } from '../../theme'
import { breakpoints } from '../../theme/breakpoints'
import {
  ADDITIONAL_PADDING,
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  DIMENSIONS,
  FONT_SIZE,
  ICON_SIZE,
  LETTER_SPACING,
  PADDING,
  TEXT_BUTTON_FONT_SIZE,
} from './Button.constants'

export const ButtonStyled = styled.button<{
  $size: keyof typeof sizes
  $xsSize?: keyof typeof sizes
  $smSize?: keyof typeof sizes
  $variant: keyof typeof variants
  $fullWidth: boolean
  $smFullWidth: boolean
  $xsFullWidth: boolean
  $margin?: string
  $mt?: number
  $mb?: number
  $isInline?: boolean
  $isIconOnly?: boolean
  $hasIcon?: boolean
  $hasChildren?: boolean
  $align?: 'left'
  $disabled?: boolean
  $isLoading?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $align }) => ($align === 'left' ? 'justify-content: flex-start;' : '')};
  height: ${({ $variant, $size }) => ($variant === 'text' ? 'auto' : `${DIMENSIONS[$size]}px`)};
  padding: ${({ $variant, $size }) => ($variant === 'text' ? '0' : PADDING[$size])};
  ${({ $size, $hasChildren }) =>
    $size === 'extraLarge' &&
    $hasChildren &&
    css`
      min-width: 256px;
    `};
  color: ${({ $variant }) => COLORS.REGULAR[$variant]};
  background: ${({ $variant }) => BACKGROUND_COLORS.REGULAR[$variant]};
  border: ${({ $variant }) => `1px solid ${BORDER_COLORS.REGULAR[$variant]}`};
  opacity: ${({ $disabled, $isLoading }) => ($disabled && !$isLoading ? 0.5 : 1)};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  font-size: ${({ $size }) => FONT_SIZE[$size]}px;
  ${({ $variant, $size }) =>
    $variant === variants.text || $variant === variants.flat
      ? css`
          font-size: ${TEXT_BUTTON_FONT_SIZE[$size]}px;
        `
      : ''};
  font-family: Overpass;
  font-weight: 600;
  letter-spacing: ${({ $variant, $size }) =>
    $variant === variants.primary || $variant === variants.secondary
      ? `${LETTER_SPACING[$size]}px`
      : 'inherit'};
  text-decoration: none;
  text-transform: ${({ $variant }) =>
    $variant === variants.primary || $variant === variants.secondary ? 'uppercase' : 'none'};
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  ${({ $fullWidth }) => $fullWidth && `width: 100%;`};
  ${({ $margin }) => $margin && `margin: ${$margin};`};
  ${({ $isInline }) => $isInline && `display: inline;`};
  ${({ $isIconOnly, $size }) => $isIconOnly && `width: ${DIMENSIONS[$size]}px`};

  &:hover:not(:disabled) {
    background: ${({ $variant }) => BACKGROUND_COLORS.HOVER[$variant]};
    border-color: ${({ $variant }) => `${BORDER_COLORS.HOVER[$variant]}`};
    color: ${({ $variant }) => COLORS.HOVER[$variant]};
    cursor: pointer;
  }

  ${(p) =>
    p.$mt &&
    css`
      margin-top: ${muiTheme.spacing() * p.$mt}px;
    `};

  ${(p) =>
    p.$mb &&
    css`
      margin-bottom: ${muiTheme.spacing() * p.$mb}px;
    `};

  @media ${breakpoints.sm} {
    ${({ $smSize }) =>
      $smSize &&
      css`
        height: ${DIMENSIONS[$smSize]}px;
        padding: ${PADDING[$smSize]};
        font-size: ${FONT_SIZE[$smSize]}px;
      `}

    ${({ $smFullWidth }) =>
      $smFullWidth &&
      css`
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
      `}
  }

  @media ${breakpoints.xs} {
    ${({ $xsSize }) =>
      $xsSize &&
      css`
        height: ${DIMENSIONS[$xsSize]}px;
        padding: ${PADDING[$xsSize]};
        font-size: ${FONT_SIZE[$xsSize]}px;
      `}

    ${({ $xsFullWidth }) =>
      $xsFullWidth &&
      css`
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
      `}
  }

  ${({ $variant, $size }) =>
    $variant === 'text' &&
    $size === 'small' &&
    css`
      font-size: 14px;
    `}

  ${({ $variant }) =>
    $variant === 'text' &&
    css`
      @media ${breakpoints.sm} {
        font-size: 14px;
      }
    `}

  ${({ $size }) =>
    $size === 'extraLarge' &&
    css`
      @media ${breakpoints.xs} {
        height: ${DIMENSIONS['large']}px;
        padding: ${PADDING['large']};
        font-size: ${FONT_SIZE['large']}px;
      }
    `}

  ${({ $hasChildren }) => !$hasChildren && 'padding: 0px;'};
`

export const AnchoredButtonWrap = styled.div<{
  $size: keyof typeof sizes
  $isInline?: boolean
  $variant: keyof typeof variants
  $fullWidth: boolean
  $align?: 'left'
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $fullWidth }) => $fullWidth && `width: 100%;`};
  ${({ $align }) => ($align === 'left' ? 'justify-content: flex-start;' : '')};
`

export const ButtonOuter = styled.div<{
  $size: keyof typeof sizes
  $variant: keyof typeof variants
}>`
  display: grid;
  ${({ $size, $variant }) =>
    css`
      grid-template-columns: minmax(0, ${$variant === 'text' ? 0 : ADDITIONAL_PADDING[$size]}px) 1fr minmax(
          0,
          ${$variant === 'text' ? 0 : ADDITIONAL_PADDING[$size]}px
        );
    `};
`

export const ButtonPadding = styled.div``

export const ButtonInner = styled.div<{
  $isInline?: boolean
  $variant: keyof typeof variants
  $hasIcon?: boolean
}>`
  flex-grow: 1;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ $hasIcon }) => $hasIcon && `line-height: 1;`};
  ${({ $isInline }) => $isInline && `display: inline;`};
  ${({ $variant, $hasIcon }) => $variant === 'text' && !$hasIcon && `display: inline;`};
`

export const ButtonSpinner = styled(CircularProgress)<{
  $isInline?: boolean
  $variant: keyof typeof variants
  size: number
}>`
  position: absolute;
  left: 50%;
  top: 50%;
  ${({ $variant }) => $variant === 'primary' && `svg { color: #03CDEF }`};
  ${({ size }) =>
    size &&
    css`
      margin-top: -${size / 2}px;
      margin-left: -${size / 2}px;
    `};
`

export const HiddenChildren = styled.span`
  position: relative;
  opacity: 0;
`

export const ButtonTextWrap = styled.div`
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-transform: inherit;

  position: relative;
  top: 1px;
`

export const ButtonIconWrap = styled.span<{
  $isIconOnly?: boolean
  $variant: keyof typeof variants
  $color?: string
  $iconOnRight?: boolean
  $size: keyof typeof sizes
}>`
  ${({ $iconOnRight }) => ($iconOnRight ? 'margin-left: 8px;' : 'margin-right: 8px;')}
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) =>
    p.$isIconOnly &&
    css`
      margin: 0;
      top: -1px;
    `}

  svg {
    font-size: ${({ $size }) => ICON_SIZE[$size]}px;
    ${({ $variant, $color }) => `color: ${$color ?? COLORS.REGULAR[$variant] ?? 'white'}`}
  }
`
