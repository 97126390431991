import {
  Feature,
  FeatureId,
  Product,
  ProductPrice,
  ProductSubscriptionItem,
  ProductSubscriptionItemStatus,
} from './productSubscription'

export { Feature, FeatureId, ProductSubscriptionItemStatus }
export type { ProductSubscriptionItem, Product, ProductPrice }

export interface UpcomingInvoiceDiscount {
  description: string
  amountDollars: number
  amountDollarsFormatted: string
  percentOff: number
}

export interface UpcomingInvoiceLine {
  amountDollars: number
  amountDollarsFormatted: string
  quantity: number
  description: string
  product: Product
  productPrice: Omit<ProductPrice, 'product'>
}

export interface UpcomingInvoice {
  totalDollars: number
  totalDollarsFormatted: string
  subTotalDollars: number
  subTotalDollarsFormatted: string
  subtotalNoFlatPriceDollars: number
  subtotalNoFlatPriceDollarsFormatted: string
  discount?: UpcomingInvoiceDiscount
  lines: UpcomingInvoiceLine[]
}

export function parseProduct(data: any): Product {
  return {
    id: data.id,
    featureId: data.feature_id,
    name: data.name,
  }
}

export function parsePrice(data: any) {
  return {
    id: data.id,
    amount: data.amount,
    billingPeriod: data.billing_period,
    default: data.default,
    product: data.product ? parseProduct(data.product) : null,
    recurring: data.recurring,
    description: data.description,
    maxChargedSeats: data.max_charged_seats,
  }
}

export function parseUpcomingInvoice(data: any): UpcomingInvoice | undefined {
  if (!data) return

  return {
    totalDollars: data.total_dollars,
    totalDollarsFormatted: data.total_dollars_formatted,
    subTotalDollars: data.subtotal_dollars,
    subTotalDollarsFormatted: data.subtotal_dollars_formatted,
    subtotalNoFlatPriceDollars: data.subtotal_no_flat_price_dollars,
    subtotalNoFlatPriceDollarsFormatted: data.subtotal_no_flat_price_dollars_formatted,
    discount: data.discount
      ? {
          description: data.discount.description,
          amountDollars: data.discount.amount_dollars,
          amountDollarsFormatted: data.discount.amount_dollars_formatted,
          percentOff: data.discount.percent_off,
        }
      : undefined,
    lines: data.lines.map((line: any) => ({
      amountDollars: line.amount_dollars,
      amountDollarsFormatted: line.amount_dollars_formatted,
      quantity: line.quantity,
      description: line.description,
      product: parseProduct(line.product),
      productPrice: parsePrice(line.product_price),
    })),
  }
}
