export function CopyAllIIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.16667 17.3334C1.70833 17.3334 1.31597 17.1702 0.989583 16.8438C0.663194 16.5174 0.5 16.1251 0.5 15.6667H2.16667V17.3334ZM0.5 14.4167V12.7501H2.16667V14.4167H0.5ZM0.5 11.5001V9.83341H2.16667V11.5001H0.5ZM0.5 8.58341V6.91675H2.16667V8.58341H0.5ZM0.5 5.66675C0.5 5.20841 0.663194 4.81605 0.989583 4.48966C1.31597 4.16328 1.70833 4.00008 2.16667 4.00008V5.66675H0.5ZM3.41667 17.3334V15.6667H5.08333V17.3334H3.41667ZM5.5 14.0001C5.04167 14.0001 4.64931 13.8369 4.32292 13.5105C3.99653 13.1841 3.83333 12.7917 3.83333 12.3334V2.33341C3.83333 1.87508 3.99653 1.48272 4.32292 1.15633C4.64931 0.829943 5.04167 0.666748 5.5 0.666748H13C13.4583 0.666748 13.8507 0.829943 14.1771 1.15633C14.5035 1.48272 14.6667 1.87508 14.6667 2.33341V12.3334C14.6667 12.7917 14.5035 13.1841 14.1771 13.5105C13.8507 13.8369 13.4583 14.0001 13 14.0001H5.5ZM5.5 12.3334H13V2.33341H5.5V12.3334ZM6.33333 17.3334V15.6667H8V17.3334H6.33333ZM9.25 17.3334V15.6667H10.9167C10.9167 16.1251 10.7535 16.5174 10.4271 16.8438C10.1007 17.1702 9.70833 17.3334 9.25 17.3334Z"
        fill="#A9BAC8"
      />
    </svg>
  )
}
