import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Wrap } from 'shared/components/Base/Base'
import { Button } from 'shared/components/Button/Button'
import { SimpleDialog } from 'shared/components/Dialog/SimpleDialog'
import { ExternalLink } from 'shared/components/ExternalLink/ExternalLink'
import { Text } from 'shared/components/Text/Text'
import { midGray, primaryBlack } from 'shared/theme/colors'

import { useErrorMessage } from '../../hooks/useErrorMessage'
import { acceptStripeCardholderComplianceTerms } from '../../models/stripe'
import { getUserAndTeammateInfo } from '../../redux/actions/users/actions'
import { currentUserSelector, isVendorUserSelector } from '../../redux/selectors/users'
import { ApplicationState, ReduxDispatch } from '../../redux/store/models'
import { CONTRACTOR_PAYMENTS_PATH, MOBILE_AUTH_PATH, ONBOARDING_FLEX_SUBSCRIPTION_PATH } from '../../Routes'

export function StripeTosModal() {
  const dispatch: ReduxDispatch = useDispatch()
  const isVendorUser = useSelector(isVendorUserSelector)
  const user = useSelector(currentUserSelector)
  const authUser = useSelector((state: ApplicationState) => state.auth.isAuthenticated)
  const { pathname } = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const showErrorMessage = useErrorMessage()

  if (!authUser) return null
  if (
    pathname.includes('registration') ||
    pathname.includes(MOBILE_AUTH_PATH) ||
    pathname.includes(CONTRACTOR_PAYMENTS_PATH) ||
    pathname.includes(ONBOARDING_FLEX_SUBSCRIPTION_PATH)
  )
    return null
  if (user.has_recorded_terms_on_stripe || isVendorUser) return null

  async function handleAccept() {
    setIsLoading(true)
    try {
      await acceptStripeCardholderComplianceTerms(user.id)
      await dispatch(getUserAndTeammateInfo())
    } catch (err) {
      showErrorMessage(err)
    }
    setIsLoading(false)
  }

  return (
    <SimpleDialog isOpen>
      <Wrap width="380px" maxWidth="100%">
        <Text variant="header2" fontColor={primaryBlack} align="left">
          Terms of Service update
        </Text>
        <Text variant="paragraph1" mt={1} mb={4} fontColor={midGray} align="left">
          Our Terms of Service have been updated. To continue using CloudTrucks, please review and accept the
          new{' '}
          <ExternalLink href="https://www.cloudtrucks.com/terms" variant="paragraph1">
            Terms of Service
          </ExternalLink>
          .
        </Text>
        <Button margin="0 auto" variant="primary" size="large" onClick={handleAccept} isLoading={isLoading}>
          Accept
        </Button>
      </Wrap>
    </SimpleDialog>
  )
}
