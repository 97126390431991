import { forwardRef, HTMLAttributes, Ref } from 'react'
import styled, { css, IStyledComponent } from 'styled-components'

import { header3 } from '../../theme/typography'

interface BadgeProps {
  backgroundColor?: string
  color?: string
  minWidth?: string
  isLarge?: boolean
}

const StyledBadge = styled.div<{
  $backgroundColor?: string
  $color?: string
  $minWidth?: string
  $isLarge?: boolean
}>`
  padding: 3px 10px 0;
  border-radius: 3px;
  display: inline-block;

  ${(p) =>
    p.$isLarge &&
    css`
      ${header3};
      padding: 5px 10px 4px;
    `};

  ${(p) =>
    p.$minWidth &&
    css`
      min-width: ${p.$minWidth};
    `};

  ${(p) =>
    p.$backgroundColor &&
    css`
      background-color: ${p.$backgroundColor};
    `};

  ${(p) =>
    p.$color &&
    css`
      color: ${p.$color};
    `};
`

export const Badge = forwardRef(({ backgroundColor, color, minWidth, isLarge, ...rest }, ref: Ref<any>) => {
  return (
    <StyledBadge
      $backgroundColor={backgroundColor}
      $color={color}
      $minWidth={minWidth}
      $isLarge={isLarge}
      ref={ref}
      {...rest}
    />
  )
}) as IStyledComponent<'web', BadgeProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>
