import axios from 'axios'

import { toCamelCase } from '../helpers/utils'
import { Equipment } from './equipment'

export const MAX_DOT_INSPECTION_IN_DAYS = 90

export enum ELD {
  KEEP_TRUCKIN = 'KEEP_TRUCKIN',
  SAMSARA = 'SAMSARA',
  OTHER = 'OTHER',
}

export enum EldTypeStatus {
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  VALID = 'VALID',
}

export enum EldFieldState {
  MISSING_IN_CT = 'MISSING_IN_CT',
  INCOMPATIBLE = 'INCOMPATIBLE',
  VALID = 'VALID',
}

export interface EldField {
  state: EldFieldState
  value: string
}

interface EldVehicle {
  providerName: string
  providerUrl: string
}

export interface Truck extends Equipment {
  eldType?: ELD
  eldSerialNumber?: string
  eldVehicles?: EldVehicle[]
  insuranceNonTruckingLiability?: string
  insuranceNonTruckingLiabilityExpiration?: string
  iftaRequired?: boolean
  platesProgramRequired?: boolean
  isInfoComplete?: boolean
  isBaseInfoComplete?: boolean
  isDocumentsComplete?: boolean
  isEldComplete?: boolean
  isIftaAndPlatesComplete?: boolean
  eldStatus?: EldTypeStatus
  eldFields?: {
    licensePlateNumber: EldField
    licensePlateState: EldField
    vehicleMake: EldField
    vehicleModel: EldField
    vehicleYear: EldField
    vehicleNumber: EldField
  }
  assignedDrivers?: {
    id: string
    firstName: string
    lastName: string
  }[]
  truckInspectionReport?: {
    id: string
    inspectionReport: any
  }
  drivers?: {
    id: string
    fullName: string
  }[]
  odometerReadingMi?: number
  odometerReadingMiDate?: Date
}

export interface SlimTruck {
  id?: string
  vehicleRegistration?: string
  insuranceNonTruckingLiability?: string
  lastDotInspection?: string
  insurancePhysicalDamage?: string
  licensePlateNumber?: string
  licensePlateState?: string
  vehicleNumber?: string
  vehicleMake?: string
  vehicleModel?: string
  vehicleYear?: string | number
  vinNumber?: string | number
}

export const getDocumentsInitialErrors = (equipment: SlimTruck) => {
  const {
    vehicleRegistration,
    insuranceNonTruckingLiability,
    lastDotInspection,
    insurancePhysicalDamage,
    licensePlateNumber,
    licensePlateState,
  } = equipment

  const errors: Array<string> = []

  if (!vehicleRegistration) {
    errors.push('Vehicle registration')
  }

  if (!insuranceNonTruckingLiability) {
    errors.push('Non-trucking liability insurance')
  }

  if (!insurancePhysicalDamage) {
    errors.push('Physical damage insurance')
  }

  if (!lastDotInspection) {
    errors.push(`Clean DOT inspection from past ${MAX_DOT_INSPECTION_IN_DAYS} days`)
  }

  if (!licensePlateNumber || !licensePlateState) {
    errors.push(`License plate`)
  }

  return errors
}

export function getTruckV3(id: string | undefined): Promise<{ truck: Truck | undefined }> {
  return axios
    .get(`/api/v3/trucks/${id}/`)
    .then(({ data }) => ({ truck: data ? (toCamelCase(data) as Truck) : undefined }))
}

export function getTeamTrucksV3(teamId: string | undefined): Promise<{ trucks: Truck[] }> {
  return axios
    .get(`/api/v3/trucks/?team_id=${teamId}`)
    .then(({ data }) => ({ trucks: data?.results ? (toCamelCase(data.results) as Truck[]) : [] }))
}
