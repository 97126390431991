export const migrations = {
  0: (state: any) => {
    return {
      ...state,
      jobs: {
        ...state?.jobs,
        queryFilter: {
          ...state?.jobs?.queryFilter,
          equipment: ['ALL'],
          trip_distances: ['Local', 'Short', 'Long'],
          booking_type: 'ALL',
          date_queried: '',
          hide_sort_info: false,
        },
      },
    }
  },
}
