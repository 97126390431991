import React from 'react'
import styled, { css } from 'styled-components'

import { muiTheme } from '../../theme'
import { midGray } from '../../theme/colors'
import { header4 } from '../../theme/typography'

interface PillProps {
  children: React.ReactNode
  mt?: number
  mb?: number
  background?: string
  color?: string
  variant?: 'default' | 'small' | 'large'
}

export function Pill({ children, mb, mt, background, color, variant }: PillProps) {
  return (
    <Wrap $mb={mb} $mt={mt} $background={background} $color={color} $variant={variant}>
      {children}
    </Wrap>
  )
}

const Wrap = styled.span<{
  $mt?: number
  $mb?: number
  $background?: string
  $color?: string
  $variant?: PillProps['variant']
}>`
  ${header4};
  padding: 2px 8px 0;
  color: white;
  background: ${midGray};
  border-radius: 3px;
  display: inline-block;

  ${(p) =>
    p.$variant === 'small' &&
    css`
      font-size: 12px;
      padding: 2px 6px 0;
      height: 20px;
    `};

  ${(p) =>
    p.$variant === 'large' &&
    css`
      font-size: 16px;
      padding: 5px 12px 3px;
      height: 32px;
    `};

  ${(p) =>
    p.$background &&
    css`
      background-color: ${p.$background};
    `};

  ${(p) =>
    p.$color &&
    css`
      color: ${p.$color};
    `};

  ${(p) =>
    p.$mt &&
    css`
      margin-top: ${muiTheme.spacing() * p.$mt}px;
    `};

  ${(p) =>
    p.$mb &&
    css`
      margin-bottom: ${muiTheme.spacing() * p.$mb}px;
    `};
`
