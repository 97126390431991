import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { bgGray } from 'shared/theme/colors'
import styled, { css } from 'styled-components'

import { useShouldShowOnboardingBanner } from '../../hooks/useShouldShowOnboardingBanner'
import { OnboardingChecklist } from '../../models/onboarding'
import { OnboardingBanner } from '../../pages/Onboarding/OnboardingBanner'
import { isVendorUserSelector } from '../../redux/selectors/users'
import { useLayoutContext } from '../Context/LayoutContext'
import { ContentGrid, PageContainer } from './Container'
import { Navbar } from './Navbar/NavBar'
import { SideBar } from './Navbar/SideBar'
import { useSidebarWidth } from './Navbar/useSideBarWidth'
import { VendorNav } from './Navbar/VendorNav'

interface LayoutProps {
  children: ReactNode
  checklist: readonly OnboardingChecklist[] | null
}

export const NAV_HEIGHT = 70

export const Layout = ({ children, checklist }: LayoutProps) => {
  const isVendorUser = useSelector(isVendorUserSelector)
  const shouldShowOnboardingBanner = useShouldShowOnboardingBanner(checklist)
  const shouldHideAllUI = window.location.pathname.includes('mobile-auth')
  const { toolbar, fixNavToTop, toolbarHeight, isFlowPage } = useLayoutContext()
  const sidebarWidth = useSidebarWidth()

  if (shouldHideAllUI) {
    return <ContentGrid>{children}</ContentGrid>
  }

  return (
    <PageContainer
      sideBar={<SideBar />}
      toolbar={
        <>
          {isVendorUser ? (
            <NavbarWrap
              className={fixNavToTop ? 'mui-fixed' : ''} // This class will make that when any Dialog is open, the padding that is added to the body is also added to fixed elements
              $fixNavToTop={fixNavToTop}
              $sidebarWidth={sidebarWidth}
            >
              <ContentGrid noMaxWidth>
                <VendorNav />
              </ContentGrid>
            </NavbarWrap>
          ) : (
            <NavbarWrap
              className={fixNavToTop ? 'mui-fixed' : ''} // This class will make that when any Dialog is open, the padding that is added to the body is also added to fixed elements
              $fixNavToTop={fixNavToTop}
              $sidebarWidth={sidebarWidth}
            >
              <ContentGrid noMaxWidth>
                <Navbar />
              </ContentGrid>
            </NavbarWrap>
          )}
          <ToolbarWrap
            className={fixNavToTop ? 'mui-fixed' : ''}
            $fixNavToTop={fixNavToTop}
            $sidebarWidth={sidebarWidth}
          >
            <ContentGrid isFlowPage={isFlowPage}>
              {shouldShowOnboardingBanner && fixNavToTop && <OnboardingBanner checklist={checklist} />}
              <div ref={toolbar} />
            </ContentGrid>
          </ToolbarWrap>
        </>
      }
    >
      {fixNavToTop && <Offset $size={NAV_HEIGHT} />}
      {toolbar.current && <Offset $size={toolbarHeight} />}
      {shouldShowOnboardingBanner && !fixNavToTop && <OnboardingBanner checklist={checklist} />}
      <Box mt={toolbarHeight ? 0 : 5} />
      {isVendorUser && <Box mt={7} />}
      {children}
      <Box mt={5} />
    </PageContainer>
  )
}

const GenericWrap = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
`

const NavbarWrap = styled(GenericWrap)<{ $fixNavToTop: boolean; $sidebarWidth: number }>`
  grid-area: navbar;
  z-index: 4;
  background: ${bgGray};
  top: 0;

  ${(p) =>
    p.$fixNavToTop &&
    css`
      position: fixed;
      left: ${p.$sidebarWidth}px;
      right: 0;
      width: calc(100% - ${p.$sidebarWidth}px);
    `};
`

function getToolbarOffset(fixNavToTop: boolean) {
  if (fixNavToTop) {
    return NAV_HEIGHT
  }

  return 0
}

/*
  Toolbar is a fix element usually below the nav
  It can be either fixed when nav is fixed
  Or it can be sticky, when nav is static so it become fixed to the top on the scroll.

*/
const ToolbarWrap = styled(GenericWrap)<{ $fixNavToTop: boolean; $sidebarWidth: number }>`
  grid-area: toolbar;
  position: fixed;
  left: 0;
  right: 0;
  background: ${bgGray};
  z-index: 3;

  ${(p) =>
    p.$fixNavToTop &&
    css`
      left: ${p.$sidebarWidth}px;
      width: calc(100% - ${p.$sidebarWidth}px);
    `};

  ${(p) =>
    !p.$fixNavToTop &&
    css`
      position: sticky;
    `};

  ${(p) =>
    css`
      top: ${getToolbarOffset(p.$fixNavToTop)}px;
    `};
`

const Offset = styled.div<{ $size: number }>`
  ${(p) =>
    css`
      padding-top: ${p.$size}px;
    `};
`
