import axios from 'axios'
import * as HttpStatus from 'http-status-codes'

export function fetchAppSettings() {
  const settingsUrl = `/api/v1/settings/`
  return axios
    .get(settingsUrl)
    .then((response) => {
      if (response.status === HttpStatus.OK) return response.data
    })
    .catch((error) => {
      console.error(error)
      throw error
    })
}
