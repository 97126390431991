import HelpIcon from '@material-ui/icons/Help'
import * as React from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { ExternalLink } from 'shared/components/ExternalLink/ExternalLink'
import { NAV_LEFT_WRAP } from 'shared/components/Nav/NavLeft'
import { bgGray, blueGray } from 'shared/theme/colors'
import styled from 'styled-components'

import cloudtrucksLogo from 'assets/images/CT-logo-small.svg'
import { ONBOARDING_V2_REDIRECTED_KEY, REFERRAL_CODE, REFERRAL_SOURCE } from 'helpers/constants'
import { logoutUser } from 'redux/actions/auth/authActions'
import { ReduxDispatch } from 'redux/store/models'
import { isDevelopment } from '../../../config/config'
import { useAnalyticsEvent } from '../../../hooks/useAnalyticsEvent'
import { useLogRocket } from '../../../hooks/useLogRocket'
import { usePermission } from '../../../hooks/usePermission'
import { useProductSubscription } from '../../../hooks/useProductSubscription'
import { PermissionType } from '../../../models/permission'
import { AskAiButton } from '../../../pages/Consultant/AskAiButton'
import { ONBOARDING_TASK_LIST_PATH, SIGN_IN_PATH } from '../../../Routes'
import { AvatarMenu } from './AvatarMenu'
import useStyles from './styles'
import { useShowSideBar } from './useShowSideBar'

export const AppNav = () => {
  const { shutdown } = useIntercom()
  const classes = useStyles()
  const history = useHistory()
  const dispatch: ReduxDispatch = useDispatch()
  const queryClient = useQueryClient()
  const track = useAnalyticsEvent()
  const hasAiConsultantPermission = usePermission(PermissionType.AI_CONSULTANT)
  const { helpCenterUrl } = useProductSubscription()
  const showSidebar = useShowSideBar()

  useLogRocket()

  const handleLogout = () => {
    track('profile_logout_click')

    // clear referral info
    localStorage?.removeItem?.(REFERRAL_CODE)
    localStorage?.removeItem?.(REFERRAL_SOURCE)
    localStorage?.removeItem?.(ONBOARDING_V2_REDIRECTED_KEY)
    queryClient.clear()
    if (!isDevelopment) shutdown()

    dispatch(logoutUser()).then(() => {
      history.push(SIGN_IN_PATH)
    })
  }

  function renderContent() {
    return (
      <>
        {!showSidebar && (
          <img
            src={cloudtrucksLogo}
            alt="CloudTrucks Logo"
            className="logo"
            onClick={() => history.push(ONBOARDING_TASK_LIST_PATH)}
          />
        )}
        <div id={NAV_LEFT_WRAP} />
        <div className={classes.spacer}></div>
        {hasAiConsultantPermission.enabled && <AskAiButton />}
        <ExternalLink href={helpCenterUrl} onClick={() => track('nav_help_center_click')}>
          <HelpCenterIcon />
        </ExternalLink>
        <AvatarMenu handleLogout={handleLogout} />
      </>
    )
  }

  return <NavWrap>{renderContent()}</NavWrap>
}

const NavWrap = styled.div`
  min-height: 70px;
  background-color: ${bgGray};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;

  .logo {
    cursor: pointer;
  }

  /* stylelint-disable */
  /* Hide logo when nav left has any content */
  .logo:has(+ #${NAV_LEFT_WRAP} > *) {
    display: none;
  }
  /* stylelint-enable */
`

const HelpCenterIcon = styled(HelpIcon)`
  color: ${blueGray};
  margin: 5px 0 0 20px;
`
