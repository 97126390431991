import { useDispatch } from 'react-redux'
import { showErrorBanner } from 'shared/actions/ui/actions'
import { BannerProps } from 'shared/components/Context/UserContext'
import { parseError } from 'shared/helpers/errors'

export function useErrorMessage() {
  const dispatch = useDispatch()

  // TODO: Fix the type of e in the components that use this hook
  return function errorMessage(e: BannerProps | any) {
    const bannerProps: BannerProps = {
      // TODO: @taylangocmen - fix the type of e in the components that use this hook
      ...(e?.message && typeof e.message === 'string' && !e.message.toLowerCase?.().includes('request failed')
        ? { message: e.message, title: e.title }
        : { message: parseError(e) }),
      actionLabel: e?.actionLabel,
      action: e?.action,
      footer: e?.footer,
      actionRenderer: e?.actionRenderer,
      autoHideDuration: e?.autoHideDuration,
    }

    dispatch(showErrorBanner(bannerProps))
  }
}
