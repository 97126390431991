import * as React from 'react'
import { useLocation } from 'react-router'
import { DateRangeSelector, DateRangeValueType } from 'shared/components/DateRangeSelector/DateRangeSelector'
import { Handler, HandlerOf } from 'shared/helpers/typeHelper'

import {
  convertComponentJobFiltersToSearchFilters,
  getJobFiltersOptions,
  serializeSearchForUrlParams,
} from '../../../helpers/jobs'
import { Job, JobStatus } from '../../../models/jobs'
import { Truck } from '../../../models/truck'
import { JOBS_PAST_PATH } from '../../../Routes'
import { JobFiltersTypes, SelectedJobFiltersType, SelectedJobFiltersValuesType } from './jobFiltersConstants'
import { JobFiltersSelector } from './JobFiltersSelector'

interface JobFiltersOptionProps {
  filterType: JobFiltersTypes
  label: string
  jobs: Job[]
  trucks: Truck[]
  selectedFilters: SelectedJobFiltersType
  setSelectedFilters: HandlerOf<SelectedJobFiltersType>
  selectedFiltersValues: SelectedJobFiltersValuesType
  setSelectedFiltersValues: HandlerOf<SelectedJobFiltersValuesType>
  selectedDateRangeValue: DateRangeValueType
  setSelectedDateRangeValue: (nextRange: DateRangeValueType, done: boolean) => void
  updateUrlParams: (search: string) => void
  opener?: React.ReactNode
  isInitiallyOpen?: boolean
  onBlur?: Handler
}

export function JobFiltersOption({
  filterType,
  label,
  jobs,
  trucks,
  selectedFilters,
  setSelectedFilters,
  selectedFiltersValues,
  setSelectedFiltersValues,
  selectedDateRangeValue,
  setSelectedDateRangeValue,
  updateUrlParams,
  opener,
  isInitiallyOpen,
  onBlur,
}: JobFiltersOptionProps) {
  const { pathname } = useLocation()
  const filteredJobs = jobs?.filter((job) =>
    pathname === JOBS_PAST_PATH ? job.status === JobStatus.PAST : job.status !== JobStatus.PAST
  )

  if (filterType === JobFiltersTypes.PICKUP_DATE) {
    return (
      <DateRangeSelector
        key={filterType}
        selected={selectedFilters[filterType]}
        deselect={() => {
          setSelectedFilters({ ...selectedFilters, [JobFiltersTypes.PICKUP_DATE]: false })
          setSelectedDateRangeValue([undefined, undefined], true)
          updateUrlParams(
            serializeSearchForUrlParams(
              convertComponentJobFiltersToSearchFilters(selectedFiltersValues, [undefined, undefined])
            )
          )
        }}
        label={label}
        selectedDateRange={selectedDateRangeValue}
        setSelectedDateRange={(nextRange, done) => {
          setSelectedDateRangeValue(nextRange, done)
          if (done) {
            setTimeout(() => {
              updateUrlParams(
                serializeSearchForUrlParams(
                  convertComponentJobFiltersToSearchFilters(selectedFiltersValues, nextRange)
                )
              )
            }, 400)
          }
        }}
        opener={opener}
        isInitiallyOpen={isInitiallyOpen}
        onBlur={onBlur}
      />
    )
  }

  return (
    <JobFiltersSelector
      key={filterType}
      filterOption={filterType}
      selected={selectedFilters[filterType]}
      deselect={() => {
        setSelectedFilters({ ...selectedFilters, [filterType]: false })
        setSelectedFiltersValues({ ...selectedFiltersValues, [filterType]: 'ALL' })
        updateUrlParams(
          serializeSearchForUrlParams(
            convertComponentJobFiltersToSearchFilters(
              { ...selectedFiltersValues, [filterType]: undefined },
              selectedDateRangeValue
            )
          )
        )
      }}
      label={label}
      options={getJobFiltersOptions(filteredJobs, trucks, filterType)}
      selectedValue={selectedFiltersValues[filterType]}
      setSelectedValue={(nextSelectedValue) => {
        const updatedSelectedFiltersValues = {
          ...selectedFiltersValues,
          [filterType]: nextSelectedValue,
        }
        setSelectedFiltersValues(updatedSelectedFiltersValues)
        updateUrlParams(
          serializeSearchForUrlParams(
            convertComponentJobFiltersToSearchFilters(updatedSelectedFiltersValues, selectedDateRangeValue)
          )
        )
      }}
      opener={opener}
      isInitiallyOpen={isInitiallyOpen}
      onBlur={onBlur}
    />
  )
}
