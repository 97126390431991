import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { CacheKeys } from '../../helpers/cacheKeys'
import { getUser } from '../../models/user'
import { currentUserSelector } from '../../redux/selectors/users'

interface UseJobDetail {
  id: string
  enabled?: boolean
}

export function useUser({ enabled = true, id }: UseJobDetail) {
  const user = useSelector(currentUserSelector)
  const { data, isFetching, refetch, isLoading } = useQuery(
    `${CacheKeys.GET_USER}-${user.id}-${id}`,
    () => getUser(id),
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: true,
      retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    }
  )

  return {
    user: data,
    refetch,
    isLoading,
    isFetching,
  }
}
