import { Container as MuiContainer } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Button } from 'shared/components/Button/Button'
import { ContractorPaymentsIcon } from 'shared/components/Icons/ContractorPaymentsIcon'
import { Text } from 'shared/components/Text/Text'
import { sizes } from 'shared/theme'
import { breakpoints } from 'shared/theme/breakpoints'
import { cyan, darkGray, kellyGreen, royalBlue } from 'shared/theme/colors'
import styled, { css } from 'styled-components'

import { TruckTransparentIcon } from '../../components/Icons/Icon'
import {
  hasBusinessContractorOnboardingItems,
  hasPersonalContractorOnboardingItems,
} from '../../hooks/useShouldShowOnboardingBanner'
import { OnboardingChecklist } from '../../models/onboarding'
import { currentUserSelector } from '../../redux/selectors/users'
import { CONTRACTOR_PAYMENTS_PATH, ONBOARDING_TASK_LIST_PATH } from '../../Routes'

const StyledOnboardingBannerWrap = styled.div<{
  $hasContractorOnboardingItems?: boolean
  $hasBusinessContractorOnboarding?: boolean
}>`
  padding: 18px 0;
  background-color: ${darkGray};
  margin-bottom: 20px;
  text-align: center;
  border-radius: 4px;

  ${(p) =>
    p.$hasContractorOnboardingItems &&
    css`
      background-color: ${cyan};
    `}

  ${(p) =>
    p.$hasBusinessContractorOnboarding &&
    css`
      background-color: ${kellyGreen};
    `}
`

const StyledOnboardingBanner = styled.div`
  display: grid;
  grid-template-columns: 60px auto auto;
  grid-gap: 20px;
  align-items: center;
  text-align: left;

  @media ${breakpoints.xs} {
    grid-template-columns: 40px auto;
    align-items: flex-start;

    img {
      width: 40px;
      height: 40px;
    }
  }
`

const StyledOnboardingBannerContainer = styled.div`
  display: inline-block;
`

const StyledButtonWrap = styled.div`
  padding-left: 40px;

  @media ${breakpoints.xs} {
    padding-left: 0;
    grid-column-start: 1;
    grid-column-end: 3;
  }
`

const StyledButton = styled(Button)`
  background-color: ${cyan};
  color: ${royalBlue};

  &:hover:not(:disabled) {
    background: transparent;
    border-color: ${cyan};
    color: white;
  }

  @media ${breakpoints.xs} {
    width: 100%;
  }
`

const OutlinedIcon = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid white;
  border-radius: 50%;
  color: white;

  svg {
    margin: 0;
    width: 25px;
  }

  @media ${breakpoints.xs} {
    width: 40px;
    height: 40px;
    border-width: 2px;

    svg {
      width: 18px;
    }
  }
`

const StyledContainer = styled(MuiContainer)`
  line-height: 1;
`

interface OnboardingBannerProps {
  checklist: readonly OnboardingChecklist[] | null
}

export function OnboardingBanner({ checklist }: OnboardingBannerProps) {
  const currentUser = useSelector(currentUserSelector)
  const hasBusinessContractorOnboarding = hasBusinessContractorOnboardingItems(checklist)
  const hasPersonalContractorOnboarding = hasPersonalContractorOnboardingItems(checklist)
  const hasContractorOnboardingItems = hasBusinessContractorOnboarding || hasPersonalContractorOnboarding

  function renderTitle() {
    if (!hasContractorOnboardingItems) {
      if (currentUser.is_team_admin) return 'Finish setting up your fleet'
      return 'Your onboarding is in progress'
    }
    if (hasBusinessContractorOnboarding) return 'Your payroll setup is in progress'
    return 'Finish adding yourself to payroll'
  }

  function renderContent() {
    return (
      <StyledOnboardingBannerContainer>
        <StyledOnboardingBanner>
          {hasContractorOnboardingItems ? (
            <OutlinedIcon>
              <ContractorPaymentsIcon />
            </OutlinedIcon>
          ) : (
            <img src={TruckTransparentIcon} alt="" />
          )}
          <Text variant="header2" fontColor="white">
            {renderTitle()}
          </Text>
          <StyledButtonWrap>
            {hasContractorOnboardingItems ? (
              <Button
                variant="primary"
                xsSize={sizes.medium}
                href={`${CONTRACTOR_PAYMENTS_PATH}${hasBusinessContractorOnboarding ? '' : '?type=personal'}`}
              >
                Complete Tasks
              </Button>
            ) : (
              <StyledButton variant="primary" xsSize={sizes.medium} href={ONBOARDING_TASK_LIST_PATH}>
                Complete Tasks
              </StyledButton>
            )}
          </StyledButtonWrap>
        </StyledOnboardingBanner>
      </StyledOnboardingBannerContainer>
    )
  }

  return (
    <StyledOnboardingBannerWrap
      $hasContractorOnboardingItems={hasContractorOnboardingItems}
      $hasBusinessContractorOnboarding={hasBusinessContractorOnboarding}
    >
      <StyledContainer>{renderContent()}</StyledContainer>
    </StyledOnboardingBannerWrap>
  )
}
