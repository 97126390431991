import { Theme, useMediaQuery } from '@material-ui/core'
import { Text } from 'shared/components/Text/Text'
import { lightGray1 } from 'shared/theme/colors'

import { SubTask } from './TaskList'
import { StyledIcon, StyledMain, StyledStatus, StyledSubTask } from './TaskList.styles'
import { getIconName } from './TaskList.util'
import { TaskListCta } from './TaskListCta'

interface TaskListSubTaskProps {
  subTask: SubTask
  isReadOnly?: boolean
}

export function TaskListSubTask({ subTask, isReadOnly }: TaskListSubTaskProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  function handleClick() {
    if (!isMobile) return
    subTask.onCtaClick?.()
  }

  return (
    <StyledSubTask onClick={handleClick}>
      <StyledIcon>
        <img src={getIconName(subTask)} width="24px" alt="" />
      </StyledIcon>
      <StyledMain>
        <Text variant="header3" fontColor={isReadOnly ? lightGray1 : undefined}>
          {subTask.title}

          {subTask.subTitle && (
            <Text
              variant="paragraph3"
              as={isMobile ? 'div' : 'span'}
              fontColor={lightGray1}
              fontWeight={300}
              styles={`
                margin: ${isMobile ? '0' : '0 0 0 7px'};
                position: relative;
                bottom: 1px;
              `}
            >
              {subTask.subTitle}
            </Text>
          )}
        </Text>
      </StyledMain>
      <StyledStatus>
        <Text variant="paragraph2" fontColor={lightGray1} fontWeight={400}>
          {subTask.statusText}
        </Text>
      </StyledStatus>
      <TaskListCta task={subTask} isReadOnly={isReadOnly} />
    </StyledSubTask>
  )
}
