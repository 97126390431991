import SendIcon from '@material-ui/icons/Send'
import autosize from 'autosize'
import React, { KeyboardEventHandler, useEffect, useMemo, useRef, useState } from 'react'
import { Button } from 'shared/components/Button/Button'
import { TextAreaInput } from 'shared/components/TextInput/TextAreaInput'
import { HandlerOf } from 'shared/helpers/typeHelper'
import { breakpoints } from 'shared/theme/breakpoints'
import { formFill } from 'shared/theme/colors'
import styled from 'styled-components'

import { ConsultantConversationMessage } from '../../models/consultant'
import { ConsultantConversationExamples } from './ConsultantConversationExamples'

interface ConsultantConversationComposerProps {
  messages: ConsultantConversationMessage[]
  onMessage: (value: string) => Promise<void>
  onResize: HandlerOf<number>
}

const defaultQuestionExamples = [
  "What's the weather like at my current job's dropoff?",
  "How's the LA to Dallas lane looking?",
  'How much would it cost me to drive to Austin?',
  'How do I use the CloudTrucks app to find the best markets?',
]

export function ConsultantConversationComposer({
  messages,
  onMessage,
  onResize,
}: ConsultantConversationComposerProps) {
  const textarea = useRef<HTMLTextAreaElement>(null)
  const [message, setMessage] = useState('')
  const [showExamples, setShowExamples] = useState(messages.length === 0)
  const questionExamples = useMemo(
    () => defaultQuestionExamples.filter((q) => !messages.some((m) => m.text === q)),
    [messages]
  )

  useEffect(() => {
    if (textarea.current) {
      const el = textarea.current
      autosize(el)
      el.addEventListener('autosize:resized', () => onResize(300))

      return () => {
        const evt = new Event('autosize:destroy', { bubbles: true, cancelable: false })
        el?.dispatchEvent(evt)
      }
    }
  }, [onResize])

  function handleTextChange(e: any) {
    setMessage(e.target.value)
  }

  async function handleSubmit() {
    if (!message) return
    if (showExamples) setShowExamples(false)
    await onMessage(message)
    setMessage('')
  }

  const handleEnter: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey) {
      event.preventDefault()
      event.stopPropagation()
      handleSubmit()
      setTimeout(() => {
        const evt = new Event('autosize:update', { bubbles: true, cancelable: false })
        textarea.current?.dispatchEvent(evt)
      }, 10)
    }
  }

  async function handleExampleSubmit(message: string) {
    await onMessage(message)
    setShowExamples(false)
  }

  function handleShowExamples() {
    setShowExamples(!showExamples)
  }

  return (
    <Composer>
      {showExamples && (
        <ConsultantConversationExamples onMessage={handleExampleSubmit} questionExamples={questionExamples} />
      )}

      <ComposerWrap>
        <TextAreaInput
          ref={textarea}
          rows={1}
          noMargin
          placeholder="Ask a question"
          value={message}
          onChange={handleTextChange}
          onKeyDown={handleEnter}
        />
        <ComposerButton icon={<SendIcon />} variant="primary" size="large" onClick={handleSubmit} />
      </ComposerWrap>

      {messages.length !== 0 && questionExamples.length > 0 && (
        <Button variant="text" mt={2} onClick={handleShowExamples}>
          See example questions
        </Button>
      )}
    </Composer>
  )
}

const Composer = styled.div`
  grid-area: conversation-composer;
  padding: 20px 40px;
  position: sticky;
  background: white;
  bottom: 0;
  z-index: 5;

  @media ${breakpoints.sm} {
    padding: 20px;
  }
`

const ComposerWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  gap: 12px;

  .textarea-wrap {
    width: 100%;
    min-width: 0;
    max-width: 100%;
    border-radius: 3px;
    background: ${formFill};
    height: auto;
  }

  textarea {
    padding-top: 15px;
    max-height: 200px;
    overflow-x: hidden !important;
  }
`
const ComposerButton = styled(Button)`
  margin-top: 8px;

  span {
    margin-right: 0;
  }

  svg {
    margin: 3px 0 0 1px;
  }
`
