import { CashState } from 'models/cashSummary'
import * as types from '../actions/cashActions/actionTypes'

let initState: CashState = {
  summary: {
    account_balances: [],
    num_cards_not_cancelled: 0,
    team: '',
    payouts_enabled: false,
    ineligibleCreditStates: [],
    ineligibleSolePropCreditStates: [],
    team_card_user_object: {
      id: '',
      first_name: '',
      last_name: '',
      driver: {},
      shipping_address: { id: '', line1: '', line2: '', city: '', state: '', country: '', zip: '' },
    },
  },
  cards: [],
}

// TODO: Remove default export
// eslint-disable-next-line import/no-default-export
export default function cashReducer(state = initState, action: any) {
  switch (action.type) {
    case types.SET_CASH_SUMMARY:
      return { ...state, summary: action.data }
    case types.SET_CARD:
      const cards = [...state.cards, action.card]
      let numberCards = state.summary.num_cards_not_cancelled
      return {
        ...state,
        summary: {
          ...state.summary,
          cards,
          num_cards_not_cancelled: ++numberCards,
        },
        cards: cards,
      }
    default:
      return state || initState
  }
}
