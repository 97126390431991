import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Box } from 'shared/components/Box/Box'
import { Button } from 'shared/components/Button/Button'
import { Handler } from 'shared/helpers/typeHelper'

import { CLOUDTRUCKS_SUCCESS_MOBILE } from 'helpers/constants'
import useStyles from './style'

interface MaxAttemptsProps {
  redirectToSignIn: Handler
}

export function MaxAttempts({ redirectToSignIn }: MaxAttemptsProps) {
  const classes = useStyles()
  return (
    <Grid container justifyContent="center" spacing={2} className={classes.root}>
      <Grid item md={8} xs={12}>
        <Typography component="h2" variant="h3" align="center" className={classes.typography}>
          Maximum attempts exceeded
        </Typography>
      </Grid>
      <Grid item lg={9} xs={11}>
        <Typography variant="subtitle1" align="center" className={classes.subtitle1}>
          Please edit your contact information or call CloudTrucks Customer service at{' '}
          {CLOUDTRUCKS_SUCCESS_MOBILE}
        </Typography>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Box mt={3} />
        <Button onClick={redirectToSignIn} margin="0 auto">
          Return to home page
        </Button>
      </Grid>
    </Grid>
  )
}
