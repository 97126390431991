import { Handler } from 'shared/helpers/typeHelper'

export const loadGoogleMapApis = (
  googleApiKey: string,
  onLoad?: Handler,
  googleApisLoaded: boolean = false
) => {
  const position = document.querySelector('head')
  if (!position) return
  const maps = document.getElementById('google-maps')
  // load maps if we haven't already
  if (!maps) {
    if (onLoad) {
      ;(window as any).initGoogleMap = onLoad
    }
    const script = document.createElement('script')
    script.setAttribute('id', 'google-maps')
    script.async = true
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places${
      onLoad ? '&callback=initGoogleMap' : ''
    }`
    position.appendChild(script)
  } else {
    // maps is already loaded, just call the callback
    if (onLoad && typeof onLoad === 'function' && googleApisLoaded) {
      onLoad()
    }
  }
}
