import { forwardRef, HTMLAttributes, Ref } from 'react'
import styled, { css, IStyledComponent } from 'styled-components'

import { AddPrefixToObject } from '../../helpers/typeHelper'

export const TextCentered = styled.div`
  text-align: center;
`

export const TextLeft = styled.div`
  text-align: left;
`

export const TextRight = styled.div`
  text-align: right;
`

export const Capitalize = styled.div`
  text-transform: capitalize;
`

export const TextNoOverflow = styled.div`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const StyledTextClamp = styled.div<AddPrefixToObject<TextClampProps, '$'>>`
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${(p) =>
    p.$lines &&
    css`
      -webkit-line-clamp: ${p.$lines};
    `};

  /* stylelint-enable */
  overflow: hidden;
`

interface TextClampProps {
  lines?: number
}

export const TextClamp = forwardRef(({ lines, ...rest }, ref: Ref<any>) => {
  return <StyledTextClamp $lines={lines} ref={ref} {...rest} />
}) as IStyledComponent<'web', TextClampProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>
