import { EquipmentType } from './equipment'
import { ConnectedIntegrationStatusEnum } from './integration'

export interface Load {
  id: string
  originalSourceId?: string
  postedDate: string | null
  tripStops: string
  source: string
  providerDisplayName: string
  comment?: string
  contactPhone?: string
  contactPhoneBooking: string
  contactEmail: string
  contactEmailBooking: string
  truckLengthFt: number
  equipmentDetail?: string
  equipment: EquipmentType[]
  tripDistanceMi: number
  truckWeightLb: number
  contactName: string
  totalDeadheadMi: number
  sourceId: string
  sourceHumanId: string
  truckFullLoad: boolean
  instantBook: boolean
  standardBook: boolean
  offlineBook: boolean
  tripRate: number
  brokerName?: string | null
  brokerLoadId?: string | null
  brokerIsShipper?: boolean | null
  brokerMcNumber?: string | null
  companyName: string
  estimatedRate: number
  estimatedRateMin: number
  estimatedRateMax: number
  estimatedRevenuePerHour: number
  prexScores: number
  bidBook: boolean
  stops: LoadStop[]
  dateBooked?: string
  originDeadheadMi?: number | null
  destDeadheadMi?: number | null
  integrationStatus?: ConnectedIntegrationStatusEnum
  originPickupDate?: string
  destDeliveryDate?: string
  bidMin?: number
  bidMax?: number
  maskedData?: boolean
  ageMin?: number | null
  broker?: string
  loadTags?: LoadTag[] | null
  bookmarkedLoadId?: string
  contactedLoadId?: string
  isContacted: boolean
  bookingTypes: LoadBookingTypeOption[]
  sourceDetailLink?: string

  // Need parsing
  details: {
    origin: LoadStop
    hasEstimates: boolean
    tripRate: number
    tripRatePerMile: string
    loadEquipment: string
    ctRange: string | null
    ctEstimate: string | null
    loadOriginLocation: string
    destination?: LoadStop
    loadDestLocation: string
  }
}

export interface LoadStop {
  dateEnd: string
  dateStart: string
  dateEndTimezoneName: string
  dateStartTimezoneName: string
  isAppointmentConfirmed: boolean
  loadingType: LoadStopLoadingType | null
  locationAddress1: string
  locationAddress2: string
  locationCity: string
  locationLat: number
  locationLong: number
  locationName?: string
  locationState: string
  locationTimezone: string
  locationZip: string
  sequenceNumber: number
  notes: string
  type: LoadStopType
  referenceCode: string
  truckWeightLb: number
  typeDetail: LoadStopTypeDetail
  trailerNumber: string
}

export interface LocationVector {
  latitude: number
  longitude: number
  timestamp: string
}

export interface LoadStopProgress {
  dateArrived: string
  dateDeparted: string
  dateEstimatedArrival: string
  dateUpdated: string
  isCurrent: boolean
  status: LoadStopProgressStatus
  stop: LoadStop
  trackingLastLocationVector: LocationVector
  lastScheduleEvent: ScheduleEvent
}

export interface ScheduleEvent {
  driveDateEnd: string
  offDutyHours: number
  onDutyHours: number
  totalHours: number
}

export interface LoadTag {
  name: LoadTagName
  value: boolean
}

export interface ContactedLoad {
  id: string
  status: ContactedLoadStatus
  load: Load
  bidAmount: number
  assignedTo: string
  bidHistory?: any[]
  bidMinimum?: string
  bidMaximum?: string
}

export enum LoadTagName {
  HOT_MARKET = 'hot_market',
  RECOMMENDED = 'recommended',
  SHIPPER = 'shipper',
  RELOAD = 'reload',
  BUNDLED_LOAD = 'bundled_load',
}

export const LoadTagNameDisplay = {
  [LoadTagName.HOT_MARKET]: 'More reloads',
  [LoadTagName.RECOMMENDED]: 'Recommended',
  [LoadTagName.SHIPPER]: 'Shipper direct',
  [LoadTagName.RELOAD]: 'Reload',
  [LoadTagName.BUNDLED_LOAD]: 'Bundled load',
}

export const LoadTagNameDisplaySortOrder = {
  [LoadTagName.RECOMMENDED]: 1,
  [LoadTagName.HOT_MARKET]: 2,
  [LoadTagName.BUNDLED_LOAD]: 3,
  [LoadTagName.SHIPPER]: 4,
  [LoadTagName.RELOAD]: 5,
}

export enum BookingTypeOption {
  INSTANT = 'INSTANT',
  STANDARD = 'STANDARD',
  BIDDING = 'BIDDING',
  OFFLINE = 'OFFLINE',
  PLATFORM = 'PLATFORM',
  CONTACTED = 'CONTACTED',
}

export interface LoadBookingTypeOption {
  type: BookingTypeOption
  error: null | 'link_provider' | 'mc_number' | 'dot_number'
}

export enum LoadStopProgressStatus {
  SCHEDULED = 'SCHEDULED',
  EN_ROUTE = 'EN_ROUTE',
  AT_FACILITY = 'AT_FACILITY',
  DEPARTED = 'DEPARTED',
  SKIPPED = 'SKIPPED',
}

export enum TrackingStatus {
  EARLY = 'EARLY',
  ON_TIME = 'ON_TIME',
  MAYBE_LATE = 'MAYBE_LATE',
  LATE = 'LATE',
  AT_FACILITY = 'AT_FACILITY',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export enum LoadStopType {
  ORIGIN = 'ORIGIN',
  DESTINATION = 'DESTINATION',
  STOP = 'STOP',
}

export enum LoadStopTypeDetail {
  PREHAUL = 'PREHAUL',
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
}

export const LoadStopTypeDetailLabel: Record<LoadStopTypeDetail, string> = {
  [LoadStopTypeDetail.PREHAUL]: 'Prehaul',
  [LoadStopTypeDetail.PICKUP]: 'Pickup',
  [LoadStopTypeDetail.DELIVERY]: 'Delivery',
}

export enum LoadStopLoadingType {
  DROP = 'DROP',
  LIVE = 'LIVE',
  PRELOADED = 'PRELOADED',
}

export const LoadStopLoadingTypeLabel: Record<LoadStopLoadingType, string> = {
  [LoadStopLoadingType.DROP]: 'Drop',
  [LoadStopLoadingType.LIVE]: 'Live',
  [LoadStopLoadingType.PRELOADED]: 'Preloaded',
}

export enum ContactedLoadStatus {
  BOOKED = 'BOOKED',
  EXPIRED = 'EXPIRED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  OPEN = 'OPEN',
}

export enum DistanceTypes {
  LOCAL = 'Local',
  SHORT = 'Short',
  LONG = 'Long',
}

export enum LoadBookingTypes {
  INSTANT = 'INSTANT',
  STANDARD = 'STANDARD',
  ALL = 'ALL',
}

export enum LoadBookingSources {
  DAT = 'DAT',
}
