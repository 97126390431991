import { useEffect } from 'react'
import { FlexBox } from 'shared/components/Base/Base'
import { DateRangeValueType } from 'shared/components/DateRangeSelector/DateRangeSelector'
import { Handler, HandlerOf } from 'shared/helpers/typeHelper'
import styled from 'styled-components'

import { useJobs } from '../../../hooks/query/useJobs'
import { useTrucks } from '../../../hooks/query/useTrucks'
import {
  JOB_FILTERS_TOOLBAR_HEIGHT,
  JobFiltersOptions,
  SelectedJobFiltersType,
  SelectedJobFiltersValuesType,
} from './jobFiltersConstants'
import { JobFiltersOption } from './JobFiltersOption'

interface Props {
  updateUrlParams: HandlerOf<string>
  initializeAllFilters: Handler
  selectedFilters: SelectedJobFiltersType
  setSelectedFilters: HandlerOf<SelectedJobFiltersType>
  selectedFiltersValues: SelectedJobFiltersValuesType
  setSelectedFiltersValues: HandlerOf<SelectedJobFiltersValuesType>
  selectedDateRangeValue: DateRangeValueType
  setSelectedDateRangeValue: HandlerOf<DateRangeValueType>
}

export function JobFiltersRow({
  updateUrlParams,
  initializeAllFilters,
  selectedFilters,
  setSelectedFilters,
  selectedFiltersValues,
  setSelectedFiltersValues,
  selectedDateRangeValue,
  setSelectedDateRangeValue,
}: Props) {
  const { jobs, isLoading } = useJobs()
  const { trucks } = useTrucks({ shouldFetchAllPages: true })

  useEffect(() => {
    initializeAllFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading || !jobs) {
    return <Container />
  }

  return (
    <Container
      justifyContent="flex-start"
      alignItems="center"
      gap="8px"
      $hasSelected={Object.values(selectedFilters).some((x) => x)}
    >
      {JobFiltersOptions.filter(({ value: filterType }) => selectedFilters[filterType]).map(
        ({ value: filterType, label }) => (
          <JobFiltersOption
            key={filterType}
            filterType={filterType}
            label={label}
            jobs={jobs}
            trucks={trucks}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            selectedFiltersValues={selectedFiltersValues}
            setSelectedFiltersValues={setSelectedFiltersValues}
            selectedDateRangeValue={selectedDateRangeValue}
            setSelectedDateRangeValue={setSelectedDateRangeValue}
            updateUrlParams={updateUrlParams}
          />
        )
      )}
    </Container>
  )
}

const Container = styled(FlexBox)<{ $hasSelected?: boolean }>`
  height: ${({ $hasSelected }) => ($hasSelected ? `${JOB_FILTERS_TOOLBAR_HEIGHT}px` : '0')};

  flex-wrap: wrap;
`
