import { forwardRef, HTMLAttributes, Ref } from 'react'
import styled, { css, IStyledComponent } from 'styled-components'

import { AddPrefixToObject } from '../../helpers/typeHelper'
import { muiTheme } from '../../theme'

const StyledWrap = styled.div<AddPrefixToObject<WrapProps, '$'>>`
  max-width: 424px;
  margin: 0 auto;

  ${(p) =>
    p.$width &&
    css`
      width: ${p.$width};
    `};

  ${(p) =>
    p.$mt &&
    css`
      margin-top: ${muiTheme.spacing() * p.$mt}px;
    `};

  ${(p) =>
    p.$mb &&
    css`
      margin-bottom: ${muiTheme.spacing() * p.$mb}px;
    `};

  ${(p) =>
    p.$maxWidth &&
    css`
      max-width: ${p.$maxWidth};
    `};

  ${(p) =>
    p.$align &&
    css`
      text-align: ${p.$align};
    `};

  ${(p) => p.$styles};
`

interface WrapProps {
  mt?: number
  mb?: number
  width?: string
  maxWidth?: string
  align?: 'left' | 'right' | 'center'
  styles?: string
}

export const Wrap = forwardRef(({ mt, mb, width, maxWidth, align, styles, ...rest }, ref: Ref<any>) => {
  return (
    <StyledWrap
      $mt={mt}
      $mb={mb}
      $width={width}
      $maxWidth={maxWidth}
      $align={align}
      $styles={styles}
      ref={ref}
      {...rest}
    />
  )
}) as IStyledComponent<'web', WrapProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>

const StyledWideWrap = styled(Wrap)<AddPrefixToObject<WideWrapProps, '$'>>`
  max-width: 648px;

  ${(p) =>
    p.$display &&
    css`
      display: ${p.$display};
    `};
`

export interface WideWrapProps extends WrapProps {
  display?: string
}

export const WideWrap = forwardRef(({ mt, mb, width, maxWidth, align, display, ...rest }, ref: Ref<any>) => {
  return (
    <StyledWideWrap
      $mt={mt}
      $mb={mb}
      $width={width}
      $maxWidth={maxWidth}
      $align={align}
      $display={display}
      ref={ref}
      {...rest}
    />
  )
}) as IStyledComponent<'web', WideWrapProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>

export const StyledPageWrap = styled(Wrap)<AddPrefixToObject<PageWrapProps, '$'>>`
  margin: 60px auto 0;
  max-width: 1440px;

  ${(p) =>
    p.$isNarrow &&
    css`
      max-width: 1280px;
    `};

  ${(p) =>
    p.$noMargin &&
    css`
      margin-top: 0;
    `};

  ${(p) =>
    p.$padding &&
    css`
      padding: ${p.$padding};
    `};

  ${(p) =>
    p.$maxWidth &&
    css`
      max-width: ${p.$maxWidth};
    `};
`

interface PageWrapProps extends WrapProps {
  isNarrow?: boolean
  noMargin?: boolean
  maxWidth?: string
  padding?: string
}

export const PageWrap = forwardRef(
  ({ mt, mb, width, maxWidth, align, isNarrow, noMargin, padding, ...rest }, ref: Ref<any>) => {
    return (
      <StyledPageWrap
        $mt={mt}
        $mb={mb}
        $width={width}
        $maxWidth={maxWidth}
        $align={align}
        $padding={padding}
        $isNarrow={isNarrow}
        $noMargin={noMargin}
        ref={ref}
        {...rest}
      />
    )
  }
) as IStyledComponent<'web', PageWrapProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>
