import axios from 'axios'
import { sumBy } from 'lodash'
import queryString from 'query-string'
import { CamelToSnakeCaseNested, DeepPartial, PaginatedResults } from 'shared/helpers/typeHelper'

import { formatDateForApiWithoutTime } from '../helpers/formatters'
import { parsePlaidAccount, PlaidAccount } from './plaid'

export enum AstraUserType {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL',
}

export enum AstraUserStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  RETRY = 'retry',
  DOCUMENT = 'document',
  SUSPENDED = 'suspended',
  REJECTED = 'rejected',
  CONVERTED_TO_USER = 'converted_to_user',
  NOT_ONBOARDED = 'not_onboarded',
}

export enum AstraLinkType {
  ACCOUNT = 'account',
  CARD = 'card',
}

export enum AstraLinkStatus {
  CONNECTED = 'connected',
  PROCESSING = 'processing',
  ERROR = 'error',
  REMOVED = 'removed',
}

export enum ContractorPaymentLineItemType {
  // addition line item types
  LUMPER = 'lumper',
  TONU = 'tonu',
  DETENTION = 'detention',
  BONUS = 'bonus',
  LAYOVER = 'layover',
  OTHER_ADDITION = 'other_addition',

  // deduction line item types
  FUEL = 'fuel',
  MAINTENANCE = 'maintenance',
  TRAILER = 'trailer',
  TOLL = 'toll',
  AUTO_CARGO_LIABILITY_INSURANCE = 'auto_cargo_liability_insurance',
  OCC_ACC_INSURANCE = 'occ_acc_insurance',
  PHYSICAL_INSURANCE = 'physical_insurance',
  ELD = 'eld',
  OTHER_DEDUCTION = 'other_deduction',
}

export enum PaymentOption {
  ASTRA_BANK = 'astra_bank',
  CT_CASH_BALANCE = 'ct_cash_balance',
}

export const ContractorAdditionPaymentLineItem = [
  ContractorPaymentLineItemType.LUMPER,
  ContractorPaymentLineItemType.TONU,
  ContractorPaymentLineItemType.DETENTION,
  ContractorPaymentLineItemType.BONUS,
  ContractorPaymentLineItemType.LAYOVER,
  ContractorPaymentLineItemType.OTHER_ADDITION,
]

export const ContractorDeductionPaymentLineItem = [
  ContractorPaymentLineItemType.FUEL,
  ContractorPaymentLineItemType.MAINTENANCE,
  ContractorPaymentLineItemType.TRAILER,
  ContractorPaymentLineItemType.TOLL,
  ContractorPaymentLineItemType.AUTO_CARGO_LIABILITY_INSURANCE,
  ContractorPaymentLineItemType.OCC_ACC_INSURANCE,
  ContractorPaymentLineItemType.PHYSICAL_INSURANCE,
  ContractorPaymentLineItemType.ELD,
  ContractorPaymentLineItemType.OTHER_DEDUCTION,
]

export const ContractorPaymentLineItemTypeText: Record<ContractorPaymentLineItemType, string> = {
  [ContractorPaymentLineItemType.LUMPER]: 'Lumper',
  [ContractorPaymentLineItemType.TONU]: 'TONU',
  [ContractorPaymentLineItemType.DETENTION]: 'Detention',
  [ContractorPaymentLineItemType.BONUS]: 'Bonus',
  [ContractorPaymentLineItemType.LAYOVER]: 'Layover',
  [ContractorPaymentLineItemType.FUEL]: 'Fuel',
  [ContractorPaymentLineItemType.MAINTENANCE]: 'Maintenance',
  [ContractorPaymentLineItemType.TRAILER]: 'Trailer',
  [ContractorPaymentLineItemType.TOLL]: 'Toll',
  [ContractorPaymentLineItemType.AUTO_CARGO_LIABILITY_INSURANCE]: 'Auto & Cargo Liability Insurance',
  [ContractorPaymentLineItemType.OCC_ACC_INSURANCE]: 'Occupational Accident Insurance',
  [ContractorPaymentLineItemType.PHYSICAL_INSURANCE]: 'Physical Damage Insurance',
  [ContractorPaymentLineItemType.ELD]: 'ELD',
  [ContractorPaymentLineItemType.OTHER_ADDITION]: 'Other',
  [ContractorPaymentLineItemType.OTHER_DEDUCTION]: 'Other',
}

export enum AstraRoutineStatus {
  REQUIRES_USER_VERIFICATION = 'requires_user_verification',
  PENDING_ACCOUNT_AUTHORIZATION = 'pending_account_authorization',
  USER_SUSPENDED = 'user_suspended',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
  COMPLETED = 'completed',
}

export enum AstraRoutineType {
  ONE_TIME = 'one-time',
  PERCENTAGE_BASED = 'percentage-based',
  PERCENTAGE_BALANCE = 'percentage-balance',
  RECURRING = 'recurring',
  REFILL = 'refill',
  ROUND_UP = 'round-up',
  SWEEP = 'sweep',
  FIFTY_TWO_WEEK = 'fifty-two-week',
}

export enum AstraRoutinePaymentType {
  ACH = 'ach',
  DEBIT = 'debit',
  LEDGER = 'ledger',
}

export enum AstraRoutineTransferStatus {
  PENDING = 'pending',
  PROCESSED = 'processed',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
}

export const AstraRoutineTransferStatusText: Record<AstraRoutineTransferStatus, string> = {
  [AstraRoutineTransferStatus.PENDING]: 'Pending',
  [AstraRoutineTransferStatus.PROCESSED]: 'Completed',
  [AstraRoutineTransferStatus.CANCELLED]: 'Canceled',
  [AstraRoutineTransferStatus.FAILED]: 'Failed',
}

export enum AstraSettlementSpeedType {
  NOT_SUPPORTED = 'not_supported',
  INSTANT = 'instant',
  T_1 = 'T+1',
  T_2 = 'T+2',
  T_4 = 'T+4',
}

export enum PaymentCalculationMethod {
  CUSTOM_AMOUNT = 'custom_amount',
  LINE_HAUL_PERCENTAGE = 'line_haul_percentage',
  PER_MILE = 'per_mile',
}

export const PaymentCalculationMethodText: Record<PaymentCalculationMethod, string> = {
  [PaymentCalculationMethod.CUSTOM_AMOUNT]: 'Custom',
  [PaymentCalculationMethod.LINE_HAUL_PERCENTAGE]: 'Line haul percentage',
  [PaymentCalculationMethod.PER_MILE]: 'Rate per mile',
}

export interface SlimAstraUser {
  id: string
  customUserId: string
  type: AstraUserType
  status: AstraUserStatus
  name: string
  isOnboarded: boolean
  isOnboardedForInstantPayouts: boolean
  isRefreshTokenExpired: boolean
}

export interface AstraUser extends SlimAstraUser {
  userId: string | null
  userIntentId: string | null
  createdById: string
  rate: string
  debitInstantFeePercent: number
  card?: AstraUserLink
  bankAccount?: AstraUserLink
}

export interface AstraUserLink {
  id: string
  status: AstraLinkStatus
  type: AstraLinkType
  last4: string
  name: string
  plaidAccount: PlaidAccount | null
  astraUser: SlimAstraUser
  supportsInstantPay: boolean
}

export interface AstraRoutineLineItem {
  type: ContractorPaymentLineItemType
  amount: number
  description: string
}

export interface AstraRoutine {
  id: string
  dateCreated: Date
  dateUpdated: Date
  routineId: string
  status: AstraRoutineStatus
  type: AstraRoutineType
  paymentType: AstraRoutinePaymentType
  preferredSettlementSpeed: AstraSettlementSpeedType
  fee: number
  amount: number
  lineItems: AstraRoutineLineItem[]
  sourceLink: AstraUserLink
  destinationLink: AstraUserLink

  paymentCalculationMethod: PaymentCalculationMethod
  baseRate: number
  lineHaulPercent?: number
  miles?: number

  canBeCanceled: boolean

  transfers: AstraRoutineTransfer[]
  transfer?: AstraRoutineTransfer
}

export interface AstraRoutineTransfer {
  id: string
  transferId: string
  dateCreated: Date
  dateUpdated: Date
  status: AstraRoutineTransferStatus
  amount: number
}

/* ************************************* */
/*                API                    */
/* ************************************* */

/************ ASTRA USERS  *************/

export function getAstraVerificationUrl({
  userType,
  userIntentId,
}: {
  userType: AstraUserType
  userIntentId?: string
}): Promise<string> {
  const params = {
    is_business: userType === AstraUserType.BUSINESS ? true : undefined,
    user_intent_id: userIntentId,
  }
  return axios
    .get(`/api/v1/cash/contractor-payments/astra/urls/?${queryString.stringify(params)}`)
    .then((response) => {
      return response.data.authorization_url
    })
}

export function createAstraUser({
  userType,
  customUserId,
  authorizationCode,
}: {
  userType: AstraUserType
  customUserId?: string
  authorizationCode?: string
}): Promise<string> {
  const payload = {
    custom_user_id: customUserId,
    user_type: userType,
    authorization_code: authorizationCode,
  }
  return axios.post(`/api/v1/cash/contractor-payments/astra/users/`, payload).then((response) => {
    return response.data.user_intent_id
  })
}

interface CreateAstraUserIntentPayload {
  email: string
  phone: string
  first_name: string
  last_name: string
  address1: string
  city: string
  state: string
  postal_code: string
  date_of_birth: string
  ssn: string
}

export function createAstraUserIntent(userInfo: CreateAstraUserIntentPayload): Promise<string> {
  return axios
    .post(`/api/v1/cash/contractor-payments/astra/user-intents/`, { user_info: userInfo })
    .then((response) => {
      return response.data.user_intent_id
    })
}

export function sentInviteEmail({ customUserId }: { customUserId?: string }) {
  const payload = {
    custom_user_id: customUserId,
  }
  return axios.post(`/api/v1/cash/contractor-payments/astra/user-intents/email/`, payload)
}

export function getPayrollMembers(): Promise<AstraUser[]> {
  return axios.get(`/api/v1/cash/contractor-payments/astra/users/`).then((response) => {
    return response.data.users.map(parseAstraUser)
  })
}

export function getAstraUser(): Promise<{ business?: AstraUser; personal?: AstraUser }> {
  return axios.get(`/api/v1/cash/contractor-payments/astra/user/`).then((response) => {
    return {
      business: parseAstraUser(response.data.business),
      personal: parseAstraUser(response.data.personal),
    }
  })
}

/************ ASTRA LINKS  *************/

export function connectBankViaPlaid({
  astraUserId,
  plaidAccountId,
  institutionId,
}: {
  astraUserId?: string
  plaidAccountId: string
  institutionId?: string
}): Promise<string> {
  const payload: Record<string, any> = {
    plaid_account_id: plaidAccountId,
    institution_id: institutionId,
  }
  if (astraUserId) {
    payload.astra_user_id = astraUserId
  }
  return axios.post(`/api/v1/cash/contractor-payments/astra/bank-via-plaid/`, payload).then((response) => {
    return response.data.id
  })
}

export function editBankViaPlaid({
  id,
  astraUserId,
  plaidAccountId,
  institutionId,
}: {
  id?: string
  astraUserId?: string
  plaidAccountId: string
  institutionId?: string
}): Promise<string> {
  const payload: Record<string, any> = {
    plaid_account_id: plaidAccountId,
    institution_id: institutionId,
  }
  if (astraUserId) {
    payload.astra_user_id = astraUserId
  }
  return axios
    .post(`/api/v1/cash/contractor-payments/astra/bank-accounts/${id}/update`, payload)
    .then((response) => {
      return response.data.id
    })
}

interface DebitCardInfo {
  card_number: string
  card_security_code: string
  expiration_date: string
  zip_code: string
}

export function connectDebitCard({ cardInfo }: { cardInfo: DebitCardInfo }): Promise<string> {
  const payload = {
    card_info: cardInfo,
  }
  return axios.post(`/api/v1/cash/contractor-payments/astra/debit-cards/`, payload).then((response) => {
    return response.data.id
  })
}

export function editDebitCard({ id, cardInfo }: { cardInfo: DebitCardInfo; id: string }): Promise<string> {
  const payload = {
    updated_card_info: cardInfo,
  }
  return axios
    .post(`/api/v1/cash/contractor-payments/astra/debit-cards/${id}/update`, payload)
    .then((response) => {
      return response.data.id
    })
}

interface CreatePaymentOption {
  calculation_method: PaymentCalculationMethod
  base_rate: number
  line_haul_percent?: number
  miles?: number
}

interface CreatePayment {
  payment_option: PaymentOption
  preferred_settlement_speed: AstraSettlementSpeedType
  final_payout_amount: number
  custom_user_id: string
  calculation_option: CreatePaymentOption
  line_item_options: CamelToSnakeCaseNested<AstraRoutineLineItem>[]
}

/************ PAYMENTS  *************/
export function createPayment(payment: CreatePayment): Promise<string> {
  return axios.post(`/api/v1/cash/contractor-payments/astra/routines/`, payment).then((response) => {
    return response.data.routine_id
  })
}

export function deletePayment(id: string): Promise<void> {
  return axios.delete(`/api/v1/cash/contractor-payments/astra/routines/${id}/delete`)
}

export function cancelPayment(payload: { transfer_id: string }): Promise<void> {
  return axios.post(`/api/v1/cash/contractor-payments/astra/transfers/`, payload)
}

export function sendPaymentsEmail(payments: string[]): Promise<void> {
  return axios.post(`/api/v1/cash/contractor-payments/astra/emails/`, { routine_ids: payments })
}

/************ TRANSFERS  *************/

interface TransfersFilters {
  customUserId?: string
  startDate?: Date
  endDate?: Date
  page?: string
}

export function getTransfers(filters: TransfersFilters): Promise<PaginatedResults<AstraRoutine>> {
  const params = {
    custom_user_id: filters.customUserId,
    start_date: filters.startDate ? formatDateForApiWithoutTime(filters.startDate) : undefined,
    end_date: filters.endDate ? formatDateForApiWithoutTime(filters.endDate) : undefined,
    page: filters.page,
  }

  return axios
    .get(`/api/v1/cash/contractor-payments/astra/transfers/?${queryString.stringify(params)}`)
    .then((response) => {
      return {
        ...response.data,
        results: response.data.results.map(parseAstraRoutine),
      }
    })
}

/************ PARSE  *************/

export function parseAstraUserLink(data: any): AstraUserLink | undefined {
  if (!data) return undefined

  return {
    id: data.id,
    status: data.status,
    type: data.type,
    last4: data.last4,
    name: data.name,
    supportsInstantPay: data.supports_instant_pay,
    plaidAccount: data.plaid_account ? parsePlaidAccount(data.plaid_account) : null,
    astraUser: {
      id: data.astra_user.id,
      type: data.astra_user.type,
      customUserId: data.astra_user.custom_user_id,
      status: data.astra_user.status,
      name: data.astra_user.name,
      isOnboarded: data.astra_user.is_onboarded,
      isOnboardedForInstantPayouts: data.astra_user.is_onboarded_for_instant_payouts,
      isRefreshTokenExpired: data.astra_user.is_refresh_token_expired,
    },
  }
}

function parseAstraRoutine(data: any): AstraRoutine | undefined {
  if (!data) return undefined
  return {
    id: data.id,
    dateCreated: new Date(data.date_created),
    dateUpdated: new Date(data.date_updated),
    routineId: data.routine_id,
    status: data.status,
    type: data.type,
    paymentType: data.payment_type,
    preferredSettlementSpeed: data.preferred_settlement_speed,
    fee: data.fee ? Number(data.fee) : 0,
    amount: Number(data.amount),
    lineItems: data.line_items.map(parseAstraRoutineLineItem),
    sourceLink: parseAstraUserLink(data.source_link)!,
    destinationLink: parseAstraUserLink(data.destination_link)!,

    paymentCalculationMethod: data.payment_calculation_method,
    baseRate: Number(data.base_rate),
    lineHaulPercent: data.line_haul_percent ? Number(data.line_haul_percent) : undefined,
    miles: data.miles ? Number(data.miles) : undefined,

    canBeCanceled: data.can_be_canceled,

    transfers: data.transfers.map(parseAstraTransfer),
    transfer: data.transfers.length
      ? parseAstraTransfer(data.transfers[data.transfers.length - 1])
      : undefined,
  }
}

function parseAstraRoutineLineItem(data: any): AstraRoutineLineItem | undefined {
  if (!data) return undefined
  return {
    type: data.type,
    amount: Number(data.amount),
    description: data.description,
  }
}

function parseAstraTransfer(data: any): AstraRoutineTransfer | undefined {
  if (!data) return undefined
  return {
    id: data.id,
    transferId: data.transfer_id,
    dateCreated: new Date(data.date_created),
    dateUpdated: new Date(data.date_updated),
    status: data.status,
    amount: Number(data.amount),
  }
}

function parseAstraUser(data: any): AstraUser | undefined {
  if (!data) return undefined

  return {
    id: data.id,
    userId: data.user_id,
    userIntentId: data.user_intent_id,
    type: data.type,
    customUserId: data.custom_user_id,
    status: data.status,
    createdById: data.created_by_id,
    rate: data.rate,
    name: data.name,
    debitInstantFeePercent: data.debit_instant_fee_percent,
    bankAccount: parseAstraUserLink(data.bank_account_link),
    card: parseAstraUserLink(data.card_link),
    isOnboarded: data.is_onboarded,
    isOnboardedForInstantPayouts: data.is_onboarded_for_instant_payouts,
    isRefreshTokenExpired: data.is_refresh_token_expired,
  }
}

export function calculateTotalFromRoutine(payment: DeepPartial<AstraRoutine>) {
  let amount = 0
  if (payment.paymentCalculationMethod === PaymentCalculationMethod.CUSTOM_AMOUNT && payment.baseRate) {
    amount = amount + payment.baseRate
  }

  if (
    payment.paymentCalculationMethod === PaymentCalculationMethod.LINE_HAUL_PERCENTAGE &&
    payment.baseRate &&
    payment.lineHaulPercent
  ) {
    amount = amount + payment.baseRate * (payment.lineHaulPercent / 100)
  }

  if (
    payment.paymentCalculationMethod === PaymentCalculationMethod.PER_MILE &&
    payment.baseRate &&
    payment.miles
  ) {
    amount = amount + payment.baseRate * payment.miles
  }

  const deductions = sumBy(
    payment.lineItems?.filter((i) => i?.type && ContractorDeductionPaymentLineItem.includes(i.type)),
    (item) => item?.amount || 0
  )
  const additions = sumBy(
    payment.lineItems?.filter((i) => i?.type && ContractorAdditionPaymentLineItem.includes(i.type)),
    (item) => item?.amount || 0
  )
  amount = amount + additions
  amount = amount - deductions

  return Math.round(amount * 100) / 100
}

export function formatCalculationOption(payment: DeepPartial<AstraRoutine>) {
  if (payment.paymentCalculationMethod === PaymentCalculationMethod.CUSTOM_AMOUNT) {
    return {
      calculation_method: payment.paymentCalculationMethod!,
      base_rate: payment.baseRate!,
    }
  }

  if (payment.paymentCalculationMethod === PaymentCalculationMethod.LINE_HAUL_PERCENTAGE) {
    return {
      calculation_method: payment.paymentCalculationMethod!,
      base_rate: payment.baseRate!,
      line_haul_percent: payment.lineHaulPercent || undefined,
    }
  }

  return {
    calculation_method: payment.paymentCalculationMethod!,
    base_rate: payment.baseRate!,
    miles: payment.miles || undefined,
  }
}

export function getPaymentPayload(
  payment: DeepPartial<AstraRoutine>,
  speed: AstraSettlementSpeedType,
  paymentOption: PaymentOption,
  payrollmember: AstraUser | null
) {
  return {
    payment_option: paymentOption,
    preferred_settlement_speed: payrollmember?.card?.supportsInstantPay
      ? speed
      : AstraSettlementSpeedType.T_2,
    final_payout_amount: calculateTotalFromRoutine(payment),
    custom_user_id: payment.destinationLink?.astraUser?.customUserId!,
    calculation_option: formatCalculationOption(payment),
    line_item_options: payment.lineItems as AstraRoutineLineItem[],
  }
}

export function calculateInstantFee(amount: number, feePercent: number) {
  return (amount / (100 - (feePercent || 0))) * 100 - amount
}
