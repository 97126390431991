export enum Feature {
  CREDIT = 'CREDIT',
  FACTORING_OR_INSTANT_PAY = 'FACTORING_OR_INSTANT_PAY',
  FLEX = 'Flex',
  FLEX_STARTER_SEAT = 'FLEX_STARTER_SEAT',
  LOAD_BOARD = 'LOAD_BOARD',
  INSURANCE = 'INSURANCE',
  CONTRACTOR_PAYMENTS = 'CONTRACTOR_PAYMENTS',
  DISPATCH_SERVICE = 'DISPATCH_SERVICE',
  TRUCK_LEASE = 'TRUCK_LEASE',
  DISPATCH_SERVICE_FREE = 'DISPATCH_SERVICE_FREE',
  FUEL_CARD = 'FUEL_CARD',
}

export enum FeatureId {
  CREDIT = 1,
  FACTORING = 2,
  FLEX_STARTER_SEAT = 3,
  LOAD_BOARD = 4,
  INSURANCE = 5,
  CONTRACTOR_PAYMENTS = 6,
  DISPATCH_SERVICE = 7,
  TRUCK_LEASE = 8,
  DISPATCH_SERVICE_FREE = 9,
  FUEL_CARD = 10,
}

export interface Product {
  id: string
  featureId: FeatureId
  name: Feature
}

export enum BillingPeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  QUARTERLY = 'QUARTERLY',
}

export interface ProductPrice {
  id: string
  amount: string
  billingPeriod: BillingPeriod
  default: boolean
  product: Product
  recurring: 'RECURRING' | 'ONE_TIME'
  description: string
  maxChargedSeats?: number
}

interface TwoLineAddress {
  line1: string
  line2: string
}

export interface Carrier {
  name: string
  twoLinePhysicalAddress: TwoLineAddress
  mcNumber: string
}

export interface ProductSubscription {
  carrier?: Carrier
  contractedLeaseFeePercent: string
  contractedServiceFeePercent: string
  dateAgreementSigned: null | string
  productName: ProductSubscriptionName
  bundleName: ProductSubscriptionBundleName
  formattedBundleName: string
  supportEmail: string
  rateConEmail: string
  supportNumber: string
  hasCreditFeature: boolean
  hasFactoringFeature: boolean
  hasStarterFeature: boolean
  isCreditOnly: boolean
  isFlexStarter: boolean
  hasStripeSubscription: boolean
  isStripeSubscriptionActive: boolean
  isStripeSubscriptionCanceled: boolean
  stripeSubscriptionStatus: ProductSubscriptionStripeStatus
  items: ProductSubscriptionItem[]
  helpCenterUrl: string
  ctMcNumber: string
}

export interface ProductSubscriptionItem {
  quantity: number
  product: Product
  productPrice: Omit<ProductPrice, 'product'>
  status: ProductSubscriptionItemStatus
  isFlatPricing: boolean
  lineItemDescription: string
  lineItemDescriptionAdd: string
  lineItemDescriptionRemove: string
  lineItemPrice: number
  lineItemPriceFormatted: string
  lineItemPriceTotalFormatted: string
  lineItemPriceAddFormatted: string
  lineItemPriceTotalAddFormatted: string
  lineItemPriceRemoveFormatted: string
  lineItemPriceTotalRemoveFormatted: string
}

export enum ProductSubscriptionItemStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  OFFBOARDING = 'OFFBOARDING',
  REMOVED = 'REMOVED',
}

export enum ProductSubscriptionName {
  FLEX = 'FLEX',
  PREMIUM = 'PREMIUM',
  CASH = 'CASH',
}

export enum ProductSubscriptionBundleName {
  CASH = 'CASH',
  FLEX = 'FLEX',
  PREMIUM = 'PREMIUM',
  CREDIT_ONLY = 'CREDIT_ONLY',
  FLEX_STARTER = 'FLEX_STARTER',
}

export enum ProductSubscriptionStripeStatus {
  NOT_APPLICABLE = 'not_applicable',
  ACTIVE = 'active',
  TRIALING = 'trialing',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  PAST_DUE = 'past_due',
}

export const ProductSubscriptionNames = {
  [ProductSubscriptionName.FLEX]: 'Flex',
  [ProductSubscriptionName.PREMIUM]: 'Virtual Carrier',
  [ProductSubscriptionName.CASH]: 'Cash',
}

export const ProductSubscriptionBundleNames = {
  [ProductSubscriptionBundleName.FLEX]: 'Flex',
  [ProductSubscriptionBundleName.PREMIUM]: 'Virtual Carrier',
  [ProductSubscriptionBundleName.CASH]: 'Cash',
  [ProductSubscriptionBundleName.CREDIT_ONLY]: 'Credit',
  [ProductSubscriptionBundleName.FLEX_STARTER]: 'Flex Starter',
}

export enum ProductEventTags {
  PREMIUM = 'VC_account_created',
  FLEX = 'Flex_account_created',
  CASH = 'Cash_account_created',
  CREDIT = 'Credit_account_created',
}

export interface Entitlement {
  name: Feature
  quantity: number
  featureId: FeatureId
  status: ProductSubscriptionItemStatus
}

export function getBillingPeriodName(billingPeriod: BillingPeriod) {
  if (billingPeriod === BillingPeriod.MONTHLY) return 'mo'
  if (billingPeriod === BillingPeriod.YEARLY) return 'yr'
  if (billingPeriod === BillingPeriod.QUARTERLY) return 'quarter'
  return ''
}

export function getBillingPeriodFullName(billingPeriod: BillingPeriod) {
  if (billingPeriod === BillingPeriod.MONTHLY) return 'Monthly'
  if (billingPeriod === BillingPeriod.YEARLY) return 'Yearly'
  if (billingPeriod === BillingPeriod.QUARTERLY) return 'Quarterly'
  return ''
}
