import React from 'react'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { breakpoints } from 'shared/theme/breakpoints'
import styled, { css } from 'styled-components'

import { useLayoutContext } from '../Context/LayoutContext'
import { useShowSideBar } from './Navbar/useShowSideBar'
import { useSidebarWidth } from './Navbar/useSideBarWidth'

interface PageContainerProps {
  children: React.ReactNode
  toolbar?: React.ReactNode
  sideBar?: React.ReactNode
}

export function PageContainer({ children, toolbar, sideBar }: PageContainerProps) {
  const { isFlowPage } = useLayoutContext()
  const sidebarWidth = useSidebarWidth()

  function renderContent() {
    if (toolbar) {
      return (
        <StyledContent>
          {toolbar}
          <MainWrap>
            <ContentGrid isFlowPage={isFlowPage}>{children}</ContentGrid>
          </MainWrap>
        </StyledContent>
      )
    }

    return <ContentGrid>{children}</ContentGrid>
  }

  return (
    <PageWrap $sidebarWidth={sidebarWidth}>
      <div>{sideBar}</div>
      {renderContent()}
    </PageWrap>
  )
}

interface ContentGridProps {
  children: React.ReactNode
  noMaxWidth?: boolean
  isFlowPage?: boolean
}

export function ContentGrid({ children, noMaxWidth, isFlowPage }: ContentGridProps) {
  const { pageWidth } = useLayoutContext()
  const sidebarWidth = useSidebarWidth()
  const showSidebar = useShowSideBar()

  return (
    <StyledContentGrid
      $sidebarWidth={sidebarWidth}
      $pageWidth={pageWidth}
      $noMaxWidth={noMaxWidth}
      $showSidebar={showSidebar}
      $isFlowPage={isFlowPage}
    >
      <div></div>
      <StyledContentGridChildren>
        <div className="content">{children}</div>
      </StyledContentGridChildren>
      <div></div>
    </StyledContentGrid>
  )
}

const PageWrap = styled.div<{ $sidebarWidth: number }>`
  display: grid;
  transition: grid-template-columns 0.6s;

  ${(p) =>
    css`
      grid-template-columns: ${p.$sidebarWidth}px 1fr;
    `}

  @media ${breakpoints.sm} {
    transition: none;
  }
`

const StyledContent = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto auto 1fr;
  box-sizing: border-box;
  grid-template-areas:
    'navbar'
    'toolbar'
    'main';
`

const MainWrap = styled.div`
  grid-area: main;
`

const MAX_CONTENT_WIDTH = 1230

const StyledContentGrid = styled.div<{
  $pageWidth: number | null
  $sidebarWidth: number
  $showSidebar: boolean
  $noMaxWidth?: boolean
  $isFlowPage?: boolean
}>`
  height: 100%;
  display: grid;
  grid-template-columns: minmax(16px, auto) 1fr minmax(16px, auto);

  @media (min-width: 600px) {
    grid-template-columns: minmax(24px, auto) 1fr minmax(24px, auto);
  }

  ${(p) => css`
    @media (min-width: ${(p.$pageWidth || MAX_CONTENT_WIDTH) + 24 * 2 + p.$sidebarWidth}px) {
      grid-template-columns: minmax(24px, 280px) ${p.$pageWidth || MAX_CONTENT_WIDTH}px minmax(24px, 280px);

      ${!p.$showSidebar &&
      css`
        grid-template-columns: minmax(24px, 1fr) ${p.$pageWidth || MAX_CONTENT_WIDTH}px minmax(24px, 1fr);
      `}
    }
  `}

  ${(p) =>
    p.$noMaxWidth &&
    css`
      grid-template-columns: 16px 1fr 16px;

      @media (min-width: 600px) {
        grid-template-columns: 24px 1fr 24px;
      }
    `}

  ${(p) =>
    p.$isFlowPage &&
    css`
      grid-template-columns: 16px 1fr 16px;
      margin: 0 auto;
      max-width: ${MAX_CONTENT_WIDTH}px;

      // see explanation: https://datacadamia.com/web/css/grid/overflow
      > * {
        min-width: 0;
      }

      @media (min-width: 600px) {
        grid-template-columns: 24px 1fr 24px;
      }
    `}
`

const StyledContentGridChildren = styled(FlexBox)`
  flex-direction: column;

  > * {
    width: 100%;
    min-width: 100%;
    flex: 1;
  }
`
