import { snackbarsState } from 'shared/reducers/snackbarsReducer'

import { Snackbar, SnackBarReduxProps } from './Snackbar'

export function WarningSnackbar({ onClose, useSelector }: SnackBarReduxProps) {
  const {
    warningSnackbarOpen,
    message,
    title,
    footer,
    action,
    actionLabel,
    actionRenderer,
    autoHideDuration,
  } = useSelector(({ snackbars }: { snackbars: snackbarsState }) => snackbars)

  return (
    <Snackbar
      type="warning"
      isOpen={warningSnackbarOpen}
      title={title}
      message={message}
      footer={footer}
      action={action}
      actionLabel={actionLabel}
      actionRenderer={actionRenderer}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    />
  )
}
