import { snackbarsState } from 'shared/reducers/snackbarsReducer'

import { Snackbar, SnackBarReduxProps } from './Snackbar'

export function SuccessSnackbar({ onClose, useSelector }: SnackBarReduxProps) {
  const {
    successSnackbarOpen,
    message,
    title,
    footer,
    action,
    actionLabel,
    actionRenderer,
    autoHideDuration,
  } = useSelector(({ snackbars }: { snackbars: snackbarsState }) => snackbars)

  return (
    <Snackbar
      type="success"
      isOpen={successSnackbarOpen}
      title={title}
      message={message}
      footer={footer}
      action={action}
      actionLabel={actionLabel}
      actionRenderer={actionRenderer}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    />
  )
}
