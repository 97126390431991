import { makeStyles } from '@material-ui/core'
import Avatar, { AvatarProps } from '@material-ui/core/Avatar'

import { getInitials, getInitialsFromUsername } from '../../helpers/formatters'
import { WithExclusiveOneOf } from '../../helpers/typeHelper'
import { ListUser } from '../../models/user'
import { cyan, darkGray, dividerGray, grey4d, information, informationL1 } from '../../theme/colors'

type UserAvatarProps = WithExclusiveOneOf<
  StylesProps & {
    onClick?: AvatarProps['onClick']
    className?: string
    backgroundColor?: string
    fontSize?: string
    borderWidth?: string
    variant?: 'standard' | 'highlighted'
  },
  { user: ListUser },
  { userName: string }
>

type StylesProps = {
  width?: string
  height?: string
  fontSize?: string
  backgroundColor?: string
  cursor?: string
  borderWidth?: string
}

const useStyles = makeStyles<unknown, StylesProps>(() => ({
  avatar: ({ width, height, backgroundColor, fontSize, cursor }) => ({
    width: width || '3rem',
    height: height || '3rem',
    border: '3px solid',
    borderColor: cyan,
    background: backgroundColor || grey4d,
    fontSize: fontSize || 14,
    cursor: cursor || 'pointer',
    textTransform: 'uppercase',
  }),
  avatarLight: ({ fontSize, backgroundColor, borderWidth }) => ({
    background: backgroundColor || information,
    color: darkGray,
    fontWeight: 600,
    borderWidth: borderWidth || '2px',
    fontSize,
    paddingTop: '1px',
  }),
  avatarStandard: () => ({
    background: informationL1,
    borderColor: dividerGray,
  }),
}))

export function UserAvatar({
  onClick,
  user,
  userName,
  className = '',
  variant,
  ...stylesProps
}: UserAvatarProps) {
  const classes = useStyles(stylesProps)
  const initials = (userName && getInitialsFromUsername(userName)) || (user && getInitials(user)) || ''

  return (
    <Avatar
      className={`${classes.avatar} ${className} ${classes.avatarLight} ${
        variant === 'standard' ? classes.avatarStandard : ''
      }`}
      onClick={onClick}
      data-testid="menu-icon"
    >
      {initials}
    </Avatar>
  )
}
