import { forwardRef, HTMLAttributes, Ref } from 'react'
import styled, { css, IStyledComponent } from 'styled-components'

import { AddPrefixToObject } from '../../helpers/typeHelper'
import { muiTheme } from '../../theme'
import { breakpoints } from '../../theme/breakpoints'

export { Wrap, WideWrap, PageWrap } from './Wrap'
export { List, BulletList } from './List'
export { TextCentered, TextLeft, TextRight, Capitalize } from './TextFormat'

const StyledCard = styled.div<AddPrefixToObject<CardProps, '$'>>`
  text-align: center;
  min-width: 280px;
  max-width: 100%;
  background: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 28px 46px;

  ${(p) => p.$hasSubtleShadow && 'box-shadow: 0px 8px 8px rgba(0, 44, 177, 0.01); padding: 32px 40px;'}
  ${(p) => p.$noShadow && 'box-shadow: none;'}
  ${(p) => p.$smallerPadding && 'padding: 24px;'}
  ${(p) => p.$smallPadding && 'padding: 20px 24px;'}
  ${(p) => p.$mediumPadding && 'padding: 32px;'}
  ${(p) => p.$padding && `padding: ${p.$padding};`}
  ${(p) => p.$fullWidth && 'width: 100%;'}
  ${(p) => p.$align && `text-align: ${p.$align};`}
  ${(p) => p.$height && `height: ${p.$height};`}
  ${(p) => p.$ml && `margin-left: ${p.$ml};`}
  ${(p) => p.$mr && `margin-right: ${p.$mr};`}
  ${(p) =>
    p.$mt &&
    css`
      margin-top: ${muiTheme.spacing() * p.$mt}px;
    `};

  ${(p) =>
    p.$mb &&
    css`
      margin-bottom: ${muiTheme.spacing() * p.$mb}px;
    `};

  ${(p) =>
    !p.$noResponsivePaddding &&
    `@media ${breakpoints.xs} {
      padding: 20px;
    }`}

  ${(p) => p.onClick && 'cursor: pointer;'}
  ${(p) => p.$styles}

  @media ${breakpoints.xs} {
    ${(p) =>
      p.$xsFulWidth &&
      css`
        box-shadow: none;
        background: none;
        border-radius: 0;
        padding: 0;
      `}
  }
`

export interface CardProps {
  fullWidth?: boolean
  smallPadding?: boolean
  smallerPadding?: boolean
  mediumPadding?: boolean
  padding?: string
  noResponsivePaddding?: boolean
  hasSubtleShadow?: boolean
  noShadow?: boolean
  xsFulWidth?: boolean
  align?: 'left' | 'right' | 'center'
  height?: string
  mt?: number
  mb?: number
  ml?: string
  mr?: string
  styles?: string
}

export const Card = forwardRef(
  (
    {
      fullWidth,
      smallPadding,
      smallerPadding,
      mediumPadding,
      padding,
      noResponsivePaddding,
      hasSubtleShadow,
      noShadow,
      xsFulWidth,
      align,
      height,
      mt,
      mb,
      ml,
      mr,
      styles,
      ...rest
    },
    ref: Ref<any>
  ) => {
    return (
      <StyledCard
        $fullWidth={fullWidth}
        $smallPadding={smallPadding}
        $smallerPadding={smallerPadding}
        $mediumPadding={mediumPadding}
        $padding={padding}
        $noResponsivePaddding={noResponsivePaddding}
        $hasSubtleShadow={hasSubtleShadow}
        $noShadow={noShadow}
        $xsFulWidth={xsFulWidth}
        $align={align}
        $height={height}
        $mt={mt}
        $mb={mb}
        $ml={ml}
        $mr={mr}
        $styles={styles}
        ref={ref}
        {...rest}
      />
    )
  }
) as IStyledComponent<'web', CardProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>

export const DialogCard = styled(Card)<{ $centerAlign?: boolean }>`
  padding: ${muiTheme.spacing(6)}px;
  ${(p) => p.$centerAlign && `align-self: center;`}

  @media ${breakpoints.xs} {
    padding: ${muiTheme.spacing(4)}px;
  }
`
