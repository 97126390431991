import { snackbarsState } from 'shared/reducers/snackbarsReducer'

import { Snackbar, SnackBarReduxProps } from './Snackbar'

export function ErrorSnackbar({ onClose, useSelector }: SnackBarReduxProps) {
  const { errorSnackbarOpen, message, title, footer, action, actionLabel, actionRenderer, autoHideDuration } =
    useSelector(({ snackbars }: { snackbars: snackbarsState }) => snackbars)

  return (
    <Snackbar
      type="error"
      isOpen={errorSnackbarOpen}
      title={title}
      message={message}
      footer={footer}
      action={action}
      actionLabel={actionLabel}
      actionRenderer={actionRenderer}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    />
  )
}
