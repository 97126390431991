import queryString from 'query-string'
import { convertBooleanToYesNo, YesNoRadioOptions } from 'shared/helpers/utils'

export { YesNoRadioOptions, convertBooleanToYesNo }

export const checkVerticalElementVisible = (el: HTMLElement | null) => {
  const rect = el?.getBoundingClientRect()

  return rect && rect.top >= 210 && rect.bottom <= 800
}

export const formSmoothFocus = (fieldName: string) => {
  const fieldElement = document.getElementsByName(fieldName)[0]
  fieldElement?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' })
  setTimeout(() => fieldElement?.focus(), 500)
}

export const parseQueryParams = (url: string) => {
  return Object.fromEntries(new URLSearchParams(url.split('?')[1]).entries()) || {}
}

export function extractEmailsFromText(text: string) {
  return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
}

export function formatPhoneNumber(text?: string | number) {
  if (!text) return text
  const temp = String(text).replace(/\D/g, '')
  return temp.slice(0, 3) + '-' + temp.slice(3, 6) + '-' + temp.slice(6)
}

export function delay(timeout: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout)
  })
}

export function openPopup({
  url,
  title,
  width,
  height,
  features,
}: {
  url: string
  title: string
  width: number
  height: number
  features?: string
}) {
  const left = window.screen.width / 2 - width / 2
  const top = window.screen.height / 2 - height / 2

  return window.open(
    url,
    title,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left},${features}`
  )
}

export function getQueryParams(window?: Window): Record<string, string> {
  try {
    return queryString.parse(window?.location.search || '') as Record<string, string>
  } catch (err) {
    // While the Motive popup is open, accessing window.location will throw cors error
    return {}
  }
}
