import Pusher from 'pusher-js'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { HandlerOf } from 'shared/helpers/typeHelper'

import { ConsultantConversationMessage, parseConsultantMessage } from '../../models/consultant'
import { ApplicationState } from '../../redux/store/models'

interface UseConversationAsyncMessagesProps {
  channelId: string | null
  handleAddNewMessage: HandlerOf<ConsultantConversationMessage>
  handleErrorMessage: HandlerOf<string>
}

const NEW_MESSAGE_EVENT = 'new_message'
const NEW_MESSAGE_ERROR_EVENT = 'new_message_error'

export function useConversationAsyncMessages({
  channelId,
  handleAddNewMessage,
  handleErrorMessage,
}: UseConversationAsyncMessagesProps) {
  const pusherKey = useSelector((state: ApplicationState) => state.settings.pusher_key)
  const pusherCluster = useSelector((state: ApplicationState) => state.settings.pusher_cluster)
  const [pusher, setPusher] = useState<Pusher | null>(null)

  useEffect(() => {
    if (pusherKey && pusherCluster && !pusher)
      setPusher(
        new Pusher(pusherKey, {
          cluster: pusherCluster,
          enabledTransports: ['ws'],
          forceTLS: true,
        })
      )

    return () => {
      if (pusher) {
        pusher.unbind_all()
        pusher.allChannels().forEach(({ name }) => pusher.unsubscribe(name))
      }
    }
  }, [pusherKey, pusherCluster, pusher])

  useEffect(() => {
    if (channelId && pusher) {
      const channel = pusher.subscribe(channelId)

      channel.bind(NEW_MESSAGE_EVENT, (message: any) => handleAddNewMessage(parseConsultantMessage(message)))
      channel.bind(NEW_MESSAGE_ERROR_EVENT, (message: any) => handleErrorMessage(message?.message_content))
    }
  }, [channelId, pusher, handleAddNewMessage, handleErrorMessage])
}
