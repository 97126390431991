import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import * as React from 'react'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { Dropdown } from 'shared/components/Dropdown/Dropdown'
import { DropdownWithSearchOption } from 'shared/components/Dropdown/DropdownWithSearchOption'
import { Text } from 'shared/components/Text/Text'
import { Handler } from 'shared/helpers/typeHelper'
import { lightGray2, primaryBlack, white } from 'shared/theme/colors'
import styled from 'styled-components'

import { JobFiltersTypes } from './jobFiltersConstants'

type Props = {
  options:
    | { label: string; value: string }[]
    | { label: string; dropDownLabel?: string | Element; value: string }[]
  filterOption: Omit<JobFiltersTypes, JobFiltersTypes.PICKUP_DATE>
  selected: boolean
  deselect: Handler
  label: string
  selectedValue: string | null | undefined
  setSelectedValue: (selectedValue: string | undefined) => void
  opener?: React.ReactNode
  isInitiallyOpen?: boolean
  onBlur?: Handler
}

export function JobFiltersSelector({
  options,
  filterOption,
  selected,
  deselect,
  label,
  selectedValue,
  setSelectedValue,
  opener,
  isInitiallyOpen,
  onBlur,
}: Props) {
  if (!selected && !isInitiallyOpen) return null
  const DropdownComponent = filterOption === JobFiltersTypes.BROKER ? DropdownWithSearchOption : Dropdown

  return (
    <DropdownComponent
      noMargin
      options={options}
      value={selectedValue ?? undefined}
      onChange={setSelectedValue}
      renderOpener={() =>
        opener ?? (
          <StyledOpener justifyContent="flex-start" alignItems="center" gap="8px">
            <Text variant="header4" fontColor={lightGray2}>
              {label}
            </Text>
            <Text variant="header4" fontColor={primaryBlack}>
              {options.find((x) => x.value === selectedValue)?.label || ''}
            </Text>

            <HighlightOffIcon htmlColor={lightGray2} fontSize="small" onClick={deselect} />
          </StyledOpener>
        )
      }
      maxHeight={350}
      width={280}
      isInitiallyOpen={isInitiallyOpen}
      onBlur={onBlur}
    />
  )
}

const StyledOpener = styled(FlexBox)<{ $selected?: boolean }>`
  padding: 8px 12px;
  border-radius: 3px;
  background: ${white};
  cursor: pointer;
  opacity: 0.7;

  & > div {
    position: relative;
    top: 1px;
  }
`
