export const SET_ASYNC_QUERY_CHANNEL = 'SET_ASYNC_QUERY_CHANNEL'
export const SET_ASYNC_QUERY_LOADING = 'SET_ASYNC_QUERY_LOADING'
export const ADD_ASYNC_QUERY_RESULTS = 'ADD_ASYNC_QUERY_RESULTS'
export const SET_ASYNC_QUERY_RESULTS = 'SET_ASYNC_QUERY_RESULTS'
export const SET_QUERY_MADE = 'SET_QUERY_MADE'
export const SET_QUERY_RESULTS = 'SET_QUERY_RESULTS'
export const SET_FILTERED_LOADS = 'SET_FILTERED_LOADS'
export const SET_QUERY = 'SET_QUERY'
export const SET_PENDING_JOBS = 'SET_PENDING_JOBS'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const UPDATE_LOAD = 'UPDATE_LOAD'
export const SET_SAVED_SEARCH_BEING_EDITED = 'SET_SAVED_SEARCH_BEING_EDITED'

//jobs
export const SET_JOBS = 'SET_JOBS'
export const UPDATE_JOBS_FILTER = 'UPDATE_JOBS_FILTER'
export const UPDATE_PENDING_JOBS_FILTER = 'UPDATE_PENDING_JOBS_FILTER'

//documents
export const SAVE_DOCUMENT = 'SAVE_DOCUMENT'
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT'
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'
