import styled, { css, keyframes } from 'styled-components'

export function TypingIcon() {
  return (
    <Wrap>
      <Bullet $background="#E3E8EC" />
      <Bullet $background="#C7D1DA" $delay="0.1s" />
      <Bullet $background="#9FA3AF" $delay="0.2s" />
      <Bullet $background="#8A8F9D" $delay="0.3s" />
    </Wrap>
  )
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  height: 7px;
`
/* stylelint-disable */
const jump = keyframes`
  0% { transform: translateY(0); }
  25% { transform: translateY(-10px); }
  50% { transform: rotate(0); }
  100% { transform: rotate(0); }
`
/* stylelint-enable */

const Bullet = styled.div<{ $background?: string; $delay?: string }>`
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  /* stylelint-disable */
  animation: ${jump} 1.5s infinite;
  /* stylelint-enable */

  ${(p) =>
    p.$background &&
    css`
      background-color: ${p.$background};
    `}

  ${(p) =>
    p.$delay &&
    css`
      animation-delay: ${p.$delay};
    `}
`
