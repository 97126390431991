import { useCallback, useEffect } from 'react'
import * as React from 'react'
import { HandlerOf } from 'shared/helpers/typeHelper'

import { OverlayElement } from './Overlay.styles'

interface Props {
  children?: React.ReactNode
  onClick: HandlerOf<React.MouseEvent>
  className?: string
  isTransparent?: boolean
}

export function Overlay({ children, onClick, className, isTransparent = false }: Props) {
  const handleOverlayClick = useCallback(
    (event) => {
      if (event.target === event.currentTarget) onClick(event)
    },
    [onClick]
  )

  const handleKeydown = useCallback(
    (event) => {
      if (event.key === 'Escape') onClick(event)
    },
    [onClick]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    return () => document.removeEventListener('keydown', handleKeydown)
  }, [handleKeydown])

  return (
    <OverlayElement className={className} onClick={handleOverlayClick} $isTransparent={isTransparent}>
      {children}
    </OverlayElement>
  )
}

// TODO: Remove default export
// eslint-disable-next-line import/no-default-export
export default Overlay
