import axios from 'axios'
import { PermissionsConfigType, PermissionType, TeamLevelPermissions } from 'shared/models/permission'

import {
  ACCOUNT_ACTIVITY_PATH,
  ADD_ADMIN_DRIVER_PATH,
  ADD_ADMIN_PATH,
  ADD_DRIVER_PATH,
  ADD_USER_PATH,
  CANCEL_SUBSCRIPTION_PATH,
  CARD_MANAGEMENT,
  CARD_MANAGEMENT_ADD_CARDHOLDER,
  CARD_MANAGEMENT_CHANGE_WEEKLY_LIMIT,
  CARDHOLDER_ACTIVITY,
  CONTRACTOR_PAYMENTS_INSTANT_PAYOUTS_ONBOARDING_PATH,
  CONTRACTOR_PAYMENTS_PATH,
  CREDIT_PATH,
  DEACTIVATE_USER_PATH,
  FLEET_PROFILE_PATH,
  JOBS_PATH,
  PERMISSIONS_SETTINGS_PATH,
  PROFILE_PATH,
  PROFILE_SUBSCRIPTION_CHANGE_PAYMENT_PATH,
  SAFETY_DASHBOARD_PATH,
  SEARCH_ASYNC_PATH,
  SEARCH_ASYNC_RESULTS_PATH,
  SEARCH_PATH,
  SEARCH_RESULTS_PATH,
} from '../Routes'
import { UserRoles } from './user'

export type {
  PermissionResult,
  Permissions,
  PermissionsConfigType,
  TeamLevelPermissions,
} from 'shared/models/permission'

export { PermissionType, PermissionErrorType } from 'shared/models/permission'

export function getPermissionsConfig(): PermissionsConfigType {
  return {
    [ADD_USER_PATH]: {
      permission: PermissionType.ADD_TEAMMATE,
      fallbackUrl: FLEET_PROFILE_PATH,
    },
    [ADD_ADMIN_PATH]: {
      permission: PermissionType.ADD_TEAMMATE,
      fallbackUrl: FLEET_PROFILE_PATH,
    },
    [ADD_DRIVER_PATH]: {
      permission: PermissionType.ADD_TEAMMATE,
      fallbackUrl: FLEET_PROFILE_PATH,
    },
    [ADD_ADMIN_DRIVER_PATH]: {
      permission: PermissionType.ADD_TEAMMATE,
      fallbackUrl: FLEET_PROFILE_PATH,
    },
    [DEACTIVATE_USER_PATH]: {
      permission: PermissionType.DEACTIVATE_TEAMMATE,
      fallbackUrl: FLEET_PROFILE_PATH,
    },
    [JOBS_PATH]: {
      permission: PermissionType.JOB_LIST,
      fallbackUrl: PROFILE_PATH,
    },
    [SEARCH_PATH]: {
      permission: PermissionType.SEARCH,
      fallbackUrl: PROFILE_PATH,
    },
    [SEARCH_RESULTS_PATH]: {
      permission: PermissionType.SEARCH,
      fallbackUrl: PROFILE_PATH,
    },
    [SEARCH_ASYNC_PATH]: {
      permission: PermissionType.SEARCH,
      fallbackUrl: PROFILE_PATH,
    },
    [SEARCH_ASYNC_RESULTS_PATH]: {
      permission: PermissionType.SEARCH,
      fallbackUrl: PROFILE_PATH,
    },
    [CARD_MANAGEMENT]: {
      permission: PermissionType.CT_CASH_TAB,
      fallbackUrl: PROFILE_PATH,
    },
    [CARD_MANAGEMENT_ADD_CARDHOLDER]: {
      permission: PermissionType.CT_CASH_TAB,
      fallbackUrl: PROFILE_PATH,
    },
    [CARD_MANAGEMENT_CHANGE_WEEKLY_LIMIT]: {
      permission: PermissionType.CT_CASH_TAB,
      fallbackUrl: PROFILE_PATH,
    },
    [ACCOUNT_ACTIVITY_PATH]: {
      permission: PermissionType.CT_CASH_DEBIT_TAB,
      fallbackUrl: PROFILE_PATH,
    },
    [CARDHOLDER_ACTIVITY]: {
      permission: PermissionType.CT_CASH_DEBIT_TAB,
      fallbackUrl: PROFILE_PATH,
    },
    [CREDIT_PATH]: {
      permission: PermissionType.CT_CASH_CREDIT_TAB,
      fallbackUrl: PROFILE_PATH,
    },
    [PROFILE_SUBSCRIPTION_CHANGE_PAYMENT_PATH]: {
      permission: PermissionType.EDIT_STRIPE_SUBSCRIPTION,
      fallbackUrl: PROFILE_PATH,
    },
    [PERMISSIONS_SETTINGS_PATH]: {
      permission: PermissionType.MANAGE_PERMISSIONS,
      fallbackUrl: PROFILE_PATH,
    },
    [SAFETY_DASHBOARD_PATH]: {
      permission: PermissionType.SAFETY_DASHBOARD,
      fallbackUrl: PROFILE_PATH,
    },
    [CANCEL_SUBSCRIPTION_PATH]: {
      permission: PermissionType.CANCEL_STRIPE_SUBSCRIPTION,
      fallbackUrl: PROFILE_PATH,
    },
    [CONTRACTOR_PAYMENTS_PATH]: {
      permission: PermissionType.CONTRACTOR_PAYMENTS,
      fallbackUrl: PROFILE_PATH,
      allow: [CONTRACTOR_PAYMENTS_INSTANT_PAYOUTS_ONBOARDING_PATH],
    },
  }
}

interface PermissionsPayload {
  permission_names: PermissionName[]
}

interface PermissionName {
  group: UserRoles
  name: keyof TeamLevelPermissions
  enabled: boolean
}

export function saveTeamLevelPermissions(payload: PermissionsPayload): Promise<TeamLevelPermissions> {
  return axios.post(`/api/v1/teams/team-level-permissions/`, payload).then((response) => {
    return response.data
  })
}

export function getTeamLevelPermissions(): Promise<TeamLevelPermissions> {
  return axios.get(`/api/v1/teams/team-level-permissions/`).then((response) => {
    return {
      [PermissionType.TEAM_USER_CAN_BOOK_LOADS]:
        response.data[PermissionType.TEAM_USER_CAN_BOOK_LOADS]?.enabled,
      [PermissionType.TEAM_USER_CAN_VIEW_RATES]:
        response.data[PermissionType.TEAM_USER_CAN_VIEW_RATES]?.enabled,
    }
  })
}
