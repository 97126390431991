import { keyBy } from 'lodash'

import { DriverStatus } from '../../models/models'
import { ApplicationState } from '../store/models'

export const currentTeamSelector = (state: ApplicationState) => state.team
export const currentTeamMembersSelector = (state: ApplicationState) => state.team.members
export const currentTeamMembersByIdSelector = (state: ApplicationState) => keyBy(state.team.members, 'id')
export const hasMultipleDriversSelector = (state: ApplicationState) =>
  state.team.members.filter((user) => user.is_team_driver)?.length > 1

export const driversSelector = (state: ApplicationState) =>
  currentTeamMembersSelector(state).filter((member) => member.is_team_driver)

export const activeDriversSelector = (state: ApplicationState) =>
  currentTeamMembersSelector(state).filter(
    (member) => member.is_team_driver && member.default_team_membership?.driver_status === DriverStatus.ACTIVE
  )

export const ratesEmailSelector = (state: ApplicationState) =>
  currentTeamSelector(state).product_subscription?.rate_con_email || 'rates@cloudtrucks.com'
