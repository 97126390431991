import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { SIGN_IN_PATH } from 'Routes'
import { showSuccessBanner } from 'shared/actions/ui/actions'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { Box } from 'shared/components/Box/Box'
import { Button } from 'shared/components/Button/Button'
import { Spinner } from 'shared/components/Spinner'
import { sizes } from 'shared/theme'

import { requestLoginToken, verifyLoginCodeToken } from 'api/authApi'
import { getLoginToken, userAuthenticationSuccess } from 'redux/actions/auth/authActions'
import { ApplicationState } from 'redux/store/models'
import { CodeInput } from '../../../components/Input/CodeInput'
import { useAnalyticsEvent } from '../../../hooks/useAnalyticsEvent'
import { MaxAttempts } from './MaxAttempts/MaxAttempts'
import { badCodeError, maxAttempts, notExistingContact } from './VerifyCode.constants'
import { Contact, Container, ErrorMessage, Paragraph1, Paragraph2, Title } from './VerifyCode.styles'
import VerifyDialog from './VerifyModal/VerifyModal'

interface VerifyPageProps {
  nextStep: () => void
  isFromRegistration: boolean
}

export const LOG_IN_REDIRECT = 'LOG_IN_REDIRECT'
const VERIFY_GLOBAL_STATE = {
  requestIsInitiated: false,
}

// requestInitiated globally track if there was a request sent to the server for 6 digit code
// This handles scenario when this component is rendered in isolation, without request being sent to server
// to sent code. For example when you reload the page while this component is mounted.
export function setVerifyRequestIsInitiated() {
  VERIFY_GLOBAL_STATE.requestIsInitiated = true
}

export function VerifyCode({ nextStep, isFromRegistration }: VerifyPageProps) {
  const dispatch = useDispatch()
  const history = useHistory()

  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [numberOfAttempts, setNumberOfAttempts] = useState(0)
  const authUserContact = useSelector((state: ApplicationState) => state.auth.userContact)
  const registeredEmail = useSelector((state: ApplicationState) => state.user.email)
  const trackEvent = useAnalyticsEvent()

  const [userContact, setUserContact] = useState(authUserContact || registeredEmail || '')
  const isAuthenticated = useSelector((state: ApplicationState) => state.auth.isAuthenticated)

  useEffect(() => {
    if (isAuthenticated) {
      if (isFromRegistration) {
        nextStep()
      } else {
        const query = history.location.search
        history.replace(`/${query}`)
      }
    } else if (!VERIFY_GLOBAL_STATE.requestIsInitiated) {
      if (!authUserContact) return history.replace('/')
      dispatch(getLoginToken(authUserContact || ''))
    }
    // This only needs to run first time it mounts just in case user is already authenticated and we want to skip the step
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const verifyCode = useMutation(() => verifyLoginCodeToken(code, userContact), {
    onSuccess: () => {
      setTimeout(() => trackEvent('verify_verification_success'), 2000)
      setLoading(false)
      dispatch(userAuthenticationSuccess())

      if (isFromRegistration) {
        nextStep()
      } else {
        localStorage?.setItem?.(LOG_IN_REDIRECT, '1')
        const query = history.location.search
        history.replace(`/${query}`)
      }
    },
    onError: () => {
      trackEvent('verify_verification_failure')
      setCodeError(true)
      setNumberOfAttempts(numberOfAttempts + 1)
      setErrorMessage(badCodeError)
      setLoading(false)
    },
  })

  const loginToken = useMutation((data: string) => requestLoginToken(data), {
    onSuccess: () => {
      dispatch(
        showSuccessBanner({
          message: `We have resent CloudTrucks’ invitation to ${userContact}`,
          title: 'Resent Invitation!',
        })
      )

      setShowModal(false)
      setCode('')
      setLoading(false)
    },
    onError: () => {
      setErrorMessage(notExistingContact)
      setLoading(false)
      setShowModal(false)
    },
  })

  const handleVerification = () => {
    setLoading(true)
    setCodeError(false)
    setErrorMessage('')
    verifyCode.mutate()
  }

  const getNewToken = (contactInput: string) => {
    const contact = contactInput || userContact
    setCodeError(false)
    setErrorMessage('')
    setUserContact(contact)
    setLoading(true)
    loginToken.mutate(contact)
  }

  const setCodeValue = (code: string) => {
    setCode(code)
  }

  const redirectToSignIn = () => {
    history.push(SIGN_IN_PATH)
  }

  if (numberOfAttempts === maxAttempts) {
    return <MaxAttempts redirectToSignIn={redirectToSignIn} />
  } else {
    return (
      <Container>
        <Spinner open={loading} />
        <Title>Please verify your account with the 6-digit code sent to you.</Title>
        <Paragraph1> The code has been sent to </Paragraph1>
        <Contact>{userContact}</Contact>
        <CodeInput onChange={setCodeValue} handleVerification={handleVerification} />
        {(codeError || errorMessage !== '') && <ErrorMessage>{errorMessage || badCodeError}</ErrorMessage>}
        <Paragraph2> Haven't received your code?</Paragraph2>
        <FlexBox alignItems="center" gap="5px">
          <Button variant="text" onClick={() => getNewToken(userContact)} isInline margin="0">
            Resend your code
          </Button>{' '}
          or{' '}
          <Button variant="text" onClick={() => setShowModal(true)} isInline margin="0">
            try another method
          </Button>
        </FlexBox>
        <Box mt={3} />
        <Button size={sizes.extraLarge} onClick={handleVerification} margin="16px 0">
          Verify your account
        </Button>
        {showModal && (
          <VerifyDialog
            toggleModal={() => {
              setShowModal(false)
            }}
            getNewToken={getNewToken}
          />
        )}
      </Container>
    )
  }
}
