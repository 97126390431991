const csrfCookiePrefixProd = '__Secure-csrftoken-v2='
const csrfCookiePrefixLocal = 'csrftoken='

export function getCsrfCookieToken(): string {
  if (!document.cookie) {
    return ''
  }
  const csrfCookies = document.cookie
    .split(';')
    .map((c) => c.trim())
    .filter((c) => c.startsWith(csrfCookiePrefixProd) || c.startsWith(csrfCookiePrefixLocal))

  if (csrfCookies.length === 0) {
    return ''
  }
  return decodeURIComponent(csrfCookies[0].split('=')[1]).toString()
}
