import axios from 'axios'

import { toCamelCase } from '../helpers/utils'
import { Equipment } from './equipment'

export const MAX_DOT_INSPECTION_IN_DAYS = 90

export enum TrailerType {
  DRY_VAN = 'DRY_VAN',
  FLATBED = 'FLATBED',
  REEFER = 'REEFER',
  OTHER = 'OTHER',
}

export const TrailerTypeLabel: Record<TrailerType, string> = {
  [TrailerType.DRY_VAN]: 'Dry Van',
  [TrailerType.FLATBED]: 'Flatbed',
  [TrailerType.REEFER]: 'Reefer',
  [TrailerType.OTHER]: 'Other',
}

export interface Trailer extends Equipment {
  type?: TrailerType
  isInfoComplete?: boolean
  isBaseInfoComplete?: boolean
  isDocumentsComplete?: boolean
  trailerInspectionReport?: {
    id: string
    inspectionReport: any
  }
}

export interface SlimTrailer {
  id?: string
  vehicleRegistration?: string
  lastDotInspection?: string
  insurancePhysicalDamage?: string
  licensePlateNumber?: string
  licensePlateState?: string
  vehicleNumber?: string
  vehicleMake?: string
  vehicleModel?: string
  vehicleYear?: string | number
}

export const getDocumentsInitialErrors = (equipment: SlimTrailer) => {
  const {
    vehicleRegistration,
    lastDotInspection,
    licensePlateNumber,
    licensePlateState,
    insurancePhysicalDamage,
  } = equipment

  const errors: Array<string> = []

  if (!vehicleRegistration) {
    errors.push('Vehicle registration')
  }

  if (!lastDotInspection) {
    errors.push(`Clean DOT inspection from past ${MAX_DOT_INSPECTION_IN_DAYS} days`)
  }

  if (!insurancePhysicalDamage) {
    errors.push('Physical damage insurance')
  }

  if (!licensePlateNumber || !licensePlateState) {
    errors.push(`License plate`)
  }

  return errors
}

export function getTrailerV3(id: string | undefined): Promise<{ trailer: Trailer | undefined }> {
  return axios
    .get(`/api/v3/trailers/${id}/`)
    .then(({ data }) => ({ trailer: data ? (toCamelCase(data) as Trailer) : undefined }))
}

export function getTeamTrailersV3(teamId: string | undefined): Promise<{ trailers: Trailer[] }> {
  return axios
    .get(`/api/v3/trailers/?team_id=${teamId}`)
    .then(({ data }) => ({ trailers: data?.results ? (toCamelCase(data.results) as Trailer[]) : [] }))
}
