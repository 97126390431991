import { memo } from 'react'
import { useSelector } from 'react-redux'

import { PageNotFound } from 'pages/ErrorPage/PageNotFound'
import { currentTeamSelector } from '../redux/selectors/team'
import { PrivateRoute } from './PrivateRoute'

export const ApprovedRoute = memo(({ component: C, ...rest }: any) => {
  const team = useSelector(currentTeamSelector)
  const isApproved = team && team.is_approved

  return <PrivateRoute {...rest} component={isApproved ? C : PageNotFound} />
})
