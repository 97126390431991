import { useSelector } from 'react-redux'

import { PermissionErrorType, PermissionType } from '../models/permission'
import { currentTeamSelector } from '../redux/selectors/team'
import { currentUserSelector } from '../redux/selectors/users'
import { useSkipPermissionCheckFeature } from './useHasFeatureFlag'

export function usePermission(permission: PermissionType) {
  const hasPermission = usePermissionFactory()
  return hasPermission(permission)
}

export function usePermissionFactory() {
  const user = useSelector(currentUserSelector)
  const skipPermissionsCheck = useSkipPermissionCheckFeature()

  return (permission: PermissionType) => {
    const userPermission = user.permissions?.[permission]

    if (skipPermissionsCheck) {
      return {
        enabled: true,
        reason: null,
      }
    }

    if (!userPermission) {
      return {
        enabled: false,
        reason: PermissionErrorType.ERROR,
      }
    }

    return userPermission
  }
}

export function useCanViewRates() {
  const user = useSelector(currentUserSelector)
  const team = useSelector(currentTeamSelector)
  const viewRatesPermission = usePermission(PermissionType.TEAM_USER_CAN_VIEW_RATES)

  if (!team.rates_visible_to_non_admins && !user.is_team_admin) return false
  return viewRatesPermission.enabled
}
