import { Load, LoadStop, LoadStopType } from '../models/load'
import { ctEstimateFormatter, ctRangeFormatter } from './formatters'
import { formatEquipments, getTripRate, getTripRatePerMile, loadLocationFormatter } from './job'

export function parseLoadDetails(load: Load): Load {
  const origin = load.stops.find((stop) => stop.type === LoadStopType.ORIGIN) as LoadStop
  const hasEstimates = Boolean(load.estimatedRateMin && load.estimatedRateMax)
  const tripRate = getTripRate(load.tripRate || 0)
  const tripRatePerMile = getTripRatePerMile(tripRate, load.tripDistanceMi || 0)
  const loadEquipment = formatEquipments(load.equipment || [])
  const ctRange = load && hasEstimates ? ctRangeFormatter(load) : null
  const ctEstimate = load && hasEstimates ? ctEstimateFormatter(load) : null
  const loadOriginLocation = loadLocationFormatter(origin)
  const destination = load.stops.find((stop) => stop.type === LoadStopType.DESTINATION)
  const loadDestLocation = loadLocationFormatter(destination)

  return {
    ...load,
    details: {
      origin,
      hasEstimates,
      tripRate,
      tripRatePerMile,
      loadEquipment,
      ctRange,
      ctEstimate,
      loadOriginLocation,
      destination,
      loadDestLocation,
    },
  }
}
