import { forwardRef, HTMLAttributes, Ref } from 'react'
import styled, { css, IStyledComponent } from 'styled-components'

import { AddPrefixToObject } from '../../helpers/typeHelper'
import { colorMappings } from '../../theme'
import { cyan } from '../../theme/colors'

export { Wrap, WideWrap, PageWrap } from './Wrap'

const StyledList = styled.ul<AddPrefixToObject<ListProps, '$'>>`
  padding-left: 0;
  margin-left: 0;
  list-style: none;

  li {
    padding-left: 20px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${cyan};
      left: 0;
      top: 10px;
      transform: translate(0, -50%);
    }
  }

  ${(p) =>
    p.$fontColor &&
    css`
      color: ${colorMappings[p.$fontColor] || p.$fontColor};
    `};

  > li + li {
    margin-top: 8px;
  }
`

interface ListProps {
  fontColor?: string
}

export const List = forwardRef(({ fontColor, ...rest }, ref: Ref<any>) => {
  return <StyledList $fontColor={fontColor} ref={ref} {...rest} />
}) as IStyledComponent<'web', ListProps & HTMLAttributes<HTMLUListElement> & { ref?: Ref<any> }>

export const BulletList = styled.ul`
  padding-left: 13px;
  margin: 0;

  li {
    padding-left: 5px;
  }

  li + li {
    margin-top: 5px;
  }
`
