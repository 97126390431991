import { Component, createRef } from 'react'
import { Handler } from 'shared/helpers/typeHelper'
import { cyan, darkgrey } from 'shared/theme/colors'
import styled, { css } from 'styled-components'

const CODE_LENGTH = new Array(6).fill(0)

type Variant = 'small' | 'large'

const SMALL_INPUT_SIZE = 30

interface Props {
  onChange: (value: string) => void
  value?: string
  handleVerification?: Handler
  variant?: Variant
}

interface State {
  value: string
  focused: boolean
  mobileView: boolean
}

export class CodeInput extends Component<Props, State> {
  private inputRef = createRef<HTMLInputElement>()
  private wrapRef = createRef<HTMLInputElement>()

  constructor(props: any) {
    super(props)
    this.state = {
      value: props.value || '',
      focused: false,
      mobileView: false,
    }
  }

  public componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this))
    this.resize()
  }

  public resize() {
    this.setState({ mobileView: window.innerWidth <= 768 })
  }

  handleClick = () => {
    const field = this.inputRef.current
    if (field) {
      field.focus()
    }
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleBlur = () => {
    this.setState({
      focused: false,
    })
  }

  handleKeyUp = (e: any) => {
    if (e.key === 'Backspace') {
      this.setState(() => {
        return {
          value: this.state.value.slice(0, this.state.value.length - 1),
        }
      })
    }
  }

  handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      this.props.handleVerification?.()
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    //we add the number that we inserted to the code value we have in state
    const newValue = (this.state.value + value).slice(0, CODE_LENGTH.length)
    this.setState(() => {
      if (this.state.value.length >= CODE_LENGTH.length) return null
      return {
        value: newValue,
      }
    })
    if (newValue) {
      this.props.onChange(newValue)
    }
  }

  getLeftOffset() {
    const values = this.state.value.split('')
    const selectedIndex = Math.min(values.length, CODE_LENGTH.length)
    const currentOffsetElement = this.wrapRef.current?.querySelector<HTMLElement>(
      `[data-index="${selectedIndex}"]`
    )
    if (currentOffsetElement) return currentOffsetElement.offsetLeft
    const lastOffsetElement = this.wrapRef.current?.querySelector<HTMLElement>(
      `[data-index="${CODE_LENGTH.length - 1}"]`
    )
    if (lastOffsetElement) return lastOffsetElement.offsetLeft + lastOffsetElement.clientWidth / 2
    return 0
  }

  public render() {
    const { value, focused } = this.state
    const values = value.split('')
    const leftMovement = this.getLeftOffset()

    return (
      <Wrap $variant={this.props.variant} ref={this.wrapRef}>
        <CodeInputWrap onClick={this.handleClick}>
          <Input
            type="number"
            value=""
            ref={this.inputRef}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onKeyUp={this.handleKeyUp}
            onKeyPress={this.handleKeyPress}
            style={{ left: `${leftMovement}px` }}
            data-testid="code-input"
            autoFocus
            pattern="\d*"
            $variant={this.props.variant}
          />
          {CODE_LENGTH.map((item, index) => {
            const selected: boolean = values.length === index
            const filled: boolean = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1
            const addSelectedClass = (selected || filled) && focused

            return (
              <Display key={index} $variant={this.props.variant} data-index={index}>
                {values[index]}
                <BottomLine $variant={this.props.variant} $isSelected={addSelectedClass} />
              </Display>
            )
          })}
        </CodeInputWrap>
      </Wrap>
    )
  }
}

const Wrap = styled.div<{ $variant?: Variant }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 56px;
  margin-bottom: 72px;

  ${(p) =>
    p.$variant === 'small' &&
    css`
      margin: 0;
    `}
`

const CodeInputWrap = styled.div<{ $variant?: Variant }>`
  display: flex;
  position: relative;
  flex-wrap: nowrap;
`

const Input = styled.input<{ $variant?: Variant }>`
  font-family: Overpass, sans-serif;
  font-size: 32px;
  font-weight: 600;
  width: 100px;
  top: 0;
  bottom: 0;
  height: 100%;
  position: absolute;
  border: none;
  text-align: center;
  background-color: transparent;
  outline: none;
  color: #000;
  /* stylelint-disable */
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* stylelint-enable */

  @media (max-width: 680px) {
    width: 60px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 36px;
  }

  ${(p) =>
    p.$variant === 'small' &&
    css`
      margin-bottom: 5px;
      font-size: 18px;
      width: ${SMALL_INPUT_SIZE}px;

      @media (max-width: 680px) {
        width: ${SMALL_INPUT_SIZE}px;
      }

      @media (min-width: 320px) and (max-width: 480px) {
        width: ${SMALL_INPUT_SIZE}px;
      }
    `}
`

const Display = styled.div<{ $variant?: Variant }>`
  font-family: Overpass, sans-serif;
  font-weight: normal;
  font-size: 36px;
  line-height: 55px;
  width: 100px;
  height: 100px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #000;
  border-radius: 6px;

  @media (max-width: 680px) {
    width: 60px;
    height: 66px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 36px;
    height: 66px;
  }

  ${(p) =>
    p.$variant === 'small' &&
    css`
      font-size: 18px;
      width: ${SMALL_INPUT_SIZE}px;
      height: ${SMALL_INPUT_SIZE}px;

      @media (max-width: 680px) {
        width: ${SMALL_INPUT_SIZE}px;
        height: ${SMALL_INPUT_SIZE}px;
        margin: 10px 5px;
      }

      @media (min-width: 320px) and (max-width: 480px) {
        width: ${SMALL_INPUT_SIZE}px;
        height: ${SMALL_INPUT_SIZE}px;
      }
    `}
`

const BottomLine = styled.div<{ $variant?: Variant; $isSelected?: boolean }>`
  border-radius: 6px;
  background: ${darkgrey};
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;

  ${(p) =>
    p.$variant === 'small' &&
    css`
      height: 2px;
    `}

  ${(p) =>
    p.$isSelected &&
    css`
      background: ${cyan};
    `}
`
