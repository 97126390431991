import {
  detectEnvironment,
  Environment,
  getVGSDomain,
  isDevelopment,
  isIntegrationTest,
  isProduction,
  isTest,
  privateFieldNames,
} from 'shared/config/config'

export {
  detectEnvironment,
  Environment,
  getVGSDomain,
  privateFieldNames,
  isProduction,
  isDevelopment,
  isTest,
  isIntegrationTest,
}

const INTERCOM_PROD_APP_ID = 'mdg1akld'
const INTERCOM_TEST_APP_ID = 'e3d4fmq2'

const BRANCH_PROD_KEY = '' // TODO
const BRANCH_TEST_KEY = 'key_test_hzkuS15MCEj1bSBGU7JpdjhbrujSpKUg'

export const SENTRY_DSN = 'https://02eaf77cf5a640b29c6ff31b6e0ad194@sentry.io/5181431'
export const LOG_ROCKET_APP_ID_WEB = '7zp8gw/cloudtrucks-web'
export const GTM_CONTAINER = 'GTM-KBRTSRN'
export const ONBOARDING_SUPPORT_NUMBER = '(214) 778-1091'

export function getIntercomAppId() {
  if (detectEnvironment() === Environment.PRODUCTION) {
    return INTERCOM_PROD_APP_ID
  }

  return INTERCOM_TEST_APP_ID
}

export function getBranchKey() {
  if (detectEnvironment() === Environment.PRODUCTION) {
    return BRANCH_PROD_KEY
  }

  return BRANCH_TEST_KEY
}

export function getDocusignEnv() {
  if (detectEnvironment() === Environment.PRODUCTION) {
    return 'https://na3.docusign.net'
  }

  return 'https://demo.docusign.net'
}

export function getDocusignAccountId() {
  if (detectEnvironment() === Environment.PRODUCTION) {
    return '2a7555f2-b0ea-43e4-9b62-781e9c5f3598'
  }

  return '4edeb575-28e3-45f3-8e92-559605268a05'
}
