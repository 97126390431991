import styled from 'styled-components'

import { hexWithOpacity } from '../../helpers/utils'
import { primaryBlack } from '../../theme/colors'
import { centeredContent } from '../../theme/mixins'

export const OverlayElement = styled.div<{ $isTransparent: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: ${({ $isTransparent }) => ($isTransparent ? 'transparent' : hexWithOpacity(primaryBlack, 0.6))};
  ${centeredContent};
`
