export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_CURRENT_USER_INFO = 'SET_CURRENT_USER_INFO'
export const RESET_CURRENT_USER_INFO = 'RESET_CURRENT_USER_INFO'
export const USER_INVITE_SUCCESS = 'USER_INVITE_SUCCESS'
export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER'
export const SAVE_MEDICAL_CARD_CURRENT_USER = 'SAVE_MEDICAL_CARD_CURRENT_USER'
export const SAVE_MEDICAL_CARD_INVITED_USER = 'SAVE_MEDICAL_CARD_INVITED_USER'
export const SAVE_MEDICAL_CARD_DRIVER = 'SAVE_MEDICAL_CARD_DRIVER'
export const SAVE_BACKGROUND_CHECK_STATUS_CURRENT_USER = 'SAVE_BACKGROUNDCHECK_STATUS'
export const SAVE_BACKGROUND_CHECK_STATUS_DRIVER = 'SAVE_BACKGROUNDCHECK_STATUS_DRIVER'
export const SAVE_USER_LOCATION = 'SAVE_USER_LOCATION'
export const CDL_UPLOAD_SUCCESS = 'CDL_UPLOAD_SUCCESS'
