import Fraction from 'fraction.js'
import { Camelized, camelizeKeys, Decamelized, decamelizeKeys } from 'humps'
import * as React from 'react'
import { FRACTION_REGEX } from 'shared/helpers/constants'
import { css, RuleSet } from 'styled-components'

import { YesNo } from '../models'

const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/

export function isHex(value: any) {
  return typeof value === 'string' && hexRegex.test(value)
}

export function hexWithOpacity(hex: any, alpha: number) {
  const isAlphaValid = typeof alpha === 'number' && alpha >= 0 && alpha <= 1
  const opacity = isAlphaValid ? alpha : 1

  if (!isHex(hex)) return 'rgb(0,0,0,1)'

  const r = `0x${hex[1]}${hex[2]}`
  const g = `0x${hex[3]}${hex[4]}`
  const b = `0x${hex[5]}${hex[6]}`

  return `rgb(${+r},${+g},${+b},${opacity})`
}

export const smoothScrollDialogIntoView = (fieldName: string, parentSelector?: string) => {
  const fieldElement = document.getElementsByName(fieldName)[0]
  if (!fieldElement) return
  if (parentSelector && fieldElement.closest && fieldElement.closest(parentSelector)) {
    return fieldElement.closest(parentSelector)?.scrollIntoView({ behavior: 'smooth' })
  }

  fieldElement.scrollIntoView({ behavior: 'smooth' })
}

export const smoothScrollIntoView = (fieldName: string, behavior?: ScrollBehavior) => {
  const fieldElement = document.getElementsByName(fieldName)[0] || document.getElementById(fieldName)
  if (!fieldElement) return
  return smoothScrollElementIntoView(fieldElement, undefined, behavior)
}

export const smoothScrollElementIntoView = (
  element: HTMLElement | null,
  offset = 40,
  behavior?: ScrollBehavior
) => {
  if (!element) return
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: behavior || 'smooth',
  })
}

export function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        ;(ref as React.MutableRefObject<T | null>).current = value
      }
    })
  }
}

export const parseQueryParams = (url: string) => {
  return Object.fromEntries(new URLSearchParams(url.split('?')[1]).entries()) || {}
}

export const fixFloatingPointArithmetic = (x: number, tailingPrecision: number = 2): number => {
  return parseFloat(x.toPrecision(Math.floor(x).toPrecision().length + tailingPrecision))
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d as any)
}

export const toCamelCase = <T>(o: T): Camelized<T> => {
  return camelizeKeys(o, (key, convert, options) => {
    return isUpperCase(key.replaceAll ? key.replaceAll('_', '') : key) ? key : convert(key, options)
  })
}

export const toSnakeCase = <T>(o: T): Decamelized<T> => {
  return decamelizeKeys(o, (key, convert, options) => {
    return isUpperCase(key.replaceAll ? key.replaceAll('_', '') : key) ? key : convert(key, options)
  })
}

function isUpperCase(str: string) {
  return str === str.toUpperCase() && str !== str.toLowerCase()
}

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase())
}

export const toSentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const toAllSentenceCase = (str: string) => {
  return str
    .replace(/\w\S*/g, (txt) => txt.toLowerCase())
    .replace(/\w\S*/, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1))
}

export const fieldToTitleCase = (str: string) => {
  return toTitleCase(toSnakeCase(str).replaceAll('_', ' '))
}

export function containerQuery(query: string, styles: string | RuleSet<object>) {
  return css`
    /* Fallback */
    @media ${query} {
      ${styles}
    }

    /* stylelint-disable */
    @container ${query.replace('only screen and', '')} {
      ${styles}
    }
    /* stylelint-enable */
  `
}

// pluralize(0, 'turtle'); // 0 turtles
// pluralize(1, 'turtle'); // 1 turtle
// pluralize(2, 'turtle'); // 2 turtles
// pluralize(3, 'fox', 'es'); // 3 foxes
// pluralize(2, 'discrepancy'); // 2 discrepancies

export const pluralize = (count: number, noun: string, suffix = 's', includeCountPrefix = true) => {
  let pluralSuffix = suffix
  if (count !== 1 && noun.match(/[^aeiou]y$/)) {
    noun = noun.slice(0, -1)
    pluralSuffix = 'ies'
  }
  const pluralPrefix = includeCountPrefix ? `${count} ` : ''
  return `${pluralPrefix}${noun}${count !== 1 ? pluralSuffix : ''}`
}

export const fractionToDecimal = (value: string) => {
  if (!value) return 0

  const fractionMatch = FRACTION_REGEX.exec(value)
  if (fractionMatch === null || fractionMatch.length === 0) return value
  return new Fraction(value).valueOf().toFixed(8)
}

export const YesNoRadioOptions = [
  {
    label: 'Yes',
    value: YesNo.YES,
  },
  {
    label: 'No',
    value: YesNo.NO,
  },
]

export function convertBooleanToYesNo(val: boolean | null | undefined) {
  if (val === undefined || val === null) return null
  return val ? YesNo.YES : YesNo.NO
}
