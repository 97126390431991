import { Team } from 'models/models'
import { parsePrice, parseProduct } from '../../models/subscription'
import * as stripeActions from '../actions/stripeActions/actionTypes'
import * as types from '../actions/teams/actionTypes'
import * as userTypes from '../actions/users/actionTypes'
import { teamMembersReducer } from './teamMembers'

let initState: Partial<Team> = {
  num_active_drivers: 0,
  billing_address: { id: '', line1: '', line2: '', city: '', state: '', country: '', zip: '' },
  members: [],
}

// TODO: Remove default export
// eslint-disable-next-line import/no-default-export
export default function teamsReducer(state = initState, action: any) {
  switch (action.type) {
    case types.SAVE_TEAM_INFO:
      const team = action.team
      if (team?.product_subscription?.items) {
        team.product_subscription.items = team.product_subscription.items.map((item: any) => ({
          quantity: item.quantity,
          status: item.status,
          isFlatPricing: item.is_flat_pricing,
          lineItemDescription: item.line_item_description,
          lineItemDescriptionAdd: item.line_item_description_add,
          lineItemDescriptionRemove: item.line_item_description_remove,
          lineItemPrice: item.line_item_price,
          lineItemPriceFormatted: item.line_item_price_formatted,
          lineItemPriceTotalFormatted: item.line_item_price_total_formatted,
          lineItemPriceAddFormatted: item.line_item_price_add_formatted,
          lineItemPriceTotalAddFormatted: item.line_item_price_total_add_formatted,
          lineItemPriceRemoveFormatted: item.line_item_price_remove_formatted,
          lineItemPriceTotalRemoveFormatted: item.line_item_price_total_remove_formatted,
          product: item.product ? parseProduct(item.product) : undefined,
          productPrice: item.product_price ? parsePrice(item.product_price) : undefined,
        }))
      }
      return { ...state, ...team }
    case stripeActions.SAVE_STRIPE_API_KEY:
      return { ...state, stripe_api_key: action.key }
    case stripeActions.STRIPE_ACCOUNT_CREATED:
      return { ...state, stripe_account_created: true }
    case stripeActions.SAVE_STRIPE_ACCOUNT_INFO:
      return { ...state, stripe_account: action.account }

    case stripeActions.SAVE_BANK_ACCOUNT_ERROR:
      return {
        ...state,
        stripe_account: { ...state.stripe_account, bank_account_error: action.message },
      }
    case stripeActions.SAVE_BANK_ACCOUNT_SUCCESS:
      return { ...state, stripe_account: { ...state.stripe_account, bank_account_created: true } }
    case userTypes.SET_CURRENT_USER_INFO:
    case userTypes.SET_USER_INFO:
    case userTypes.ADD_TEAM_MEMBER:
    case userTypes.SAVE_MEDICAL_CARD_DRIVER:
    case userTypes.SAVE_BACKGROUND_CHECK_STATUS_DRIVER:
      return { ...state, members: teamMembersReducer(state.members, action) }
    default:
      return state || initState
  }
}
