import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { Text } from 'shared/components/Text/Text'
import { Handler } from 'shared/helpers/typeHelper'
import { lightGray2, primaryBlack, white } from 'shared/theme/colors'
import styled from 'styled-components'

type Props = {
  label: string
  optionLabel: string
  deselect?: Handler
}

export function DateRangeSelectorLabel({ deselect, label, optionLabel }: Props) {
  return (
    <StyledOpener justifyContent="flex-start" alignItems="center" gap="8px">
      <Text variant="header4" fontColor={lightGray2}>
        {label}
      </Text>
      <Text variant="header4" fontColor={primaryBlack}>
        {optionLabel}
      </Text>

      <HighlightOffIcon htmlColor={lightGray2} fontSize="small" onClick={deselect} />
    </StyledOpener>
  )
}

const StyledOpener = styled(FlexBox)<{ $selected?: boolean }>`
  padding: 8px 12px;
  border-radius: 3px;
  background: ${white};
  cursor: pointer;
  opacity: 0.7;

  & > div {
    position: relative;
    top: 1px;
  }
`
