import { useDispatch } from 'react-redux'
import { showWarningBanner } from 'shared/actions/ui/actions'
import { BannerProps } from 'shared/components/Context/UserContext'

export function useWarningMessage() {
  const dispatch = useDispatch()

  return function warningMessage({
    message,
    title,
    actionLabel,
    action,
    footer,
    actionRenderer,
    autoHideDuration,
  }: BannerProps | any) {
    const bannerProps: BannerProps = {
      message,
      title,
      actionLabel: actionLabel,
      action: action,
      footer: footer,
      actionRenderer: actionRenderer,
      autoHideDuration: autoHideDuration,
    }

    dispatch(showWarningBanner(bannerProps))
  }
}
