import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { isProduction, LOG_ROCKET_APP_ID_WEB, privateFieldNames } from '../config/config'
import { currentUserSelector } from '../redux/selectors/users'

// This hook is only called in PrivateRoute which is for routes after user signs in,
// but '/' is also a PrivateRoute, so this can still be called before user signs in.
// so for consistency we should check for user?.id.
export function useLogRocket() {
  const user = useSelector(currentUserSelector)
  const [logRocketInitialized, setLogRocketInitialized] = useState(false)

  useEffect(() => {
    if (isProduction && !logRocketInitialized) {
      if (user?.email && ['test+demo@cloudtrucks.com'].includes(user.email)) {
        return
      }
      // initialize logrocket
      const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(privateFieldNames)
      LogRocket.init(LOG_ROCKET_APP_ID_WEB, {
        network: {
          // @ts-ignore
          requestSanitizer,
          // @ts-ignore
          responseSanitizer,
        },
      })

      // Add a LogRocket session recording URL to every Sentry exception report.
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra('sessionURL', sessionURL)
        })

        // add sessionURL to heap
        // eslint-disable-next-line no-restricted-syntax
        if ((window as any)?.heap?.track) (window as any).heap.track('LogRocket', { sessionURL: sessionURL })
      })

      setLogRocketInitialized(true)
    }
  }, [logRocketInitialized, user?.email])
}
