import { FocusEvent, forwardRef, Fragment, KeyboardEventHandler, useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'

import { HandlerOf } from '../../helpers/typeHelper'
import { mergeRefs } from '../../helpers/utils'
import { warningD1 } from '../../theme/colors'
import { Text } from '../Text/Text'
import {
  Currency,
  ErrorMessage,
  Field,
  HelperMessage,
  InputStyled,
  Label,
  TextEndAdornment,
  TextStartAdornment,
} from './TextInput.styles'

export interface TextInputProps {
  onChange?: HandlerOf<React.ChangeEvent<HTMLInputElement>>
  onClick?: HandlerOf<React.MouseEvent>
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  onBlur?: HandlerOf<FocusEvent<HTMLInputElement>>
  value?: string | number
  label?: string
  placeholder?: string
  name?: string
  type?: string
  error?: React.ReactElement | string | boolean
  warning?: string | React.ReactElement
  helperText?: string
  onFocus?: HandlerOf<FocusEvent<HTMLInputElement>>
  noMargin?: boolean
  isCurrency?: boolean
  noInputAppearance?: boolean
  backgroundColor?: string
  dataPrivate?: string
  min?: string | number
  autoFocus?: boolean
  dataTestid?: string
  disabled?: boolean
  disableInputWheel?: boolean
  disableBorder?: boolean
  className?: string
  endAdornment?: React.ReactNode
  startAdornment?: React.ReactNode
  disableClickFocus?: boolean
  autocomplete?: string
  maxWidth?: string
  isSmall?: boolean
  inputMode?: 'text' | 'none' | 'search' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal'
}

export const TextInput = forwardRef(
  (
    {
      onChange,
      onClick,
      onKeyUp,
      onKeyDown,
      onBlur,
      disableInputWheel,
      value,
      label,
      placeholder,
      name,
      type = 'text',
      error,
      warning,
      helperText,
      onFocus,
      noMargin,
      isCurrency,
      noInputAppearance,
      backgroundColor,
      dataPrivate = 'false',
      autoFocus,
      dataTestid,
      min,
      disabled,
      className,
      endAdornment,
      startAdornment,
      inputMode,
      disableBorder,
      disableClickFocus,
      autocomplete,
      maxWidth,
      isSmall,
    }: TextInputProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const input = useRef<HTMLInputElement>(null)
    const hasError = Boolean(error)
    const hasLabel = Boolean(label)
    const hasValue = !!value || (typeof value === 'number' && Number(value) === 0)

    const handleSetFocus = (e: React.MouseEvent) => {
      if (!disableClickFocus) input?.current?.focus()
      else e.preventDefault()
      onClick?.(e)
    }

    function handleFocus(e: FocusEvent<HTMLInputElement>) {
      setIsFocused(true)
      onFocus && onFocus(e)
    }

    function handleBlur(e: FocusEvent<HTMLInputElement>) {
      setIsFocused(false)
      onBlur?.(e)
    }

    useEffect(() => {
      if (!disableInputWheel) return

      function handleWheel(e: WheelEvent) {
        e.preventDefault()
      }

      const inputEl = input.current
      inputEl?.addEventListener('wheel', handleWheel, { passive: false })
      return () => inputEl?.removeEventListener('wheel', handleWheel)
    }, [disableInputWheel])

    const isCurrencyVisible = isCurrency && (hasValue || isFocused)
    const Wrap = warning ? Div : Fragment

    return (
      <Wrap>
        <Field
          onClick={handleSetFocus}
          $maxWidth={maxWidth}
          $hasError={hasError}
          $hasLabel={hasLabel}
          $backgroundColor={backgroundColor}
          $noMargin={Boolean(noMargin)}
          $disabled={disabled}
          $hasStartAdornment={Boolean(startAdornment)}
          $hasWarning={Boolean(warning && !error)}
          $isSmall={isSmall}
          className={className}
        >
          {startAdornment ? <TextStartAdornment>{startAdornment}</TextStartAdornment> : null}
          <InputStyled
            ref={mergeRefs([input, ref])}
            type={type}
            name={name}
            id={name}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            onWheel={(e) => (noInputAppearance ? (e.target as HTMLInputElement).blur() : null)}
            value={value}
            data-private={dataPrivate}
            min={min}
            inputMode={inputMode}
            autoFocus={autoFocus}
            disabled={disabled}
            autoComplete={autocomplete}
            data-testid={dataTestid}
            $hasError={hasError}
            $hasLabel={hasLabel}
            $isCurrencyVisible={isCurrencyVisible}
            $noInputAppearance={noInputAppearance}
            $hasStartAdornment={Boolean(startAdornment)}
            $hasEndAdornment={Boolean(endAdornment)}
            $backgroundColor={backgroundColor}
            $disableBorder={disableBorder}
            $isSmall={isSmall}
          />
          {isCurrencyVisible && <Currency>$</Currency>}
          {label && (
            <Label
              htmlFor={name}
              $active={hasValue}
              $hasStartAdornment={Boolean(startAdornment)}
              $isSmall={isSmall}
            >
              {label}
            </Label>
          )}
          {hasError && error !== true && <ErrorMessage>{error}</ErrorMessage>}
          {!hasError && helperText && <HelperMessage>{helperText}</HelperMessage>}
          {endAdornment ? <TextEndAdornment>{endAdornment}</TextEndAdornment> : null}
        </Field>
        {warning && !error && (
          <Text variant="label3" fontColor={warningD1} mt={0.2}>
            {warning}
          </Text>
        )}
      </Wrap>
    )
  }
)

const Div = styled.div``
