import { forwardRef, HTMLAttributes, Ref } from 'react'
import styled, { IStyledComponent } from 'styled-components'

const StyledInlineSize = styled.div<{ $styles?: string }>`
  /* container-type is needed for container queries to work */
  /* stylelint-disable */
  container-type: inline-size;
  /* stylelint-enable */

  ${(p) => p.$styles}
`

export interface InlineSizeProps {
  styles?: string
}

export const InlineSize = forwardRef(({ styles, ...rest }, ref: Ref<any>) => {
  return <StyledInlineSize $styles={styles} ref={ref} {...rest} />
}) as IStyledComponent<'web', InlineSizeProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>
