import axios from 'axios'
//extend global window intreface and define Stripe field
declare global {
  interface Window {}
}

export enum Status {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  COMPLETE = 'COMPLETE',
  PENDING = 'PENDING',
}

export enum StripeAccountStatus {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  VERIFIED = 'VERIFIED',
  PENDING = 'PENDING',
}

export interface BankAccount {
  bank_name: string
  routing_number: string
  account_number_masked: string
  account_holder_name: string
  account_holder_type: string
}

export interface StripeAccount {
  status: StripeAccountStatus
  status_detail: string
  outstanding_requirements: any
  bank_account: BankAccount
  bank_account_error: string
  bank_account_created: boolean
}

export interface StripeBankAccountDetails {
  country?: string
  currency?: string
  routing_number: string
  account_number: string
  account_holder_name: string
  account_holder_type: string
}

export const StripeAccountStatusMessage = new Map([
  [StripeAccountStatus.ACTION_REQUIRED, 'Action required to verify information'],
  [StripeAccountStatus.VERIFIED, 'Information verified'],
  [StripeAccountStatus.PENDING, 'Verification Pending'],
])

export const STRIPE_SUCCESS_REDIRECT = 'STRIPE_SUCCESS_REDIRECT'

export function acceptStripeCardholderComplianceTerms(userId: string) {
  const url = `/api/v1/users/${userId}/accept-stripe-cardholder-compliance-terms/`
  return axios.post(url)
}
