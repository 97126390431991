export const breakpointsSize = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

export const breakpoints = {
  /* Extra small devices (phones, 600px and down) */
  xs: `only screen and (max-width: ${breakpointsSize.sm - 1}px)`,

  /* Small devices (portrait tablets and large phones, 960px and down) */
  sm: `only screen and (max-width: ${breakpointsSize.md - 1}px)`,

  /* Not mobile */
  mdUp: `only screen and (min-width: ${breakpointsSize.md}px)`,

  /* Medium devices (landscape tablets, 1280px and down) */
  md: `only screen and (max-width: ${breakpointsSize.lg}px)`,

  /* Large devices (laptops/desktops, 1920px and down) */
  lg: `only screen and (max-width: ${breakpointsSize.xl}px)`,

  /* Extra large devices (large laptops and desktops, 1920px and up) */
  xl: `only screen and (min-width: ${breakpointsSize.xl}px)`,
}
