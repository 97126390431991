import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import styled from 'styled-components'

import { cyan, dividerGray, errorD1, errorL1, midGray } from '../../theme/colors'
import { header3, label3 } from '../../theme/typography'

export const StyledCheckbox = styled(Checkbox)<{ $error: boolean | undefined }>`
  &.MuiIconButton-root {
    background-color: transparent !important;
  }

  .MuiSvgIcon-root {
    fill: ${dividerGray};
  }

  &:hover .MuiSvgIcon-root,
  &.Mui-checked .MuiSvgIcon-root {
    fill: ${cyan};
  }

  .MuiSvgIcon-root,
  &.Mui-checked .MuiSvgIcon-root {
    ${({ $error }) => $error && `fill: ${errorD1}; background-color: ${errorL1}; border-radius: 12px;`}
  }
`

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin: 5px 0;
  margin-left: -10px;
  display: flex;
  align-items: flex-start;
  height: auto;

  .MuiTypography-body1 {
    ${header3};
    color: ${midGray};
    margin-top: 4px;
    font-weight: 400;
    padding-top: 7px;
  }
`

export const ErrorMessage = styled.div`
  ${label3};
  color: ${errorD1};
  margin-top: 5px;
  margin-left: 32px;
`
