import { useEffect, useRef } from 'react'
import { HandlerOf } from 'shared/helpers/typeHelper'

import { getQueryParams, openPopup } from '../helpers/utils'
import { useErrorMessage } from './useErrorMessage'
import { useOauthDisablePopupFlag } from './useHasFeatureFlag'

export interface UseOauthPopupProps {
  setLoading: HandlerOf<boolean>
  onSuccess: HandlerOf<string>
  codeKey: 'code' | 'authorization_code'
  fetchUrl: () => Promise<string>
}

export function useOauthPopup({ setLoading, onSuccess, codeKey, fetchUrl }: UseOauthPopupProps) {
  const showErrorMessage = useErrorMessage()
  const params = useRef(getQueryParams(window))
  const onSuccessExecuted = useRef(false)
  const currentOnSuccess = useRef(onSuccess)
  const interval = useRef<NodeJS.Timeout | null>(null)
  const oauthDisablePopupFlag = useOauthDisablePopupFlag()

  useEffect(() => {
    currentOnSuccess.current = onSuccess
  }, [onSuccess])

  useEffect(() => {
    if (params.current[codeKey] && !onSuccessExecuted.current && interval.current === null) {
      onSuccessExecuted.current = true
      setTimeout(() => {
        currentOnSuccess.current(params.current[codeKey])
      }, 1000)
    }
  }, [codeKey])

  return async function handleOauthSetup() {
    setLoading(true)

    try {
      const authUrl = await fetchUrl()
      setLoading(false)

      if (oauthDisablePopupFlag) {
        window.open(authUrl, '_self')
        return
      }

      const popup = openPopup({ url: authUrl, title: '', width: 600, height: 800 })

      if (!popup) {
        window.open(authUrl, '_self')
        return
      }

      interval.current = setInterval(() => {
        const params = getQueryParams(popup?.window)

        console.warn('params', params, codeKey, popup)

        if (params[codeKey]) {
          popup?.close()
          currentOnSuccess.current(params[codeKey])
        }

        if (popup?.closed && interval.current) {
          clearInterval(interval.current)
        }
      }, 300)
    } catch (err) {
      showErrorMessage(err)
    }
  }
}
