import axios from 'axios'
import * as HttpStatus from 'http-status-codes'
import queryString from 'query-string'

export function createAccount(teamId: string) {
  const stripeSetupUrl = `/api/v1/teams/${teamId}/stripe/setup/`
  return axios.post(stripeSetupUrl).then((response) => {
    if (response.status === HttpStatus.NO_CONTENT) {
      return verifyAccount(teamId)
    }
    throw response
  })
}

//returns a verfication url for stripe
export function verifyAccount(
  teamId: string,
  isFromOnboarding?: boolean,
  redirectPath?: string
): Promise<string> {
  const queryParams = queryString.stringify({ onboarding: isFromOnboarding, redirect_path: redirectPath })
  const stripeVerifyUrl = `/api/v1/teams/${teamId}/stripe/verification/?${queryParams}`

  return axios.post(stripeVerifyUrl).then((response) => {
    if (response.status === HttpStatus.OK) return response.data.account_verification_url
    throw response
  })
}

export function getAccount(teamId: string) {
  const stripeAccountUrl = `/api/v1/teams/${teamId}/stripe/`
  return axios.get(stripeAccountUrl).then((response) => {
    if (response.status === HttpStatus.OK) return response.data
    throw response
  })
}

export function saveBankAccount(teamId: string, stripeToken: string) {
  const bankAccountUrl = `/api/v1/teams/${teamId}/stripe/bank-account/`
  return axios.post(bankAccountUrl, { stripe_token: stripeToken }).then((response) => {
    if (response.status === HttpStatus.CREATED || response.status === HttpStatus.NO_CONTENT) return response
    throw response
  })
}
