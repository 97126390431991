import * as React from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { FlexBox } from 'shared/components/Base/Base'
import { NAV_LEFT_WRAP } from 'shared/components/Nav/NavLeft'
import { isDevelopment } from 'shared/config/config'
import { bgGray } from 'shared/theme/colors'
import styled from 'styled-components'

import { useAnalyticsEvent } from '../../../hooks/useAnalyticsEvent'
import { useLogRocket } from '../../../hooks/useLogRocket'
import { logoutUser } from '../../../redux/actions/auth/authActions'
import { ReduxDispatch } from '../../../redux/store/models'
import { SIGN_IN_PATH } from '../../../Routes'
import { AvatarMenu, AvatarMenuOptions } from './AvatarMenu'

export function VendorNav() {
  const { shutdown } = useIntercom()
  const history = useHistory()
  const dispatch: ReduxDispatch = useDispatch()
  const queryClient = useQueryClient()
  const track = useAnalyticsEvent()

  useLogRocket()

  const handleLogout = () => {
    track('profile_logout_click')

    queryClient.clear()
    if (!isDevelopment) shutdown()

    dispatch(logoutUser()).then(() => {
      history.push(SIGN_IN_PATH)
    })
  }

  return (
    <FlexBox mt={1}>
      <div id={NAV_LEFT_WRAP} />
      <NavWrap>
        <AvatarMenu handleLogout={handleLogout} options={[AvatarMenuOptions.LOGOUT]} />
      </NavWrap>
    </FlexBox>
  )
}

const NavWrap = styled(FlexBox)`
  width: 100%;
  background-color: ${bgGray};
  position: relative;
  align-items: center;
  justify-content: flex-end;
`
