import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Handler } from 'shared/helpers/typeHelper'

import { lightGray1 } from '../../theme/colors'

interface CloseButtonProps {
  size?: string
  className?: string
  onClose: Handler
}

interface StylesProps {
  size?: CloseButtonProps['size']
}

const useStyles = makeStyles(() => ({
  closeButton: () => ({
    color: lightGray1,
    padding: '0',
  }),
  closeButtonIcon: (props: StylesProps) => ({
    width: props.size || '34px',
    height: props.size || '34px',
  }),
}))

export function CloseButton({ size, onClose, className }: CloseButtonProps) {
  const classes = useStyles({ size })

  return (
    <IconButton aria-label="close" onClick={onClose} className={`${classes.closeButton} ${className}`}>
      <CloseIcon className={classes.closeButtonIcon} />
    </IconButton>
  )
}
