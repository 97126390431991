import axios from 'axios'

export enum ConsultantConversationMessageFeedbackType {
  THUMBS_UP = 'THUMBS_UP',
  THUMBS_DOWN = 'THUMBS_DOWN',
}

export enum ConsultantConversationMessageFeedbackTypeDetail {
  INACCURATE = 'INACCURATE',
  OFFENSIVE = 'OFFENSIVE',
  NOT_HELPFUL = 'NOT_HELPFUL',
  OTHER = 'OTHER',
}

export interface ConsultantConversationMessageLinkWidget {
  name: 'link'
  parameters: { url: string; text: string }
}

export type ConsultantConversationMessageWidget = ConsultantConversationMessageLinkWidget

export interface ConsultantConversationMessage {
  id: string
  timestamp: string
  text: string
  isFromUser: boolean
  isPending?: boolean
  isError?: boolean
  feedback?: {
    type: ConsultantConversationMessageFeedbackType
  }
  widgets: ConsultantConversationMessageWidget[]
}

export function parseConsultantMessage(message: any): ConsultantConversationMessage {
  return {
    id: message.id || message.timestamp,
    // New line can be encoded in the api as \\n
    // Replacing ending \\ as streaming can send whitespace characters as separate tokens
    text: message.content?.trim()?.replaceAll('\\n', '\n').replace(/\\$/, ''),
    isFromUser: message.type === 'HUMAN',
    isPending: false,
    timestamp: message.timestamp,
    feedback: {
      type: message.feedback?.type,
    },
    widgets: message.widgets || [],
  }
}

export function getConsultant() {
  return axios.post(`/api/v1/consultant/`).then((res) => res.data.id)
}

export function getConsultantMessages(id: string): Promise<ConsultantConversationMessage[]> {
  return axios.get(`/api/v1/consultant/${id}/messages/`).then((res) => res.data.map(parseConsultantMessage))
}

export function createConsultantMessage(id: string, message: string): Promise<ConsultantConversationMessage> {
  return axios
    .post(`/api/v1/consultant/${id}/messages/`, { message: message.trim() })
    .then((res) => parseConsultantMessage(res.data))
}

interface ConsultantMessagePositiveFeedbackPayload {
  message_id: string
  type: ConsultantConversationMessageFeedbackType.THUMBS_UP
}

interface ConsultantMessageNegativeFeedbackPayload {
  message_id: string
  type: ConsultantConversationMessageFeedbackType.THUMBS_DOWN
  type_detail: ConsultantConversationMessageFeedbackTypeDetail[]
  feedback_content?: string
}

type ConsultantMessageFeedbackPayload =
  | ConsultantMessagePositiveFeedbackPayload
  | ConsultantMessageNegativeFeedbackPayload

export function submitConsultantMessageFeedback(id: string, payload: ConsultantMessageFeedbackPayload) {
  return axios.post(`/api/v1/consultant/${id}/feedback/`, payload)
}
