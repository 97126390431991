export enum JobFiltersTypes {
  DRIVER = 'DRIVER',
  TRUCK = 'TRUCK',
  PICKUP_DATE = 'PICKUP_DATE',
  BROKER = 'BROKER',
}

export const JobFiltersOptions = [
  { value: JobFiltersTypes.DRIVER, label: 'Driver' },
  { value: JobFiltersTypes.TRUCK, label: 'Truck' },
  { value: JobFiltersTypes.PICKUP_DATE, label: 'Pickup date' },
  { value: JobFiltersTypes.BROKER, label: 'Broker' },
]

export const JOB_FILTERS_TOOLBAR_HEIGHT = 40

export type SelectedJobFiltersType = Record<JobFiltersTypes, boolean>

export type SelectedJobFiltersValuesType = Record<
  JobFiltersTypes.DRIVER | JobFiltersTypes.BROKER | JobFiltersTypes.TRUCK,
  string | 'ALL' | undefined
>
