import styled, { css } from 'styled-components'

import { cyan, cyanD1, errorD1, errorL1, lightGray2, midGray, warningD1, white } from '../../theme/colors'
import { inputActiveLabel } from '../../theme/mixins'
import { header3, paragraph, paragraph3 } from '../../theme/typography'

export const Label = styled.label<{
  $active: boolean
  $hasStartAdornment?: boolean
  $isSmall?: boolean
}>`
  position: absolute;
  top: 17px;
  left: 16px;
  transition: all 0.2s;
  touch-action: manipulation;
  ${header3};
  font-size: 16px !important;
  color: ${lightGray2};

  ${({ $isSmall }) => $isSmall && `top: 12px; font-size: 13px !important;`}

  ${({ $active }) => $active && inputActiveLabel};
  ${({ $hasStartAdornment }) =>
    $hasStartAdornment &&
    css`
      left: 40px;
    `};
`

export const Field = styled.div<{
  $hasError: boolean
  $hasWarning: boolean
  $hasLabel: boolean
  $noMargin: boolean
  $disabled?: boolean
  $backgroundColor?: string
  $hasStartAdornment?: boolean
  $height?: string
  $maxWidth?: string
  $isSmall?: boolean
}>`
  position: relative;
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 24px;
  padding: ${({ $hasLabel }) => ($hasLabel ? '16px 1px 0 1px' : 0)};
  background-color: ${white};
  border-radius: 3px;
  height: 56px;
  width: 100%;
  max-width: 424px;
  min-width: 120px;
  cursor: pointer;
  ${({ $backgroundColor }) => $backgroundColor && `background-color: ${$backgroundColor};`}
  ${({ $hasError }) => $hasError && `border: 1px solid ${errorD1}; background-color: ${errorL1};`}
  ${({ $hasWarning }) => $hasWarning && `border: 1px solid ${warningD1};`}
  ${({ $noMargin }) => $noMargin && `margin-bottom: 0;`}
  ${({ $disabled }) => $disabled && `opacity: 0.6; cursor: default;`}
  ${({ $height }) => $height && `height: ${$height};`}
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
  ${({ $isSmall }) => $isSmall && `height: 40px;`}

  input::placeholder {
    color: ${lightGray2};
  }

  &:focus-within > ${Label} {
    transform: scale(0.8);
    transform-origin: top left;
    cursor: pointer;
    color: ${cyanD1};
    top: 2px;
    left: 14px;
    font-weight: 500;
    ${({ $hasError }) => $hasError && `color: ${lightGray2};`};

    ${({ $hasStartAdornment }) =>
      $hasStartAdornment &&
      css`
        left: 40px;
      `};
  }

  &:has(input:focus),
  &:has(textarea:focus) {
    outline: 0;
    box-shadow: 0 1px 0 0 ${cyanD1};
  }
`

export const InputStyled = styled.input<{
  disabled?: boolean
  $hasError: boolean
  $hasLabel: boolean
  $isCurrencyVisible?: boolean
  $noInputAppearance?: boolean
  $hasStartAdornment?: boolean
  $disableBorder?: boolean
  $hasEndAdornment?: boolean
  $backgroundColor?: string
  $isTextarea?: boolean
  $isSmall?: boolean
}>`
  ${header3};
  font-size: 16px !important;
  transition: all 0.2s;
  touch-action: manipulation;
  border: 0;
  border-bottom: 1px solid transparent;
  background: transparent;
  resize: none;
  padding: ${({ $hasLabel }) => ($hasLabel ? '4px 15px' : '14px 16px')};
  caret-color: ${cyan};
  ${({ $hasStartAdornment }) => $hasStartAdornment && `padding-left: 40px;`}
  ${({ $hasEndAdornment }) => $hasEndAdornment && `padding-right: 40px;`}
  ${({ $isTextarea }) => $isTextarea && `height: 100%; padding-top: 10px;`}
  ${({ $isSmall }) => $isSmall && `font-size: 13px !important; padding-left: 13px;`}

  &:focus {
    outline: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    /* stylelint-disable */
    ${({ $backgroundColor }) =>
      `-webkit-box-shadow: 0 0 0 9999px ${$backgroundColor || 'white'} inset !important;`}
    ${({ $backgroundColor }) => `box-shadow: 0 0 0 9999px  ${$backgroundColor || 'white'} inset !important;`}
    /* stylelint-enable */
    background-color: transparent !important;
    background-clip: content-box !important;
  }

  ${({ $isCurrencyVisible }) =>
    $isCurrencyVisible &&
    css`
      padding-left: 30px;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    `}

  ${({ $noInputAppearance }) =>
    $noInputAppearance &&
    css`
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    `}

  ${({ $hasError }) => $hasError && `background-color: ${errorL1} !important;`}
  ${({ $disableBorder }) => $disableBorder && `border-bottom: none !important;`}
`

const helperText = css`
  ${paragraph};
  ${paragraph3};
  position: absolute;
  top: 60px;
  left: 0;
`

export const ErrorMessage = styled.div<{ $top?: string }>`
  ${helperText};
  color: ${errorD1};

  ${({ $top }) => $top && `top: ${$top};`}

  &::first-letter {
    text-transform: capitalize;
  }
`

export const HelperMessage = styled.div`
  ${helperText};
  color: ${midGray};
`

export const Currency = styled.span`
  position: absolute;
  left: 15px;
  bottom: 8px;
  font-size: 16px;
`

export const TextStartAdornment = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: ${lightGray2};
`

export const TextEndAdornment = styled(TextStartAdornment)`
  left: auto;
  right: 10px;
`

export const InputContainer = styled.div`
  background-color: #fff;
  border-radius: 3px;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px 15px 4px;
`
