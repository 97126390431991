import Toolbar from '@material-ui/core/Toolbar'

import logo from 'assets/images/CT-logo-small.svg'
import { CLOUDTRUCKS_MARKETING_PAGE_URL } from 'helpers/constants'
import useStyles from './styles'

export const BasicNav = () => {
  const classes = useStyles()

  const openCTMarketingPage = () => {
    window.location.href = CLOUDTRUCKS_MARKETING_PAGE_URL
  }

  return (
    <Toolbar className={classes.toolbar}>
      <img src={logo} className={classes.logo2} alt="CloudTrucks Logo" onClick={openCTMarketingPage} />
    </Toolbar>
  )
}
