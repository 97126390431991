import * as types from 'shared/actions/ui/actionTypes'
import { Handler } from 'shared/helpers/typeHelper'

export interface snackbarsState {
  successSnackbarOpen: boolean
  errorSnackbarOpen: boolean
  infoSnackbarOpen: boolean
  warningSnackbarOpen: boolean
  message: string | string[]
  title: string
  footer: string
  action: null | Handler
  autoHideDuration?: number
  actionLabel: string
  actionRenderer: (() => React.ReactNode) | null
}

let initState: snackbarsState = {
  successSnackbarOpen: false,
  errorSnackbarOpen: false,
  infoSnackbarOpen: false,
  warningSnackbarOpen: false,
  message: '',
  title: '',
  footer: '',
  action: null,
  actionLabel: '',
  actionRenderer: null,
}

export function snackbarsReducer(state = initState, action: any) {
  switch (action.type) {
    case types.SNACKBAR_SUCCESS:
      return {
        ...state,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
        successSnackbarOpen: true,
        warningSnackbarOpen: false,
        message: action.message,
        title: action.title,
        footer: action.footer,
        autoHideDuration: action.autoHideDuration,
        action: action.action,
        actionLabel: action.actionLabel,
        actionRenderer: action.actionRenderer,
      }
    case types.SNACKBAR_ERROR:
      return {
        ...state,
        infoSnackbarOpen: false,
        successSnackbarOpen: false,
        errorSnackbarOpen: true,
        warningSnackbarOpen: false,
        message: action.message,
        title: action.title,
        footer: action.footer,
        autoHideDuration: action.autoHideDuration,
        action: action.action,
        actionLabel: action.actionLabel,
        actionRenderer: action.actionRenderer,
      }
    case types.SNACKBAR_WARNING:
      return {
        ...state,
        infoSnackbarOpen: false,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        warningSnackbarOpen: true,
        message: action.message,
        title: action.title,
        footer: action.footer,
        autoHideDuration: action.autoHideDuration,
        action: action.action,
        actionLabel: action.actionLabel,
        actionRenderer: action.actionRenderer,
      }
    case types.SNACKBAR_INFO:
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: true,
        warningSnackbarOpen: false,
        message: action.message,
        title: action.title,
        footer: action.footer,
        autoHideDuration: action.autoHideDuration,
        action: action.action,
        actionLabel: action.actionLabel,
        actionRenderer: action.actionRenderer,
      }
    case types.SNACKBAR_CLEAR:
      return initState
    default:
      return state || initState
  }
}
