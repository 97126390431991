import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { CacheKeys } from '../../helpers/cacheKeys'
import { getAstraUser } from '../../models/contractorPayments'
import { currentUserSelector } from '../../redux/selectors/users'

interface UseAstraUser {
  enabled?: boolean
}

export function useAstraUser({ enabled = true }: UseAstraUser = {}) {
  const currentUser = useSelector(currentUserSelector)

  const { data, isLoading, isFetching, refetch } = useQuery(
    `${CacheKeys.ASTRA_USER}-${currentUser.id}`,
    () => getAstraUser(),
    {
      refetchOnWindowFocus: false,
      retry: true,
      enabled,
      retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    }
  )

  return {
    business: data?.business,
    personal: data?.personal,
    isLoading,
    isFetching,
    refetch,
  }
}
