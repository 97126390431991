import { yupResolver } from '@hookform/resolvers/yup'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Controller, useForm } from 'react-hook-form'
import { Button } from 'shared/components/Button/Button'
import Overlay from 'shared/components/Overlay/Overlay'
import { TextInput } from 'shared/components/TextInput/TextInput'
import { sizes } from 'shared/theme'
import { object, string } from 'yup'

import { FormContainer, Paragraph } from './VerifyModal.styles'

export type VerifyForm = {
  email: string
}

export const initialForm: VerifyForm = {
  email: '',
}

export const VerifySchema = object().shape({
  email: string().email('Email format not recognized').required(),
})

const VerifyModal = ({ toggleModal, getNewToken }: any) => {
  const { control, handleSubmit } = useForm<VerifyForm>({
    mode: 'onSubmit',
    defaultValues: initialForm,
    resolver: yupResolver(VerifySchema),
  })

  const onSubmit = (formData: VerifyForm) => {
    getNewToken(formData.email)
  }

  return (
    <Overlay onClick={toggleModal}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <IconButton aria-label="close" onClick={toggleModal}>
          <CloseIcon />
        </IconButton>
        <Paragraph>
          Enter the email address on your account and we’ll send you a new verification code.
        </Paragraph>
        <Controller
          render={({ field, fieldState }) => (
            <TextInput {...field} label="E-mail address" error={fieldState.error?.message} />
          )}
          name="email"
          control={control}
          defaultValue={initialForm.email}
        />
        <Button type="submit" size={sizes.extraLarge} margin="16px 0" onClick={() => {}}>
          Verify Account
        </Button>
      </FormContainer>
    </Overlay>
  )
}
// TODO: Remove default export
// eslint-disable-next-line import/no-default-export
export default VerifyModal
