import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'shared/components/Button/Button'
import { lightgreye3 } from 'shared/theme/colors'
import styled from 'styled-components'

import { Sparkles } from '../../components/Icons/Icon'
import { useAnalyticsEvent } from '../../hooks/useAnalyticsEvent'
import { openConsultant } from '../../redux/actions/consultant/consultant'

export function AskAiButton() {
  const dispatch = useDispatch()
  const track = useAnalyticsEvent()

  function handleOpen() {
    dispatch(openConsultant())
    track('nav_ask_ai_click')
  }

  return (
    <StyledButton variant="secondary" size="medium" onClick={handleOpen} icon={<img src={Sparkles} alt="" />}>
      Ask Cai
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  font-size: 16px;
  border: 1px solid ${lightgreye3};
  box-shadow: 0 2px 10px 2px rgba(0, 44, 177, 0.05);
  text-transform: none;
  height: 34px;
  letter-spacing: 0;

  &:hover:not(:disabled) {
    border-color: ${lightgreye3};
  }
`
