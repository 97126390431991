import axios from 'axios'
import * as HttpStatus from 'http-status-codes'
import { PaginatedResults } from 'shared/helpers/typeHelper'

import { Equipment, EquipmentPayload, parseEquipment } from './equipment'

// Models

export enum ELD {
  KEEP_TRUCKIN = 'KEEP_TRUCKIN',
  SAMSARA = 'SAMSARA',
  OTHER = 'OTHER',
}

export const EldType = {
  KEEP_TRUCKIN: 'KeepTruckin',
  SAMSARA: 'Samsara',
  OTHER: 'Other',
}

export enum EldTypeStatus {
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  VALID = 'VALID',
}

export interface Truck extends Equipment {
  eldType?: ELD
  eldSerialNumber?: string
  insuranceNonTruckingLiability?: string
  insuranceNonTruckingLiabilityExpiration?: string
  iftaRequired?: boolean
  platesProgramRequired?: boolean
  isInfoComplete?: boolean
  isBaseInfoComplete?: boolean
  isDocumentsComplete?: boolean
  isEldComplete?: boolean
  isIftaAndPlatesComplete?: boolean
  eldStatus?: EldTypeStatus
  eldFields?: {
    licensePlateNumber: EldField
    licensePlateState: EldField
    vehicleMake: EldField
    vehicleModel: EldField
    vehicleYear: EldField
    vehicleNumber: EldField
  }
  assignedDrivers?: {
    id: string
    firstName: string
    lastName: string
  }[]
}

export enum EldFieldState {
  MISSING_IN_CT = 'MISSING_IN_CT',
  INCOMPATIBLE = 'INCOMPATIBLE',
  VALID = 'VALID',
}

export interface EldField {
  state: EldFieldState
  value: string
}

interface EquipmentEldFieldsPayload {
  license_plate_number: EldField
  license_plate_state: EldField
  vehicle_make: EldField
  vehicle_model: EldField
  vehicle_year: EldField
  vehicle_number: EldField
}

export interface TruckPayload extends EquipmentPayload {
  eld_serial_number?: string
  eld_type?: ELD
  insurance_non_trucking_liability?: string
  insurance_non_trucking_liability_expiration?: string
  ifta_required?: boolean
  plates_program_required?: boolean
  is_info_complete?: boolean
  is_base_info_complete?: boolean
  is_documents_complete?: boolean
  is_eld_complete?: boolean
  is_ifta_and_plates_complete?: boolean
  eld_status?: EldTypeStatus
  eld_fields?: EquipmentEldFieldsPayload
  assigned_drivers?: {
    id: string
    first_name: string
    last_name: string
  }[]
}

// Apis

export function createTruck(payload: TruckPayload): Promise<Truck> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return axios
    .post(`/api/v1/trucks/`, payload.formData || payload, config)
    .then((response) => {
      if (response.status === HttpStatus.CREATED) {
        return parseTruck(response.data)
      }
      throw response.data
    })
    .catch((err) => {
      // Temp fix for server side 500
      if (typeof err?.response?.data === 'string')
        // eslint-disable-next-line no-throw-literal
        throw { data: { message: 'Something went wrong' } }
      throw err
    })
}

export function fetchTrucks(page = 1): Promise<PaginatedResults<Truck>> {
  return axios.get(`/api/v1/trucks/?page=${page}`).then((response) => {
    if (response.status === HttpStatus.OK) {
      return {
        ...response.data,
        results: response.data.results.map(parseTruck),
      }
    }

    throw response.data
  })
}

export function fetchTruck(id: string): Promise<Truck> {
  return axios.get(`/api/v1/trucks/${id}/`).then((response) => {
    if (response.status === HttpStatus.OK) {
      return parseTruck(response.data)
    }

    throw response.data
  })
}

export function updateTruck(payload: TruckPayload): Promise<Truck> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return axios
    .patch(`/api/v1/trucks/${payload.id}/`, payload.formData || payload, config)
    .then((response) => {
      if (response.status === HttpStatus.OK) {
        return parseTruck(response.data)
      }
      throw response.data
    })
}

export function deleteTruck(truckId: string) {
  return axios.delete(`/api/v1/trucks/${truckId}/`)
}

export function parseTruck(truck: TruckPayload): Truck {
  return {
    ...parseEquipment(truck),
    eldType: truck.eld_type,
    eldSerialNumber: truck.eld_serial_number,
    insuranceNonTruckingLiability: truck.insurance_non_trucking_liability,
    insuranceNonTruckingLiabilityExpiration: truck.insurance_non_trucking_liability_expiration,
    iftaRequired: truck.ifta_required,
    platesProgramRequired: truck.plates_program_required,
    isInfoComplete: truck.is_info_complete,
    isBaseInfoComplete: truck.is_base_info_complete,
    isDocumentsComplete: truck.is_documents_complete,
    isIftaAndPlatesComplete: truck.is_ifta_and_plates_complete,
    assignedDrivers: truck?.assigned_drivers?.map((d) => ({
      id: d.id,
      firstName: d.first_name,
      lastName: d.last_name,
    })),
    eldStatus: truck.eld_status,
    eldFields: truck.eld_fields
      ? {
          licensePlateNumber: truck.eld_fields.license_plate_number,
          licensePlateState: truck.eld_fields.license_plate_state,
          vehicleMake: truck.eld_fields.vehicle_make,
          vehicleModel: truck.eld_fields.vehicle_model,
          vehicleYear: truck.eld_fields.vehicle_year,
          vehicleNumber: truck.eld_fields.vehicle_number,
        }
      : undefined,
  }
}

export function createEldFieldsPayload(fields: Truck['eldFields']): OverrideVehicleKeeptruckinData['fields'] {
  if (!fields) return {}
  return {
    license_plate_number: fields.licensePlateNumber,
    license_plate_state: fields.licensePlateState,
    vehicle_make: fields.vehicleMake,
    vehicle_model: fields.vehicleModel,
    vehicle_year: fields.vehicleYear,
    vehicle_number: fields.vehicleNumber || {
      state: EldFieldState.VALID,
      value: '',
    },
  }
}

// Helpers
export function getTruckMissingFields(truck: Truck) {
  const errors = [
    !truck.isBaseInfoComplete,
    !truck.isDocumentsComplete,
    !truck.isIftaAndPlatesComplete,
  ].filter(Boolean)

  if (errors.length > 1) {
    return 'Missing information'
  }

  if (!truck.isBaseInfoComplete) {
    return 'Missing truck information'
  }

  if (!truck.isDocumentsComplete) {
    return 'Missing documents'
  }

  if (!truck.isIftaAndPlatesComplete) {
    return 'Missing IFTA and plates'
  }

  return ''
}

export interface OverrideVehicleKeeptruckinData {
  id: string
  fields: Partial<Record<keyof EquipmentEldFieldsPayload, EldField>>
}

export interface OverrideVehiclesWithKeeptruckinDataPayload {
  trucks: OverrideVehicleKeeptruckinData[]
}

export function overrideVehiclesWithKeeptruckinData(
  payload: OverrideVehiclesWithKeeptruckinDataPayload
): Promise<void> {
  return axios.post(`/api/v1/trucks/override-vehicles-with-keeptruckin-data/`, payload).then((response) => {
    return response.data
  })
}
