import axios from 'axios'
import * as HttpStatus from 'http-status-codes'
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link'
import {
  isPlaidAccountPending,
  isPlaidAccountVerified,
  parsePlaidAccount,
  PlaidAccount,
  PlaidAccountError,
  PlaidAccountResponse,
  PlaidAccountSource,
  PlaidAccountStatus,
  PlaidAccountSubtype,
  PlaidAccountType,
  PlaidItem,
  PlaidProduct,
  PlainLinkToken,
} from 'shared/models/plaid'

export {
  PlaidAccountType,
  PlaidAccountSubtype,
  PlaidProduct,
  PlaidAccountSource,
  PlaidAccountStatus,
  isPlaidAccountVerified,
  isPlaidAccountPending,
  parsePlaidAccount,
}

export type { PlaidAccount, PlaidAccountResponse, PlaidAccountError, PlaidItem, PlainLinkToken }

// Api

export function createLinkToken({
  teamId,
  accountId,
  products,
}: {
  teamId: string
  accountId?: string
  products?: string
}): Promise<PlainLinkToken> {
  return axios
    .post(
      `/api/v1/teams/${teamId}/plaid/link/?${accountId ? `account_id=${accountId}` : ''}${
        products ? `products=${products}` : ''
      }`
    )
    .then((response) => {
      if (response.status === HttpStatus.OK) {
        return {
          linkToken: response.data.plaid_link_token,
        }
      }
      throw response.data
    })
}

interface VerifyPublicTokenPayload {
  teamId: string
  publicToken: string
  metadata: PlaidLinkOnSuccessMetadata
  products?: PlaidProduct[]
  isPersonal?: boolean
  source?: PlaidAccountSource
}

export function verifyPublicToken({
  teamId,
  publicToken,
  metadata,
  products,
  isPersonal,
  source,
}: VerifyPublicTokenPayload) {
  return axios.post(`/api/v1/teams/${teamId}/plaid/verification/`, {
    public_token: publicToken,
    metadata,
    products,
    is_personal: isPersonal,
    source,
  })
}

interface VerificationFailedPayload {
  teamId: string
  accountId: string
}

export function verificationFailed({ teamId, accountId }: VerificationFailedPayload) {
  return axios.post(`/api/v1/teams/${teamId}/plaid/verification-failed/`, { account_id: accountId })
}

export function syncLastLinkedAccountStatus({ teamId }: { teamId: string }) {
  return axios.post(`/api/v1/teams/${teamId}/plaid/sync-last-linked-account-status/`)
}
