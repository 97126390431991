import axios from 'axios'

import { ContactType, generateMobileBody, getContactType } from '../helpers/authHelper'

const csrfTokenUrl = '/api/v1/auth/csrf/'
const authEmailUrl = '/api/v1/auth/email/'
const authMobileUrl = '/api/v1/auth/mobile/'
const authVerifyUrl = '/api/v1/auth/session/'
const logoutUrl = '/api/v1/logout/session/'

export function getCsrfTokenCookie() {
  return axios.get(csrfTokenUrl)
}

export function requestLoginToken(data: string) {
  const type = getContactType(data)
  const url = type === ContactType.Email ? authEmailUrl : authMobileUrl
  const body = type === ContactType.Email ? { email: data } : generateMobileBody(data)
  return axios.post(url, body)
}

export function verifyLoginToken(loginToken: string, userContact: string) {
  const type = getContactType(userContact)
  let body: Record<string, string> = {}
  if (type === ContactType.Email) {
    body.email = userContact
  } else {
    body = generateMobileBody(userContact)
  }
  body.token = loginToken

  return axios.post(authVerifyUrl, body)
}

export function logout() {
  return axios.post(logoutUrl)
}

// New API requests used with react-query
export function verifyLoginCodeToken(loginToken: string, userContact: string) {
  const type = getContactType(userContact)
  let body: Record<string, string> = {}
  if (type === ContactType.Email) {
    body.email = userContact
  } else {
    body = generateMobileBody(userContact)
  }
  body.token = loginToken

  return axios.post(authVerifyUrl, body)
}
