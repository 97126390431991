import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { InlineSpinner } from 'shared/components/Spinner'
import { Text } from 'shared/components/Text/Text'
import { parseError } from 'shared/helpers/errors'
import { breakpoints } from 'shared/theme/breakpoints'
import { errorD1 } from 'shared/theme/colors'
import styled from 'styled-components'

import { useErrorMessage } from '../../hooks/useErrorMessage'
import {
  ConsultantConversationMessage,
  createConsultantMessage,
  getConsultant,
  getConsultantMessages,
} from '../../models/consultant'
import { ConsultantConversationComposer } from './ConsultantConversationComposer'
import { ConsultantMessage, ConsultantMessageTyping } from './ConsultantMessage'
import { useConversationAsyncMessages } from './useConversationAsyncMessages'

interface ConsultantConversationProps {
  container: HTMLDivElement | null
}

export function ConsultantConversation({ container }: ConsultantConversationProps) {
  const initialFetch = useRef(false)
  const showErrorMessage = useErrorMessage()
  const [error, setError] = useState<null | string | JSX.Element[] | JSX.Element>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [consultant, setConsultant] = useState<string | null>(null)
  const [messages, setMessages] = useState<ConsultantConversationMessage[]>([])
  const [enableTypingIndicator, setEnableTypingIndicator] = useState(false)
  const lastMessageFromUser = messages[messages.length - 1]?.isFromUser

  const scrollToBottom = useCallback(
    (timeout = 100, behavior: ScrollToOptions['behavior'] = 'smooth') => {
      setTimeout(() => container?.scrollTo({ top: container.scrollHeight, behavior }), timeout)
    },
    [container]
  )

  const handleAddNewMessage = useCallback(
    (newMessage: ConsultantConversationMessage) => {
      setMessages((messages) => {
        if (messages.some((msg) => msg.id === newMessage.id)) {
          return messages.map((msg) =>
            // Length check is to make sure we append only newer message
            msg.id === newMessage.id && newMessage.text.length > msg.text.length ? newMessage : msg
          )
        } else {
          return [...messages, newMessage].sort(
            (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
          )
        }
      })

      scrollToBottom()
      scrollToBottom(300)
      scrollToBottom(700)
    },
    [scrollToBottom]
  )

  const handleErrorMessage = useCallback((text: string) => {
    setMessages((messages) => {
      let match = false
      return messages
        .reverse()
        .map((msg) => {
          if (!match && msg.text === text) {
            match = true
            return {
              ...msg,
              isError: true,
            }
          }
          return msg
        })
        .reverse()
    })
    setEnableTypingIndicator(false)
  }, [])

  useConversationAsyncMessages({ channelId: consultant, handleAddNewMessage, handleErrorMessage })

  useEffect(() => {
    getConsultant()
      .then(setConsultant)
      .catch((err) => setError(parseError(err)))
  }, [])

  useEffect(() => {
    if (consultant && !initialFetch.current) {
      initialFetch.current = true
      getConsultantMessages(consultant).then((messages) => {
        setMessages(messages)
        setIsLoading(false)
        scrollToBottom(undefined, 'auto')
      })
    }
  }, [consultant, scrollToBottom, messages.length, isLoading])

  async function handleNewMessage(message: string) {
    if (!consultant) return

    const id = String(new Date().getTime())
    createConsultantMessage(consultant, message)
      .then(() => {
        setMessages((messages) => messages.map((m) => (m.id === id ? { ...m, isPending: false } : m)))
      })
      .catch((err) => {
        showErrorMessage(err)
        setEnableTypingIndicator(false)
        setMessages((messages) =>
          messages.map((m) => (m.id === id ? { ...m, isPending: false, isError: true } : m))
        )
      })

    setMessages([
      ...messages,
      {
        id,
        timestamp: id,
        text: message,
        isFromUser: true,
        isPending: true,
        widgets: [],
      },
    ])

    scrollToBottom()
    setTimeout(() => {
      setEnableTypingIndicator(true)
      scrollToBottom()
    }, 1000)
  }

  function renderContent() {
    if (error)
      return (
        <FlexBox justifyContent="center" alignItems="center">
          <Text variant="header3" fontColor={errorD1}>
            {error}
          </Text>
        </FlexBox>
      )
    if (isLoading || !consultant) return <InlineSpinner />

    return (
      <>
        <Messages>
          {messages.map((message, i) => (
            <ConsultantMessage
              key={message.id}
              consultant={consultant}
              message={message}
              scrollToBottom={scrollToBottom}
              isLast={i === messages.length - 1}
            />
          ))}

          {lastMessageFromUser && enableTypingIndicator && <ConsultantMessageTyping />}
        </Messages>

        <ConsultantConversationComposer
          onMessage={handleNewMessage}
          onResize={scrollToBottom}
          messages={messages}
        />
      </>
    )
  }

  return <Conversation>{renderContent()}</Conversation>
}

const Conversation = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'conversation-messages'
    'conversation-composer';
`

const Messages = styled.div`
  grid-area: conversation-messages;
  padding: 0 40px;

  @media ${breakpoints.sm} {
    padding: 0 20px;
  }
`
