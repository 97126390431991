import { STATES } from './constants'

export const getState = (selectedState: string) => {
  selectedState = selectedState.trim().toLowerCase()
  return STATES.find(
    (state) =>
      state.abbreviation.toLowerCase() === selectedState || state.name.toLowerCase() === selectedState
  )
}

export const getDropdownStates = () =>
  STATES.map((state) => ({ label: state.abbreviation, value: state.abbreviation }))

//  We are getting states from BE part of the project,
//  it's not included in the frontend folder,
//  so we import it here from 'shared_component folder and also export,
//  so we can easily import inside the fronted application
export { STATES }
