import { useDispatch } from 'react-redux'
import { showInfoBanner } from 'shared/actions/ui/actions'
import { BannerProps } from 'shared/components/Context/UserContext'

export function useInfoMessage() {
  const dispatch = useDispatch()

  return function infoMessage(bannerProps: BannerProps) {
    dispatch(showInfoBanner(bannerProps))
  }
}
