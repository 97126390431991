import { Button } from 'shared/components/Button/Button'
import { TaskStatusEnum } from 'shared/components/TaskList/TaskList'

import { OnboardingChecklist, OnboardingSubChecklist } from '../../../models/onboarding'
import { CONTRACTOR_PERSONAL_PERSONAL_INFO_PATH } from '../../../Routes'

interface ContractorPaymentsPersonalOnboardingButtonProps {
  task: OnboardingChecklist | OnboardingSubChecklist
}

export function ContractorPaymentsPersonalOnboardingButton({
  task,
}: ContractorPaymentsPersonalOnboardingButtonProps) {
  function renderText() {
    if (task.status === TaskStatusEnum.IN_PROGRESS) {
      return 'Continue'
    }

    return 'Start'
  }

  if (
    task.status === TaskStatusEnum.NOT_STARTED ||
    task.status === TaskStatusEnum.IN_PROGRESS ||
    task.status === TaskStatusEnum.ERROR
  )
    return (
      <Button size="medium" variant="secondary" href={CONTRACTOR_PERSONAL_PERSONAL_INFO_PATH}>
        {renderText()}
      </Button>
    )
  return null
}
