import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IntercomProvider } from 'react-use-intercom'
import { clearBanner } from 'shared/actions/ui/actions'
import { Snackbars } from 'shared/components/Snackbars/Snackbars'

import { Intercom } from './components/Intercom/Intercom'
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop'
import { StripeTosModal } from './components/StripeTos/StripeTosModal'
import { getIntercomAppId } from './config/config'
import { Consultant } from './pages/Consultant/Consultant'
import { Routes } from './Routes'

export const AppContainer = ({ children }: { children?: React.ReactNode }) => {
  const dispatch = useDispatch()

  return (
    <IntercomProvider appId={getIntercomAppId()}>
      <Intercom>
        {children ? children : <Routes />}
        <Snackbars onClose={() => dispatch(clearBanner())} useSelector={useSelector} />
        <ScrollToTop />
        <StripeTosModal />
        <Consultant />
      </Intercom>
    </IntercomProvider>
  )
}
