import { forwardRef, HTMLAttributes, Ref } from 'react'
import styled, { css, IStyledComponent } from 'styled-components'

import { AddPrefixToObject } from '../../helpers/typeHelper'

export const StyledCentered = styled.div<AddPrefixToObject<CenteredProps, '$'>>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(p) => p.$textCenter && 'text-align: center;'}
`
interface CenteredProps {
  textCenter?: boolean
}

export const Centered = forwardRef(({ textCenter, ...rest }, ref: Ref<any>) => {
  return <StyledCentered $textCenter={textCenter} ref={ref} {...rest} />
}) as IStyledComponent<'web', CenteredProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>

export const StyledRelative = styled.div<AddPrefixToObject<RelativeProps, '$'>>`
  position: relative;
  ${(p) => p.$zIndex && `z-index: ${p.$zIndex};`}
`

interface RelativeProps {
  zIndex?: number
}

export const Relative = forwardRef(({ zIndex, ...rest }, ref: Ref<any>) => {
  return <StyledRelative $zIndex={zIndex} ref={ref} {...rest} />
}) as IStyledComponent<'web', RelativeProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>

export const InlineBlock = styled.div`
  display: inline-block;
`

const StyledBlurred = styled.div<AddPrefixToObject<BlurredProps, '$'>>`
  ${(p) =>
    p.$enabled &&
    css`
      filter: blur(4px);
    `};
`

interface BlurredProps {
  enabled?: boolean
}

export const Blurred = forwardRef(({ enabled, ...rest }, ref: Ref<any>) => {
  return <StyledBlurred $enabled={enabled} ref={ref} {...rest} />
}) as IStyledComponent<'web', BlurredProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>
