import { css } from 'styled-components'

export const centeredContent = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const truncatedText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const inputActiveLabel = css`
  transform: scale(0.8);
  transform-origin: top left;
  cursor: pointer;
  top: 2px;
  left: 14px;
  font-weight: 500;
`

export const verticallyCentered = css`
  display: grid;
  align-items: center;
`

export const scrollbarHidden = css`
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
