import { useSelector } from 'react-redux'
import { Feature, ProductSubscriptionItemStatus } from 'shared/models/productSubscription'

import { ProductSubscriptionName, ProductSubscriptionStripeStatus } from '../models/productSubscription'
import { currentTeamSelector } from '../redux/selectors/team'

export function useProductSubscription() {
  const team = useSelector(currentTeamSelector)
  const isFlex = team.product_subscription?.product_name === ProductSubscriptionName.FLEX
  const isPremium = team.product_subscription?.product_name === ProductSubscriptionName.PREMIUM
  const isCash = team.product_subscription?.product_name === ProductSubscriptionName.CASH
  const supportEmail = team.product_subscription?.support_email
  const supportNumber = team?.product_subscription?.support_number
  const hasCreditFeature = team.product_subscription?.has_credit_feature
  const hasFactoringFeature = team.product_subscription?.has_factoring_feature
  const hasStarterFeature = team.product_subscription?.has_starter_feature
  const isCreditOnly = team.product_subscription?.is_credit_only
  const isFlexStarter = team.product_subscription?.is_flex_starter
  const isStripeSubscriptionActive = team.product_subscription?.is_stripe_subscription_active
  const isStripeSubscriptionCanceled = team.product_subscription?.is_stripe_subscription_canceled
  const isStripeSubscriptionNotStarted =
    team.product_subscription?.stripe_subscription_status === ProductSubscriptionStripeStatus.NOT_APPLICABLE
  const isStripeSubscriptionTrialling =
    team.product_subscription?.stripe_subscription_status === ProductSubscriptionStripeStatus.TRIALING
  const isStripeSubscriptionIncomplete =
    team.product_subscription?.stripe_subscription_status === ProductSubscriptionStripeStatus.INCOMPLETE ||
    team.product_subscription?.stripe_subscription_status ===
      ProductSubscriptionStripeStatus.INCOMPLETE_EXPIRED
  const isStripeSubscriptionPastDue =
    team.product_subscription?.stripe_subscription_status === ProductSubscriptionStripeStatus.PAST_DUE
  const items = team.product_subscription?.items
  const helpCenterUrl = team.product_subscription?.help_center_url
  const hasContractorInterrest = items?.find((item) => item.product.name === Feature.CONTRACTOR_PAYMENTS)
  const hasContractorFeature = hasContractorInterrest?.status === ProductSubscriptionItemStatus.ACTIVE
  const hasFuelCardInterrest = items?.find((item) => item.product.name === Feature.FUEL_CARD)
  const hasFuelCardFeature = hasFuelCardInterrest?.status === ProductSubscriptionItemStatus.ACTIVE
  const carrier = team.product_subscription?.carrier

  return {
    isFlex,
    isPremium,
    isCash,
    hasCreditFeature,
    hasFactoringFeature,
    hasStarterFeature,
    hasContractorFeature,
    hasContractorInterrest,
    hasFuelCardInterrest,
    hasFuelCardFeature,
    isCreditOnly,
    isFlexStarter,
    isStripeSubscriptionActive,
    isStripeSubscriptionCanceled,
    isStripeSubscriptionNotStarted,
    isStripeSubscriptionIncomplete,
    isStripeSubscriptionPastDue,
    isStripeSubscriptionTrialling,
    supportEmail,
    supportNumber,
    helpCenterUrl,
    items,
    carrier,
  }
}
