import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { TaskStatusEnum } from 'shared/components/TaskList/TaskList'

import { OnboardingChecklist, OnboardingChecklistName } from '../models/onboarding'
import { hasCompletedOnboarding } from '../pages/Onboarding/OnboardingTaskList.util'
import { currentTeamSelector } from '../redux/selectors/team'
import { currentUserSelector, isVendorUserSelector } from '../redux/selectors/users'
import {
  ADD_DRIVER_PATH,
  CONTRACTOR_PAYMENTS_PATH,
  JOBS_DETAILS_PATH,
  LOADS_DETAILS_PATH,
  PAYMENT_INFORMATION_BANK_ACCOUNT_PROFILE_PATH,
  publicRoutesPaths,
} from '../Routes'
import { useIsPath } from './useIsPath'

function hasChecklistItems(
  checklist: readonly OnboardingChecklist[] | null,
  items: OnboardingChecklistName[]
) {
  const onboardingItems = checklist?.filter((i) => items.includes(i.name))

  if (onboardingItems?.length && onboardingItems.some((i) => i.status !== TaskStatusEnum.COMPLETED)) {
    return true
  }

  return false
}

export function hasBusinessContractorOnboardingItems(checklist: readonly OnboardingChecklist[] | null) {
  return hasChecklistItems(checklist, [
    OnboardingChecklistName.CONTRACTOR_PAYMENTS_PROFILE,
    OnboardingChecklistName.PAYROLL_BANK_ACCOUNT,
    OnboardingChecklistName.ADD_CONTRACTOR,
  ])
}

export function hasPersonalContractorOnboardingItems(checklist: readonly OnboardingChecklist[] | null) {
  return hasChecklistItems(checklist, [
    OnboardingChecklistName.CONTRACTOR_PERSONAL_PROFILE,
    OnboardingChecklistName.CONTRACTOR_PERSONAL_DIRECT_DEPOSIT,
    OnboardingChecklistName.CONTRACTOR_PERSONAL_DEBIT_CARD,
  ])
}

export function useShouldShowOnboardingBanner(checklist: readonly OnboardingChecklist[] | null) {
  const isVendorUser = useSelector(isVendorUserSelector)
  const user = useSelector(currentUserSelector)
  const team = useSelector(currentTeamSelector)
  const isPublicPath = useIsPath(publicRoutesPaths)
  const { pathname } = useLocation()
  const shouldHideBannerOnThisPath = useIsPath([
    JOBS_DETAILS_PATH,
    LOADS_DETAILS_PATH,
    ADD_DRIVER_PATH,
    PAYMENT_INFORMATION_BANK_ACCOUNT_PROFILE_PATH,
  ])
  const isOnboardingPath = pathname.startsWith('/onboarding/')
  const isOnboardingCompleted = hasCompletedOnboarding(checklist, user, team)
  const hasContractorOnboardingItems =
    hasPersonalContractorOnboardingItems(checklist) || hasBusinessContractorOnboardingItems(checklist)

  if (isVendorUser) {
    return false
  }

  if (isPublicPath || !user.id || isOnboardingPath) {
    return false
  }

  if (pathname.startsWith(CONTRACTOR_PAYMENTS_PATH)) {
    return false
  }

  if (hasContractorOnboardingItems) {
    return true
  }

  if (shouldHideBannerOnThisPath) {
    return false
  }

  return !isOnboardingCompleted
}
