import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  .MuiIconButton-root:hover {
    background-color: rgba(199, 209, 218, 0.4);
  }

  body.overflow-hidden {
    overflow: hidden;
  }
`
