export const EMPLOYMENT_HISTORY_MIN_MONTHS_EMPLOYED = 12
// we waived recent experience requirement
export const EMPLOYMENT_HISTORY_MIN_MONTHS_EMPLOYED_YEARS_BACK = 20

export const EMPLOYMENT_ROUTES = {
  ONBOARDING_EMPLOYMENT_HISTORY: '/onboarding/employment-history/',
  ONBOARDING_EMPLOYMENT_HISTORY_ADD_FMCSR: '/onboarding/employment-history/add-fmcsr-validation/:id/',
  ONBOARDING_EMPLOYMENT_HISTORY_ADD: '/onboarding/employment-history/add-position/',
  ONBOARDING_EMPLOYMENT_HISTORY_EDIT_FMCSR: '/onboarding/employment-history/edit-fmcsr-validation/:id/',
  ONBOARDING_EMPLOYMENT_HISTORY_EDIT: '/onboarding/employment-history/edit-position/:id/',
  ONBOARDING_EMPLOYMENT_SUBMIT: '/onboarding/employment-history/submit-application/',
}
