import axios from 'axios'
import * as HttpStatus from 'http-status-codes'

import { toCamelCase } from '../helpers/utils'

// export function acceptDriverTerms(userId: string): Promise<void> {
//   return axios.post(`/api/v3/users/${userId}/accept-terms/`)
// }

// export function notifyUserToSignAgreement(userId: string, sendAlsoSms = false): Promise<void> {
//   return axios.post(
//     `/api/v3/users/${userId}/notify-sign-agreement/${sendAlsoSms ? '?send-also-sms=true' : ''}`
//   )
// }

export enum OnboardingBackgroundCheckStatus {
  VERIFIED = 'VERIFIED',
  PENDING = 'PENDING',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  WAITING_FOR_INVITATION = 'WAITING_FOR_INVITATION',
}

export interface BackgroundCheckResult {
  status: OnboardingBackgroundCheckStatus
  invitation_complete: boolean
  invitation_url?: string
}

export function getBackgroundCheckStatus(
  userId: string,
  useSecondaryPackage?: boolean
): Promise<BackgroundCheckResult> {
  const url = `/api/v3/users/${userId}/background-checks/onboarding/${
    useSecondaryPackage ? '?use_secondary_package=true' : ''
  }`
  return axios.get(url).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
    throw response.data
  })
}

export function createBackgroundCheck(userId: string, initial = true): Promise<void> {
  return axios.post(
    `/api/v3/users/${userId}/background-checks/onboarding/create/${initial ? '?initial=true' : ''}`
  )
}

export interface CheckrCandidate {
  consentPackageName: string
  complianceLocationState: string
  checkrCandidateId: string
}

export function createCheckrCandidate(userId: string): Promise<CheckrCandidate> {
  return axios
    .post(`/api/v3/users/${userId}/background-checks/onboarding/checkr-candidate/`)
    .then((response) => toCamelCase(response.data) as CheckrCandidate)
}

export function recordUserConsent(userId: string): Promise<void> {
  return axios.post(`/api/v3/users/${userId}/background-checks/onboarding/record-checkr-consent/`)
}

export function getDrugScreeningUrl(userId: string): Promise<string> {
  return axios
    .get(`/api/v3/users/${userId}/background-checks/onboarding/drug-screening-pass-uri/`)
    .then((res) => res.data.pass_uri)
}

export function getCheckrTokenUrl(userId: string) {
  return `/api/v3/users/${userId}/background-checks/onboarding/checkr-token/`
}

export function deactivateCandidate(userId: string) {
  return axios.post(`/api/v3/users/${userId}/background-checks/onboarding/checkr-candidate/deactivate/`)
}

export function manualHirerightCreateAndSync(
  userId: string,
  isInitialPackage: boolean,
  hirerightRegId: string
) {
  let url = `/api/v3/users/${userId}/background-checks/onboarding/manual-hireright-create-and-sync/`
  let payload = {
    initial: isInitialPackage ? 'true' : 'false', // convert boolean to string for backend
    reg_id: hirerightRegId,
  }

  return axios.post(url, payload)
}
