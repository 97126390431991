import { makeStyles } from '@material-ui/core/styles'
import { bgGray, cyan } from 'shared/theme/colors'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: bgGray,
    justifyContent: 'space-between',
    minHeight: 70,
    maxWidth: 1440,
    margin: 'auto',
  },
  logo: {
    width: '65px',
    height: '50px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '37px',
      height: '24px',
    },
  },
  logo2: {
    width: '49px',
    height: '32px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '37px',
      height: '24px',
    },
  },
  spacer: {
    flexGrow: 1,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '3px',
    '& > div': {
      maxWidth: 52,
      width: '100%',
      backgroundColor: cyan,
    },
  },
}))

// TODO: Remove default export
// eslint-disable-next-line import/no-default-export
export default useStyles
