import React, { useContext } from 'react'

import { OnboardingChecklist } from '../../models/onboarding'

interface OnboardingChecklistContextType {
  allChecklistItems: readonly OnboardingChecklist[] | null
}

const defaultValue = {
  allChecklistItems: [],
}

export const OnboardingChecklistContext = React.createContext<OnboardingChecklistContextType>(defaultValue)

export const useOnboardingChecklistContext = () => {
  return useContext(OnboardingChecklistContext)
}
