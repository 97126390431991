import axios from 'axios'
import * as HttpStatus from 'http-status-codes'

import { getVGSDomain } from '../config/config'
import { CreateFleet, FleetApplication, Team } from '../models/models'
import {
  FleetApplicationPayload,
  FleetApplicationTaxInfoPayload,
  FleetDocumentsPayload,
  UpdateTrailerCountPayload,
} from '../models/payloads'

let createTeamUrl = '/api/v1/teams/'

export function createTeam(team: Team) {
  return axios.post(createTeamUrl, JSON.stringify(team)).then((response) => {
    if (response.status === HttpStatus.CREATED) return response
  })
}

export function updateTeamInfo(team: Team) {
  let url = `/api/v1/teams/${team.id}/`
  return axios.patch(url, team).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
  })
}

export function updateTeamTrailerCounts(payload: UpdateTrailerCountPayload) {
  let url = `/api/v1/teams/${payload.team_id}/`
  return axios.patch(url, payload).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
  })
}

// New API request used with react-query
export function createFleet(fleet: CreateFleet) {
  const { bundle_name, ...payload } = fleet
  return axios
    .post(createTeamUrl, JSON.stringify(payload), {
      params: {
        bundle_name,
      },
    })
    .then((response) => {
      if (response.status === HttpStatus.CREATED) {
        return response
      }
    })
    .catch((error) => {
      throw error.response
    })
}

export function fetchFleetDocumentation(teamId: string): Promise<FleetApplication> {
  const url = `/api/v1/teams/${teamId}/documentation/`

  return axios.get(url).then((response) => {
    if (response.status === HttpStatus.OK) {
      return {
        businessDocumentation: response.data.business_documentation,
        businessType: response.data.business_type,
        certificateOfInsurance: response.data.certificate_of_insurance,
        operatingAuthorityLetter: response.data.operating_authority_letter,
      }
    } else {
      throw response
    }
  })
}

export function createFleetDocumentation(documentation: FleetApplicationPayload) {
  const url = `/api/v1/teams/${documentation.teamId}/documentation/`
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return axios.post(url, documentation.formData, config).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
  })
}

export function createFleetBankDocuments({ teamId, ...payload }: FleetDocumentsPayload) {
  const url = `${getVGSDomain()}/api/v1/teams/${teamId}/encrypted-documentation/`
  return axios.post(url, payload, { withCredentials: true })
}

export function createTaxInfo({ teamId, ...payload }: FleetApplicationTaxInfoPayload) {
  const url = `${getVGSDomain()}/api/v1/teams/${teamId}/add-tax-info/`

  return axios.post(url, payload, { withCredentials: true }).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
  })
}
