import axios from 'axios'
import { Decamelized } from 'humps'
import { Trailer } from 'shared/models/trailer'
import { Truck } from 'shared/models/truck'

import { toCamelCase } from '../helpers/utils'
import { Address } from './address'
import { CreditApplicationStatus } from './cash'
import { Entitlement, ProductSubscription, ProductSubscriptionBundleName } from './productSubscription'
import { TruckListSetting } from './truckList'
import { StaffUser, User } from './user'

export enum TeamStatus {
  APPROVED = 'APPROVED',
  APPLYING = 'APPLYING',
  OFFBOARDED = 'OFFBOARDED',
  OFFBOARDING = 'OFFBOARDING',
  DEACTIVATED = 'DEACTIVATED',
  ONBOARDING = 'ONBOARDING',
}

export type TeamMember = {
  dateJoined: string
  email: string
  firstName: string
  id: string
  isTeamAdmin: boolean
  isTeamDriver: boolean
  lastName: string
  mobile: string
  roles: string[]
}

export interface SlimTeamCarrier {
  id: string
  name: string
  mcNumber: string
  dotNumber: string
  billingAddress: string
  contactEmail: string
  phoneNumber: string
  legalNameOwner: string
  legalNameBusiness: string
}

export interface SlimTeam {
  id: string
  name?: string
  isApproved?: boolean
  isFlexStarter?: boolean
  isCreditOnly?: boolean
  hasStarterFeature?: boolean
  hasFactoringFeature?: boolean
  entitlements?: Entitlement[]
  creditStatus?: CreditApplicationStatus
  isTaxInfoComplete?: boolean
  productSubscriptionName?: ProductSubscriptionBundleName
  numActiveDrivers: number
  carrier?: SlimTeamCarrier
}

export interface Team extends SlimTeam {
  numPowerUnits?: number
  numTrailers?: number
  composition?: string
  dotNumber?: string
  mcNumber?: string
  locationCity?: string
  locationState?: string
  referralSource?: string
  dateCreated?: string
  dateUpdated?: string
  dateEsignActConsent?: string
  numTrailersDryVan?: number
  numTrailersFlatbed?: number
  numTrailersReefer?: number
  stripeAccountCreated?: boolean
  billingAddress: Address
  ratesVisibleToNonAdmins?: boolean
  productSubscription: ProductSubscription
  ctMcNumber: string
  plaidAssetsEligible?: boolean
  businessType?: BusinessType
  businessDocumentation?: string
  legalNameBusiness?: string
  legalFormationState?: string
  legalBusinessOwner?: string
  isCreditStatusOpenToUser?: boolean
  hasTaxId?: boolean
  formattedSubscriptionName?: boolean
  truckListSetting: TruckListSetting
  status: TeamStatus
  greyList: TeamGreyList
}

export type ConsoleTeam = Decamelized<
  Team & {
    status?: string
    offboardingStatus?: string | null
    members: TeamMember[]
    trucks: Truck[]
    trailers: Trailer[]
    weeklyRevenueDollars: string | null
  }
>

export interface TeamGreyList {
  onGreyList: boolean
  reasonCodes: TeamGreyListReasonCode[]
}

export enum TeamGreyListReasonCode {
  EXCESSIVE_ADVANCES = 'EXCESSIVE_ADVANCES',
  CREDIT_FRAUD = 'CREDIT_FRAUD',
  CREDIT_OVERDUE_BALANCE = 'CREDIT_OVERDUE_BALANCE',
}

export enum BusinessType {
  LLC = 'LLC',
  CORP = 'CORP',
  SOLE_PROPRIETORSHIP = 'SP',
}

// TODO: extend TeamV3 with more fields
export interface TeamV3 extends Team {
  members: User[]
}

export function getTeamV3(id: string | undefined) {
  const url = `/api/v3/teams/${id}/`

  return axios.get(url).then((response) => ({
    team: response.data ? (toCamelCase(response.data) as TeamV3) : undefined,
  }))
}

export function getStaffUsers(): Promise<StaffUser[]> {
  const url = `/api/v3/teams/staff-users/`

  return axios.get(url).then((response) => {
    return response.data.map(toCamelCase) as StaffUser[]
  })
}
