import { forwardRef, Fragment, KeyboardEventHandler, useRef } from 'react'
import { styled } from 'styled-components'

import { Handler, HandlerOf } from '../../helpers/typeHelper'
import { mergeRefs } from '../../helpers/utils'
import { warningD1 } from '../../theme/colors'
import { Text } from '../Text/Text'
import {
  ErrorMessage,
  Field,
  HelperMessage,
  InputStyled,
  Label,
  TextEndAdornment,
  TextStartAdornment,
} from './TextInput.styles'

export interface TextAreaInputProps {
  onChange?: HandlerOf<React.ChangeEvent<HTMLTextAreaElement>>
  onClick?: HandlerOf<React.MouseEvent>
  onKeyUp?: KeyboardEventHandler<HTMLTextAreaElement>
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>
  onBlur?: Handler
  value?: string | number
  label?: string
  placeholder?: string
  name?: string
  error?: React.ReactElement | string | boolean
  warning?: string | React.ReactElement
  helperText?: string
  onFocus?: Handler
  noMargin?: boolean
  backgroundColor?: string
  dataPrivate?: string
  autoFocus?: boolean
  dataTestid?: string
  disabled?: boolean
  disableBorder?: boolean
  className?: string
  height?: string
  endAdornment?: React.ReactNode
  startAdornment?: React.ReactNode
  rows?: number
  children?: React.ReactNode
}

export const TextAreaInput = forwardRef(
  (
    {
      onChange,
      onClick,
      onKeyUp,
      onKeyDown,
      onBlur,
      value,
      label,
      placeholder,
      name,
      error,
      warning,
      helperText,
      onFocus,
      noMargin,
      backgroundColor,
      dataPrivate = 'false',
      autoFocus,
      dataTestid,
      disabled,
      className,
      disableBorder,
      height,
      startAdornment,
      endAdornment,
      rows,
      children,
    }: TextAreaInputProps,
    ref
  ) => {
    const input = useRef<HTMLInputElement>(null)
    const hasError = Boolean(error)
    const hasLabel = Boolean(label)
    const hasValue = !!value || (typeof value === 'number' && Number(value) === 0)

    const handleSetFocus = (e: React.MouseEvent) => {
      input?.current?.focus()
      onClick?.(e)
    }

    const Wrap = warning ? Div : Fragment

    return (
      <Wrap>
        <Field
          onClick={handleSetFocus}
          $hasError={hasError}
          $hasLabel={hasLabel}
          $backgroundColor={backgroundColor}
          $noMargin={Boolean(noMargin)}
          $disabled={disabled}
          $height={height}
          $hasStartAdornment={Boolean(startAdornment)}
          $hasWarning={Boolean(warning && !error)}
          className={`${className} textarea-wrap`}
        >
          {startAdornment ? <TextStartAdornment>{startAdornment}</TextStartAdornment> : null}
          <TextArea
            as="textarea"
            ref={mergeRefs([input, ref])}
            name={name}
            id={name}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            value={value}
            data-private={dataPrivate}
            autoFocus={autoFocus}
            disabled={disabled}
            data-testid={dataTestid}
            $hasError={hasError}
            $hasLabel={hasLabel}
            $hasStartAdornment={Boolean(startAdornment)}
            $hasEndAdornment={Boolean(endAdornment)}
            $backgroundColor={backgroundColor}
            $disableBorder={disableBorder}
            $isTextarea
            rows={rows}
          />
          {label && (
            <Label htmlFor={name} $active={hasValue} $hasStartAdornment={Boolean(startAdornment)}>
              {label}
            </Label>
          )}
          {hasError && error !== true && <ErrorMessage $top={height}>{error}</ErrorMessage>}
          {!hasError && helperText && <HelperMessage>{helperText}</HelperMessage>}
          {endAdornment ? <TextEndAdornment>{endAdornment}</TextEndAdornment> : null}
          <span onClick={(e) => e.stopPropagation()}>{children}</span>
        </Field>

        {warning && !error && (
          <Text variant="label3" fontColor={warningD1} mt={0.2}>
            {warning}
          </Text>
        )}
      </Wrap>
    )
  }
)

const Div = styled.div``

const TextArea = styled(InputStyled)`
  transition: box-shadow 0.2s;
`
