import { Button } from 'shared/components/Button/Button'
import { DateRangeValueType } from 'shared/components/DateRangeSelector/DateRangeSelector'
import { Dropdown } from 'shared/components/Dropdown/Dropdown'
import { Text } from 'shared/components/Text/Text'
import { HandlerOf } from 'shared/helpers/typeHelper'
import { lightGray1 } from 'shared/theme/colors'
import styled from 'styled-components'

import { FilterListGrayIcon } from '../../../components/Icons/Icon'
import { convertComponentJobFiltersToSearchFilters, serializeSearchForUrlParams } from '../../../helpers/jobs'
import { useJobs } from '../../../hooks/query/useJobs'
import { useTrucks } from '../../../hooks/query/useTrucks'
import {
  JobFiltersOptions,
  JobFiltersTypes,
  SelectedJobFiltersType,
  SelectedJobFiltersValuesType,
} from './jobFiltersConstants'
import { JobFiltersOption } from './JobFiltersOption'

interface Props {
  isDisabled?: boolean
  updateUrlParams: HandlerOf<string>
  selectedFilters: SelectedJobFiltersType
  setSelectedFilters: HandlerOf<SelectedJobFiltersType>
  selectedFiltersValues: SelectedJobFiltersValuesType
  setSelectedFiltersValues: HandlerOf<SelectedJobFiltersValuesType>
  selectedDateRangeValue: DateRangeValueType
  setSelectedDateRangeValue: HandlerOf<DateRangeValueType>
  currentlyActiveFilter: JobFiltersTypes | undefined
  setCurrentlyActiveFilter: HandlerOf<JobFiltersTypes | undefined>
}

export function JobFiltersDropdown({
  isDisabled,
  updateUrlParams,
  setSelectedFilters,
  selectedFilters,
  selectedFiltersValues,
  setSelectedFiltersValues,
  selectedDateRangeValue,
  setSelectedDateRangeValue,
  currentlyActiveFilter,
  setCurrentlyActiveFilter,
}: Props) {
  const { jobs } = useJobs()
  const { trucks } = useTrucks({ shouldFetchAllPages: true })
  const filtersSelected = Object.values(selectedFilters).some((x) => x)

  const opener = (
    <StyledIconButton
      variant="text"
      isDisabled={isDisabled}
      icon={<img src={FilterListGrayIcon} alt="filter_jobs_inactive" width={24} height={24} />}
      $selected={filtersSelected}
    >
      <Text variant="label2" fontColor={lightGray1}>
        Filter
      </Text>
    </StyledIconButton>
  )

  function selectCurrentlyActiveFilter() {
    if (currentlyActiveFilter) {
      setSelectedFilters({
        ...selectedFilters,
        [currentlyActiveFilter]: !selectedFilters[currentlyActiveFilter],
      })

      updateUrlParams(
        serializeSearchForUrlParams(
          convertComponentJobFiltersToSearchFilters(
            {
              ...selectedFiltersValues,
              [currentlyActiveFilter]: 'ALL',
            },
            currentlyActiveFilter === JobFiltersTypes.PICKUP_DATE ? ['ALL', 'ALL'] : selectedDateRangeValue
          )
        )
      )

      setCurrentlyActiveFilter(undefined)
    }
  }

  if (currentlyActiveFilter) {
    return (
      <JobFiltersOption
        filterType={currentlyActiveFilter}
        label={currentlyActiveFilter}
        jobs={jobs}
        trucks={trucks}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        selectedFiltersValues={selectedFiltersValues}
        setSelectedFiltersValues={(nextValues) => {
          setSelectedFiltersValues(nextValues)
          selectCurrentlyActiveFilter()
        }}
        selectedDateRangeValue={selectedDateRangeValue}
        setSelectedDateRangeValue={(nextRange, done) => {
          setSelectedDateRangeValue(nextRange)
          if (done) {
            selectCurrentlyActiveFilter()
          }
        }}
        updateUrlParams={updateUrlParams}
        isInitiallyOpen
        opener={opener}
        onBlur={() => setCurrentlyActiveFilter(undefined)}
      />
    )
  }

  return (
    <Dropdown
      noMargin
      onChange={(filter) => setCurrentlyActiveFilter(filter)}
      options={JobFiltersOptions.filter(({ value }) => !selectedFilters[value])}
      renderOpener={() => opener}
      maxHeight={258}
      width={250}
    />
  )
}

const StyledIconButton = styled(Button)<{ $selected?: boolean }>`
  padding: 0 5px;
  border-radius: 0;
  margin-top: -3px;

  img {
    margin-top: -4px;
  }
`
