import { flatten } from 'lodash'
import { useInfiniteQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { parseQueryParams } from 'shared/helpers/utils'

import { getJobs, GetJobsOptions } from '../../api/jobs'
import { CacheKeys } from '../../helpers/cacheKeys'
import { currentUserSelector } from '../../redux/selectors/users'

export function useJobs({ enabled = true } = {}, options: GetJobsOptions = {}) {
  const currentUser = useSelector(currentUserSelector)

  const { data, fetchNextPage, hasNextPage, isFetching, refetch, isLoading } = useInfiniteQuery(
    `${CacheKeys.JOBS}-${currentUser.id}`,
    ({ pageParam = 1 }) => getJobs(pageParam, options),
    {
      enabled,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => (lastPage.next ? parseQueryParams(lastPage.next).page : null),
    }
  )

  return {
    jobs: flatten(data?.pages.map((page) => page.results)),
    refetch,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  }
}
