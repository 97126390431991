import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'

import { applyMiddleware, compose, createStore } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import thunk from 'redux-thunk'
import { reducers } from '../reducers/reducers'
import { ApplicationState } from './models'

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// adds logrocket middleware https://docs.logrocket.com/reference#redux-logging
// we should be mindful of storing sensitive info in redux state
const middlewares = [thunk, reduxImmutableStateInvariant(), LogRocket.reduxMiddleware()]

export const store = createStore<ApplicationState & PersistPartial, any, ApplicationState, ApplicationState>(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares), Sentry.createReduxEnhancer())
)
