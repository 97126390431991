export enum PlaidAccountType {
  DEPOSITORY = 'DEPOSITORY',
}

export enum PlaidAccountSubtype {
  SAVINGS = 'savings',
  CHECKING = 'checking',
}

export enum PlaidProduct {
  AUTH = 'auth',
  ASSETS = 'assets',
}

export interface PlaidItem {
  itemId?: string
  institutionName: string
  hasAssetsProduct: boolean
}

export enum PlaidAccountSource {
  PAYMENT_PROFILE = 'PAYMENT_PROFILE',
  CONTRACTOR_PAYMENTS = 'CONTRACTOR_PAYMENTS',
}

export interface PlaidAccount {
  accountId: string
  accountType: string
  accountSubtype: string
  mask: string
  name: string
  verificationStatus: PlaidAccountStatus
  item: PlaidItem
  isExpired: boolean
  isActive: boolean
  source: PlaidAccountSource
}

export interface PlaidAccountResponse {
  account_id: string
  account_type: PlaidAccountType
  account_subtype: PlaidAccountSubtype
  mask: string
  name: string
  verification_status: PlaidAccountStatus | ''
  is_active: boolean
  source: PlaidAccountSource
  item: {
    item_id?: string
    institution_name: string
    has_assets_product: boolean
  }
}

export interface PlaidAccountError {
  detail: string
}

export enum PlaidAccountStatus {
  PENDING_AUTOMATIC_VERIFICATION = 'pending_automatic_verification',
  PENDING_MANUAL_VERIFICATION = 'pending_manual_verification',
  AUTOMATICALLY_VERIFIED = 'automatically_verified',
  MANUALLY_VERIFIED = 'manually_verified',
  VERIFICATION_EXPIRED = 'verification_expired',
  VERIFICATION_FAILED = 'verification_failed',
}

export interface PlainLinkToken {
  linkToken: string
}

// Helpers

export function isPlaidAccountVerified(account?: PlaidAccount) {
  return (
    account &&
    (account.verificationStatus === PlaidAccountStatus.AUTOMATICALLY_VERIFIED ||
      account.verificationStatus === PlaidAccountStatus.MANUALLY_VERIFIED)
  )
}

export function isPlaidAccountPending(account?: PlaidAccount) {
  return (
    account &&
    (account.verificationStatus === PlaidAccountStatus.PENDING_AUTOMATIC_VERIFICATION ||
      account.verificationStatus === PlaidAccountStatus.PENDING_MANUAL_VERIFICATION)
  )
}

export function parsePlaidAccount(account: PlaidAccountResponse): PlaidAccount {
  return {
    accountId: account.account_id,
    accountType: account.account_type,
    accountSubtype: account.account_subtype,
    mask: account.mask,
    name: account.name,
    // if verification status isn't explicitly provided it's assumed verified
    verificationStatus: account.verification_status || PlaidAccountStatus.AUTOMATICALLY_VERIFIED,
    isExpired: account.verification_status === PlaidAccountStatus.VERIFICATION_EXPIRED,
    item: {
      itemId: account.item?.item_id || '',
      institutionName: account.item?.institution_name || '',
      hasAssetsProduct: account.item?.has_assets_product,
    },
    isActive: account.is_active,
    source: account.source,
  }
}
