import * as Sentry from '@sentry/browser'
import React from 'react'
import { PageWrap, TextCentered } from 'shared/components/Base/Base'
import { Button } from 'shared/components/Button/Button'
import { Text } from 'shared/components/Text/Text'

import { RoadError } from '../../components/Icons/Icon'
import { isProduction } from '../../config/config'

interface MainErrorBoundaryProps {}

interface MainErrorBoundaryState {
  hasError: boolean
}

export class MainErrorBoundary extends React.Component<MainErrorBoundaryProps, MainErrorBoundaryState> {
  constructor(props: MainErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo)
    if (isProduction) Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <PageWrap>
          <TextCentered>
            <img src={RoadError} alt="" />
            <Text variant="header1" mt={1} mb={2}>
              Something went wrong
            </Text>
            <Button variant="text" onClick={() => window.location.reload()} margin="0 auto">
              Reload the page
            </Button>
          </TextCentered>
        </PageWrap>
      )
    }

    return this.props.children
  }
}
