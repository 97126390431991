import { HandlerOf } from 'shared/helpers/typeHelper'

import { useOauthPopup } from '../../../hooks/useOuathPoup'
import { AstraUserType, getAstraVerificationUrl } from '../../../models/contractorPayments'

interface UseMotiveOauthProps {
  setLoading: HandlerOf<boolean>
  onSuccess: HandlerOf<string>
  userType: AstraUserType
  userIntentId?: string
}

export function useAstraVerification({ setLoading, onSuccess, userType, userIntentId }: UseMotiveOauthProps) {
  return useOauthPopup({
    setLoading,
    onSuccess,
    codeKey: 'authorization_code',
    fetchUrl: () => getAstraVerificationUrl({ userType, userIntentId }),
  })
}
