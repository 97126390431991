import { useSelector } from 'react-redux'

import { useIsPath } from '../../../hooks/useIsPath'
import { currentTeamSelector } from '../../../redux/selectors/team'
import { currentUserSelector } from '../../../redux/selectors/users'
import { publicRoutesPaths } from '../../../Routes'
import { AppNav } from './AppNav'
import { BasicNav } from './BasicNav'

export const Navbar = () => {
  const isPublicPath = useIsPath(publicRoutesPaths)
  const user = useSelector(currentUserSelector)
  const team = useSelector(currentTeamSelector)

  if (isPublicPath || !user.id || !team.id) {
    return <BasicNav />
  }

  return <AppNav />
}
