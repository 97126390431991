import { useBreakpoint } from 'shared/hooks/useBreakpoint'

import { useLayoutContext } from '../../Context/LayoutContext'
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_EXPANDED_WIDTH } from './SideBar'
import { useShowSideBar } from './useShowSideBar'

export const useSidebarWidth = () => {
  const showSidebar = useShowSideBar()
  const { isSideBarCollapsed } = useLayoutContext()
  const isSmallBreakpoint = useBreakpoint('sm')
  const isXSmallBreakpoint = useBreakpoint('xs')

  // Sidebar hidden
  if (!showSidebar) return 0

  // On mobile the sidebar is hidden
  if (isXSmallBreakpoint) return 0

  // In medium screens sidebar always takes just collapsed space and opens over the content
  if (isSmallBreakpoint) return SIDEBAR_COLLAPSED_WIDTH

  if (isSideBarCollapsed) return SIDEBAR_COLLAPSED_WIDTH
  return SIDEBAR_EXPANDED_WIDTH
}
