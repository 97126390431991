import { memo } from 'react'
import * as React from 'react'
import { lazily } from 'react-lazily'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { EMPLOYMENT_ROUTES } from 'shared/components/Onboarding/EmploymentHistory/EmploymentHistory.constants'

import { ApprovedRoute } from 'components/ApprovedRoute'
import { PrivateRoute } from 'components/PrivateRoute'

const { PermissionsSettings } = lazily(() => import(/* webpackChunkName: "PermissionsSettingsPage" */ './pages/PermissionsSettings/PermissionsSettings'))
const { ConfirmFailedPage } = lazily(() => import(/* webpackChunkName: "ConfirmFailedPage" */ 'pages/ConfirmFailed/ConfirmFailed'))
const { ConfirmInvite } = lazily(() => import(/* webpackChunkName: "ConfirmInvite" */ 'pages/ConfirmInvite/ConfirmInvite'))
const { PageNotFound } = lazily(() => import(/* webpackChunkName: "PageNotFound" */ 'pages/ErrorPage/PageNotFound'))
const { HomePage } = lazily(() => import(/* webpackChunkName: "HomePage" */ 'pages/Home/Home'))
const { JobDetails } = lazily(() => import(/* webpackChunkName: "JobDetails" */ 'pages/JobDetails/JobDetails'))
const { BookmarkedLoads } = lazily(() => import(/* webpackChunkName: "BookmarkedLoads" */ 'pages/Jobs/BookmarkedLoads'))
const { JobsPage } = lazily(() => import(/* webpackChunkName: "JobsPage" */ 'pages/Jobs/JobsPage'))
const { JobCopy } = lazily(() => import(/* webpackChunkName: "JobCopy" */ 'pages/Jobs/JobCopy'))
const { JobsTemplates } = lazily(() => import(/* webpackChunkName: "JobsTemplates" */ 'pages/Jobs/JobsTemplates'))
const { JobsNewTemplate } = lazily(() => import(/* webpackChunkName: "JobsNewTemplate" */ 'pages/Jobs/JobsNewTemplate'))
const { JobsEditTemplate } = lazily(() => import(/* webpackChunkName: "JobsEditTemplate" */ 'pages/Jobs/JobsEditTemplate'))
const { SelfServeBrokerLoadTrackingPage } = lazily(() => import(/* webpackChunkName: "SelfServeBrokerLoadTrackingPage" */ 'pages/JobTracking/SelfServeBrokerLoadTracking'))
const { SelfServeBrokerLoadTrackingLookupPage } = lazily(() => import(/* webpackChunkName: "SelfServeBrokerLoadTrackingLookupPage" */ 'pages/JobTracking/SelfServeBrokerLoadTrackingLookupPage'))
const { LoadDetails } = lazily(() => import(/* webpackChunkName: "LoadDetails" */ 'pages/LoadDetails/LoadDetails'))
const { OnboardingDriverApplication } = lazily(() => import(/* webpackChunkName: "OnboardingDriverApplication" */ 'pages/Onboarding/OnboardingDriverApplication'))
const { OnboardingEmploymentHistory } = lazily(() => import(/* webpackChunkName: "OnboardingEmploymentHistory" */ 'pages/Onboarding/OnboardingEmploymentHistory/OnboardingEmploymentHistory'))
const { RegistrationPage } = lazily(() => import(/* webpackChunkName: "RegistrationPage" */ 'pages/Registration/Registration'))
const { SearchFormSync } = lazily(() => import(/* webpackChunkName: "SearchForm" */ 'pages/SearchForm/SearchFormSync'))
const { SearchResultsForm } = lazily(() => import(/* webpackChunkName: "SearchResultsForm" */ 'pages/SearchForm/SearchResultsForm'))
const { SearchFormAsync } = lazily(() => import(/* webpackChunkName: "SearchFormAsync" */ 'pages/SearchForm/SearchFormAsync'))
const { SearchResultsFormAsync } = lazily(() => import(/* webpackChunkName: "SearchFormAsync" */ 'pages/SearchForm/SearchResultsFormAsync'))
const { UploadCDLPage } = lazily(() => import(/* webpackChunkName: "UploadCDLPage" */ 'pages/UploadCDLPage/UploadCDLPage'))
const { AccountActivity } = lazily(() => import(/* webpackChunkName: "AccountActivity" */ './pages/CTCash/AccountActivity'))
const { AddCardHolder } = lazily(() => import(/* webpackChunkName: "AddCardHolder" */ './pages/CTCash/AddCardHolder'))
const { CardManagement } = lazily(() => import(/* webpackChunkName: "CardManagement" */ './pages/CTCash/CardManagement'))
const { ChangeWeeklyLimit } = lazily(() => import(/* webpackChunkName: "ChangeWeeklyLimit" */ './pages/CTCash/ChangeWeeklyLimit'))
const { Credit } = lazily(() => import(/* webpackChunkName: "Credit" */ './pages/CTCash/Credit'))
const { ExternalBook } = lazily(() => import(/* webpackChunkName: "ExternalBook" */ './pages/ExternalBook/ExternalBook'))
const { JobDetailsEdit } = lazily(() => import(/* webpackChunkName: "JobDetailsEdit" */ './pages/JobDetails/JobDetailsEdit'))
const { JobCreateInvoice } = lazily(() => import(/* webpackChunkName: "JobInvoice" */ './pages/JobInvoice/JobCreateInvoice'))
const { JobSendInvoiceToBroker } = lazily(() => import(/* webpackChunkName: "JobInvoice" */ './pages/JobInvoice/JobSendInvoiceToBroker'))
const { JobSendInvoiceToFactoringCompany } = lazily(() => import(/* webpackChunkName: "JobInvoice" */ './pages/JobInvoice/JobSendInvoiceToFactoringCompany'))
const { JobTracking } = lazily(() => import(/* webpackChunkName: "JobTracking" */ './pages/JobTracking/JobTracking'))
const { ContactedLoads } = lazily(() => import(/* webpackChunkName: "ContactedLoads" */ './pages/Jobs/ContactedLoads'))
const { JobsPastPage } = lazily(() => import(/* webpackChunkName: "JobsPastPage" */ './pages/Jobs/JobsPastPage'))
const { LoadDetailsBook } = lazily(() => import(/* webpackChunkName: "LoadDetailsBook" */ './pages/LoadDetails/LoadDetailsBook'))
const { MarketConditions } = lazily(() => import(/* webpackChunkName: "MarketConditions" */ './pages/MarketConditions/MarketConditions'))
const { MobileAuth } = lazily(() => import(/* webpackChunkName: "MobileAuth" */ './pages/MobileAuth/MobileAuth'))
const { OnboardingBrokerSetup } = lazily(() => import(/* webpackChunkName: "OnboardingBrokerSetup" */ './pages/Onboarding/Broker/OnboardingBrokerSetup'))
const { OnboardingELDSetup } = lazily(() => import(/* webpackChunkName: "OnboardingELDSetup" */ './pages/Onboarding/ELD/OnboardingELDSetup'))
const { OnboardingEquipmentAddTrailer } = lazily(() => import(/* webpackChunkName: "OnboardingEquipmentAddTrailer" */ './pages/Onboarding/Equipment/OnboardingEquipmentAddTrailer'))
const { OnboardingEquipmentAddTruck } = lazily(() => import(/* webpackChunkName: "OnboardingEquipmentAddTruck" */ './pages/Onboarding/Equipment/OnboardingEquipmentAddTruck'))
const { OnboardingEquipmentEditTrailer } = lazily(() => import(/* webpackChunkName: "OnboardingEquipmentEditTrailer" */ './pages/Onboarding/Equipment/OnboardingEquipmentEditTrailer'))
const { OnboardingEquipmentEditTruck } = lazily(() => import(/* webpackChunkName: "OnboardingEquipmentEditTruck" */ './pages/Onboarding/Equipment/OnboardingEquipmentEditTruck'))
const { OnboardingEquipmentTruckDocumentsPage } = lazily(() => import(/* webpackChunkName: "OnboardingEquipmentTruckDocuments" */ './pages/Onboarding/Equipment/OnboardingEquipmentTruckDocuments'))
const { OnboardingEquipmentTrailerDocumentsPage } = lazily(() => import(/* webpackChunkName: "OnboardingEquipmentTrailerDocuments" */ './pages/Onboarding/Equipment/OnboardingEquipmentTrailerDocuments'))
const { OnboardingAddDriver } = lazily(() => import(/* webpackChunkName: "OnboardingAddDriver" */ './pages/Onboarding/OnboardingAddDriver'))
const { OnboardingAddAdminDriver } = lazily(() => import(/* webpackChunkName: "OnboardingAddAdminDriver" */ './pages/Onboarding/OnboardingAddAdminDriver'))
const { OnboardingAddAdmin } = lazily(() => import(/* webpackChunkName: "OnboardingAddAdmin" */ './pages/Onboarding/OnboardingAddAdmin'))
const { OnboardingAddUser } = lazily(() => import(/* webpackChunkName: "OnboardingAddUser" */ './pages/Onboarding/OnboardingAddUser'))
const { OnboardingBankAccount } = lazily(() => import(/* webpackChunkName: "OnboardingBankAccount" */ './pages/Onboarding/OnboardingBankAccount'))
const { OnboardingConfirmCTCash } = lazily(() => import(/* webpackChunkName: "OnboardingConfirmCTCash" */ './pages/Onboarding/OnboardingConfirmCTCash'))
const { OnboardingSignLeaseAgreement } = lazily(() => import(/* webpackChunkName: "OnboardingSignLeaseAgreement" */ './pages/Onboarding/OnboardingSignLeaseAgreement'))
const { OnboardingSignLeaseAgreementAmendment } = lazily(() => import(/* webpackChunkName: "OnboardingSignLeaseAgreement" */ './pages/Onboarding/OnboardingSignLeaseAgreementAmendment'))
const { OnboardingEmploymentHistoryAddFmcsrValidation } = lazily(() => import(/* webpackChunkName: "EmploymentHistoryAddFmcsrValidation" */ './pages/Onboarding/OnboardingEmploymentHistory/OnboardingEmploymentHistoryAddFmcsrValidation'))
const { OnboardingEmploymentHistoryEditFmcsrValidation } = lazily(() => import(/* webpackChunkName: "EmploymentHistoryEditFmcsrValidation" */ './pages/Onboarding/OnboardingEmploymentHistory/OnboardingEmploymentHistoryEditFmcsrValidation'))
const { OnboardingEmploymentHistoryAddPosition } = lazily(() => import(/* webpackChunkName: "EmploymentHistoryAddPosition" */ './pages/Onboarding/OnboardingEmploymentHistory/OnboardingEmploymentHistoryAddPosition'))
const { OnboardingEmploymentHistoryEditPosition } = lazily(() => import(/* webpackChunkName: "EmploymentHistoryEditPosition" */ './pages/Onboarding/OnboardingEmploymentHistory/OnboardingEmploymentHistoryEditPosition'))
const { OnboardingEmploymentHistorySubmitApplication } = lazily(() => import(/* webpackChunkName: "EmploymentHistorySubmitApplication" */ './pages/Onboarding/OnboardingEmploymentHistory/OnboardingEmploymentHistorySubmitApplication'))
const { OnboardingFleetApplication } = lazily(() => import(/* webpackChunkName: "OnboardingFleetApplication" */ './pages/Onboarding/OnboardingFleetApplication'))
const { OnboardingFleetDetails } = lazily(() => import(/* webpackChunkName: "OnboardingFleetDetails" */ './pages/Onboarding/OnboardingFleetDetails'))
const { OnboardingFlexSubscription } = lazily(() => import(/* webpackChunkName: "OnboardingFlexSubscription" */ './pages/Onboarding/OnboardingFlexSubscription'))
const { OnboardingInsuranceDocuments } = lazily(() => import(/* webpackChunkName: "OnboardingInsuranceDocuments" */ './pages/Onboarding/OnboardingInsuranceDocuments'))
const { OnboardingMaintenanceSetAside } = lazily(() => import(/* webpackChunkName: "OnboardingMaintenanceSetAside" */ './pages/Onboarding/OnboardingMaintenanceSetAside'))
const { OnboardingTaskListPage } = lazily(() => import(/* webpackChunkName: "OnboardingTaskList" */ './pages/Onboarding/OnboardingTaskList'))
const { OnboardingTaxInfo } = lazily(() => import(/* webpackChunkName: "OnboardingTaxInfo" */ './pages/Onboarding/OnboardingTaxInfo'))
const { PlaidRedirect } = lazily(() => import(/* webpackChunkName: "PlaidRedirect" */ './pages/PlaidRedirect/PlaidRedirect'))
const { BankAccountPage } = lazily(() => import(/* webpackChunkName: "BankAccountPage" */ './pages/ProfilePage/BankAccountPage'))
const { BankAccountAssetsPage } = lazily(() => import(/* webpackChunkName: "BankAccountAssetPage" */ './pages/ProfilePage/BankAccountAssetsPage'))
const { BrokersPage } = lazily(() => import(/* webpackChunkName: "BrokersPage" */ './pages/ProfilePage/BrokersPage'))
const { ConfirmCTCashPage } = lazily(() => import(/* webpackChunkName: "ConfirmCTCashPage" */ './pages/ProfilePage/ConfirmCTCashPage'))
const { EquipmentAddTrailerPage } = lazily(() => import(/* webpackChunkName: "EquipmentAddTrailerPage" */ './pages/ProfilePage/EquipmentAddTrailerPage'))
const { EquipmentAddTruckPage } = lazily(() => import(/* webpackChunkName: "EquipmentAddTruckPage" */ './pages/ProfilePage/EquipmentAddTruckPage'))
const { EquipmentEditTrailerPage } = lazily(() => import(/* webpackChunkName: "EquipmentEditTrailerPage" */ './pages/ProfilePage/EquipmentEditTrailerPage'))
const { EquipmentEditTruckPage } = lazily(() => import(/* webpackChunkName: "EquipmentEditTruckPage" */ './pages/ProfilePage/EquipmentEditTruckPage'))
const { EquipmentTrailerPage } = lazily(() => import(/* webpackChunkName: "EquipmentTrailerPage" */ './pages/Equipment/EquipmentTrailerPage'))
const { EquipmentTruckPage } = lazily(() => import(/* webpackChunkName: "EquipmentTruckPage" */ './pages/Equipment/EquipmentTruckPage'))
const { EquipmentPage } = lazily(() => import(/* webpackChunkName: "EquipmentPage" */ './pages/ProfilePage/EquipmentPage'))
const { FleetPage } = lazily(() => import(/* webpackChunkName: "FleetPage" */ './pages/ProfilePage/FleetPage'))
const { FleetMembersPage } = lazily(() => import(/* webpackChunkName: "FleetMembersPage" */ './pages/ProfilePage/FleetMembersPage'))
const { PaymentInformationPage } = lazily(() => import(/* webpackChunkName: "PaymentInformationPage" */ './pages/ProfilePage/PaymentInformationPage'))
const { ProfilePageSubscriptionChangePayment } = lazily(() => import(/* webpackChunkName: "ProfilePageSubscriptionChangePayment" */ './pages/ProfilePage/ProfilePageSubscriptionChangePayment'))
const { VerifyCode } = lazily(() => import(/* webpackChunkName: "VerifyCode" */ './pages/Registration/VerifyCode/VerifyCode'))
const { RevenueCalculator } = lazily(() => import(/* webpackChunkName: "RevenueCalculator" */ './pages/RevenueCalculator/RevenueCalculator'))
const { AddAdmin } = lazily(() => import(/* webpackChunkName: "AddAdmin" */ './pages/User/AddAdmin'))
const { AddAdminDriver } = lazily(() => import(/* webpackChunkName: "AddAdminDriver" */ './pages/User/AddAdminDriver'))
const { AddDriver } = lazily(() => import(/* webpackChunkName: "AddDriver" */ './pages/User/AddDriver'))
const { AddUser } = lazily(() => import(/* webpackChunkName: "AddUser" */ './pages/User/AddUser'))
const { DeactivateUser } = lazily(() => import(/* webpackChunkName: "DeactivateUser" */ './pages/User/DeactivateUser'))
const { EditUserRole } = lazily(() => import(/* webpackChunkName: "EditUserRole" */ './pages/User/EditUserRole'))
const { SignInPage } = lazily(() => import(/* webpackChunkName: "SignInPage" */ 'pages/SignIn/SignIn'))
const { ProfilePage } = lazily(() => import(/* webpackChunkName: "ProfilePage" */ 'pages/ProfilePage/ProfilePage'))
const { SafetyDashboard } = lazily(() => import(/* webpackChunkName: "SafetyDashboard" */ 'pages/Safety/SafetyDashboard'))
const { CancelSubscription } = lazily(() => import(/* webpackChunkName: "CancelSubscription" */ 'pages/CancelAccount/CancelSubscription'))
const { SubscriptionPage } = lazily(() => import(/* webpackChunkName: "SubscriptionPage" */ 'pages/ProfilePage/SubscriptionPage'))
const { ContractorPayments } = lazily(() => import(/* webpackChunkName: "ContractorPayments" */ 'pages/ContractorPayments/ContractorPayments'))
const { ContractorPaymentsPayrollBankAccount } = lazily(() => import(/* webpackChunkName: "ContractorPayments" */ 'pages/ContractorPayments/ContractorPaymentsPayrollBankAccount'))
const { ContractorPaymentsAddContractors } = lazily(() => import(/* webpackChunkName: "ContractorPayments" */ 'pages/ContractorPayments/ContractorPaymentsAddContractors'))
const { ContractorPaymentsDirectDeposit } = lazily(() => import(/* webpackChunkName: "ContractorPayments" */ 'pages/ContractorPayments/ContractorPaymentsDirectDeposit'))
const { ContractorPaymentsDebitCard } = lazily(() => import(/* webpackChunkName: "ContractorPayments" */ 'pages/ContractorPayments/ContractorPaymentsDebitCard'))
const { ContractorPaymentsContractors } = lazily(() => import(/* webpackChunkName: "ContractorPayments" */ 'pages/ContractorPayments/ContractorPaymentsContractors'))
const { ContractorPaymentsPay } = lazily(() => import(/* webpackChunkName: "ContractorPayments" */ 'pages/ContractorPayments/ContractorPaymentsPay'))
const { ContractorPaymentsPersonalInfo } = lazily(() => import(/* webpackChunkName: "ContractorPayments" */ 'pages/ContractorPayments/ContractorPaymentsPersonalInfo'))
const { ContractorPaymentsPersonalSettings } = lazily(() => import(/* webpackChunkName: "ContractorPayments" */ 'pages/ContractorPayments/ContractorPaymentsPersonalSettings'))
const { ContractorPaymentsInstantPayoutsOnboarding } = lazily(() => import(/* webpackChunkName: "ContractorPayments" */ 'pages/ContractorPayments/ContractorPaymentsInstantPayoutsOnboarding'))
const { Report } = lazily(() => import(/* webpackChunkName: "FuelReport" */ 'pages/Report/Report'))
const { OnboardingPspConsent } = lazily(() => import(/* webpackChunkName: "OnboardingPspConsent" */ 'pages/Onboarding/PspConsent/OnboardingPspConsent'))
const { OnboardingDacConsent } = lazily(() => import(/* webpackChunkName: "OnboardingDacConsent" */ 'pages/Onboarding/DacConsent/OnboardingDacConsent'))
const { OnboardingFuelCard } = lazily(() => import(/* webpackChunkName: "OnboardingFuelCard" */ 'pages/Onboarding/FuelCard/OnboardingFuelCard'))
const { Deductions } = lazily(() => import(/* webpackChunkName: "VendorDeductions" */ 'pages/Vendor/Deductions'))
const { DeductionDetail } = lazily(() => import(/* webpackChunkName: "VendorDeductions" */ 'pages/Vendor/DeductionDetail'))
const { DeductionRefinance } = lazily(() => import(/* webpackChunkName: "VendorDeductions" */ 'pages/Vendor/DeductionRefinance'))

export const INDEX_PATH = '/'
export const SIGN_IN_PATH = '/signin/'
export const VERIFY_PATH = '/verify/'
export const SELF_SERVE_BROKER_LOAD_TRACKING_LOOKUP_PATH = '/job-tracking/'
export const SELF_SERVE_BROKER_LOAD_TRACKING_PATH = '/job-tracking/:brokerLoadId'
export const REGISTRATION_PATH = '/registration/:bundle'
export const CONFIRM_INVITATION_PATH = '/confirm-invitation/'
export const CONFIRM_FAILED_PATH = '/confirm-failed/'
export const MOBILE_AUTH_PATH = '/mobile-auth/'
export const SEARCH_PATH = '/search-sync/'
export const SEARCH_RESULTS_PATH = '/search-sync/results/'
export const SEARCH_ASYNC_PATH = '/search/'
export const SEARCH_ASYNC_RESULTS_PATH = '/search/results/'
export const SAVED_LOADS_PATH = '/saved-loads/'
export const CONFIRM_INFO_PATH = '/confirm-info/'
export const SIGN_UP_DRIVER_PATH = '/signup/driver/'
export const TEAM_PATH = '/team/'
export const USERS_PROFILE_PATH = '/users/:id'
export const PROFILE_PATH = '/profile/'
export const FLEET_PROFILE_PATH = '/profile/fleet/'
export const FLEET_MEMBERS_PROFILE_PATH = '/profile/fleet-members/'
export const EQUIPMENT_PROFILE_PATH = '/profile/equipment/'
export const EQUIPMENT_PROFILE_PATH_ADD_TRUCK = '/profile/equipment/add-truck/'
export const EQUIPMENT_PROFILE_PATH_EDIT_TRUCK = '/profile/equipment/edit-truck/:equipmentId'
export const EQUIPMENT_PROFILE_PATH_ADD_TRAILER = '/profile/equipment/add-trailer/'
export const EQUIPMENT_PROFILE_PATH_EDIT_TRAILER = '/profile/equipment/edit-trailer/:equipmentId'
export const TRUCK_EQUIPMENT_PATH = '/profile/equipment/truck/:equipmentId'
export const TRAILER_EQUIPMENT_PATH = '/profile/equipment/trailer/:equipmentId'
export const PROFILE_SUBSCRIPTION_PATH = '/profile/subscription/'
export const PROFILE_SUBSCRIPTION_CHANGE_PAYMENT_PATH = '/profile/subscription/change-payment'
export const CANCEL_SUBSCRIPTION_PATH = '/subscription/cancel/'
export const BROKERS_PROFILE_PATH = '/profile/brokers/'
export const PAYMENT_INFORMATION_PROFILE_PATH = '/profile/payment-information/'
export const PAYMENT_INFORMATION_BANK_ACCOUNT_PROFILE_PATH = '/profile/payment-information/bank-account/'
export const PAYMENT_INFORMATION_BANK_ACCOUNT_ASSETS_PROFILE_PATH = '/profile/payment-information/bank-account/assets/'
export const PAYMENT_INFORMATION_CONFIRM_CT_CASH_PROFILE_PATH = '/profile/payment-information/confirm-ct-cash/'
export const CDL_UPLOAD_PATH = '/cdl-upload/:userId/'
export const JOBS_PATH = '/jobs/'
export const JOBS_PAST_PATH = '/jobs/past/'
export const CONTACTED_LOADS_PATH = '/contacted-loads/'
export const JOBS_TEMPLATES_PATH = '/jobs/templates/'
export const JOBS_TEMPLATES_NEW_PATH = '/jobs/templates/new/'
export const JOBS_TEMPLATES_EDIT_PATH = '/jobs/templates/:id/edit/'
export const JOBS_DETAILS_PATH = '/jobs/details/:id/'
export const JOBS_COPY_PATH = '/jobs/copy/:id/'
export const JOBS_TRACKING_PATH = '/jobs/tracking/:id/'
export const JOBS_DETAILS_EDIT_PATH = '/jobs/details/:id/edit/'
export const JOBS_DETAILS_CREATE_INVOICE_PATH = '/jobs/details/:id/create-invoice/'
export const JOBS_DETAILS_NOTIFY_BROKER_PATH = '/jobs/details/:id/notify-broker/:invoice'
export const JOBS_DETAILS_NOTIFY_FACTORING_COMPANY_PATH = '/jobs/details/:id/notify-factoring-company/'
export const EXTERNAL_BOOK_PATH = '/external-book/'
export const EXTERNAL_BOOK_FROM_TEMPLATE_PATH = '/external-book/:template/'
export const LOADS_DETAILS_PATH = '/loads/details/:id/'
export const LOADS_DETAILS_BOOK_PATH = '/loads/details/:id/book/:type'
export const LOADS_BIDDING_REQUEST_SUCCESS_PATH = '/loads/details/:id/bidding-submitted/'
export const EDIT_CURRENT_USER_ROLE_PATH = '/user-role/'
export const EDIT_USER_ROLE_PATH = '/user-role/:id'
export const ADD_USER_PATH = '/add-user/'
export const ADD_ADMIN_PATH = '/add-admin/'
export const ADD_DRIVER_PATH = '/add-driver/'
export const ADD_ADMIN_DRIVER_PATH = '/add-admin-driver/'
export const PERMISSIONS_SETTINGS_PATH = '/permissions-settings/'
export const DEACTIVATE_USER_PATH = '/deactivate-user/:id'
export const ONBOARDING_TASK_LIST_PATH = '/onboarding/tasklist/'
export const ONBOARDING_USER_TASK_LIST_PATH = '/onboarding/tasklist/:userId/'
export const ONBOARDING_ADD_USER_PATH = '/onboarding/add-user/'
export const ONBOARDING_ADD_DRIVER_PATH = '/onboarding/add-driver/'
export const ONBOARDING_ADD_ADMIN_PATH = '/onboarding/add-admin/'
export const ONBOARDING_ADD_ADMIN_DRIVER_PATH = '/onboarding/add-admin-driver/'
export const ONBOARDING_FLEET_APPLICATION_PATH = '/onboarding/fleet-application/'
export const ONBOARDING_FLEET_DETAILS_PATH = '/onboarding/fleet-details/'
export const ONBOARDING_FLEX_SUBSCRIPTION_PATH = '/onboarding/subscription/'
export const ONBOARDING_TAX_INFO_PATH = '/onboarding/tax-info/'
export const ONBOARDING_ELD_SETUP_PATH = '/onboarding/eld-setup/'
export const ONBOARDING_EQUIPMENT_ADD_TRUCK_PATH = '/onboarding/equipment-setup/add-truck/'
export const ONBOARDING_EQUIPMENT_EDIT_TRUCK_PATH = '/onboarding/equipment-setup/edit-truck/:equipmentId'
export const ONBOARDING_EQUIPMENT_ADD_TRAILER_PATH = '/onboarding/equipment-setup/add-trailer/'
export const ONBOARDING_EQUIPMENT_EDIT_TRAILER_PATH = '/onboarding/equipment-setup/edit-trailer/:equipmentId'
export const ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH = '/onboarding/equipment-setup/truck-documents/:equipmentId'
export const ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH_PLATES = '/onboarding/equipment-setup/truck-documents/:equipmentId/plates/'
export const ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH_DOT_INSPECTION = '/onboarding/equipment-setup/truck-documents/:equipmentId/dot-inspection/'
export const ONBOARDING_EQUIPMENT_TRAILER_DOCUMENTS_PATH = '/onboarding/equipment-setup/trailer-documents/:equipmentId'
export const ONBOARDING_BROKER_SETUP_PATH = '/onboarding/broker-setup/'
export const ONBOARDING_BANK_ACCOUNT_PATH = '/onboarding/bank-account/'
export const ONBOARDING_CONFIRM_CT_CASH_PATH = '/onboarding/confirm-ct-cash/'
export const ONBOARDING_SIGN_LEASE_AGREEMENT_PATH = '/onboarding/sign-lease-agreement/'
export const ONBOARDING_SIGN_LEASE_AGREEMEN_AMENDMENT_PATH = '/onboarding/sign-lease-agreement-amendment/'
export const ONBOARDING_DRIVER_APPLICATION = '/onboarding/driver-application/'
export const ONBOARDING_EMPLOYMENT_HISTORY = EMPLOYMENT_ROUTES.ONBOARDING_EMPLOYMENT_HISTORY
export const ONBOARDING_EMPLOYMENT_HISTORY_ADD_FMCSR = EMPLOYMENT_ROUTES.ONBOARDING_EMPLOYMENT_HISTORY_ADD_FMCSR
export const ONBOARDING_EMPLOYMENT_HISTORY_ADD = EMPLOYMENT_ROUTES.ONBOARDING_EMPLOYMENT_HISTORY_ADD
export const ONBOARDING_EMPLOYMENT_HISTORY_EDIT_FMCSR = EMPLOYMENT_ROUTES.ONBOARDING_EMPLOYMENT_HISTORY_EDIT_FMCSR
export const ONBOARDING_EMPLOYMENT_HISTORY_EDIT = EMPLOYMENT_ROUTES.ONBOARDING_EMPLOYMENT_HISTORY_EDIT
export const ONBOARDING_EMPLOYMENT_SUBMIT = EMPLOYMENT_ROUTES.ONBOARDING_EMPLOYMENT_SUBMIT
export const ONBOARDING_INSURANCE_DOCUMENTS = '/onboarding/insurance-documents/'
export const ONBOARDING_MAINTENANCE_SET_ASIDE = '/onboarding/maintenance-fund/'
export const ONBOARDING_PSP_CONSENT = '/onboarding/psp-consent/'
export const ONBOARDING_DAC_CONSENT = '/onboarding/dac-consent/'
export const ONBOARDING_FUEL_CARD_PATH = '/onboarding/fuel-card/'
export const CREDIT_PATH = '/ct-cash/credit/'
export const ACCOUNT_ACTIVITY_PATH = '/ct-cash/account-activity/'
export const CARDHOLDER_ACTIVITY = '/ct-cash/account-activity/:id/'
export const CARD_MANAGEMENT = '/ct-cash/card-management/'
export const CARD_MANAGEMENT_ADD_CARDHOLDER = '/ct-cash/card-management/add-cardholder/'
export const CARD_MANAGEMENT_CHANGE_WEEKLY_LIMIT = '/ct-cash/card-management/change-weekly-limit/:id/'
export const PLAID_REDIRECT = '/plaid-redirect/'
export const REVENUE_CALCULATOR = '/revenue-calculator/'
export const MARKET_CONDITIONS_PATH = '/market-conditions/'
export const SAFETY_DASHBOARD_PATH = '/safety/'
export const CONTRACTOR_PAYMENTS_PATH = '/contractor-payments/'
export const CONTRACTOR_PAYMENTS_PAYROLL_BANK_ACCOUNT_PATH = '/contractor-payments/payroll-bank-account/'
export const CONTRACTOR_PAYMENTS_ADD_CONTRACTORS_PATH = '/contractor-payments/add-contractors/'
export const CONTRACTOR_PERSONAL_PERSONAL_INFO_PATH = '/contractor-payments/personal-info/'
export const CONTRACTOR_PERSONAL_DIRECT_DEPOSIT_PATH = '/contractor-payments/direct-deposit/'
export const CONTRACTOR_PERSONAL_DEBIT_CARD_PATH = '/contractor-payments/debit-card/'
export const CONTRACTOR_PAYMENTS_CONTRACTORS_PATH = '/contractor-payments/contractors/'
export const CONTRACTOR_PAYMENTS_PERSONAL_SETTINGS_PATH = '/contractor-payments/settings/personal/'
export const CONTRACTOR_PAYMENTS_PAY_PATH = '/contractor-payments/pay/'
export const CONTRACTOR_PAYMENTS_USER_PAY_PATH = '/contractor-payments/pay/:userId'
export const CONTRACTOR_PAYMENTS_INSTANT_PAYOUTS_ONBOARDING_PATH = '/contractor-payments/instant-payouts-onboarding/'
export const REPORT_PATH = '/profile/fleet/report'
export const VENDOR_DEDUCTIONS_PATH = '/vendor/deductions'
export const VENDOR_DEDUCTION_DETAIL_PATH = '/vendor/deductions/:id'
export const VENDOR_DEDUCTION_REFINANCE_PATH = '/vendor/deductions/:id/refinance/'

type RoutesType = Record<string, React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>>

const marketingRoutes: RoutesType = {
  [REVENUE_CALCULATOR]: RevenueCalculator,
}

const publicRoutes: RoutesType = {
  [SIGN_IN_PATH]: SignInPage,
  [VERIFY_PATH]: VerifyCode,
  [REGISTRATION_PATH]: RegistrationPage,
  [CONFIRM_INVITATION_PATH]: ConfirmInvite,
  [CONFIRM_FAILED_PATH]: ConfirmFailedPage,
  [MOBILE_AUTH_PATH]: MobileAuth,
  [SELF_SERVE_BROKER_LOAD_TRACKING_LOOKUP_PATH]: SelfServeBrokerLoadTrackingLookupPage,
  [SELF_SERVE_BROKER_LOAD_TRACKING_PATH]: SelfServeBrokerLoadTrackingPage,
}

const approvedRoutes: RoutesType = {
  [SEARCH_PATH]: SearchFormSync,
  [SEARCH_RESULTS_PATH]: SearchResultsForm,
  [SEARCH_ASYNC_PATH]: SearchFormAsync,
  [SEARCH_ASYNC_RESULTS_PATH]: SearchResultsFormAsync,
  [SAVED_LOADS_PATH]: BookmarkedLoads,
  [CONTACTED_LOADS_PATH]: ContactedLoads,
  [JOBS_DETAILS_PATH]: JobDetails,
  [JOBS_TRACKING_PATH]: JobTracking,
  [JOBS_DETAILS_EDIT_PATH]: JobDetailsEdit,
  [JOBS_DETAILS_CREATE_INVOICE_PATH]: JobCreateInvoice,
  [JOBS_DETAILS_NOTIFY_BROKER_PATH]: JobSendInvoiceToBroker,
  [JOBS_DETAILS_NOTIFY_FACTORING_COMPANY_PATH]: JobSendInvoiceToFactoringCompany,
  [JOBS_PATH]: JobsPage,
  [JOBS_COPY_PATH]: JobCopy,
  [JOBS_TEMPLATES_PATH]: JobsTemplates,
  [JOBS_TEMPLATES_NEW_PATH]: JobsNewTemplate,
  [JOBS_TEMPLATES_EDIT_PATH]: JobsEditTemplate,
  [JOBS_PAST_PATH]: JobsPastPage,
  [EXTERNAL_BOOK_PATH]: ExternalBook,
  [EXTERNAL_BOOK_FROM_TEMPLATE_PATH]: ExternalBook,
  [LOADS_DETAILS_PATH]: LoadDetails,
  [LOADS_DETAILS_BOOK_PATH]: LoadDetailsBook,
  [ACCOUNT_ACTIVITY_PATH]: AccountActivity,
  [CARDHOLDER_ACTIVITY]: AccountActivity,
  [CARD_MANAGEMENT]: CardManagement,
  [CARD_MANAGEMENT_ADD_CARDHOLDER]: AddCardHolder,
  [CARD_MANAGEMENT_CHANGE_WEEKLY_LIMIT]: ChangeWeeklyLimit,
  [CREDIT_PATH]: Credit,
  [CONTRACTOR_PAYMENTS_PATH]: ContractorPayments,
  [CONTRACTOR_PAYMENTS_PAYROLL_BANK_ACCOUNT_PATH]: ContractorPaymentsPayrollBankAccount,
  [CONTRACTOR_PAYMENTS_ADD_CONTRACTORS_PATH]: ContractorPaymentsAddContractors,
  [CONTRACTOR_PERSONAL_DIRECT_DEPOSIT_PATH]: ContractorPaymentsDirectDeposit,
  [CONTRACTOR_PERSONAL_DEBIT_CARD_PATH]: ContractorPaymentsDebitCard,
  [CONTRACTOR_PAYMENTS_CONTRACTORS_PATH]: ContractorPaymentsContractors,
  [CONTRACTOR_PAYMENTS_PAY_PATH]: ContractorPaymentsPay,
  [CONTRACTOR_PAYMENTS_USER_PAY_PATH]: ContractorPaymentsPay,
  [CONTRACTOR_PERSONAL_PERSONAL_INFO_PATH]: ContractorPaymentsPersonalInfo,
  [CONTRACTOR_PAYMENTS_PERSONAL_SETTINGS_PATH]: ContractorPaymentsPersonalSettings,
  [CONTRACTOR_PAYMENTS_INSTANT_PAYOUTS_ONBOARDING_PATH]: ContractorPaymentsInstantPayoutsOnboarding,
}

const privateRoutes: RoutesType = {
  [INDEX_PATH]: HomePage,
  [USERS_PROFILE_PATH]: ProfilePage,
  [PROFILE_PATH]: ProfilePage,
  [FLEET_PROFILE_PATH]: FleetPage,
  [FLEET_MEMBERS_PROFILE_PATH]: FleetMembersPage,
  [EQUIPMENT_PROFILE_PATH]: EquipmentPage,
  [EQUIPMENT_PROFILE_PATH_ADD_TRUCK]: EquipmentAddTruckPage,
  [EQUIPMENT_PROFILE_PATH_EDIT_TRUCK]: EquipmentEditTruckPage,
  [EQUIPMENT_PROFILE_PATH_ADD_TRAILER]: EquipmentAddTrailerPage,
  [EQUIPMENT_PROFILE_PATH_EDIT_TRAILER]: EquipmentEditTrailerPage,
  [TRUCK_EQUIPMENT_PATH]: EquipmentTruckPage,
  [TRAILER_EQUIPMENT_PATH]: EquipmentTrailerPage,
  [BROKERS_PROFILE_PATH]: BrokersPage,
  [PROFILE_SUBSCRIPTION_PATH]: SubscriptionPage,
  [PAYMENT_INFORMATION_PROFILE_PATH]: PaymentInformationPage,
  [PAYMENT_INFORMATION_BANK_ACCOUNT_PROFILE_PATH]: BankAccountPage,
  [PAYMENT_INFORMATION_BANK_ACCOUNT_ASSETS_PROFILE_PATH]: BankAccountAssetsPage,
  [PAYMENT_INFORMATION_CONFIRM_CT_CASH_PROFILE_PATH]: ConfirmCTCashPage,
  [PROFILE_SUBSCRIPTION_CHANGE_PAYMENT_PATH]: ProfilePageSubscriptionChangePayment,
  [CDL_UPLOAD_PATH]: UploadCDLPage,
  [EDIT_CURRENT_USER_ROLE_PATH]: EditUserRole,
  [EDIT_USER_ROLE_PATH]: EditUserRole,
  [ADD_USER_PATH]: AddUser,
  [ADD_ADMIN_PATH]: AddAdmin,
  [ADD_DRIVER_PATH]: AddDriver,
  [ADD_ADMIN_DRIVER_PATH]: AddAdminDriver,
  [PERMISSIONS_SETTINGS_PATH]: PermissionsSettings,
  [DEACTIVATE_USER_PATH]: DeactivateUser,
  [ONBOARDING_TASK_LIST_PATH]: OnboardingTaskListPage,
  [ONBOARDING_USER_TASK_LIST_PATH]: OnboardingTaskListPage,
  [ONBOARDING_ADD_DRIVER_PATH]: OnboardingAddDriver,
  [ONBOARDING_ADD_ADMIN_DRIVER_PATH]: OnboardingAddAdminDriver,
  [ONBOARDING_ADD_ADMIN_PATH]: OnboardingAddAdmin,
  [ONBOARDING_ADD_USER_PATH]: OnboardingAddUser,
  [ONBOARDING_FLEET_APPLICATION_PATH]: OnboardingFleetApplication,
  [ONBOARDING_FLEET_DETAILS_PATH]: OnboardingFleetDetails,
  [ONBOARDING_FLEX_SUBSCRIPTION_PATH]: OnboardingFlexSubscription,
  [ONBOARDING_TAX_INFO_PATH]: OnboardingTaxInfo,
  [ONBOARDING_ELD_SETUP_PATH]: OnboardingELDSetup,
  [ONBOARDING_EQUIPMENT_ADD_TRUCK_PATH]: OnboardingEquipmentAddTruck,
  [ONBOARDING_EQUIPMENT_EDIT_TRUCK_PATH]: OnboardingEquipmentEditTruck,
  [ONBOARDING_EQUIPMENT_ADD_TRAILER_PATH]: OnboardingEquipmentAddTrailer,
  [ONBOARDING_EQUIPMENT_EDIT_TRAILER_PATH]: OnboardingEquipmentEditTrailer,
  [ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH]: OnboardingEquipmentTruckDocumentsPage,
  [ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH_PLATES]: OnboardingEquipmentTruckDocumentsPage,
  [ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH_DOT_INSPECTION]: OnboardingEquipmentTruckDocumentsPage,
  [ONBOARDING_EQUIPMENT_TRAILER_DOCUMENTS_PATH]: OnboardingEquipmentTrailerDocumentsPage,
  [ONBOARDING_BROKER_SETUP_PATH]: OnboardingBrokerSetup,
  [ONBOARDING_BANK_ACCOUNT_PATH]: OnboardingBankAccount,
  [ONBOARDING_CONFIRM_CT_CASH_PATH]: OnboardingConfirmCTCash,
  [ONBOARDING_SIGN_LEASE_AGREEMENT_PATH]: OnboardingSignLeaseAgreement,
  [ONBOARDING_SIGN_LEASE_AGREEMEN_AMENDMENT_PATH]: OnboardingSignLeaseAgreementAmendment,
  [ONBOARDING_DRIVER_APPLICATION]: OnboardingDriverApplication,
  [ONBOARDING_EMPLOYMENT_HISTORY]: OnboardingEmploymentHistory,
  [ONBOARDING_EMPLOYMENT_HISTORY_ADD_FMCSR]: OnboardingEmploymentHistoryAddFmcsrValidation,
  [ONBOARDING_EMPLOYMENT_HISTORY_ADD]: OnboardingEmploymentHistoryAddPosition,
  [ONBOARDING_EMPLOYMENT_HISTORY_EDIT_FMCSR]: OnboardingEmploymentHistoryEditFmcsrValidation,
  [ONBOARDING_EMPLOYMENT_HISTORY_EDIT]: OnboardingEmploymentHistoryEditPosition,
  [ONBOARDING_EMPLOYMENT_SUBMIT]: OnboardingEmploymentHistorySubmitApplication,
  [ONBOARDING_INSURANCE_DOCUMENTS]: OnboardingInsuranceDocuments,
  [ONBOARDING_MAINTENANCE_SET_ASIDE]: OnboardingMaintenanceSetAside,
  [ONBOARDING_PSP_CONSENT]: OnboardingPspConsent,
  [ONBOARDING_DAC_CONSENT]: OnboardingDacConsent,
  [ONBOARDING_FUEL_CARD_PATH]: OnboardingFuelCard,
  [PLAID_REDIRECT]: PlaidRedirect,
  [MARKET_CONDITIONS_PATH]: MarketConditions,
  [SAFETY_DASHBOARD_PATH]: SafetyDashboard,
  [CANCEL_SUBSCRIPTION_PATH]: CancelSubscription,
  [REPORT_PATH]: Report,
}

export const vendorRoutes: RoutesType = {
  [VENDOR_DEDUCTIONS_PATH]: Deductions,
  [VENDOR_DEDUCTION_DETAIL_PATH]: DeductionDetail,
  [VENDOR_DEDUCTION_REFINANCE_PATH]: DeductionRefinance,
}

export const marketingRoutesPaths = Object.keys(marketingRoutes)
export const publicRoutesPaths = Object.keys(publicRoutes)
export const approvedRoutesPaths = Object.keys(approvedRoutes)
export const privateRoutesPaths = Object.keys(privateRoutes)
export const vendorRoutesPaths = Object.keys(vendorRoutes)

export const Routes = memo(() => {
  return (
    <Switch>
      <Route exact path={SIGN_IN_PATH} component={publicRoutes[SIGN_IN_PATH]} />
      <Route path={VERIFY_PATH} component={publicRoutes[VERIFY_PATH]} />
      <Redirect exact from="/registration/flex-starter" to="/registration/flex" />
      <Redirect exact from="/registration/starter" to="/registration/flex" />
      <Redirect exact from="/registration/cash" to="/registration/flex" />
      <Redirect exact from="/registration/credit" to="/registration" />
      <Route exact path="/registration" component={publicRoutes[REGISTRATION_PATH]} />
      <Route path={REGISTRATION_PATH} component={publicRoutes[REGISTRATION_PATH]} />
      <Redirect exact from="/profile/payment-information/bank-accout/" to={PAYMENT_INFORMATION_BANK_ACCOUNT_PROFILE_PATH} />
      <Route path={CONFIRM_INVITATION_PATH} component={publicRoutes[CONFIRM_INVITATION_PATH]} />
      <Route path={CONFIRM_FAILED_PATH} component={publicRoutes[CONFIRM_FAILED_PATH]} />
      <Route path={MOBILE_AUTH_PATH} component={publicRoutes[MOBILE_AUTH_PATH]} />
      <Route exact path={SELF_SERVE_BROKER_LOAD_TRACKING_LOOKUP_PATH} component={publicRoutes[SELF_SERVE_BROKER_LOAD_TRACKING_LOOKUP_PATH]} />
      <Route exact path={SELF_SERVE_BROKER_LOAD_TRACKING_PATH} component={publicRoutes[SELF_SERVE_BROKER_LOAD_TRACKING_PATH]} />
      <Route path={REVENUE_CALCULATOR} component={marketingRoutes[REVENUE_CALCULATOR]} />
      <ApprovedRoute path={SEARCH_RESULTS_PATH} component={approvedRoutes[SEARCH_RESULTS_PATH]} />
      <ApprovedRoute path={SEARCH_PATH} component={approvedRoutes[SEARCH_PATH]} />
      <ApprovedRoute path={SEARCH_ASYNC_RESULTS_PATH} component={approvedRoutes[SEARCH_ASYNC_RESULTS_PATH]} />
      <ApprovedRoute path={SEARCH_ASYNC_PATH} component={approvedRoutes[SEARCH_ASYNC_PATH]} />
      <ApprovedRoute path={SAVED_LOADS_PATH} component={approvedRoutes[SAVED_LOADS_PATH]} />
      <ApprovedRoute path={CONTACTED_LOADS_PATH} component={approvedRoutes[CONTACTED_LOADS_PATH]} />
      <ApprovedRoute path={EXTERNAL_BOOK_FROM_TEMPLATE_PATH} component={approvedRoutes[EXTERNAL_BOOK_FROM_TEMPLATE_PATH]} />
      <ApprovedRoute path={EXTERNAL_BOOK_PATH} component={approvedRoutes[EXTERNAL_BOOK_PATH]} />
      <ApprovedRoute path={JOBS_TEMPLATES_EDIT_PATH} component={approvedRoutes[JOBS_TEMPLATES_EDIT_PATH]} />
      <ApprovedRoute path={JOBS_TEMPLATES_NEW_PATH} component={approvedRoutes[JOBS_TEMPLATES_NEW_PATH]} />
      <ApprovedRoute path={JOBS_COPY_PATH} component={approvedRoutes[JOBS_COPY_PATH]} />
      <ApprovedRoute path={JOBS_DETAILS_CREATE_INVOICE_PATH} component={approvedRoutes[JOBS_DETAILS_CREATE_INVOICE_PATH]} />
      <ApprovedRoute path={JOBS_DETAILS_NOTIFY_BROKER_PATH} component={approvedRoutes[JOBS_DETAILS_NOTIFY_BROKER_PATH]} />
      <ApprovedRoute path={JOBS_DETAILS_NOTIFY_FACTORING_COMPANY_PATH} component={approvedRoutes[JOBS_DETAILS_NOTIFY_FACTORING_COMPANY_PATH]} />
      <ApprovedRoute path={JOBS_DETAILS_EDIT_PATH} component={approvedRoutes[JOBS_DETAILS_EDIT_PATH]} />
      <ApprovedRoute path={JOBS_DETAILS_PATH} component={approvedRoutes[JOBS_DETAILS_PATH]} />
      <ApprovedRoute path={JOBS_TRACKING_PATH} component={approvedRoutes[JOBS_TRACKING_PATH]} />
      <ApprovedRoute path={JOBS_PAST_PATH} component={approvedRoutes[JOBS_PAST_PATH]} />
      <ApprovedRoute path={JOBS_TEMPLATES_PATH} component={approvedRoutes[JOBS_TEMPLATES_PATH]} />
      <ApprovedRoute path={JOBS_PATH} component={approvedRoutes[JOBS_PATH]} />
      <ApprovedRoute path={LOADS_DETAILS_BOOK_PATH} component={approvedRoutes[LOADS_DETAILS_BOOK_PATH]} />
      <ApprovedRoute path={LOADS_DETAILS_PATH} component={approvedRoutes[LOADS_DETAILS_PATH]} />
      <Redirect exact from="/ct-cash/activity/" to={ACCOUNT_ACTIVITY_PATH} />
      <ApprovedRoute exact path={ACCOUNT_ACTIVITY_PATH} component={approvedRoutes[ACCOUNT_ACTIVITY_PATH]} />
      <ApprovedRoute exact path={CARDHOLDER_ACTIVITY} component={approvedRoutes[CARDHOLDER_ACTIVITY]} />
      <ApprovedRoute exact path={CARD_MANAGEMENT} component={approvedRoutes[CARD_MANAGEMENT]} />
      <ApprovedRoute exact path={CARD_MANAGEMENT_ADD_CARDHOLDER} component={approvedRoutes[CARD_MANAGEMENT_ADD_CARDHOLDER]} />
      <ApprovedRoute exact path={CARD_MANAGEMENT_CHANGE_WEEKLY_LIMIT} component={approvedRoutes[CARD_MANAGEMENT_CHANGE_WEEKLY_LIMIT]} />
      <ApprovedRoute exact path={CREDIT_PATH} component={approvedRoutes[CREDIT_PATH]} />

      <ApprovedRoute exact path={CONTRACTOR_PAYMENTS_PATH} component={approvedRoutes[CONTRACTOR_PAYMENTS_PATH]} />
      <ApprovedRoute exact path={CONTRACTOR_PAYMENTS_PAYROLL_BANK_ACCOUNT_PATH} component={approvedRoutes[CONTRACTOR_PAYMENTS_PAYROLL_BANK_ACCOUNT_PATH]} />
      <ApprovedRoute exact path={CONTRACTOR_PERSONAL_DIRECT_DEPOSIT_PATH} component={approvedRoutes[CONTRACTOR_PERSONAL_DIRECT_DEPOSIT_PATH]} />
      <ApprovedRoute exact path={CONTRACTOR_PERSONAL_PERSONAL_INFO_PATH} component={approvedRoutes[CONTRACTOR_PERSONAL_PERSONAL_INFO_PATH]} />
      <ApprovedRoute exact path={CONTRACTOR_PERSONAL_DEBIT_CARD_PATH} component={approvedRoutes[CONTRACTOR_PERSONAL_DEBIT_CARD_PATH]} />
      <ApprovedRoute exact path={CONTRACTOR_PAYMENTS_ADD_CONTRACTORS_PATH} component={approvedRoutes[CONTRACTOR_PAYMENTS_ADD_CONTRACTORS_PATH]} />
      <ApprovedRoute exact path={CONTRACTOR_PAYMENTS_CONTRACTORS_PATH} component={approvedRoutes[CONTRACTOR_PAYMENTS_CONTRACTORS_PATH]} />
      <ApprovedRoute exact path={CONTRACTOR_PAYMENTS_USER_PAY_PATH} component={approvedRoutes[CONTRACTOR_PAYMENTS_USER_PAY_PATH]} />
      <ApprovedRoute exact path={CONTRACTOR_PAYMENTS_PAY_PATH} component={approvedRoutes[CONTRACTOR_PAYMENTS_PAY_PATH]} />
      <ApprovedRoute exact path={CONTRACTOR_PAYMENTS_PERSONAL_SETTINGS_PATH} component={approvedRoutes[CONTRACTOR_PAYMENTS_PERSONAL_SETTINGS_PATH]} />
      <ApprovedRoute exact path={CONTRACTOR_PAYMENTS_INSTANT_PAYOUTS_ONBOARDING_PATH} component={approvedRoutes[CONTRACTOR_PAYMENTS_INSTANT_PAYOUTS_ONBOARDING_PATH]} />

      <PrivateRoute exact path={INDEX_PATH} component={privateRoutes[INDEX_PATH]} />
      <Redirect from={SIGN_UP_DRIVER_PATH} to={ADD_DRIVER_PATH} />
      <Redirect from={CONFIRM_INFO_PATH} to={ONBOARDING_TASK_LIST_PATH} />
      <PrivateRoute exact path={EDIT_USER_ROLE_PATH} component={privateRoutes[EDIT_USER_ROLE_PATH]} />
      <PrivateRoute exact path={EDIT_CURRENT_USER_ROLE_PATH} component={privateRoutes[EDIT_CURRENT_USER_ROLE_PATH]} />
      <PrivateRoute exact path={ADD_USER_PATH} component={privateRoutes[ADD_USER_PATH]} />
      <PrivateRoute exact path={ADD_ADMIN_PATH} component={privateRoutes[ADD_ADMIN_PATH]} />
      <PrivateRoute exact path={ADD_DRIVER_PATH} component={privateRoutes[ADD_DRIVER_PATH]} />
      <PrivateRoute exact path={ADD_ADMIN_DRIVER_PATH} component={privateRoutes[ADD_ADMIN_DRIVER_PATH]} />
      <PrivateRoute exact path={DEACTIVATE_USER_PATH} component={privateRoutes[DEACTIVATE_USER_PATH]} />
      <PrivateRoute exact path={PERMISSIONS_SETTINGS_PATH} component={privateRoutes[PERMISSIONS_SETTINGS_PATH]} />
      <Redirect from={TEAM_PATH} to={FLEET_PROFILE_PATH} />
      <PrivateRoute path={TEAM_PATH} component={privateRoutes[TEAM_PATH]} />
      <PrivateRoute path={USERS_PROFILE_PATH} component={privateRoutes[USERS_PROFILE_PATH]} />
      <PrivateRoute exact path={PROFILE_PATH} component={privateRoutes[USERS_PROFILE_PATH]} />
      <PrivateRoute exact path={FLEET_PROFILE_PATH} component={privateRoutes[FLEET_PROFILE_PATH]} />
      <PrivateRoute exact path={FLEET_MEMBERS_PROFILE_PATH} component={privateRoutes[FLEET_MEMBERS_PROFILE_PATH]} />
      <PrivateRoute exact path={EQUIPMENT_PROFILE_PATH} component={privateRoutes[EQUIPMENT_PROFILE_PATH]} />
      <PrivateRoute exact path={PROFILE_SUBSCRIPTION_PATH} component={privateRoutes[PROFILE_SUBSCRIPTION_PATH]} />
      <PrivateRoute exact path={PROFILE_SUBSCRIPTION_CHANGE_PAYMENT_PATH} component={privateRoutes[PROFILE_SUBSCRIPTION_CHANGE_PAYMENT_PATH]} />
      <PrivateRoute exact path={EQUIPMENT_PROFILE_PATH_ADD_TRUCK} component={privateRoutes[EQUIPMENT_PROFILE_PATH_ADD_TRUCK]} />
      <PrivateRoute exact path={EQUIPMENT_PROFILE_PATH_EDIT_TRUCK} component={privateRoutes[EQUIPMENT_PROFILE_PATH_EDIT_TRUCK]} />
      <PrivateRoute exact path={EQUIPMENT_PROFILE_PATH_ADD_TRAILER} component={privateRoutes[EQUIPMENT_PROFILE_PATH_ADD_TRAILER]} />
      <PrivateRoute exact path={EQUIPMENT_PROFILE_PATH_EDIT_TRAILER} component={privateRoutes[EQUIPMENT_PROFILE_PATH_EDIT_TRAILER]} />
      <PrivateRoute exact path={TRUCK_EQUIPMENT_PATH} component={privateRoutes[TRUCK_EQUIPMENT_PATH]} />
      <PrivateRoute exact path={TRAILER_EQUIPMENT_PATH} component={privateRoutes[TRAILER_EQUIPMENT_PATH]} />
      <PrivateRoute exact path={BROKERS_PROFILE_PATH} component={privateRoutes[BROKERS_PROFILE_PATH]} />
      <PrivateRoute exact path={PAYMENT_INFORMATION_PROFILE_PATH} component={privateRoutes[PAYMENT_INFORMATION_PROFILE_PATH]} />
      <PrivateRoute exact path={PAYMENT_INFORMATION_BANK_ACCOUNT_PROFILE_PATH} component={privateRoutes[PAYMENT_INFORMATION_BANK_ACCOUNT_PROFILE_PATH]} />
      <PrivateRoute exact path={PAYMENT_INFORMATION_BANK_ACCOUNT_ASSETS_PROFILE_PATH} component={privateRoutes[PAYMENT_INFORMATION_BANK_ACCOUNT_ASSETS_PROFILE_PATH]} />
      <PrivateRoute exact path={PAYMENT_INFORMATION_CONFIRM_CT_CASH_PROFILE_PATH} component={privateRoutes[PAYMENT_INFORMATION_CONFIRM_CT_CASH_PROFILE_PATH]} />

      <PrivateRoute path={CDL_UPLOAD_PATH} component={privateRoutes[CDL_UPLOAD_PATH]} />

      <PrivateRoute exact path={ONBOARDING_ADD_DRIVER_PATH} component={privateRoutes[ONBOARDING_ADD_DRIVER_PATH]} />
      <PrivateRoute exact path={ONBOARDING_ADD_ADMIN_DRIVER_PATH} component={privateRoutes[ONBOARDING_ADD_ADMIN_DRIVER_PATH]} />
      <PrivateRoute exact path={ONBOARDING_ADD_ADMIN_PATH} component={privateRoutes[ONBOARDING_ADD_ADMIN_PATH]} />
      <PrivateRoute exact path={ONBOARDING_ADD_USER_PATH} component={privateRoutes[ONBOARDING_ADD_USER_PATH]} />
      <PrivateRoute exact path={ONBOARDING_TASK_LIST_PATH} component={privateRoutes[ONBOARDING_TASK_LIST_PATH]} />
      <PrivateRoute exact path={ONBOARDING_USER_TASK_LIST_PATH} component={privateRoutes[ONBOARDING_USER_TASK_LIST_PATH]} />
      <PrivateRoute exact path={ONBOARDING_FLEET_APPLICATION_PATH} component={privateRoutes[ONBOARDING_FLEET_APPLICATION_PATH]} />
      <PrivateRoute exact path={ONBOARDING_FLEET_DETAILS_PATH} component={privateRoutes[ONBOARDING_FLEET_DETAILS_PATH]} />
      <PrivateRoute path={ONBOARDING_FLEX_SUBSCRIPTION_PATH} component={privateRoutes[ONBOARDING_FLEX_SUBSCRIPTION_PATH]} />

      <PrivateRoute exact path={ONBOARDING_TAX_INFO_PATH} component={privateRoutes[ONBOARDING_TAX_INFO_PATH]} />
      <PrivateRoute exact path={ONBOARDING_ELD_SETUP_PATH} component={privateRoutes[ONBOARDING_ELD_SETUP_PATH]} />
      <PrivateRoute exact path={ONBOARDING_EQUIPMENT_ADD_TRUCK_PATH} component={privateRoutes[ONBOARDING_EQUIPMENT_ADD_TRUCK_PATH]} />
      <PrivateRoute exact path={ONBOARDING_EQUIPMENT_EDIT_TRUCK_PATH} component={privateRoutes[ONBOARDING_EQUIPMENT_EDIT_TRUCK_PATH]} />
      <PrivateRoute exact path={ONBOARDING_EQUIPMENT_ADD_TRAILER_PATH} component={privateRoutes[ONBOARDING_EQUIPMENT_ADD_TRAILER_PATH]} />
      <PrivateRoute exact path={ONBOARDING_EQUIPMENT_EDIT_TRAILER_PATH} component={privateRoutes[ONBOARDING_EQUIPMENT_EDIT_TRAILER_PATH]} />
      <PrivateRoute exact path={ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH} component={privateRoutes[ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH]} />
      <PrivateRoute exact path={ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH_PLATES} component={privateRoutes[ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH_PLATES]} />
      <PrivateRoute exact path={ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH_DOT_INSPECTION} component={privateRoutes[ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH_DOT_INSPECTION]} />
      <PrivateRoute exact path={ONBOARDING_EQUIPMENT_TRAILER_DOCUMENTS_PATH} component={privateRoutes[ONBOARDING_EQUIPMENT_TRAILER_DOCUMENTS_PATH]} />
      <PrivateRoute exact path={ONBOARDING_BROKER_SETUP_PATH} component={privateRoutes[ONBOARDING_BROKER_SETUP_PATH]} />
      <PrivateRoute exact path={ONBOARDING_BANK_ACCOUNT_PATH} component={privateRoutes[ONBOARDING_BANK_ACCOUNT_PATH]} />
      <PrivateRoute exact path={ONBOARDING_CONFIRM_CT_CASH_PATH} component={privateRoutes[ONBOARDING_CONFIRM_CT_CASH_PATH]} />
      <PrivateRoute exact path={ONBOARDING_SIGN_LEASE_AGREEMENT_PATH} component={privateRoutes[ONBOARDING_SIGN_LEASE_AGREEMENT_PATH]} />
      <PrivateRoute exact path={ONBOARDING_SIGN_LEASE_AGREEMEN_AMENDMENT_PATH} component={privateRoutes[ONBOARDING_SIGN_LEASE_AGREEMEN_AMENDMENT_PATH]} />
      <PrivateRoute exact path={ONBOARDING_DRIVER_APPLICATION} component={privateRoutes[ONBOARDING_DRIVER_APPLICATION]} />
      <PrivateRoute exact path={ONBOARDING_EMPLOYMENT_HISTORY} component={privateRoutes[ONBOARDING_EMPLOYMENT_HISTORY]} />
      <PrivateRoute exact path={ONBOARDING_EMPLOYMENT_HISTORY_ADD_FMCSR} component={privateRoutes[ONBOARDING_EMPLOYMENT_HISTORY_ADD_FMCSR]} />
      <PrivateRoute exact path={ONBOARDING_EMPLOYMENT_HISTORY_ADD} component={privateRoutes[ONBOARDING_EMPLOYMENT_HISTORY_ADD]} />
      <PrivateRoute exact path={ONBOARDING_EMPLOYMENT_HISTORY_EDIT_FMCSR} component={privateRoutes[ONBOARDING_EMPLOYMENT_HISTORY_EDIT_FMCSR]} />
      <PrivateRoute exact path={ONBOARDING_EMPLOYMENT_HISTORY_EDIT} component={privateRoutes[ONBOARDING_EMPLOYMENT_HISTORY_EDIT]} />
      <PrivateRoute exact path={ONBOARDING_EMPLOYMENT_SUBMIT} component={privateRoutes[ONBOARDING_EMPLOYMENT_SUBMIT]} />
      <PrivateRoute exact path={ONBOARDING_INSURANCE_DOCUMENTS} component={privateRoutes[ONBOARDING_INSURANCE_DOCUMENTS]} />
      <PrivateRoute exact path={ONBOARDING_MAINTENANCE_SET_ASIDE} component={privateRoutes[ONBOARDING_MAINTENANCE_SET_ASIDE]} />
      <PrivateRoute exact path={ONBOARDING_PSP_CONSENT} component={privateRoutes[ONBOARDING_PSP_CONSENT]} />
      <PrivateRoute exact path={ONBOARDING_DAC_CONSENT} component={privateRoutes[ONBOARDING_DAC_CONSENT]} />
      <PrivateRoute exact path={ONBOARDING_FUEL_CARD_PATH} component={privateRoutes[ONBOARDING_FUEL_CARD_PATH]} />
      <PrivateRoute path={PLAID_REDIRECT} component={privateRoutes[PLAID_REDIRECT]} />
      <PrivateRoute path={MARKET_CONDITIONS_PATH} component={privateRoutes[MARKET_CONDITIONS_PATH]} />
      <PrivateRoute exact path={SAFETY_DASHBOARD_PATH} component={privateRoutes[SAFETY_DASHBOARD_PATH]} />
      <PrivateRoute exact path={CANCEL_SUBSCRIPTION_PATH} component={privateRoutes[CANCEL_SUBSCRIPTION_PATH]} />
      <PrivateRoute exact path={REPORT_PATH} component={privateRoutes[REPORT_PATH]} />

      <PrivateRoute exact path={VENDOR_DEDUCTIONS_PATH} component={vendorRoutes[VENDOR_DEDUCTIONS_PATH]} />
      <PrivateRoute exact path={VENDOR_DEDUCTION_DETAIL_PATH} component={vendorRoutes[VENDOR_DEDUCTION_DETAIL_PATH]} />
      <PrivateRoute exact path={VENDOR_DEDUCTION_REFINANCE_PATH} component={vendorRoutes[VENDOR_DEDUCTION_REFINANCE_PATH]} />

      <Route component={PageNotFound} />
      <Redirect from="*" to={SIGN_IN_PATH} />
    </Switch>
  )
})
