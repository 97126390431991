import { PageWrap, TextCentered } from 'shared/components/Base/Base'
import { Button } from 'shared/components/Button/Button'
import { Text } from 'shared/components/Text/Text'

import { RoadError } from '../../components/Icons/Icon'
import { INDEX_PATH } from '../../Routes'

export function PageNotFound() {
  return (
    <PageWrap>
      <TextCentered>
        <img src={RoadError} alt="" />
        <Text variant="header1" mb={2}>
          We can’t find the page you’re looking for.
        </Text>
        <Button variant="text" href={INDEX_PATH}>
          Go to Home Page
        </Button>
      </TextCentered>
    </PageWrap>
  )
}
