import { bgGray } from 'shared/theme/colors'
import { centeredContent } from 'shared/theme/mixins'
import { paragraph1 } from 'shared/theme/typography'
import styled from 'styled-components'

export const FormContainer = styled.form`
  ${centeredContent};
  position: relative;
  flex-direction: column;
  width: 648px;
  max-width: 100%;
  background: ${bgGray};
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 40px 72px 72px;
  margin: 0 20px;

  @media only screen and (max-width: 680px) {
    padding: 60px 20px 40px;
  }

  > button:first-child {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  > button:nth-child(2) {
    margin-top: 56px;
  }
`

export const Paragraph = styled.p`
  ${paragraph1}
  margin: 0;
  margin-bottom: 32px;
`
