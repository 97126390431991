import queryString from 'query-string'
import { useState } from 'react'
import { Button } from 'shared/components/Button/Button'
import { TaskStatusEnum } from 'shared/components/TaskList/TaskList'

import { useAstraUser } from '../../../hooks/query/useAstraUser'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { AstraUserStatus, AstraUserType, createAstraUser } from '../../../models/contractorPayments'
import { OnboardingChecklist, OnboardingSubChecklist } from '../../../models/onboarding'
import { useAstraVerification } from './useAstraVerification'

interface ContractorPaymentsBusinessOnboardingButtonProps {
  task: OnboardingChecklist | OnboardingSubChecklist
}

export function ContractorPaymentsBusinessOnboardingButton({
  task,
}: ContractorPaymentsBusinessOnboardingButtonProps) {
  const params = queryString.parse(window.location.search)
  const [isSetupLoading, setIsSetupLoading] = useState(false)
  const handleStartAstraVerification = useAstraVerification({
    setLoading: setIsSetupLoading,
    onSuccess: handleAstraCallback,
    userType: AstraUserType.BUSINESS,
  })
  const showErrorMessage = useErrorMessage()
  const { business, isLoading: isAstraUserLoading } = useAstraUser()

  async function handleAstraCallback(code: string) {
    if (task.status === TaskStatusEnum.COMPLETED) return
    setIsSetupLoading(true)
    try {
      // Remove the authorisation code from url if its there to prevent any race conditions
      window.history.pushState(undefined, '', `${window.location.origin}${window.location.pathname}`)
      await createAstraUser({ userType: AstraUserType.BUSINESS, authorizationCode: code })
      window.location.reload()
    } catch (err) {
      showErrorMessage(err)
    }
    setIsSetupLoading(false)
  }

  async function onAstraVerificationStart() {
    setIsSetupLoading(true)
    try {
      await createAstraUser({ userType: AstraUserType.BUSINESS })
      await handleStartAstraVerification()
    } catch (err) {
      showErrorMessage(err)
    }
    setIsSetupLoading(false)
  }

  function renderText() {
    if (task.status === TaskStatusEnum.NOT_STARTED) {
      return 'Start'
    }

    if (task.status === TaskStatusEnum.IN_PROGRESS) {
      return 'Continue'
    }

    if (business?.isRefreshTokenExpired) {
      return 'Start'
    }

    return 'See details'
  }

  if (isAstraUserLoading) return null

  if (business?.status === AstraUserStatus.PENDING && business?.userId && !params.show_cta) return null

  if (
    task.status === TaskStatusEnum.NOT_STARTED ||
    task.status === TaskStatusEnum.IN_PROGRESS ||
    (task.status === TaskStatusEnum.ERROR && business?.isRefreshTokenExpired)
  )
    return (
      <Button size="medium" variant="secondary" onClick={onAstraVerificationStart} isLoading={isSetupLoading}>
        {renderText()}
      </Button>
    )
  return null
}
