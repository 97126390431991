import * as React from 'react'
import { Button } from 'shared/components/Button/Button'
import { royalBlue } from 'shared/theme/colors'

import { isConsole } from '../../config/config'
import { SubTask, Task, TaskStatusEnum } from './TaskList'
import { StyledCta, StyledTextButton } from './TaskList.styles'

interface TaskListCtaProps {
  task: Task | SubTask
  isReadOnly?: boolean
}

export function TaskListCta({ task, isReadOnly }: TaskListCtaProps) {
  const hasCta = task.onCtaClick && task.ctaText
  const isDisabled = task.status === TaskStatusEnum.PREREQUISITES_NOT_MET
  const isCompleted = task.status === TaskStatusEnum.COMPLETED
  const isInProgress = task.status === TaskStatusEnum.IN_PROGRESS

  if (task.ctaRenderer && isConsole()) {
    return <StyledCta>{task.ctaRenderer}</StyledCta>
  }

  if (isDisabled || isReadOnly) return null

  if (task.ctaRenderer) {
    return <StyledCta>{task.ctaRenderer}</StyledCta>
  }

  if (!hasCta) return null

  function handleClick(e: React.MouseEvent) {
    e.stopPropagation()
    task.onCtaClick?.()
  }

  if (isCompleted || isInProgress) {
    return (
      <StyledCta>
        <StyledTextButton variant="paragraph2" fontColor={royalBlue} fontWeight={400} onClick={handleClick}>
          {task.ctaText}
        </StyledTextButton>
      </StyledCta>
    )
  }

  return (
    <StyledCta>
      <Button size="medium" variant="secondary" onClick={handleClick}>
        {task.ctaText}
      </Button>
    </StyledCta>
  )
}
