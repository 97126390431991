export const CONSULTANT_OPEN = 'CONSULTANT_OPEN'
export const CONSULTANT_CLOSE = 'CONSULTANT_CLOSE'

export interface ConsultantState {
  isOpen: boolean
}

let initState: ConsultantState = {
  isOpen: false,
}

export function consultantReducer(state = initState, action: { type: string }) {
  switch (action.type) {
    case CONSULTANT_OPEN:
      return {
        ...state,
        isOpen: true,
      }
    case CONSULTANT_CLOSE:
      return {
        ...state,
        isOpen: false,
      }
    default:
      return state || initState
  }
}
