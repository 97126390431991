import { ListUser } from '../../models/user'
import * as types from '../actions/users/actionTypes'

let initState: ListUser[] = []

export function teamMembersReducer(state = initState, action: any) {
  switch (action.type) {
    case types.SET_CURRENT_USER_INFO:
    case types.SET_USER_INFO:
    case types.SAVE_MEDICAL_CARD_DRIVER:
      return state.map((user) => (user.id === action.user.id ? action.user : user))
    case types.ADD_TEAM_MEMBER:
      return [...state, action.newMember]
    case types.SAVE_BACKGROUND_CHECK_STATUS_DRIVER:
      return state.map((user) =>
        user.id === action.userId
          ? {
              ...user,
              background_check_status: action.status,
            }
          : user
      )
    default:
      return state || initState
  }
}
