import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Handler, HandlerOf } from 'shared/helpers/typeHelper'
import { useBreakpoint } from 'shared/hooks/useBreakpoint'

import { isIntegrationTest } from '../../config/config'

interface LayoutContextType {
  toolbar: React.MutableRefObject<HTMLDivElement | null>
  toolbarHeight: number
  fixNavToTop: boolean
  setFixedNavToTop: HandlerOf<boolean>
  recalculateToolbarHeight: Handler
  isSideBarCollapsed: boolean
  setIsSideBarCollapsed: (collapse: boolean) => void
  pageWidth: number | null
  setPageWidth: (width: number | null) => void
  isFlowPage: boolean
  setIsFlowPage: (isFlowPage: boolean) => void
}

let sidebarCollapsedGlobalState = false

const defaultValue = {
  toolbar: {
    current: null,
  },
  toolbarHeight: 0,
  fixNavToTop: false,
  setFixedNavToTop: () => null,
  recalculateToolbarHeight: () => null,
  isSideBarCollapsed: false,
  setIsSideBarCollapsed: () => null,
  pageWidth: null,
  setPageWidth: () => null,
  isFlowPage: false,
  setIsFlowPage: () => null,
}

const LayoutContext = React.createContext<LayoutContextType>(defaultValue)

interface LayoutProviderProps {
  children: React.ReactNode
}

export function LayoutProvider(props: LayoutProviderProps) {
  const [toolbarHeight, setToolbarHeight] = useState(0)
  const [fixNavToTop, setFixedNavToTop] = useState(false)
  const [pageWidth, setPageWidth] = useState<number | null>(null)
  const [isFlowPage, setIsFlowPage] = useState(false)
  const isSmallBreakpoint = useBreakpoint('sm')
  const isMediumBreakpoint = useBreakpoint('md')
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(
    isSmallBreakpoint ? true : sidebarCollapsedGlobalState
  )
  const toolbarRef = useRef<HTMLDivElement | null>(null)

  const recalculateToolbarHeight = useCallback(() => {
    // parentElement as the toolbar ref is empty div that is nested in the toolbar component that can have more items inside
    const toolbarWrap = toolbarRef.current?.parentElement
    if (toolbarWrap && toolbarWrap?.offsetHeight !== toolbarHeight) {
      setToolbarHeight(toolbarWrap?.offsetHeight)
    }
  }, [toolbarHeight])

  useEffect(() => {
    recalculateToolbarHeight()
  })

  useEffect(() => {
    if (isMediumBreakpoint) {
      setIsSideBarCollapsed(isMediumBreakpoint)
    }
  }, [isMediumBreakpoint])

  useEffect(() => {
    sidebarCollapsedGlobalState = isSideBarCollapsed
  }, [isSideBarCollapsed])

  useEffect(() => {
    window.addEventListener('resize', recalculateToolbarHeight, { passive: true })
    return () => window.removeEventListener('resize', recalculateToolbarHeight)
  }, [recalculateToolbarHeight])

  return (
    <LayoutContext.Provider
      value={{
        toolbar: toolbarRef,
        fixNavToTop,
        setFixedNavToTop,
        toolbarHeight,
        recalculateToolbarHeight,
        isSideBarCollapsed,
        setIsSideBarCollapsed,
        pageWidth,
        setPageWidth,
        isFlowPage,
        setIsFlowPage,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  )
}

export const useLayoutContext = () => {
  return useContext(LayoutContext)
}

interface ToolbarPortalProps {
  children?: React.ReactNode
}

export function ToolbarPortal(props: ToolbarPortalProps) {
  const { toolbar, recalculateToolbarHeight } = useContext(LayoutContext)

  useEffect(() => {
    recalculateToolbarHeight()
    // Need to run just once after the mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!toolbar.current) return null
  return ReactDOM.createPortal(props.children, toolbar.current)
}

export const useFixedNav = () => {
  const { setFixedNavToTop } = useContext(LayoutContext)

  useLayoutEffect(() => {
    setFixedNavToTop(true)

    return () => setFixedNavToTop(false)
  }, [setFixedNavToTop])
}

export const useCollapsedSideBar = (isEnabled = true) => {
  const { setIsSideBarCollapsed } = useLayoutContext()

  useEffect(() => {
    setTimeout(() => (isEnabled ? setIsSideBarCollapsed(true) : null), 1)
  }, [setIsSideBarCollapsed, isEnabled])
}

export const useIsFullWidthPage = (value?: boolean) => {
  const { setIsFlowPage } = useLayoutContext()

  useEffect(() => {
    setIsFlowPage(value ?? true)
    return () => {
      setIsFlowPage(false)
    }
  }, [setIsFlowPage, value])
}

export const useIsFlowPage = (value?: boolean) => {
  useIsFullWidthPage(value)
  useCollapsedSideBar(!isIntegrationTest)
}

export enum PageWidth {
  XSMALL = 648,
  XSMALLER = 744,
  SMALL = 876,
  MEDIUM = 936,
  LARGE = 1096,
}

export const usePageWidth = (width: number | PageWidth | null) => {
  const { setPageWidth } = useLayoutContext()

  useEffect(() => {
    setPageWidth(width)
    return () => setPageWidth(null)
  }, [setPageWidth, width])
}
