import { Backdrop, Box, makeStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useEffect } from 'react'

import { Handler } from '../../helpers/typeHelper'
import { FlexBox } from '../Base/FlexBox'

interface SpinnerProps {
  open: boolean
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

export const Spinner = ({ open }: SpinnerProps) => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Box alignContent="center">
        <CircularProgress color="primary" />
      </Box>
    </Backdrop>
  )
}

interface InlineSpinnerProps {
  size?: string
  className?: string
  mt?: number
  mb?: number
  styles?: string
  color?: 'inherit' | 'primary' | 'secondary'
  executeOnLoad?: Handler
}

export const InlineSpinner = ({
  size,
  className,
  mt,
  mb,
  styles,
  color,
  executeOnLoad,
}: InlineSpinnerProps) => {
  useEffect(() => {
    executeOnLoad?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FlexBox
      alignItems="center"
      justifyContent="center"
      className={className}
      mt={mt}
      mb={mb}
      styles={styles}
    >
      <CircularProgress color={color || 'primary'} size={size || '2em'} />
    </FlexBox>
  )
}
