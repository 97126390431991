import React from 'react'

import { useBreakpoint } from '../../hooks/useBreakpoint'
import { Grid, InlineBlock, TextCentered, VerticalDivider } from '../Base/Base'

interface ButtonVerticalGroupProps {
  leftButton: React.ReactNode
  rightButton: React.ReactNode
  className?: string
  isStackedOnMobile?: boolean
}

export function ButtonVerticalGroup({
  className,
  leftButton,
  rightButton,
  isStackedOnMobile,
}: ButtonVerticalGroupProps) {
  const isMobile = useBreakpoint('sm')
  const isStacked = isMobile && isStackedOnMobile

  if (!rightButton) return <>{leftButton}</>
  if (!leftButton) return <>{rightButton}</>

  return (
    <TextCentered>
      <InlineBlock>
        <Grid
          columns="1fr 44px 1fr"
          smColumns={isStacked ? '1fr' : undefined}
          alignItems={isStacked && isMobile ? 'flex-start' : 'center'}
          gap={isStacked && isMobile ? '10px' : '0'}
          className={className}
        >
          {leftButton}
          {isMobile && isStacked ? null : (
            <TextCentered style={{ height: '24px' }}>
              <VerticalDivider />
            </TextCentered>
          )}
          {rightButton}
        </Grid>
      </InlineBlock>
    </TextCentered>
  )
}
