import { forwardRef, HTMLAttributes, Ref } from 'react'
import styled, { css, IStyledComponent } from 'styled-components'

import { AddPrefixToObject } from '../../helpers/typeHelper'
import { colorMappings, muiTheme } from '../../theme'
import * as TypographyStyles from '../../theme/typography'

export type TypographyVariant =
  | 'header0'
  | 'header1'
  | 'header2'
  | 'header3'
  | 'header4'
  | 'header5'
  | 'header6'
  | 'paragraph0'
  | 'paragraph1'
  | 'paragraph2'
  | 'paragraph3'
  | 'label1'
  | 'label2'
  | 'label3'
  | 'label4'
  | 'uppercaseLabel1'
  | 'uppercaseLabel2'
  | 'uppercaseLabel2noResponsive'
  | 'uppercaseLabel3'

export interface TextProps {
  variant: TypographyVariant
  fontColor?: string
  color?: string
  align?: string
  mt?: number
  mb?: number
  mr?: number
  ml?: number
  styles?: string
  fontWeight?: 300 | 400 | 500 | 600 | 700 | 800
  lineHeight?: number
}

export const StyledText = styled.div<AddPrefixToObject<TextProps, '$'>>`
  ${(p) => (TypographyStyles as any)[p.$variant]};

  @media all {
    ${(p) =>
      p.$fontColor &&
      css`
        color: ${colorMappings[p.$fontColor] || p.$fontColor};
      `};

    ${(p) =>
      p.$color &&
      css`
        color: ${colorMappings[p.$color] || p.$color};
      `};

    ${(p) =>
      p.$fontWeight &&
      css`
        font-weight: ${p.$fontWeight};
      `};

    ${(p) =>
      p.$lineHeight &&
      css`
        line-height: ${p.$lineHeight};
      `};

    ${(p) =>
      p.$align &&
      css`
        text-align: ${p.$align};
      `};

    ${(p) =>
      p.$mt &&
      css`
        margin-top: ${muiTheme.spacing() * p.$mt}px;
      `};

    ${(p) =>
      p.$mb &&
      css`
        margin-bottom: ${muiTheme.spacing() * p.$mb}px;
      `};

    ${(p) =>
      p.$mr &&
      css`
        margin-right: ${muiTheme.spacing() * p.$mr}px;
      `};

    ${(p) =>
      p.$ml &&
      css`
        margin-left: ${muiTheme.spacing() * p.$ml}px;
      `};

    ${(p) =>
      p.onClick &&
      css`
        cursor: pointer;
      `};

    ${(p) => p.$styles};
  }
`

export const Text = forwardRef(
  (
    { variant, fontColor, align, mt, mb, mr, ml, styles, fontWeight, lineHeight, color, ...rest },
    ref: Ref<any>
  ) => {
    return (
      <StyledText
        $variant={variant}
        $fontColor={fontColor}
        $color={color}
        $align={align}
        $mt={mt}
        $mb={mb}
        $mr={mr}
        $ml={ml}
        $styles={styles}
        $fontWeight={fontWeight}
        $lineHeight={lineHeight}
        ref={ref}
        {...rest}
      />
    )
  }
) as IStyledComponent<'web', TextProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>
