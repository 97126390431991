import { Grid, List } from 'shared/components/Base/Base'
import { Button } from 'shared/components/Button/Button'
import { Text } from 'shared/components/Text/Text'
import { breakpoints } from 'shared/theme/breakpoints'
import { information, midGray, primaryBlack } from 'shared/theme/colors'
import styled from 'styled-components'

interface ConsultantConversationExamplesProps {
  questionExamples: string[]
  onMessage: (value: string) => Promise<void>
}

export function ConsultantConversationExamples({
  questionExamples,
  onMessage,
}: ConsultantConversationExamplesProps) {
  return (
    <Wrap>
      <Text variant="uppercaseLabel3" fontColor={midGray}>
        Try questions like...
      </Text>

      <UL>
        {questionExamples.map((question, i) => (
          <li key={String(i)}>
            <Grid gap="10px" columns="1fr 80px">
              <Text variant="header3" fontColor={primaryBlack}>
                {question}
              </Text>
              <Button variant="secondary" size="small" onClick={() => onMessage(question)}>
                Ask Cai
              </Button>
            </Grid>
          </li>
        ))}
      </UL>
    </Wrap>
  )
}

const Wrap = styled.div`
  background: ${information};
  box-shadow: 0 1px 4px rgba(0, 44, 177, 0.05);
  border-radius: 6px;
  margin-bottom: 34px;
  padding: 32px;
  width: calc(100% - 52px);
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    bottom: -8px;
    left: 33px;
    background: ${information};
    transform: rotate(45deg);
  }

  @media ${breakpoints.sm} {
    padding: 20px;
  }
`

const UL = styled(List)`
  margin-bottom: 0;

  li + li {
    margin-top: 30px;
  }
`
